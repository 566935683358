import React from 'react'
import ReactDOM from 'react-dom'
import App from './App';
import * as Sentry from "@sentry/react";


if(import.meta.env.PROD) {
    Sentry.init({
        dsn: "https://d38800c6b8e04328aa99e42ec8ffcf79@us.sentry.io/4504955713290240",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/next\.holta\.kitchen/],
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.render(
  
  <App />,
  document.getElementById('app')
)
