
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setQuoteRef = (quoteRef: libTypes.Order['quoteRef']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.quoteRef = fns.order.set.quoteRef(quoteRef);
        })
    });
};