import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
    NoResults
} from "@holta/ui";
import { Header } from "./parts/Header";
import { ProductComponentsTable } from "./parts/ProductComponentsTable";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import {FiltersNotificationBar} from "../../components/FiltersNotificationBar";
import {clearAllProductComponentsFilters} from "../../actions/ui";
import {useProductComponentsFilterCount} from '../../hooks/useProductComponentsFilterCount';
import {useFilteredProductComponents} from '../../hooks/useFilteredProductComponents';

export const ProductComponents = () => {

    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useProductComponentsFilterCount();
    const productComponents = useFilteredProductComponents();

    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
                <div ref={filtersAnimationParent}>
                        {activeFilterCount > 0 && (
                            <Spacer xs={[0, 2, 2, 2]}>
                                <FiltersNotificationBar filtersCount={activeFilterCount} handleClear={clearAllProductComponentsFilters} />
                            </Spacer>
                        )}
                    </div>
            </div>
            <div style={{ flex: 1, overflow: "auto" }}>
                    {productComponents.length > 0 ? (
                        <ProductComponentsTable />
                    ) : (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <NoResults text="No product Product Components found." />
                        </Spacer>
                    )}
                </div>

        </Container>
    );
};
