import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";


export const setCarcaseItemCode = (productId: string) => {

        const editOrder = getEditOrder();
        const mergedOrder = getMergedOrder();
        const mergedOrderItem = getMergedOrderItem();
        const products = useStore.getState().products;
        const components = useStore.getState().productComponents;
        const product = products.find((p) => p.id === productId);


        if (!product) return;
        if (!mergedOrderItem || !fns.order_item.isCarcaseItem(mergedOrderItem)) return;

        useStore.setState({
            editOrder: create(editOrder, draft => {
                draft.newValues.orderItem = fns.order_item.createCarcaseItemFromProduct(
                    product,
                    components,
                    mergedOrder.discount,
                    mergedOrderItem
                );
            }),
        });
    };