import {feathersApp} from "../config/feathers";
import {libTypes} from "@holta/lib";


type Data = {
    type : "INVOICE", id: string
} | {
    type : "LABELS", id: string
} | {
    type : "WORKSHOP_ORDER", id: string
} | {
    type : "DELIVERY_NOTE", id: string
} | {
    type : "ORDER_CONFIRMATION", id: string
} | {
    type : "PURCHASE_ORDER", id: string, supplierId: string
} 

export function getPdfData(data: Data, callback: (blob : Blob) => void) {
    feathersApp
    .get("authentication")
    .then(({ accessToken }: any) => {
        fetch(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}/pdf?${new URLSearchParams(data)}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res: any) => {
                res.blob().then((blob: any) => {
                    callback(blob);
                });
            })
            .catch((err) => {
                console.log(err);
            });
    });
}


