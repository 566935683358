import { libTypes, staticData, fns } from "@holta/lib";
import { Grid, Tooltip } from "@holta/ui";
import React from "react";
import styled from "styled-components";

interface Props {
    orders: libTypes.Order[];
    children?: React.ReactNode;
}

export const ProductionStatusBar: React.FC<Props> = ({ orders }) => {
    return (
        <Wrapper>
            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.PROVISIONAL.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.PROVISIONAL.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.PROVISIONAL.key
                        )}
                        status={staticData.productionStatuses.PROVISIONAL.key}
                    />
                </Tooltip>
            )}

            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.IN_PRODUCTION.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.IN_PRODUCTION.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.IN_PRODUCTION.key
                        )}
                        status={staticData.productionStatuses.IN_PRODUCTION.key}
                    />
                </Tooltip>
            )}
            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.CUT.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.CUT.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.CUT.key
                        )}
                        status={staticData.productionStatuses.CUT.key}
                    />
                </Tooltip>
            )}
            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.EDGED.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.EDGED.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.EDGED.key
                        )}
                        status={staticData.productionStatuses.EDGED.key}
                    />
                </Tooltip>
            )}
            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.MACHINED.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.MACHINED.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.MACHINED.key
                        )}
                        status={staticData.productionStatuses.MACHINED.key}
                    />
                </Tooltip>
            )}

            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.BUILT.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.BUILT.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.BUILT.key
                        )}
                        status={staticData.productionStatuses.BUILT.key}
                    />
                </Tooltip>
            )}

            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.COMPLETE.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.COMPLETE.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.COMPLETE.key
                        )}
                        status={staticData.productionStatuses.COMPLETE.key}
                    />
                </Tooltip>
            )}

            {fns.orders.getProductionStatusPercentage(
                orders,
                staticData.productionStatuses.DELIVERED.key
            ) > 0 && (
                <Tooltip label={staticData.productionStatuses.DELIVERED.label}>
                    <Section
                        width={fns.orders.getProductionStatusPercentage(
                            orders,
                            staticData.productionStatuses.DELIVERED.key
                        )}
                        status={staticData.productionStatuses.DELIVERED.key}
                    />
                </Tooltip>
            )}
        </Wrapper>
    );
};

const Section = styled.div<{ width: number; status: libTypes.ProductionStatus }>`
    height: ${(p) => p.theme.spacing(1)};
    width: ${(p) => p.width}%;
    background-color: ${(p) =>
        (p.theme.color as { [key: string]: string })["production_" + p.status]};
`;

const Wrapper = styled.div`
    display: flex;
    border-radius: 1000px;
    overflow: hidden;
    gap: 2px;
`;
