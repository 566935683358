import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
    NoResults
} from "@holta/ui";
import { Header } from "./parts/Header";
import { CategoriesTable } from "./parts/CategoriesTable";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import {FiltersNotificationBar} from "../../components/FiltersNotificationBar";
import {clearAllCategoriesFilters} from "../../actions/ui";
import {useCategoriesFilterCount} from '../../hooks/useCategoriesFilterCount';
import { useFilteredCategories } from "../../hooks/useFilteredCategories";

export const Categories = () => {

    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useCategoriesFilterCount();
    const categories = useFilteredCategories();

    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
                <div ref={filtersAnimationParent}>
                        {activeFilterCount > 0 && (
                            <Spacer xs={[0, 2, 2, 2]}>
                                <FiltersNotificationBar filtersCount={activeFilterCount} handleClear={clearAllCategoriesFilters} />
                            </Spacer>
                        )}
                    </div>
            </div>
            <div style={{ flex: 1, overflow: "auto" }}>
                    {categories.length > 0 ? (
                        <CategoriesTable />
                    ) : (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <NoResults text="No categories found." />
                        </Spacer>
                    )}
                </div>

            <Modal id="ORDERS_FILTER" sideBar>
                <>
                    <ModalHeader>Apply Filters</ModalHeader>
                    <ModalBody>
                        <Spacer xs={[2]}>
                            <FormLabel>Quote Ref</FormLabel>
                            <FormInput />
                        </Spacer>
                    </ModalBody>
                </>
            </Modal>
        </Container>
    );
};
