import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

interface Props {}

const Row = ({style} : {style:React.CSSProperties}) => (
    <Tr style={style}>
        <Td colspan={2} align="c">
           <FormCheckbox />
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Ellipsis tooltipText="asdaa sdaasda asdaasda">
                <Text size="m">asdaa sdaasda asdaasda</Text>
            </Ellipsis>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={8}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={6}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
    </Tr>
);

export const CustomersTable: React.FC<Props> = () => {
    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={48}>
                <Thead>
                    <Tr>
                        <Th colspan={2} align="c"></Th>
                        <Th colspan={2}>Prod</Th>
                        <Th colspan={4}>Date</Th>
                        <Th colspan={2}>Type</Th>
                        <Th colspan={2}>Acc</Th>
                        <Th colspan={4}>Quote No.</Th>
                        <Th colspan={4}>Order No.</Th>
                        <Th colspan={8}>Customer</Th>
                        <Th colspan={6}>Customer Ref.</Th>
                        <Th colspan={4}>Value</Th>
                        <Th colspan={4}>Prod Week</Th>
                        <Th colspan={4}>Del Date</Th>
                        <Th colspan={2}></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List height={height - 2} itemCount={1000} itemSize={46} width={width - 1} overscanCount={30}>
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
