
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";



export const createSupplierItem = (supplierItem: libTypes.SupplierComputedProduct) => {
    const mergedOrder = getMergedOrder();
    const editOrder = getEditOrder();

    const newOrderItem = fns.order_item.createSupplierOrderItem(supplierItem, mergedOrder.supplierDiscount);
    

    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.order.items = [
                ...(editOrder.newValues.order.items || []),
                newOrderItem,
            ];
        }),
    });
}

