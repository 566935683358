import { libTypes } from "@holta/lib";
import React from "react";
import { useStore } from "../../hooks/useStore";

export const CustomersContext = React.createContext(undefined);

export const useCustomersContext = () => {
    const context = React.useContext(CustomersContext);
    if (context === undefined) {
        throw new Error("useCustomersContext must be within the correct provider");
    }
    return context;
};

export const useContextValue = () => {
    
};
