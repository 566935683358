import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
import { useStore } from "../../../hooks/useStore";
import {setProductComponentsSearchFilter} from '../../../actions/ui';
import {useModalProductComponentContext} from '../../../components/ModalProductComponent/context';
import { fns } from "@holta/lib";
import {user} from '../../../fns/user';
import {saveProductComponent} from '../../../actions/productComponents';
interface Props {}

export const Header: React.FC<Props> = () => {

    const { openModalProductComponent } = useModalProductComponentContext();

    const searchFilter = useStore(store => store.ui.productComponents.searchFilter);

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Product Components</Text>
                </Grid.Col>
                <Grid.Col>
                    <button className="primary" onClick={() => openModalProductComponent(fns.productComponent.create(user.id as string), true, saveProductComponent, () => {})}>New Component</button>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormInput placeholder="Search..." value={searchFilter} onChange={e => setProductComponentsSearchFilter(e.target.value)}/>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
 