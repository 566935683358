
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setDeliveryAddress = (address: libTypes.Address) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.deliveryAddress = fns.order.set.deliveryAddress(address);
        })
    });
};



