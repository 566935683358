import {
    Container,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    IconButton,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
} from "@holta/ui";
import { fns } from "@holta/lib";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { useOrderContext } from "../context";

export const OrderTotals = () => {
    const editOrder = useMergedEditOrder();
    const { modalPayment } = useOrderContext();
    if (!editOrder) return null;

    return (
        <>
            <hr />
            <Container xl="fixed">
                <Spacer xs={[3, 1]}>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6, offset: 6 }}>
                            <Spacer xs={[1]}>
                                <Island>
                                    <Text size="xl" bold>
                                        Totals
                                    </Text>
                                    <Spacer xs={[1, 0]}>
                                    </Spacer>
                                    <Grid.Row xs={{ justify: "space-between" }}>
                                        <Text size="m" bold>
                                            Carcases
                                        </Text>
                                    </Grid.Row>
                                    <Spacer xs={[1, 0]}>
                                        <hr />
                                    </Spacer>
                                    <Grid.Row xs={{ justify: "space-between" }}>
                                        <Text size="s">SubTotal</Text>
                                        <Text size="s" bold>
                                            {fns.shared.formatPrice(fns.order_items.getCarcaseItemsTotal(editOrder.items))}
                                        </Text>
                                    </Grid.Row>
                                    <Spacer xs={[1, 0]}>
                                        <hr />
                                    </Spacer>
                                    <Spacer xs={[1, 0]}>
                                    </Spacer>

                                    { (editOrder.hasDoors || Number(fns.order_items.getManuallyAddedSupplierItemsTotal(editOrder.items)) > 0) && (
                                        <>
                                            <Grid.Row xs={{ justify: "space-between" }}>
                                                <Text size="m" bold>
                                                    Supplier Products
                                                </Text>
                                            </Grid.Row>
                                            <Spacer xs={[1, 0]}>
                                                <hr />
                                            </Spacer>
                                            <Grid.Row xs={{ justify: "space-between" }}>
                                                <Text size="s">SubTotal</Text>
                                                <Text size="s" bold>
                                                    {editOrder.hasDoors && fns.shared.formatPrice(fns.order_items.getAllSupplierItemsTotal(editOrder.items))}
                                                    {!editOrder.hasDoors && fns.shared.formatPrice(fns.order_items.getManuallyAddedSupplierItemsTotal(editOrder.items))}
                                                </Text>
                                            </Grid.Row>
                                            <Spacer xs={[1, 0]}>
                                        <hr />
                                    </Spacer>
                                    <Spacer xs={[1, 0]}>
                                    </Spacer>

                            
                                        </>
                                    )}
                                    
                                    <Grid.Row xs={{ justify: "space-between" }}>
                                                <Text size="s">Tax</Text>
                                                <Text size="s" bold>
                                                    {fns.shared.formatPrice(fns.order.getTaxPrice(editOrder))}
                                                </Text>
                                            </Grid.Row>
                                            <Spacer xs={[1, 0]}>
                                                <hr />
                                            </Spacer>
                                            <Grid.Row xs={{ justify: "space-between" }}>
                                                <Text size="m" bold>
                                                    Total
                                                </Text>
                                                <Text size="m" bold>
                                                    {fns.shared.formatPrice(fns.order.getTotal(editOrder))}
                                                </Text>
                                            </Grid.Row>


                                    <Spacer xs={[2, 0]}></Spacer>
                                    <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                                        <Text size="l" bold>
                                            Payments
                                        </Text>
                                        <IconButton
                                            icon="add"
                                            onClick={() => modalPayment.openModal()}
                                        >
                                            <Text size="s" bold>
                                                Add Payment
                                            </Text>
                                        </IconButton>
                                    </Grid.Row>
                                    <Spacer xs={[1, 0]}>
                                        <hr />
                                    </Spacer>
                                    {editOrder.payments.map((payment, index) => (
                                        <div key={payment.id}>
                                        <Grid.Row xs={{ justify: "space-between" }} >
                                            <Text size="m">
                                            {payment?.label} - 
                                                {fns.shared.formatDate(payment.date)} 
                                            </Text>
                                            <div>
                                            <Text size="m" bold color="confirm">£{payment.amount}</Text>&nbsp;&nbsp;<Text color="highlight" bold>
                                                    <span
                                                        onClick={() =>
                                                            modalPayment.openModal(payment)
                                                        }
                                                    >
                                                        Edit
                                                    </span>
                                                </Text></div>
                                        </Grid.Row>
                                        <Spacer xs={[1, 0]}>
                                        <hr />
                                        </Spacer>
                                        </div>
                                    ))}

                                    <Spacer xs={[2, 0]}></Spacer>

                                    <Grid.Row xs={{ justify: "space-between" }}>
                                        <Text size="l" bold>
                                            Total Due
                                        </Text>
                                        <Text size="m" bold>
                                        {fns.shared.formatPrice(fns.order.getRemainingBalance(editOrder))}
                                        </Text>
                                    </Grid.Row>
                                </Island>
                            </Spacer>
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
            </Container>
        </>
    );
};
