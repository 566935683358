
import {Icon, ModalHeader, useModal} from '@holta/ui'

import React from 'react';
import {useSupplierProductBrowserContext} from '../context';


export const Header = () => {
    const { closeModal } = useModal();
    const { modalId } = useSupplierProductBrowserContext();

    return (
        <>
            <ModalHeader secondary>
                Choose product
                <Icon
                    style={{ cursor: "pointer" }}
                    name="close"
                    onClick={() => closeModal(modalId)}
                />
            </ModalHeader>
            <hr />
        </>
    );
};
