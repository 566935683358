import {
    FormInput,
    FormLabel,
    Grid,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    Icon,
    NoResults,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Ellipsis,
    useModal,
    VirtualisedTable
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { user } from "../../../fns/user";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";
import { useMergedAddresses } from "../hooks/useMergedAddresses";
import { saveAddress } from "../../../actions/editSupplier";
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import { fns, libTypes } from "@holta/lib";
import { useMergedSingleProducts } from "../hooks/useMergedSingleProducts";
import { useModalSupplierProductImport } from "../../../components/ModalSupplierProductImport/context";
import { useMergedBaseProducts } from "../hooks/useMergedBaseProducts";
import { useMergedRanges } from "../hooks/useMergedRanges";

interface Props {
    children?: React.ReactNode;
}

export const Products: React.FC<Props> = ({ children }) => {
    const { openModalAddress } = useModalAddressContext();
    const supplier = useMergedSupplier();
    const products = useMergedSingleProducts();
    const { openModalSupplierProductImport } = useModalSupplierProductImport();

    if (!supplier || !user.id) {
        return null;
    }
    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}>
            <Spacer xs={[2]}  style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Text size="xl">Products</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <button
                            className="primary"
                            onClick={() => {
                                openModalSupplierProductImport(supplier as libTypes.Supplier);
                            }}
                        >
                            Import Products
                        </button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1]}></Spacer>

                {products.length > 0 ? (
                    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <Table cols={15.5}>
                            <Thead>
                                <Tr>
                                    <Th colspan={3}>Code</Th>
                                    <Th colspan={5}>Description</Th>
                                    <Th colspan={1.5}>Width</Th>
                                    <Th colspan={1.5}>Height</Th>
                                    <Th colspan={1.5}>Depth</Th>
                                    <Th colspan={1.5}>List Price</Th>
                                    <Th colspan={1.5}>Sale Price</Th>
                                </Tr>
                            </Thead>
                            <Tbody style={{ flex: 1 }}>
                                <VirtualisedTable Row={Row} items={products} />
                            </Tbody>
                        </Table>
                    </div>
                ) : (
                    <Spacer xs={[0, 2, 2, 2]}>
                        <NoResults text="No Products" />
                    </Spacer>
                )}
            </Spacer>
        </div>
    );
};


export const Row = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: libTypes.SupplierProduct[];
}) => {
    const product = data[index];
    const supplier = useMergedSupplier();
    const baseProducts = useMergedBaseProducts();
    const ranges = useMergedRanges();

    const baseProduct = baseProducts.find((bp) => bp.id === product.baseProductId);
    const range = ranges.find((r) => r.id === product.supplierRangeId);

    return (
        <Tr style={style}>
            <Td colspan={3}>
                <Ellipsis tooltipText={product.code}>
                    <Text size="m">{product.code}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={5}>
                <Ellipsis tooltipText={product.description}>
                    <Text size="m">{product.description}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.w}</Text>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.h}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.d}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.price}</Text>
            </Td>
            <Td colspan={1.5} align="c">
                {" "}
                <Text size="m">
                    {baseProduct ? fns.supplierProduct.getSupplierProductPrice(product, baseProduct, supplier as libTypes.Supplier, range) : "No Base Product"}
                </Text>
            </Td>
        </Tr>
    );
}; 