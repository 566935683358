import React from "react";
import { feathersApp } from "../config/feathers";
import { setProductComponents } from "../actions/productComponents";
import { libTypes, constants } from "@holta/lib";

export const useLoadProductComponents = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);

    if (!hasRun.current) {
        function subscribeToProductComponents() {

         return feathersApp
            .service("product-components")
            .watch()
            .find({
                query: {
                    $limit: 10000,
                    $sort: {
                        code: 1,
                    },
                }
            })
            .subscribe(({ data }: { data: libTypes.ProductComponent[] }) => {
                setProductComponents(data);
                setIsLoaded(true);
            });
        }
        let productsSub = subscribeToProductComponents()
        hasRun.current = true;
        feathersApp.on(constants.EVENT_RELOAD_PRODUCT_COMPONENTS, () => {
            productsSub.unsubscribe();
            productsSub = subscribeToProductComponents()
        });
    }
    return isLoaded;
};


