import {
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Text,
    useDialog,
} from "@holta/ui";
import React from "react";
import { useModalProductContext } from "./context";
import { useStore } from "../../hooks/useStore";
import { deleteProduct } from "../../actions/products";
import { LinkProductsTab } from "./parts/LinkProductsTab";
import { CustomerAccessTab } from "./parts/CustomerAccessTab";
import { ComponentPricingTab } from "./parts/ComponentsPricingTab";
import { DetailsTab } from "./parts/DetailsTab";
import { SettingsTab } from "./parts/SettingsTab";

interface Props {
    children?: React.ReactNode;
}

export const ModalProduct: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        isEdited,
        isNew,
        isEditable,
        setIsEditable,
        cancel,
        confirm,
    } = useModalProductContext();


    if (!product) {
        return null;
    }


    return (
        <Modal id="MODAL_PRODUCT" style={{ width: "800px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Product</Grid.Row>
                    </div>
                    {/* TODO: Enable edit mode */}
                    {false && !isNew && !isEdited && (
                        <div onClick={() => setIsEditable(!isEditable)}>
                            <Text size="s" color="base_contrast">
                                {isEditable ? "CANCEL EDITING" : "EDIT"}
                            </Text>
                        </div>
                    )}
                </ModalHeader>
                <ModalSubHeader style={{ padding: 0 }}>
                    <Grid.Row>
                        <Spacer xs={[0, 2]}>
                            <Tabs height={6}>
                                <Tab
                                    height={6}
                                    isSelected={tab === 0}
                                    onClick={() => setTab(0)}
                                    inline
                                >
                                    Details
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 1}
                                    inline
                                    onClick={() => setTab(1)}
                                >
                                    Components & Pricing
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 2}
                                    inline
                                    onClick={() => setTab(2)}
                                >
                                    Linked Products
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 3}
                                    inline
                                    onClick={() => setTab(3)}
                                >
                                    Customer Access
                                </Tab>
                                { !isNew && (<Tab
                                    height={6}
                                    isSelected={tab === 4}
                                    inline
                                    onClick={() => setTab(4)}
                                >
                                    Settings
                                </Tab>) }
                            </Tabs>
                        </Spacer>
                    </Grid.Row>
                </ModalSubHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        {tab === 0 && <DetailsTab /> }
                        {tab === 1 && <ComponentPricingTab />}
                        {tab === 2 &&  <LinkProductsTab />}
                        {tab === 3 && <CustomerAccessTab />}
                        {tab === 4 && <SettingsTab />}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_PRODUCT")}> Ok</button>
                    )}

                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
