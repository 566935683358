import {
    FormValue,
    FormSelect,
    Grid,
    Icon,
    Spacer,
    Text,
    Table,
    Th,
    Td,
    Tr,
    Thead,
    Tbody,
    Ellipsis,
    Menu,
    MenuItem,
    VirtualisedTable,
    FormCheckbox,
    useDialog,
} from "@holta/ui";
import React from "react";
import { useModalSupplierProductImport } from "../context";
import { libTypes, fns } from "@holta/lib";
import { useModalSupplierRangeContext } from "../../../components/ModalSupplierRange/context";
import { useModalSupplierBaseProductContext } from "../../../components/ModalSupplierBaseProduct/context";

interface Props {
    children?: React.ReactNode;
}

export const UnmatchedProducts: React.FC<Props> = ({ children }) => {
    const { unmatchedProducts, supplier, saveRangeToDb, bulkMatchedUniqueProducts, toggleAllBulkMatchedUniqueProducts, saveBaseProductToDb } = useModalSupplierProductImport();
    const { openModalSupplierRange } = useModalSupplierRangeContext();
    const {openGenericDialog} = useDialog();

    if (!supplier) return null;
    return (
        <Spacer xs={[2]} style={{ height: "calc(100% - 170px)" }}>
            <div>
                <button
                    onClick={() => toggleAllBulkMatchedUniqueProducts()}>
                        Toggle All
                </button>
            </div>
            <div style={{ textAlign: "right" }}>
                <button
                    className="tertiary"
                    disabled={bulkMatchedUniqueProducts.length === 0}
                    onClick={() =>
                        openGenericDialog('Save current selection as unique products?', 'Save', true, true, () => saveBaseProductToDb(bulkMatchedUniqueProducts), true)
                    }
                >
                    Bulk add unique products
                </button>
                <button
                    className="tertiary"
                    onClick={() =>
                        openModalSupplierRange(
                            { ...fns.supplierRange.create(supplier.id as string) },
                            true,
                            saveRangeToDb
                        )
                    }
                >
                    Add New Range
                </button>
            </div>
            <Spacer xs={[1]}></Spacer>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                <Table cols={15.5}>
                    <Thead>
                        <Tr><Th colspan={1}></Th>
                            <Th colspan={3}>Code</Th>
                            <Th colspan={5}>Description</Th>
                            <Th colspan={1.5}>Width</Th>
                            <Th colspan={1.5}>Height</Th>
                            <Th colspan={1.5}>Depth</Th>
                            <Th colspan={1.5}>Price</Th>
                            <Th colspan={1.5}></Th>
                        </Tr>
                    </Thead>
                    <Tbody style={{ flex: 1 }}>
                        <VirtualisedTable Row={Row} items={unmatchedProducts.noMatch} />
                    </Tbody>
                </Table>
            </div>
        </Spacer>
    );
};

export const Row = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: libTypes.SupplierImportProduct[];
}) => {
    const product = data[index];
    const {
        supplier,
        saveBaseProductToDb,
        toggleBulkMatchedUniqueProduct,
        bulkMatchedUniqueProducts,
    } = useModalSupplierProductImport();
    const { openModalSupplierBaseProduct } = useModalSupplierBaseProductContext();

    if (!supplier) return null;

    return (
        <Tr style={style}>
            <Td colspan={1} align="c">
                <FormCheckbox
                    checked={bulkMatchedUniqueProducts.includes(product.code)}
                    onClick={() => toggleBulkMatchedUniqueProduct(product.code)}
                />
            </Td>
            <Td colspan={3}>
                <Ellipsis tooltipText={product.code}>
                    <Text size="m">{product.code}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={5}>
                <Ellipsis tooltipText={product.description}>
                    <Text size="m">{product.description}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.w}</Text>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.h}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.d}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.price}</Text>
            </Td>
            <Td colspan={1.5} align="c">
                <Menu portal={false} style={{ right: "-10px", top: "-10px", position: "absolute" }}>
                    <MenuItem
                        onClick={() =>
                            openModalSupplierBaseProduct(
                                {
                                    ...fns.supplierBaseProduct.create(supplier.id as string, {
                                        type: "UNIQUE",
                                        code: product.code,
                                    }),
                                },
                                true,
                                saveBaseProductToDb,
                                true
                            )
                        }
                    >
                        Add as Unique Product
                    </MenuItem>
                </Menu>
            </Td>
        </Tr>
    );
};
