import { FormInput, FormLabel, FormNotice, Island, Spacer, useDialog, useModal } from '@holta/ui';
import { useStore } from '../../../hooks/useStore';
import React from 'react';

import { useModalSupplierRangeContext } from '../context';

interface Props {
    children?: React.ReactNode;
}

export const DetailsTab: React.FC<Props> = () => {

    const {
        supplierRange,
        setName,
        setPrefix,
        validationResult,
    } = useModalSupplierRangeContext();

    if (!supplierRange) {
        return null;
    }
    return (
        <Island>
            <Spacer xs={[1, 0]}>
                <FormLabel>Name</FormLabel>
                <FormInput
                    value={supplierRange.name}
                    style={{ width: "100%" }}
                    onChange={(e) => setName(e.target.value)}
                />
                {validationResult && !validationResult.name._isValid && (
                    <FormNotice>{validationResult.name._errors[0]}</FormNotice>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Prefix</FormLabel>
                <FormInput
                    value={supplierRange.prefix}
                    style={{ width: "100%" }}
                    onChange={(e) => setPrefix(e.target.value)}
                />
                {validationResult && !validationResult.prefix._isValid && (
                    <FormNotice>{validationResult.prefix._errors[0]}</FormNotice>
                )}
            </Spacer>
        </Island>
    );
};
