import { fns, libTypes, staticData } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { feathersApp } from "../../config/feathers";
import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";

export const _customerId = ({
    customer,
    invoiceAddress,
    deliveryAddress,
    updateDiscount,
    updateTaxValue,
    updateSupplierDiscount,
    updateItemDiscounts,
    updateInvoiceAddress,
    updateDeliveryAddress,
}: {
    customer: libTypes.Customer;
    deliveryAddress?: libTypes.AddressFields;
    invoiceAddress?: libTypes.AddressFields;
    updateDiscount?: boolean;
    updateTaxValue?: boolean;
    updateSupplierDiscount?: boolean;
    updateItemDiscounts?: boolean;
    updateInvoiceAddress?: boolean;
    updateDeliveryAddress?: boolean;
}) => {
    
    
    
    function doUpdateCustomerId() {
        const editOrder = getEditOrder();
        useStore.setState({
            editOrder: create(editOrder, (draft) => {
                draft.newValues.order.customerId = customer.id;
            }),
        });
    }

    function doUpdateDiscount() {
        const editOrder = getEditOrder();
        useStore.setState({
            editOrder: create(editOrder, (draft) => {
                draft.newValues.order.discount = customer.discount;
            }),
        });
    }

    function doUpdateTaxValue() {
        const editOrder = getEditOrder();
        useStore.setState({
            editOrder: create(editOrder, (draft) => {
                draft.newValues.order.taxValue =
                    staticData.taxGroups[customer.taxGroup]?.value || 0;
            }),
        });
    }

    function doUpdateSupplierDiscount() {
        const editOrder = getEditOrder();
        useStore.setState({
            editOrder: create(editOrder, (draft) => {
                draft.newValues.order.supplierDiscount = customer.supplierDiscount;
            }),
        });
    }

    function doUpdateInvoiceAddress() {
        const editOrder = getEditOrder();
        if (invoiceAddress) {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    draft.newValues.order.invoiceAddress = fns.address.createFields(invoiceAddress);
                }),
            });
        } else if (fns.address.hasFieldValues(editOrder.currentValues.order.invoiceAddress)) {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    draft.newValues.order.invoiceAddress = fns.address.createFields();
                }),
            });
        } else {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    delete draft.newValues.order.invoiceAddress;
                }),
            });
        }
    }

    function doUpdateDeliveryAddress() {
        const editOrder = getEditOrder();
        if (deliveryAddress) {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    draft.newValues.order.deliveryAddress =
                        fns.address.createFields(deliveryAddress);
                }),
            });
        } else if (fns.address.hasFieldValues(editOrder.currentValues.order.deliveryAddress)) {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    draft.newValues.order.deliveryAddress = fns.address.createFields();
                }),
            });
        } else {
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    delete draft.newValues.order.deliveryAddress;
                }),
            });
        }
    }

    function doUpdateItems() {
        const editOrder = getEditOrder();
        const mergedOrder = getMergedOrder();
        const dryRunOrderUpdate = fns.order.set.discount(customer.discount, mergedOrder, {
            updateItemDiscounts: true,
            returnUpdatesOnly: true,
        });

        if (dryRunOrderUpdate.items && dryRunOrderUpdate.items?.length > 0) {
            const updates = dryRunOrderUpdate.items.map((item) => {
                const itemInStore = editOrder.newValues.order.items?.find((i) => i.id === item.id);
                if (itemInStore)
                    return {
                        ...itemInStore,
                        ...item,
                    };
                return item;
            });
            useStore.setState({
                editOrder: create(editOrder, (draft) => {
                    draft.newValues.order.items = updates as libTypes.EditedOrderItem[];
                }),
            });
        }
    }

    doUpdateCustomerId();
    if (updateDiscount) doUpdateDiscount();
    if (updateTaxValue) doUpdateTaxValue();
    if (updateSupplierDiscount) doUpdateSupplierDiscount();
    if (updateItemDiscounts) doUpdateItems();
    if (updateInvoiceAddress) doUpdateInvoiceAddress();
    if (updateDeliveryAddress) doUpdateDeliveryAddress();
};

export const setCustomerId = async (
    customer: libTypes.Customer,
    warningFn: (
        cb: (
            updateDiscount: boolean,
            updateTaxValue: boolean,
            updateSupplierDiscount: boolean,
            updateItemDiscounts: boolean,
            updateInvoiceAddress: boolean,
            updateDeliveryAddress: boolean
        ) => void
    ) => void
) => {
    const addresses: { data: libTypes.Address[] } = await feathersApp.service("addresses").find({
        query: {
            parentId: customer.id,
        },
        paginate: false,
        $limit: 10000000,
    });

    const invoiceAddress = addresses.data.find((a) => a.id === customer.defaultInvoiceAddress);
    const deliveryAddress = addresses.data.find((a) => a.id === customer.defaultDeliveryAddress);

    const mergedOrder = getMergedOrder();
    if (!mergedOrder.customerId) {
        _customerId({
            customer,
            invoiceAddress,
            deliveryAddress: deliveryAddress,
            updateDiscount: true,
            updateTaxValue: true,
            updateSupplierDiscount: true,
            updateItemDiscounts: true,
            updateInvoiceAddress: !!invoiceAddress, // Only create an invoice address if the customer has a default one
            updateDeliveryAddress: !!deliveryAddress,
        });
    } else {
        warningFn(
            (
                updateDiscount: boolean,
                updateTaxValue: boolean,
                updateSupplierDiscount: boolean,
                updateItemDiscounts: boolean,
                updateInvoiceAddress: boolean,
                updateDeliveryAddress: boolean
            ) => {
                _customerId({
                    customer,
                    invoiceAddress,
                    deliveryAddress,
                    updateDiscount,
                    updateTaxValue,
                    updateSupplierDiscount,
                    updateItemDiscounts,
                    updateInvoiceAddress,
                    updateDeliveryAddress,
                });
            }
        );
    }
};
