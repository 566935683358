import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormNumber,
    FormCheckbox,
    FormTextarea,
    FormSelect,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Icon,
    TdInput,
    FormToggle,
    Ellipsis,
    Notice,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalProductContext } from "../context";
import { useStore } from "../../../hooks/useStore";
import { deleteProduct } from "../../../actions/products";
import { libTypes, staticData, fns } from "@holta/lib";
import { useModalProductComponentContext } from "../../ModalProductComponent/context";
import { user } from "../../../fns/user";
import { saveProductComponent } from "../../../actions/productComponents";

interface Props {
    children?: React.ReactNode;
}

export const ComponentPricingTab: React.FC<Props> = () => {
    const { closeModal, openModal } = useModal();
    const {openModalProductComponent} = useModalProductComponentContext();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        addComponent,
        removeComponent,
        updateComponent,
        toggleSoldByM2,
        setPrice,
        componentIdToAdd,
        setComponentIdToAdd,
        setComponentQtyToAdd,
        componentQtyToAdd,
    } = useModalProductContext();

    const productComponents = useStore((state) => state.productComponents);

    if (!product) {
        return null;
    }

    return (
        <>
            <Spacer xs={[0, 0, 1, 0]}>
                <Island>
                    <Spacer xs={[0, 0, 1, 0]}>
                        <Text size="xl">Components</Text>
                    </Spacer>
                    <Spacer xs={[1, 0]}>
                        <FormLabel>Add Component</FormLabel>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[0, 1, 0, 0]}>
                                    <FormSelect
                                        options={productComponents
                                            .filter(
                                                (pc) =>
                                                    !product.components.find(
                                                        (ppc) => ppc.id === pc.id
                                                    )
                                            )
                                            .map((component) => {
                                                return {
                                                    value: component.id,
                                                    label: component.code,
                                                };
                                            })}
                                        onChange={(option: { id: string; value: string }) => {
                                            setComponentIdToAdd(option.value);
                                        }}
                                        value={{
                                            id: componentIdToAdd,
                                            label:
                                                productComponents.find(
                                                    (pc) => pc.id === componentIdToAdd
                                                )?.code || "",
                                        }}
                                        onAddNew={() => openModalProductComponent(fns.productComponent.create(user.id!), true, (productComponent) => {saveProductComponent(productComponent); setComponentIdToAdd(productComponent.id);} )}
                                    />
                                </Spacer>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 3 }}>
                                <Spacer xs={[0, 1, 0, 0]}>
                                    <FormNumber
                                        style={{ width: "100%" }}
                                        value={componentQtyToAdd}
                                        onChange={(e) =>
                                            setComponentQtyToAdd(
                                                !isNaN(parseFloat(e.target.value))
                                                    ? parseFloat(e.target.value)
                                                    : 1
                                            )
                                        }
                                        isFloat
                                    ></FormNumber>
                                </Spacer>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 3 }}>
                                <button
                                    className="primary"
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                    disabled={!componentIdToAdd || !componentQtyToAdd}
                                    onClick={addComponent}
                                >
                                    Add
                                </button>
                            </Grid.Col>
                        </Grid.Row>
                        <Spacer xs={[1, 0]}>
                            <hr />
                        </Spacer>
                        {product.components.length > 0 && (
                            <>
                            <Table cols={18}>
                                <Thead>
                                    <Tr>
                                        <Th colspan={4}>Code</Th>
                                        <Th colspan={4}>Qty</Th>
                                        <Th colspan={4}>Unit Price</Th>
                                        <Th colspan={4}>Total</Th>
                                        <Th colspan={2}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {product.components.map((component, index) => {
                                        const foundComponent = productComponents.find(
                                            (pc) => pc.id === component.id
                                        );
                                        if (!foundComponent) return null;

                                        return (
                                            <Tr key={component.id}>
                                                <Td colspan={4}>{foundComponent.code}</Td>

                                                <TdInput
                                                    colspan={4}
                                                    value={component.qty}
                                                    component={FormNumber}
                                                    isFloat
                                                    onChange={(e) => {
                                                        !isNaN(
                                                            parseFloat(
                                                                (e.target as HTMLInputElement).value
                                                            )
                                                        ) &&
                                                            updateComponent(index, {
                                                                qty: parseFloat(
                                                                    (e.target as HTMLInputElement)
                                                                        .value
                                                                ),
                                                                id: component.id,
                                                            });
                                                    }}
                                                />
                                                <Td colspan={4}>
                                                    <Ellipsis
                                                        tooltipText={String(
                                                            fns.productComponent.getPrice(
                                                                foundComponent
                                                            )
                                                        )}
                                                    >
                                                        {fns.productComponent.getPrice(
                                                            foundComponent
                                                        )}
                                                    </Ellipsis>
                                                </Td>
                                                <Td colspan={4}>
                                                    <Ellipsis
                                                        tooltipText={String(
                                                            fns.shared.getQuantityPrice(
                                                                fns.productComponent.getPrice(
                                                                    foundComponent
                                                                ),
                                                                component.qty
                                                            )
                                                        )}
                                                    >
                                                        {fns.shared.getQuantityPrice(
                                                            fns.productComponent.getPrice(
                                                                foundComponent
                                                            ),
                                                            component.qty
                                                        )}
                                                    </Ellipsis>
                                                </Td>
                                                <Td colspan={2} align="c">
                                                    <Icon
                                                        name="delete-bin"
                                                        onClick={() => removeComponent(index)}
                                                    ></Icon>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                            <Spacer xs={[1, 0]}></Spacer>
                            <Notice color="accent1">
                            <Text size="m" color="text">Components total: <Text bold size="m" color="text">£{fns.product.getComponentsTotal(product, productComponents)}</Text></Text>
                            </Notice></>
                        )}
                    </Spacer>
                </Island>
            </Spacer>
            <Island>
                <Spacer xs={[0, 0, 1, 0]}>
                    <Text size="xl">Base Pricing</Text>
                </Spacer>
                <Grid.Row>
                    <Grid.Col xs={{ width: '100%' }}>
                        <Spacer xs={[0, 1, 0, 0]}>
                            <FormLabel>Price</FormLabel>
                            <FormNumber
                                style={{ width: "100%" }}
                                value={product.price}
                                onChange={(e) =>
                                    setPrice(
                                        !isNaN(parseFloat(e.target.value))
                                            ? parseFloat(e.target.value)
                                            : 0
                                    )
                                }
                                isFloat
                            ></FormNumber>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ width: 'auto' }}>
                        <Spacer xs={[0, 1, 0, 0]}>
                            <FormLabel>Sold by M2</FormLabel>
                            <FormToggle
                                checked={product.soldByM2}
                                onChange={toggleSoldByM2}
                            ></FormToggle>
                        </Spacer>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1, 0]}></Spacer>
                <Notice color="accent1">
                    <Text size="m" color="text">Total Price: <Text bold size="m" color="text">£{fns.product.getPrice(product, productComponents)}</Text></Text>
                </Notice>
            </Island>
        </>
    );
};
