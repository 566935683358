
import { libTypes } from "@holta/lib";
import { useDrop } from "react-dnd";
import { updateDeliveryDate } from "../../../actions/order";

export const DropZone = ({date, dateType, children} : {date : libTypes.Order['deliveryDate'], dateType: libTypes.Order['deliveryDateType'], children: React.ReactNode}) => {
    const [collectedProps, drop] = useDrop(() => ({
        accept: 'card',
        drop : (dragItem, monitor) => updateDeliveryDate((dragItem as libTypes.Order), date, dateType),
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            };
        },
    }), [date, dateType])

    const borderColor = collectedProps.canDrop && collectedProps.isOver ? "#4D4DDC4F" : "transparent";

    return (
        <div ref={drop} style={{ border: '2px solid', borderColor}}>
            {children}
        </div>
    )
}