import { fns } from '@holta/lib';
import {useStore} from './useStore';

export const useFilteredCustomers = () => {
    const {customers, ui} = useStore();
    let filteredCustomers = [...customers];

    filteredCustomers = fns.shared.filterOutArchived(filteredCustomers);

    if(ui.customers.sortField === 'name'){
        filteredCustomers = fns.customers.sortByName(filteredCustomers, ui.customers.sortDirection);
    }
    if(ui.customers.sortField === 'dateCreated'){
        filteredCustomers = fns.customers.sortByDateCreated(filteredCustomers, ui.customers.sortDirection);
    }
    if(ui.customers.searchFilter){
        filteredCustomers = fns.customers.filterByName(filteredCustomers, ui.customers.searchFilter);
    }

    return filteredCustomers;

}
