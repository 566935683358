import { Modal, ModalHeader, ModalBody, ModalFooter, Island, Spacer, useModal} from "@holta/ui"
import { fns } from "@holta/lib";
import { InputSupplierSelect } from "../../../components/InputSupplierSelect";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { getPdfData } from "../../../fns/getPdfData";
import React from "react";


export const ModalChooseSupplierPurchaseOrder = () => {
    

    const editOrder = useMergedEditOrder();
    const [selectedSupplier, setSelectedSupplier] = React.useState<string |null>(null);
    const {closeAll, closeModal} = useModal();

    const iframe = React.useRef<HTMLIFrameElement>(null);

    function pdfCallback(blob: Blob) {
        iframe.current!.src = window.URL.createObjectURL(blob);
        iframe.current!.onload = () => {
            iframe.current!.contentWindow!.print();
        };
    }


    if(!editOrder) return null;
    return (
        <>
         <iframe style={{ display: "none" }} ref={iframe}></iframe>
        <Modal id="MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER" style={{ width: "600px" }} onDismiss={() => {closeAll(); setSelectedSupplier(null)}}>
            <>
                <ModalHeader>
                    Choose Supplier
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                                <InputSupplierSelect onChange={setSelectedSupplier} value={selectedSupplier } filterIds={fns.order.getSupplierIds(editOrder)} />
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                <button
                            onClick={() => closeModal("MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER")}
                        >
                            Cancel
                        </button>
                        <Spacer xs={[0.5]} />
                    <button disabled={!selectedSupplier} className="primary" onClick={() => {
                       
                            getPdfData(
                                { type: "PURCHASE_ORDER", id: editOrder.id, supplierId: selectedSupplier! },
                                pdfCallback
                            );
                        
                    }}>Print</button>
                </ModalFooter>
            </>
        </Modal>
        </>
    );
}