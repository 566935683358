import React from "react";
import { theme, hooks, ErrorBoundary, useDialog } from "@holta/ui";
import { ThemeProvider } from "styled-components";
import { ToastProvider } from "react-toast-notifications";
import { useAuthentication } from "./hooks/useAuthentication";
import {
    DialogProvider,
    GenericDialog,
    GlobalStyle,
    ModalProvider,
    Overlay,
    LoadingProvider,
    Loading,
} from "@holta/ui";
import { useRoutes } from "raviger";
import {
    AppLayoutBody, 
    AppLayoutContainer,
    AppLayoutHeader,
    AppLayoutMain,
    AppLayoutSidebar,
} from "./components/AppLayout";
import { AppHeader } from "./components/AppHeader";
import { Navigation } from "./components/Navigation";
import { Dashboard } from "./routes/Dashboard";
import { Orders } from "./routes/Orders";
import { Order } from "./routes/Order";
import { Production } from "./routes/Production";
import { Deliveries } from "./routes/Deliveries";
import { Customers } from "./routes/Customers";
import { Customer } from "./routes/Customer";
import { Contacts } from "./routes/Contacts";
import { Products } from "./routes/Products";
import { HoltaProducts } from "./routes/HoltaProducts";
import { ProductComponents } from "./routes/ProductComponents";
import { ModalContact } from "./components/ModalContact";
import { ModalContactContextProvider } from "./components/ModalContact/context";
import { ModalAddressContextProvider } from "./components/ModalAddress/context";
import { ModalSupplierProductImport } from "./components/ModalSupplierProductImport";
import { ModalCategoryContextProvider } from "./components/ModalCategory/context";
import { ModalProductContextProvider } from "./components/ModalProduct/context";
import { ModalProductComponentContextProvider } from "./components/ModalProductComponent/context";
import { ModalSupplierRangeContextProvider } from "./components/ModalSupplierRange/context";
import { ModalSupplierRange } from "./components/ModalSupplierRange";
import { Todo } from "./routes/Todo";
import { Planner } from "./routes/Planner";
import { ModalAddress } from "./components/ModalAddress";
import { ModalProduct } from "./components/ModalProduct";
import { ModalBulkProduct } from "./components/ModalBulkProduct";
import { ModalCategory } from "./components/ModalCategory";
import { Settings } from "./routes/Settings";
import { Suppliers } from "./routes/Suppliers";
import { Supplier } from "./routes/Supplier";
import { Categories } from "./routes/Categories";
import { LinkProducts } from "./routes/LinkProducts";
import { SheetMaterials } from "./routes/SheetMaterials";
import { MfcBoardColours } from "./routes/MfcBoardColours";
import { MfcPricingGroups } from "./routes/MfcPricingGroups";
import { useLoadOrders } from "./hooks/useLoadOrders";
import { useLoadCustomers } from "./hooks/useLoadCustomers";
import { useLoadcategories } from "./hooks/useLoadCateogries";
import { useLoadProducts } from "./hooks/useLoadProducts";
import { useLoadProductComponents } from "./hooks/useLoadProductComponents";
import { useLoadSuppliers } from "./hooks/useLoadSuppliers";
import { ModalProductComponent } from "./components/ModalProductComponent";
import { ModalSupplierBaseProduct } from "./components/ModalSupplierBaseProduct";
import { ModalSupplierBaseProductContextProvider } from "./components/ModalSupplierBaseProduct/context";
import { useLoadLinkProducts } from "./hooks/useLoadLinkProducts";
import { ModalLinkProductContextProvider } from "./components/ModalLinkProduct/context";
import { ModalLinkProduct } from "./components/ModalLinkProduct";
import { ModalSupplierProductImportProvider } from "./components/ModalSupplierProductImport/context";
import { useLoadContacts } from "./hooks/useLoadContacts";
import {useLoadOrdersSearch} from "./hooks/useLoadOrdersSearch";
import {useLoadProductionOrders} from "./hooks/useLoadProductionOrders";
import {useLoadDeliveryOrders} from "./hooks/useLoadDeliveryOrders";
import toast, { Toaster } from 'react-hot-toast';
import { ChangelogNotification } from "./components/ChangelogNotification";
import { user } from "./fns/user";
import { ModalOrder } from "./components/ModalOrder";

const adminRoutes = {
    "/": () => <Dashboard />,
    "/orders": () => <Orders />,
    "/orders/new": () => <Order />,
    "/orders/:id": () => <Order />,
    "/orders/new/:id": () => <Order />,
    "/production": () => <Production />,
    "/deliveries": () => <Deliveries />,
    "/customers": () => <Customers />,
    "/customers/new": () => <Customer />,
    "/customers/:id": () => <Customer />,
    "/contacts": () => <Contacts />,
    "/products": () => <Products />,
    "/products/product_components": () => <ProductComponents />,
    "/products/holta_products": () => <HoltaProducts />,
    "/suppliers": () => <Suppliers />,
    "/suppliers/new": () => <Supplier />,
    "/suppliers/:id": () => <Supplier />,
    "/products/categories": () => <Categories />,
    "/products/link_products": () => <LinkProducts />,
    "/products/sheet_materials": () => <SheetMaterials />,
    "/products/mfc_board_colours": () => <MfcBoardColours />,
    "/products/mfc_pricing_groups": () => <MfcPricingGroups />,
    "/todo": () => <Todo />,
    "/planner": () => <Planner date={new Date()} items={[]} />,
    "/settings": () => <Settings />,
};

const workshopRoutes = {
    "/": () => <Dashboard />,
    "/production": () => <Production />,
    "/deliveries": () => <Deliveries />,
};

function App() {
    const isAuthenticated = useAuthentication();
    if (!isAuthenticated) return <div>Loading...</div>;
    return <AuthenticatedApp />;
}

function Router() {
    let routes: any = workshopRoutes;
    if(user.roles.includes("admin")) routes = adminRoutes;
    let route = useRoutes(routes);
    return <>{route}</>;
}

function ErrorHandler({ children }: { children: React.ReactNode }) {
    const { openGenericDialog } = useDialog();
    return (
        <ErrorBoundary handleError={(e) => openGenericDialog(e.message, "An Error Occurred")}>
            {children}
        </ErrorBoundary>
    );
}

function AuthenticatedApp() {
    //hooks.useWarnOnLeave();
    const ordersLoaded = useLoadOrders();
    const customersLoaded = useLoadCustomers();
    const categoriesLoaded = useLoadcategories();
    const productComponentsLoaded = useLoadProductComponents();
    const productsLoaded = useLoadProducts();
    const suppliersLoaded = useLoadSuppliers();
    const linkProductsLoaded = useLoadLinkProducts();
    const contactsLoaded = useLoadContacts();
    const searchOrders = useLoadOrdersSearch();
    const productionOrders = useLoadProductionOrders();
    const deliveryOrders = useLoadDeliveryOrders();

    if(!productsLoaded || !productComponentsLoaded) return null;

    console.log('App Rendering')
    //if (!ordersLoaded || !customersLoaded || !categoriesLoaded || !productComponentsLoaded || !productsLoaded || !suppliersLoaded) return <div>Loading...</div>;

    return (
        <ThemeProvider
            theme={{ color: theme.colorLight, ...theme.themeMedium, maxContainerWidth: "960px" }}
        >
            <ModalProvider>
                <DialogProvider>
                    <LoadingProvider>
                        <ToastProvider>
                            <ModalContactContextProvider>
                                <ModalAddressContextProvider>
                                    <ModalCategoryContextProvider>
                                        <ModalSupplierRangeContextProvider>
                                            <ModalProductComponentContextProvider>
                                                <ModalProductContextProvider>
                                                    <ModalSupplierBaseProductContextProvider>
                                                        <ModalLinkProductContextProvider>
                                                            <ModalSupplierProductImportProvider>
                                                                <AppLayoutContainer>
                                                                    <AppLayoutHeader>
                                                                        <AppHeader />
                                                                    </AppLayoutHeader>
                                                                    <AppLayoutBody>
                                                                        <AppLayoutSidebar>
                                                                            <Navigation></Navigation>
                                                                        </AppLayoutSidebar>
                                                                        <AppLayoutMain  id="appLayoutMain">
                                                                            <Router />
                                                                        </AppLayoutMain>
                                                                        <ChangelogNotification />
                                                                    </AppLayoutBody>
                                                                </AppLayoutContainer> 
                                                            <ModalSupplierProductImport/>
                                                            <ModalAddress />
                                                            <ModalContact />
                                                            <ModalCategory />
                                                            <ModalOrder />
                                                            <ModalProductComponent />
                                                            <ModalProduct />
                                                            <ModalBulkProduct />
                                                            <ModalSupplierProductImport />
                                                            <ModalSupplierRange />
                                                            <ModalSupplierBaseProduct />
                                                            <ModalLinkProduct />
                                                            <GlobalStyle />
                                                            <GenericDialog />
                                                            <Loading />
                                                            <Overlay />
                                                            <Toaster />
                                                            </ModalSupplierProductImportProvider>
                                                        </ModalLinkProductContextProvider>
                                                    </ModalSupplierBaseProductContextProvider>
                                                </ModalProductContextProvider>
                                            </ModalProductComponentContextProvider>
                                        </ModalSupplierRangeContextProvider>
                                    </ModalCategoryContextProvider>
                                </ModalAddressContextProvider>
                            </ModalContactContextProvider>
                        </ToastProvider>
                    </LoadingProvider>
                </DialogProvider>
            </ModalProvider>
        </ThemeProvider>
    );
}

export default App;
