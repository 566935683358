import { fns } from "@holta/lib";
import {
    FormInput,
    FormLabel,
    FormNumber,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Spacer,
    Tag,
    useDialog,
    useModal,
    FormNotice,
} from "@holta/ui";
import { deleteProduct } from "../../../actions/products";
import { useStore } from "../../../hooks/useStore";
import React from "react";

import { useModalProductContext } from "../context";

interface Props {
    children?: React.ReactNode;
}

export const DetailsTab: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        setCode,
        setW,
        setH,
        setD,
        setDescription,
        setSpecification,
        setCategories,
        setImage,
        isNew,
        isEditable,
        validate,
        validationResult,
    } = useModalProductContext();

    const categories = useStore((state) => state.categories);

    if (!product) {
        return null;
    }

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Ths will completely remove this product and can't be undone'.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteProduct(product.id)
                    .then(() => {
                        closeModal("MODAL_PRODUCT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting product", "Error", false, true);
                    });
            }
        );
    };

    return (
        <Island>
            <Spacer xs={[1, 0]}>
                <FormLabel>Code</FormLabel>
                {isEditable || isNew ? (
                    <FormInput
                        value={product.code}
                        style={{ width: "100%" }}
                        onChange={(e) => setCode(e.target.value)}
                        onBlur={validate}
                    />
                ) : (
                    <FormValue>{product.code}</FormValue>
                )}
                {validationResult && !validationResult.code._isValid && (
                    <FormNotice>{validationResult.code._errors[0]}</FormNotice>
                )}
            </Spacer>
            <hr />
            <Spacer xs={[1, 0]}>
                <Grid.Row>
                    <Grid.Col xs={{ cols: 4 }}>
                        <Spacer xs={[0, 1, 1, 0]} style={{ width: "100%" }}>
                            <FormLabel>Width</FormLabel>
                            {isEditable ? (
                                <FormNumber
                                    value={product.w || ""}
                                    placeholder=""
                                    style={{ width: "100%" }}
                                    onChange={(e) => setW(parseFloat(e.target.value))}
                                />
                            ) : (
                                <FormValue style={{ width: "100%" }}>{product.w}&nbsp;</FormValue>
                            )}
                            {validationResult && !validationResult.w._isValid && (
                    <FormNotice>{validationResult.w._errors[0]}</FormNotice>
                )}
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 4 }}>
                        <Spacer xs={[0, 1, 1, 1]} style={{ width: "100%" }}>
                            <FormLabel>Height</FormLabel>
                            {isEditable ? (
                                <FormNumber
                                    value={product.h || ""}
                                    placeholder=""
                                    style={{ width: "100%" }}
                                    onChange={(e) => setH(parseFloat(e.target.value))}
                                />
                            ) : (
                                <FormValue style={{ width: "100%" }}>{product.h}&nbsp;</FormValue>
                            )}
                            {validationResult && !validationResult.h._isValid && (
                    <FormNotice>{validationResult.h._errors[0]}</FormNotice>
                )}
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 4 }}>
                        <Spacer xs={[0, 0, 1, 1]} style={{ width: "100%" }}>
                            <FormLabel>Depth</FormLabel>
                            {isEditable ? (
                                <FormNumber
                                    value={product.d || ""}
                                    placeholder=""
                                    style={{ width: "100%" }}
                                    onChange={(e) => setD(parseFloat(e.target.value))}
                                />
                            ) : (
                                <FormValue style={{ width: "100%" }}>{product.d}&nbsp;</FormValue>
                            )}
                        </Spacer>
                        {validationResult && !validationResult.d._isValid && (
                    <FormNotice>{validationResult.d._errors[0]}</FormNotice>
                )}
                    </Grid.Col>
                </Grid.Row>
            </Spacer>
            <hr />
            <Spacer xs={[1, 0]}>
                <FormLabel>Description</FormLabel>
                {isEditable ? (
                    <FormTextarea
                        value={product.description}
                        style={{ width: "100%" }}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                ) : (
                    <FormValue>{product.description}&nbsp;</FormValue>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Specification</FormLabel>
                {isEditable ? (
                    <FormTextarea
                        value={product.specification}
                        style={{ width: "100%" }}
                        onChange={(e) => setSpecification(e.target.value)}
                    />
                ) : (
                    <FormValue>{product.specification}&nbsp;</FormValue>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Categories</FormLabel>
                {isEditable ? (
                    <FormSelect
                        options={[...categories].reverse().map((category) => ({
                            label: fns.category.getHeirachicalName(category, categories),
                            value: category.id,
                        }))}
                        value={
                            product.categories &&
                            product.categories.length > 0 &&
                            product.categories.sort().map((id) => {
                                const category = categories.find((c) => c.id === id);
                                if (category)
                                    return {
                                        label: fns.category.getHeirachicalName(
                                            category,
                                            categories
                                        ),
                                        value: category.id,
                                    };
                            })
                        }
                        isMulti={true}
                        onChange={(options: { label: string; value: string }[]) => {
                            setCategories(
                                options && options.length > 0
                                    ? options.map((option) => option.value)
                                    : []
                            );
                        }}
                    />
                ) : (
                    product.categories &&
                    product.categories
                        .filter((id) => categories.find((c) => c.id === id))
                        .map((id) => {
                            const category = categories.find((c) => c.id === id);
                            return (
                                <Tag type={5} size="l" key={category?.id}>
                                    {category &&
                                        fns.category.getHeirachicalName(category, categories)}
                                </Tag>
                            );
                        })
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Image</FormLabel>
                {isEditable ? (
                    <FormInput
                        value={product.image}
                        style={{ width: "100%" }}
                        onChange={(e) => setImage(e.target.value)}
                    />
                ) : (
                    <FormValue>{product.image}&nbsp;</FormValue>
                )}
            </Spacer>
        </Island>
    );
};
