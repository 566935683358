import { useStore } from "../../config/store";
import { libTypes, fns } from "@holta/lib";
import { decode } from "js-base64";
import { createNewOrder } from "./createNewOrder";

export function createNewOrderFromCustomerOrder(hash: string) {
    const products = useStore.getState().products;
    const components = useStore.getState().productComponents;

    if (!hash) createNewOrder();
    const customerOrder: libTypes.CustomerSubmittedOrder = hash && JSON.parse(decode(hash));
    const order: Partial<libTypes.Order> = {
        customerRef: customerOrder.customerRef,
        deliveryDate: customerOrder.deliveryDate,
        deliveryDateType: customerOrder.deliveryDateType,
        deliveryAddress: customerOrder.deliveryAddress,
        deliveryNotes: customerOrder.deliveryNotes,
        carcaseColour: customerOrder.carcaseColour,
        constructionType: customerOrder.constructionType,
        drawerbox: customerOrder.drawerbox,
        voidSize: customerOrder.voidSize,
        gluedWallUnits: customerOrder.gluedWallUnits,
        items: customerOrder.items.map((item) => {
            const product = products.find((p) => p.id === item.productId);
            if (!product) throw new Error("Product not found");
            const _product = fns.order_item.createCarcaseItemFromProduct(product, components, 0);
            return {
                ..._product,
                quantity: item.quantity,
                hEdited: item.hEdited,
                wEdited: item.wEdited,
                dEdited: item.dEdited,
                handing: item.handing,
                notes: item.notes,
            };
        }),
    };
    createNewOrder(order);
}