import { fns } from '@holta/lib';
import {useStore} from './useStore';

export const useFilteredSuppliers = () => {
    const {suppliers, ui} = useStore();
    let filteredSuppliers = [...suppliers];

    if(ui.suppliers.sortField === 'name'){
        filteredSuppliers = fns.shared.sortByString(filteredSuppliers, 'name', ui.suppliers.sortDirection);
    }
    if(ui.suppliers.sortField === 'dateCreated'){
        filteredSuppliers = fns.shared.sortByNumerical(filteredSuppliers, 'dateCreated', ui.suppliers.sortDirection);
    }
    if(ui.suppliers.searchFilter){
        filteredSuppliers = fns.shared.filterByString(filteredSuppliers, 'name', ui.suppliers.searchFilter);
    }

    return filteredSuppliers;

}
