import { FormLabel, Island, Spacer, useDialog, useModal } from '@holta/ui';
import { useStore } from '../../../hooks/useStore';
import React from 'react';

import { useModalSupplierRangeContext } from '../context';

interface Props {
    children?: React.ReactNode;
}

export const SettingsTab: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);

    const {
        supplierRange,
        isNew,
        handleDelete,
    } = useModalSupplierRangeContext();

    if (!supplierRange) {
        return null;
    }
    return (
        <>
            {!isNew && (
                <Island>
                    <Spacer xs={[1, 0]}>
                        <FormLabel>Delete Contact</FormLabel>
                        <button className="danger" onClick={handleDelete}>
                            Delete
                        </button>
                    </Spacer>
                </Island>
            )}
        </>
    );
};
