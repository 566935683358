import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { hasProperty } from "../../fns/hasProperty";

export const deleteOrderItem = (orderItemId: string) => {
    const mergedOrder = getMergedOrder();
    const editOrder = getEditOrder();

    const updatedItems = mergedOrder.items
        ?.filter(
            (item) =>
                item.id === orderItemId ||
                (hasProperty(item, "parentId") && item.parentId === orderItemId)
        )
        .map((item) => ({ id: item.id, _deleted: true }));

    useStore.setState({
        editOrder: create(editOrder, (draft) => {
            draft.newValues.order.items = fns.order_items.combineEdits(
                editOrder.newValues.order.items || [],
                updatedItems
            );
        }),
    });
};
 