import { Modal, ModalHeader, ModalBody, ModalFooter, Island, Spacer, useModal } from "@holta/ui";
import { fns } from "@holta/lib";
import { InputSupplierSelect } from "../../../components/InputSupplierSelect";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { getPdfData } from "../../../fns/getPdfData";
import React from "react";
import { ModalPOEmail } from "./ModalPOEmail";

export const ModalChooseSupplierPurchaseOrderSend = () => {
    const editOrder = useMergedEditOrder();
    const [selectedSupplier, setSelectedSupplier] = React.useState<string | null>(null);
    const { closeAll, closeModal, openModal } = useModal();

    const iframe = React.useRef<HTMLIFrameElement>(null);

    function pdfCallback(blob: Blob) {
        iframe.current!.src = window.URL.createObjectURL(blob);
        iframe.current!.onload = () => {
            iframe.current!.contentWindow!.print();
        };
    }

    if (!editOrder) return null;
    return (
        <>
            <iframe style={{ display: "none" }} ref={iframe}></iframe>
            <Modal
                id="MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER_SEND"
                style={{ width: "600px" }}
                onDismiss={() => {
                    closeAll();
                    setSelectedSupplier(null);
                }}
            >
                <>
                    <ModalHeader>Choose Supplier</ModalHeader>
                    <ModalBody>
                        <Spacer xs={[2]}>
                            <Island>
                                <InputSupplierSelect
                                    onChange={setSelectedSupplier}
                                    value={selectedSupplier}
                                    filterIds={fns.order.getSupplierIds(editOrder)}
                                />
                            </Island>
                        </Spacer>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            onClick={() => closeModal("MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER_SEND")}
                        >
                            Cancel
                        </button>
                        <Spacer xs={[0.5]} />
                        <button
                            disabled={!selectedSupplier}
                            className="primary"
                            onClick={() => {
                                openModal("MODAL_PO_EMAIL");
                            }}
                        >
                            Send...
                        </button>
                    </ModalFooter>
                    {selectedSupplier && (
                        <ModalPOEmail
                            id="MODAL_PO_EMAIL"
                            supplierId={selectedSupplier}
                        />
                    )}
                </>
            </Modal>
        </>
    );
};
