import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";

export const clearEditOrderItem = () => {
    const editOrder = getEditOrder();
    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.orderItem = null;
        }),
    });
};
