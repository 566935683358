import React from "react";
import styled from "styled-components";
import {
    Spacer,
    Grid,
    Text,
    FormSelect,
    FormLabel,
    FormDate,
    IconButton,
    useModal,
    FormInput,
    Island,
    Menu,
    MenuItem,
    ProductionStausDot,
    FormNumber,
} from "@holta/ui";
import { HeaderStatusTab } from "./HeaderStatusTab";
import { fns, staticData } from "@holta/lib";
import editOrderActions from "../../../actions/editOrder";
import { useStore } from "../../../config/store";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { feathersApp } from "../../../config/feathers";
import { ModalEmail } from "./ModalEmail";
import { getPdfData } from "../../../fns/getPdfData";

interface Props {}

export const Header: React.FC<Props> = () => {
    const { openModal } = useModal();
    const iframe = React.useRef<HTMLIFrameElement>(null);
    const contacts = useStore((state) => state.contacts);
    const editOrderType = useStore((state) => state.editOrder?.type);
    const editOrderNewValues = useStore((state) => state.editOrder?.newValues.order);

    const mergedEditOrder = useMergedEditOrder();
    const customer = useStore((state) => state.customers).find(
        (c) => c.id === mergedEditOrder?.customerId
    );
    const filteredContacts = contacts.filter(
        (contact) => contact.parentId === mergedEditOrder?.customerId
    );

    const orderStatusType = useStore((state) => state.editOrder?.type);

    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];

    if (!mergedEditOrder) return null;

    const isPrintable =
        (!editOrderNewValues || Object.values(editOrderNewValues).length < 1) &&
        editOrderType === "EDIT";

    function pdfCallback(blob: Blob) {
        iframe.current!.src = window.URL.createObjectURL(blob);
        iframe.current!.onload = () => {
            iframe.current!.contentWindow!.print();
        };
    }

    return (
        <Spacer xs={[2]}>
            <iframe style={{ display: "none" }} ref={iframe}></iframe>
            <ModalEmail id="MODAL_INVOICE" type="SEND_INVOICE" />
            <ModalEmail id="MODAL_ORDER_CONFIRMATION" type="SEND_ORDER_CONFIRMATION" />
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">
                        {orderStatusType === "NEW"
                            ? "New Quote"
                            : fns.order.formatOrderRef(mergedEditOrder)}
                    </Text>
                </Grid.Col>
                <Grid.Col>
                    <Grid.Row>
                        {!isPrintable ? (
                            <button className="btn tertiary" disabled>
                                Print...
                            </button>
                        ) : (
                            <Menu button={<div className="btn tertiary">Print...</div>}>
                                <MenuItem
                                    onClick={() => {
                                        getPdfData(
                                            { type: "ORDER_CONFIRMATION", id: mergedEditOrder.id },
                                            pdfCallback
                                        );
                                    }}
                                >
                                    Order Confirmation
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        getPdfData(
                                            { type: "WORKSHOP_ORDER", id: mergedEditOrder.id },
                                            pdfCallback
                                        );
                                    }}
                                >
                                    Workshop Order
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        getPdfData(
                                            { type: "DELIVERY_NOTE", id: mergedEditOrder.id },
                                            pdfCallback
                                        );
                                    }}
                                >
                                    Delivery Note
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        getPdfData(
                                            { type: "LABELS", id: mergedEditOrder.id },
                                            pdfCallback
                                        );
                                    }}
                                >
                                    Labels
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        getPdfData(
                                            { type: "INVOICE", id: mergedEditOrder.id },
                                            pdfCallback
                                        );
                                    }}
                                >
                                    Invoice
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        openModal("MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER");
                                    }}
                                >
                                    Purchase Order...
                                </MenuItem>
                            </Menu>
                        )}

                        <Spacer xs={[0.5]}></Spacer>
                        {!isPrintable ? (
                            <button className="btn tertiary" disabled>
                                Send...
                            </button>
                        ) : (
                            <Menu button={<div className="btn tertiary">Send...</div>}>
                                <MenuItem
                                    onClick={() => {
                                        openModal("MODAL_INVOICE");
                                    }}
                                >
                                    Invoice
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        openModal("MODAL_ORDER_CONFIRMATION");
                                    }}
                                >
                                    Order Confirmation
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        openModal("MODAL_CHOOSE_SUPPLIER_PURCHASE_ORDER_SEND");
                                    }}
                                >
                                    Purchase Order...
                                </MenuItem>
                            </Menu>
                        )}
                        <Spacer xs={[0.5]}></Spacer>
                        <button
                            className="primary"
                            disabled={
                                !editOrderNewValues || Object.values(editOrderNewValues).length < 1
                            }
                            onClick={editOrderActions.saveOrder}
                        >
                            Save
                        </button>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Island p={0}>
                <Grid.Row style={{ overflow: "auto" }}>
                    <div style={{ width: "auto" }}>
                        <Menu
                            button={
                                <HeaderStatusTab
                                    heading="Prodcution Status"
                                    value={
                                        staticData.productionStatuses[
                                            mergedEditOrder!.productionStatus!
                                        ].label
                                    }
                                    color={
                                        ("production_" + mergedEditOrder!.productionStatus!) as any
                                    }
                                    icon="flag"
                                />
                            }
                        >
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.PROVISIONAL.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.PROVISIONAL.key}
                                    />
                                    &nbsp;Provisional
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.IN_PRODUCTION.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.PROVISIONAL.key}
                                    />
                                    &nbsp;In Production
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.CUT.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.CUT.key}
                                    />
                                    &nbsp;Cut
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.EDGED.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.EDGED.key}
                                    />
                                    &nbsp;Edged
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.MACHINED.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.MACHINED.key}
                                    />
                                    &nbsp;Machined
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.BUILT.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.BUILT.key}
                                    />
                                    &nbsp;Built
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.COMPLETE.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.COMPLETE.key}
                                    />
                                    &nbsp;Complete
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setProductionStatus(
                                        staticData.productionStatuses.DELIVERED.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>
                                    <ProductionStausDot
                                        size={1}
                                        status={staticData.productionStatuses.DELIVERED.key}
                                    />
                                    &nbsp;Delivered
                                </Grid.Row>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div style={{ width: "auto" }}>
                        <Menu
                            button={
                                <HeaderStatusTab
                                    heading="Order Status"
                                    value={
                                        staticData.orderStatuses[mergedEditOrder!.orderStatus!]
                                            .label
                                    }
                                    color={"accent1"}
                                    icon="file-list"
                                />
                            }
                        >
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setOrderStatus(staticData.orderStatuses.QUOTE.key)
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Quote</Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setOrderStatus(staticData.orderStatuses.ORDER.key)
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Order</Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setOrderStatus(
                                        staticData.orderStatuses.INVOICE.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Invoice</Grid.Row>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div style={{ width: "auto" }}>
                        <Menu
                            button={
                                <HeaderStatusTab
                                    heading="Order Total"
                                    value={
                                        "£" +
                                        fns.order.getOrderTotalOrEstimate(mergedEditOrder, {
                                            excludeTax: true,
                                        }) +
                                        (mergedEditOrder.orderValueType ===
                                        staticData.orderValueTypes.ESTIMATE.key
                                            ? " (E)"
                                            : "")
                                    }
                                    icon="calculator"
                                />
                            }
                        >
                            <div style={{ width: "200px" }}>
                                <Spacer xs={[1]}>
                                    <FormLabel>Order Value Type</FormLabel>
                                    <FormSelect
                                        onChange={({ key, label }: any) =>
                                            editOrderActions.setOrderValueType(key)
                                        }
                                        options={Object.values(staticData.orderValueTypes)}
                                        value={{
                                            ...Object.values(staticData.orderValueTypes).find(
                                                (item) =>
                                                    item.key === mergedEditOrder.orderValueType
                                            ),
                                        }}
                                    />
                                </Spacer>

                                {mergedEditOrder.orderValueType ===
                                    staticData.orderValueTypes.ESTIMATE.key && (
                                    <Spacer xs={[1]}>
                                        <FormLabel>Estimated Order Value</FormLabel>
                                        <FormNumber
                                            onBlur={(e) =>
                                                editOrderActions.setOrderValueEstimate(e.target.value)
                                            }
                                            value={mergedEditOrder.orderValueEstimate}
                                            isFloat
                                        />
                                    </Spacer>
                                )}
                            </div>
                        </Menu>
                    </div>
                    {/* 
                    <HeaderStatusTab
                        heading="Date Created"
                        value={fns.shared.formatDate(mergedEditOrder.dateCreated)}
                        icon="calendar"
                    />
                    */}
                    <HeaderStatusTab
                        heading="Order Conf."
                        value={mergedEditOrder.orderConfirmationSent ? "Sent" : "Not Sent"}
                        icon="send-plane"
                        color={mergedEditOrder.orderConfirmationSent ? "confirm" : "accent1"}
                        onClick={() => {
                            getPdfData(
                                { type: "ORDER_CONFIRMATION", id: mergedEditOrder.id },
                                pdfCallback
                            );
                        }}
                    />
                    <HeaderStatusTab
                        heading="Invoice"
                        value={mergedEditOrder.invoiceSent ? "Sent" : "Not Sent"}
                        icon="send-plane"
                        color={mergedEditOrder.invoiceSent ? "confirm" : "accent1"}
                        onClick={() => {
                            getPdfData({ type: "INVOICE", id: mergedEditOrder.id }, pdfCallback);
                        }}
                    />
                    <div style={{ width: "auto" }}>
                        <Menu
                            button={
                                <HeaderStatusTab
                                    heading="Accounted Status"
                                    value={
                                        staticData.accountingStatuses[
                                            mergedEditOrder.accountingStatus
                                        ].label
                                    }
                                    icon="money-pound-circle"
                                />
                            }
                        >
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setAccountingStatus(
                                        staticData.accountingStatuses.NONE.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>None</Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setAccountingStatus(
                                        staticData.accountingStatuses.ORDERED.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Ordered</Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setAccountingStatus(
                                        staticData.accountingStatuses.INVOICED.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Invoiced</Grid.Row>
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    editOrderActions.setAccountingStatus(
                                        staticData.accountingStatuses.PAID.key
                                    )
                                }
                            >
                                <Grid.Row xs={{ align: "center" }}>Paid</Grid.Row>
                            </MenuItem>
                        </Menu>
                    </div>

                    {/* <HeaderStatusTab heading="Customer Status" value="In Production" icon="flag" /> */}
                </Grid.Row>
            </Island>
        </Spacer>
    );
};
