import { libTypes } from "@holta/lib";
import { useStore } from "../../config/store";

export const setOrder = (order: libTypes.Order, doorRange?: libTypes.SupplierComputedProduct[]) => {

    const newOrder  = {
        type: "EDIT" as const, // Update the type to be either "EDIT" or "NEW"
        newValues: {
            order: {},
            orderItem: null,
        },
        currentValues: {
            order: order,
            supplierDoorRange: doorRange || [],
        },
    };
    useStore.setState({
        editOrder: newOrder,
    });

}




