import { SidebarButton } from '../../../components/SidebarButton';
import { useCustomerContext } from '../context';

export const Sidebar = () => {

    const {tab, setTab} = useCustomerContext();

    return (
        <>
            <SidebarButton className={tab === 0 ? 'isCurrent' : ''} onClick={() => setTab(0)}>Details</SidebarButton>
            <SidebarButton className={tab === 1 ? 'isCurrent' : ''} onClick={() => setTab(1)}>Contacts</SidebarButton>
            <SidebarButton className={tab === 2 ? 'isCurrent' : ''} onClick={() => setTab(2)}>Addresses</SidebarButton>
            <SidebarButton className={tab === 3 ? 'isCurrent' : ''} onClick={() => setTab(3)}>Settings</SidebarButton>
        </>
    );
};
