import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { StoreEditCustomer } from "../../config/store";
import React from "react";

export const ModalAddressContext = React.createContext<
    {
          address: StoreEditCustomer["newValues"]["addresses"][number] | null;
          isDefaultDelivery: boolean;
          isDefaultInvoice: boolean;
          toggleIsDefaultDelivery: () => void;
          toggleIsDefaultInvoice: () => void;
          isEdited: boolean;
          isNew: boolean;
          isEditable: boolean;
          handleDelete: () => void;
          type : "SUPPLIER" | "CUSTOMER";
          setIsEditable: (isEditable: boolean) => void;
          openModalAddress: (
              address: StoreEditCustomer["newValues"]["addresses"][number],
              isDefaultDelivery: boolean,
              isDefaultInvoice: boolean,
              isNew: boolean,
              handleConfirm: (
                  address: StoreEditCustomer["newValues"]["addresses"][number],
                  isDefaultDelivery: boolean,
                  isDefaultInvoice: boolean
              ) => void,
              type?: "SUPPLIER" | "CUSTOMER",
              handleCancel?: () => void,
              validationType?: "ADDRESS" | "ADDRESS_FIELDS"
          ) => void;
          setAddressLine1: (addressLine1: string) => void;
          setAddressLine2: (addressLine2: string) => void;
          setAddressLine3: (addressLine3: string) => void;
          setCity: (city: string) => void;
          setCountry: (country: string) => void;
          setCounty: (county: string) => void;
          setName: (name: string) => void;
          setNote: (note: string) => void;
          setPostcode: (postcode: string) => void;
          cancel: () => void;
          confirm: () => void;
      }
    | undefined
>(undefined);

export const useModalAddressContext = () => {
    const context = React.useContext(ModalAddressContext);
    if (context === undefined) {
        throw new Error("useModalAddressContext must be within the correct provider");
    }
    return context;
};

export const ModalAddressContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [address, setAddress] = React.useState<
        StoreEditCustomer["newValues"]["addresses"][number] | null
    >(null);
    const [isDefaultDelivery, setIsDefaultDelivery] = React.useState(false);
    const [isDefaultInvoice, setIsDefaultInvoice] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const validationTypeRef = React.useRef<"ADDRESS" | "ADDRESS_FIELDS">("ADDRESS");
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const addressType = React.useRef<"SUPPLIER" | "CUSTOMER">("CUSTOMER");
    const handleConfirmFn = React.useRef<
        (address: StoreEditCustomer["newValues"]["addresses"][number], isDefaultDelivery: boolean, isDefaultInvoice: boolean) => void
    >();
    const handleCancelFn = React.useRef<() => void>();

    const toggleIsDefaultDelivery = () => {
        setIsDefaultDelivery(!isDefaultDelivery);
        setIsEdited(true);
    };

    const toggleIsDefaultInvoice = () => {
        setIsDefaultInvoice(!isDefaultInvoice);
        setIsEdited(true);
    };

    const setAddressLine1 = (setAddressLine1: string) => {
        address && setAddress(fns.address.set.addressLine1(address, setAddressLine1));
        setIsEdited(true);
    };
    const setAddressLine2 = (setAddressLine2: string) => {
        address && setAddress(fns.address.set.addressLine2(address, setAddressLine2));
        setIsEdited(true);
    };
    const setAddressLine3 = (setAddressLine3: string) => {
        address && setAddress(fns.address.set.addressLine3(address, setAddressLine3));
        setIsEdited(true);
    };

    const setCity = (city: string) => {
        address && setAddress(fns.address.set.city(address, city));
        setIsEdited(true);
    };

    const setCountry = (country: string) => {
        address && setAddress(fns.address.set.country(address, country));
        setIsEdited(true);
    };

    const setCounty = (county: string) => {
        address && setAddress(fns.address.set.county(address, county));
        setIsEdited(true);
    };

    const setName = (name: string) => {
        address && setAddress(fns.address.set.name(address, name));
        setIsEdited(true);
    };

    const setNote = (note: string) => {
        address && setAddress(fns.address.set.note(address, note));
        setIsEdited(true);
    };

    const setPostcode = (postcode: string) => {
        address && setAddress(fns.address.set.postcode(address, postcode));
        setIsEdited(true);
    };

    const handleDelete = () => {
        handleConfirmFn.current &&
            address &&
            handleConfirmFn.current({ ...address, _deleted: true }, false, false);
        closeModal("MODAL_ADDRESS");
    };

    const wrappedOpenModal = (
        address: StoreEditCustomer["newValues"]["addresses"][number],
        isDefaultDelivery: boolean,
        isDefaultInvoice: boolean,
        isNew: boolean,
        handleConfirm: (
            address: StoreEditCustomer["newValues"]["addresses"][number],
            isDefaultDelivery: boolean,
            isDefaultInvoice: boolean
        ) => void,
        type : "SUPPLIER" | "CUSTOMER" = "CUSTOMER",
        handleCancel?: () => void,
        validationType?: "ADDRESS" | "ADDRESS_FIELDS"
    ) => {
        setAddress(address);
        setIsDefaultDelivery(isDefaultDelivery);
        setIsDefaultInvoice(isDefaultInvoice);
        setIsEdited(false);
        setIsNew(isNew);
        setIsEditable(isNew);
        openModal("MODAL_ADDRESS");
        validationTypeRef.current = validationType || "ADDRESS";
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
        addressType.current = type;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        closeModal("MODAL_ADDRESS");
    };

    const confirm = () => {
        
        if (!address) {
            closeModal("MODAL_ADDRESS");
            return;
        }

        const validationFn = validationTypeRef.current === "ADDRESS" ? fns.address.validate.address : fns.address.validate.addressFields;
        if (!validationFn(address)._isValid) {
            openGenericDialog("Address is not valid, please check the input values");
        } else {
            handleConfirmFn.current && address && handleConfirmFn.current(address, isDefaultDelivery, isDefaultInvoice);
            closeModal("MODAL_ADDRESS");
        }
    };

    return (
        <ModalAddressContext.Provider
            value={{
                address,
                isDefaultDelivery,
                isDefaultInvoice,
                toggleIsDefaultDelivery,
                toggleIsDefaultInvoice,
                isEdited,
                isNew,
                isEditable,
                setIsEditable,
                handleDelete,
                type: addressType.current,
                openModalAddress: wrappedOpenModal,
                setAddressLine1,
                setAddressLine2,
                setAddressLine3,
                setCity,
                setCountry,
                setCounty,
                setName,
                setNote,
                setPostcode,
                cancel,
                confirm,
            }}
        >
            {children}
        </ModalAddressContext.Provider>
    );
};
