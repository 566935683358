import { fns } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedSupplier = () => {
    const editedSupplierValues = useStore(state => state.editSupplier?.newValues.supplier);
    const existingSupplierValues = useStore(state => state.editSupplier?.currentValues.supplier);

    if(!existingSupplierValues && !editedSupplierValues) return {}
    if(!existingSupplierValues) return editedSupplierValues;
    if(!editedSupplierValues) return existingSupplierValues;
    
    return fns.supplier.merge(existingSupplierValues, editedSupplierValues);

}