import {
    Container,
    FormDate,
    FormInput,
    FormLabel,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Menu,
    Popover,
    useDialog,
    Icon,
    IconButton
} from "@holta/ui";
import React from "react";
import { useMenuButtonContext } from "@holta/ui";
import { InputCustomerSelect } from "../../../components/InputCustomerSelect";
import editOrderActions from "../../../actions/editOrder";
import { useStore } from "../../../config/store";
import { ModalConfirmUpdateOrderCustomer } from "./ModalConfirmCustomerChange";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { fns, libTypes } from "@holta/lib";
import { DeliveryDate } from "../../../components/InputDeliveryDate";
import { ProductionDate } from "../../../components/InputProductionDate";
import {hooks} from "@holta/ui";
import { useElementSize } from 'usehooks-ts'
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import { ChooseCustomer } from "./ChooseCustomer";

export const OrderDetails = () => {

    const editOrder = useMergedEditOrder();
    const [deliveryDateOpen, setDeliveryDateOpen] = hooks.useToggle(false);
    const [productionDateOpen, setProductionDateOpen] = hooks.useToggle(false);
    const [deliveryDateRef, deliveryDateSize] = useElementSize();
    const [productionDateRef, productionDateSize] = useElementSize();
    const {openGenericDialog} = useDialog();


    if (!editOrder) return null;

    return (
        <>
            <hr />
            <Container xl="fixed">
                <Spacer xs={[3, 1]}>
                    <Spacer xs={[1]}>
                        <Text size="xl">Order Details</Text>
                    </Spacer>
                    <Grid.Row>
                        <Grid.Col xs={{ cols: 6 }}>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <ChooseCustomer />
                                </Grid.Row>
                            </Spacer>
                        </Grid.Col>

                        <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Customer Ref
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 8 }}>
                                        <FormInput
                                            style={{ width: "100%" }}
                                            value={editOrder.customerRef}
                                            onChange={(e) =>
                                                editOrderActions.setCustomerRef(e.target.value)
                                            }
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Delivery Date
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col
                                        xs={{ cols: 8 }}
                                        style={{ width: "100%", display: "flex" }}
                                    >
                                        <Popover.Root open={deliveryDateOpen} onOpenChange={setDeliveryDateOpen} >
                                            <Popover.Trigger asChild>
                                                <FormValue style={{ width: "100%" }} ref={deliveryDateRef}>
                                                    {fns.order.formatDeliveryDate(editOrder)}
                                                </FormValue>
                                            </Popover.Trigger>
                                            <Popover.Portal>
                                                <Popover.Content asChild>
                                                    <Spacer xs={[1]} grow style={{ width : deliveryDateSize.width, top: '-50px', position: 'relative' }}>
                                                        <DeliveryDate
                                                            initialDeliveryDate={
                                                                editOrder.deliveryDate
                                                            }
                                                            initialDeliveryDateType={
                                                                editOrder.deliveryDateType
                                                            }
                                                            handleSave={(date, dateType) => {
                                                                editOrderActions.setDeliveryDate(date, dateType, (confirmFn) => {
                                                                    openGenericDialog('This date is in the past. Are you sure you want to continue?', 'Confirm Update', false, true, confirmFn);
                                                                });
                                                                setDeliveryDateOpen(false) 
                                                            }}
                                                            handleCancel={() => setDeliveryDateOpen(false)}
                                                        />
                                                    </Spacer>
                                                </Popover.Content>
                                                </Popover.Portal>
                                        </Popover.Root>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <Grid.Row>
                                <Grid.Col xs={{ cols: 4 }}>
                                        <FormLabel type="contained" position="left">
                                            Production Date
                                        </FormLabel>
                                    </Grid.Col>
                                    <Grid.Col
                                        xs={{ cols: 8 }}
                                        style={{ width: "100%", display: "flex" }}
                                    >
                                        <Popover.Root open={productionDateOpen} onOpenChange={setProductionDateOpen} >
                                            <Popover.Trigger asChild>
                                                <FormValue style={{ width: "100%" }} ref={productionDateRef}>
                                                    {fns.order.formatProductionDate(editOrder)}
                                                </FormValue>
                                            </Popover.Trigger>
                                            <Popover.Portal>
                                                <Popover.Content asChild>
                                                    <Spacer xs={[1]} grow style={{ width : productionDateSize.width, top: '-50px', position: 'relative' }}>
                                                        <ProductionDate
                                                            initialProductionDate={
                                                                editOrder.productionDate
                                                            }
                                                            initialProductionDateType={
                                                                editOrder.productionDateType
                                                            }
                                                            handleSave={(date, dateType) => {
                                                                editOrderActions.setProductionDate(date, dateType, (confirmFn) => {
                                                                    openGenericDialog('This date is in the past. Are you sure you want to continue?', 'Confirm Update', false, true, confirmFn);
                                                                });
                                                                setProductionDateOpen(false)
                                                            }}
                                                            handleCancel={() => setProductionDateOpen(false)}
                                                        />
                                                    </Spacer>
                                                   
                                                </Popover.Content>
                                                </Popover.Portal>
                                        </Popover.Root>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[0.25, 1]}>
                                <FormLabel type="contained">Delivery Notes</FormLabel>
                                <FormTextarea style={{ width: "100%" }} value={editOrder.deliveryNotes} onChange={e => editOrderActions.setDeliveryNotes(e.target.value)}></FormTextarea>
                            </Spacer>
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
            </Container>
        </>
    );
};
