import React from "react";
import { feathersApp } from "../config/feathers";
import { setCustomers } from "../actions/customers";
import { libTypes } from "@holta/lib";

export const useLoadCustomers = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);

    if (!hasRun.current) {
        feathersApp
            .service("customers")
            .watch()
            .find({
                query: {
                    $limit: 10000,
                },
            })
            .subscribe(({ data }: { data: libTypes.Customer[] }) => {
                setCustomers(data);
                setIsLoaded(true);
            });
        hasRun.current = true;
    }
    return isLoaded;
};
