
import {useStore} from './useStore';

export const useProductsFilterCount = () : number => {
    const filters = useStore( store => store.ui.products);

    let activeFilterCount = 0;
    if (filters.searchFilter) {
        activeFilterCount++;
    }
    if (filters.categoriesFilter.length > 0) {
        activeFilterCount++;
    }
    if (filters.componentsFilter.length > 0) {
        activeFilterCount++;
    }

    return activeFilterCount;
}