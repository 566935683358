import { fns } from "@holta/lib";
import { FormSelect, Grid, Island, Spacer, Text, theme} from "@holta/ui";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { useStore } from "../../../hooks/useStore";
import { endOfWeek, startOfWeek, add, startOfMonth, endOfMonth } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Bar Chart",
        },
    },
};

const labels = ["Quote", "Order", "Invoice"];


export const Chart2 = () => {

    const orders = useStore((store) => store.orders);
    const today = new Date();
    const deliveries = fns.orders.filterByDateRange(orders, "DELIVERY", startOfMonth(today).getTime(), endOfMonth(today).getTime());

    const quotesTotal = fns.orders.getTotalValue(fns.shared.filterByString(deliveries, 'orderStatus', 'QUOTE'), {excludeTax: true});
    const ordersTotal =  fns.orders.getTotalValue(fns.shared.filterByString(deliveries, 'orderStatus', 'ORDER'), {excludeTax: true});
    const invoiceTotal = fns.orders.getTotalValue(fns.shared.filterByString(deliveries, 'orderStatus', 'INVOICE'), {excludeTax: true});

    const data = {
        labels,
        datasets: [
            {
                label: "Dataset 2",
                data: [quotesTotal, ordersTotal, invoiceTotal],
                backgroundColor: [theme.colorLight.production_IN_PRODUCTION, theme.colorLight.production_COMPLETE, theme.colorLight.production_DELIVERED],
            },
        ],
    };

    
    return (
        <>
            <Text size="l" bold>
               Delivered This Month
            </Text>
            <Spacer xs={[1, 0]}>
           
            </Spacer>
            <hr />
            <Pie options={options} data={data} />
        </>
    );
};
