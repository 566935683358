import React from "react";
import { v4 as uuid } from "uuid";
import { useModal, useDialog } from "@holta/ui";
import { fns, libTypes } from "@holta/lib";
import editOrderActions from "../../../actions/editOrder";
import {useMergedEditOrder} from './useMergedEditOrder';

export const useModalPayment = () => {
    type Payment = libTypes.Order["payments"][number];

    const mergedEditOrder = useMergedEditOrder();

    const [payment, setPayment] = React.useState<Payment>({
        id: uuid(),
        label: "",
        amount: 0,
        date: new Date().getTime(),
    });
    const [isNew, setIsNew] = React.useState(true);

    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();

    const setLabel = (label: string) => {
        setPayment((p) => ({ ...p, label }));
    };

    const setAmount = (amount: number) => {
        setPayment((p) => ({ ...p, amount }));
    };

    const setDate = (date: number) => {
        setPayment((p) => ({ ...p, date }));
    };

    const setId = (id: string) => {
        setPayment((p) => ({ ...p, id }));
    };

    const setToRemainingBalance = () => {
        if(!mergedEditOrder) return;
        const amount = Number(fns.order.getRemainingBalance(mergedEditOrder));
        setPayment((p) => ({ ...p, amount }));
    }

    const _openModal = (
        payment?: libTypes.Order["payments"][number],
    ) => {
        if (payment) {
            setPayment(payment);
            setIsNew(false);
        } else {
            setPayment({
                id: uuid(),
                label: "",
                amount: 0,
                date: new Date().getTime(),
            });
        }
        openModal("MODAL_PAYMENT");
    };

    const handleSave = () => {
        editOrderActions.savePayment({
            id: payment.id,
            label: payment.label,
            date: payment.date,
            amount: payment.amount,
        });

        closeModal("MODAL_PAYMENT");
    };

    const handleDelete = () => {
        openGenericDialog(
            "Are you sure you want to delete this payment?",
            "Delete Payment",
            false,
            true,
            () => {
                editOrderActions.deletePayment(payment.id);
                closeModal("MODAL_PAYMENT");
            }
        );
    };

    const handleCancel = () => {
        closeModal("MODAL_PAYMENT");
    };

    return {
        payment,
        setAmount,
        setLabel,
        setDate,
        setId,
        isNew,
        openModal: _openModal,
        handleSave,
        handleDelete,
        handleCancel,
        setToRemainingBalance
    };
};
