import { libTypes, staticData } from "@holta/lib";
import {
    FormLabel,
    FormNumber,
    FormSelect,
    FormToggle,
    Grid,
    Icon,
    Island,
    Spacer,
    Table,
    Tbody,
    Td,
    TdInput,
    Text,
    Th,
    Thead,
    Tr,
    useDialog,
    useModal,
} from "@holta/ui";
import { deleteProduct } from "../../../actions/products";
import { useStore } from "../../../hooks/useStore";
import React from "react";
import { fns } from "@holta/lib";

import { useModalProductContext } from "../context";
import { useModalLinkProductContext } from "../../ModalLinkProduct/context";
import { saveLinkProduct } from "../../../actions/linkProducts";

interface Props {
    children?: React.ReactNode;
}

export const LinkProductsTab: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        setCode,
        setW,
        setH,
        setD,
        setDescription,
        setSpecification,
        addLinkProduct,
        removeLinkProduct,
        setCategories,
        toggleCustomerRestricted,
        toggleSoldByM2,
        setCustomerWhitelist,
        toggleActiveLinkProductGroup,
        updateLinkProductQty,
        setDrawers,
        setImage,
        setPrice,
        setPriceExpression,
        setPriceType,
        linkProductIdToAdd,
        setLinkProductIdToAdd,
        setLinkProductQtyToAdd,
        linkProductQtyToAdd,
        isEdited,
        isNew,
        isEditable,
        setIsEditable,
        cancel,
        confirm,
    } = useModalProductContext();

    const linkProducts = useStore((state) => state.linkProducts);
    const categories = useStore((state) => state.categories);
    const customers = useStore((state) => state.customers);
    const {openModalLinkProduct} = useModalLinkProductContext();

    if (!product) {
        return null;
    }

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Ths will completely remove this product and can't be undone'.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteProduct(product.id)
                    .then(() => {
                        closeModal("MODAL_PRODUCT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting product", "Error", false, true);
                    });
            }
        );
    };

    return (
        <>
            <Spacer xs={[0, 0, 1, 0]}>
               

                    {[
                        { key: "DEFAULT", label: "Default" },
                        ...Object.values(staticData.constructionTypes),
                    ].map((item) => (
                        <Spacer xs={[1, 0]} key={item.key}>
                            <Island p={0}>
                                <Spacer xs={[1]}>
                                    <Grid.Row xs={{ align: "center", justify: "space-between" }}>
                                        <Grid.Col>
                                            <Text size="l">{item.label}</Text>
                                        </Grid.Col>
                                        <Grid.Col>
                                            <FormToggle
                                                checked={product.activeLinkProductGroups.includes(
                                                    item.key as any
                                                )}
                                                onChange={() =>
                                                    toggleActiveLinkProductGroup(item.key as any)
                                                }
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Spacer>
                                
                                {product.activeLinkProductGroups.includes(item.key as any) && (
                                    <>
                                    <hr />
                                    <Spacer xs={[1]}>
                                        <Grid.Row>
                                            <Grid.Col xs={{ cols: 6 }}>
                                                <Spacer xs={[0, 1, 0, 0]}>
                                                    <FormSelect
                                                        options={linkProducts.filter(lp => !product.linkProducts.find(plp => plp.id === lp.id && plp.constructionType === item.key)).map(
                                                            (linkProduct) => {
                                                                return {
                                                                    value: linkProduct.id,
                                                                    label: linkProduct.name,
                                                                };
                                                            }
                                                        )}
                                                        placeholder="Select..."
                                                        onChange={(option: {
                                                            id: string;
                                                            value: string;
                                                        }) => {
                                                            setLinkProductIdToAdd(option.value);
                                                        }}
                                                        value={linkProductIdToAdd ? { 
                                                            id: linkProductIdToAdd,
                                                            label:
                                                                linkProducts.find(
                                                                    (pc) =>
                                                                        pc.id === linkProductIdToAdd
                                                                )?.name || "",
                                                        } : undefined}
                                                        onAddNew={() => openModalLinkProduct(fns.linkProduct.create(), true, (lp) => {saveLinkProduct(lp); setLinkProductIdToAdd(lp.id)})}
                                                    />
                                                </Spacer>
                                            </Grid.Col>
                                            <Grid.Col xs={{ cols: 3 }}>
                                                <Spacer xs={[0, 1, 0, 0]}>
                                                    <FormNumber
                                                        style={{ width: "100%" }}
                                                        value={linkProductQtyToAdd}
                                                        onChange={(e) =>
                                                            setLinkProductQtyToAdd(
                                                                !isNaN(parseFloat(e.target.value))
                                                                    ? parseFloat(e.target.value)
                                                                    : 1
                                                            )
                                                        }
                                                        isFloat
                                                    ></FormNumber>
                                                </Spacer>
                                            </Grid.Col>
                                            <Grid.Col xs={{ cols: 3 }}>
                                                <button
                                                    className="primary"
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                    }}
                                                    disabled={
                                                        !linkProductIdToAdd || !linkProductQtyToAdd
                                                    }
                                                    onClick={() => addLinkProduct(item.key as libTypes.ConstructionType)}
                                                >
                                                    Add
                                                </button>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Spacer>
                                    </>
                                )}
                                {product.activeLinkProductGroups.includes(item.key as any) && product.linkProducts.filter(plp => plp.constructionType === item.key).length > 0 && (
                                    <Spacer xs={[1]} >
                                    <hr />
                            <Table cols={9}>
                                <Thead>
                                    <Tr>
                                        <Th colspan={4}>Code</Th>
                                        <Th colspan={4}>Qty</Th>
                                        <Th colspan={1}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {product.linkProducts.filter(lp => lp.constructionType === item.key).map((linkProduct, index) => (
                                        <Tr key={linkProduct.id}>
                                            <Td colspan={4}>
                                                {linkProducts?.find(
                                                    (pc) => pc.id === linkProduct.id
                                                )?.name || ""}
                                            </Td>

                                            <TdInput
                                                colspan={4}
                                                value={linkProduct.qty}
                                                component={FormNumber}
                                                isFloat
                                                onChange={(e) => {
                                                    !isNaN(
                                                        parseFloat(
                                                            (e.target as HTMLInputElement).value
                                                        )
                                                    ) &&
                                                    updateLinkProductQty(item.key as libTypes.ConstructionType, linkProduct.id, 
                                                            parseFloat(
                                                                (e.target as HTMLInputElement).value
                                                            )
                                                        );
                                                }}
                                            />
                                            <Td colspan={1} align="c">
                                                <Icon
                                                    name="delete-bin"
                                                    onClick={() => removeLinkProduct(item.key as libTypes.ConstructionType, linkProduct.id)}
                                                ></Icon>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table></Spacer>
                        )}
                            </Island>
                        </Spacer>
                    ))}
            </Spacer>
        </>
    );
};
