
import {Spacer, Grid, OptionCard} from '@holta/ui';
import {useModalSupplierProductImport} from '../context';


export const FinishImport = () => {

    const {chosenImportType, setChosenImportType, doImport} = useModalSupplierProductImport();


    return (
        
            <Spacer xs={[1]}>
                <Grid.Row xs={{wrap: 'wrap', align: 'center', justify: 'center'}}>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <OptionCard onClick={() => setChosenImportType('REPLACE')} isChosen={chosenImportType === 'REPLACE'} heading="Replace " icon="checkbox-multiple" />
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <OptionCard onClick={() => setChosenImportType('UPDATE')} isChosen={chosenImportType === 'UPDATE'} heading="Update" icon="checkbox-blank" />
                        </Spacer>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row xs={{wrap: 'wrap', align: 'center', justify: 'center'}}>
                    <button className="primary" disabled={!chosenImportType} onClick={doImport}>Import</button>
                </Grid.Row>
            </Spacer>

    )
}