import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { deleteProductComponent } from "../../actions/productComponents";
import { useStore } from "../../config/store";
import React from "react";


// NOTE: deleteProductComponent shouldn'ty be imported directly. Should follow the same pattern as save etc.


export const ModalProductComponentContext = React.createContext<ReturnType<typeof useModalProductComponentFns>
    | undefined
>(undefined);

export const useModalProductComponentContext = () => {
    const context = React.useContext(ModalProductComponentContext);
    if (context === undefined) {
        throw new Error("useModalProductComponentContext must be within the correct provider");
    }
    return context;
};

export const ModalProductComponentContextProvider = ({ children }: { children: React.ReactNode }) => {
    const value = useModalProductComponentFns();

    return (
        <ModalProductComponentContext.Provider
            value={value}
        >
            {children}
        </ModalProductComponentContext.Provider>
    );
};

const useModalProductComponentFns = () => {
    const [productComponent, setProductComponent] = React.useState<libTypes.ProductComponent | null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.productComponent.validate.productComponent
    > | null>(null);
    const handleConfirmFn = React.useRef<(productComponent: libTypes.ProductComponent) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const productComponents = useStore((store) => store.productComponents);

    const validate = () => {
        setValidationResult(
            productComponent &&
                fns.productComponent.validate.productComponent(
                    productComponent,
                    productComponents.filter((c) => c.id !== productComponent.id)
                )
        );
    };

    

    const setCode = (code: string) => {
        productComponent && setProductComponent(fns.productComponent.set.code(productComponent, code));
        setIsEdited(true);
    };

    const setCategories = (categories: string[]) => {
        productComponent && setProductComponent(fns.productComponent.set.categories(productComponent, categories));
        setIsEdited(true);
    };

    const setD = (d: number) => {
        productComponent && setProductComponent(fns.productComponent.set.d(productComponent, d));
        setIsEdited(true);
    };

    const setDescription = (description: string) => {
        productComponent && setProductComponent(fns.productComponent.set.description(productComponent, description));
        setIsEdited(true);
    };

    const setH = (h: number) => {
        productComponent && setProductComponent(fns.productComponent.set.h(productComponent, h));
        setIsEdited(true);
    };

    const setPrice = (price: number) => {
        productComponent && setProductComponent(fns.productComponent.set.price(productComponent, price));
        setIsEdited(true);
    };

    const setDiscount = (discount: number) => {
        productComponent && setProductComponent(fns.productComponent.set.discount(productComponent, discount));
        setIsEdited(true);
    };

    const setMargin = (margin: number) => {
        productComponent && setProductComponent(fns.productComponent.set.margin(productComponent, margin));
        setIsEdited(true);
    };

    const setSpecification = (specification: string) => {
        productComponent && setProductComponent(fns.productComponent.set.specification(productComponent, specification));
        setIsEdited(true);
    };

    const setSupplierId = (supplierId: string | null) => {
        productComponent && setProductComponent(fns.productComponent.set.supplierId(productComponent, supplierId));
        setIsEdited(true);
    };

    const setW = (w: number) => {
        productComponent && setProductComponent(fns.productComponent.set.w(productComponent, w));
        setIsEdited(true);
    };

    const setPriceType = (priceType: "SIMPLE" | "EXPRESSION") => {
        productComponent && setProductComponent(fns.productComponent.set.priceType(productComponent, priceType));
        setIsEdited(true);
    };

    const setPriceExpression = (expression: string) => {
        productComponent && setProductComponent(fns.productComponent.set.priceExpression(productComponent, expression));
        setIsEdited(true);
    };


    const handleDelete = () => {
        if(!productComponent) return;
        openGenericDialog(
            "Ths will permenantly remove this Product Components.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteProductComponent(productComponent)
                    .then(() => {
                        closeModal("MODAL_PRODUCT_COMPONENT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting productComponent", "Error", false, true);
                    });
            }
        );
    };

    const wrappedOpenModal = (
        productComponent: libTypes.ProductComponent,
        isNew: boolean,
        handleConfirm: (productComponent: libTypes.ProductComponent) => void,
        handleCancel?: () => void
    ) => {
        setProductComponent(productComponent);
        setIsEdited(false);
        setIsNew(isNew);
        //setIsEditable(isNew);
        // TODO: enable this feature
        setIsEditable(true);
        openModal("MODAL_PRODUCT_COMPONENT");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        setProductComponent(null);
        setIsEdited(false);
        setIsEditable(false);
        setIsNew(false);
        setValidationResult(null);
        closeModal("MODAL_PRODUCT_COMPONENT");
    };

    const confirm = () => {
        if (!productComponent) {
            closeModal("MODAL_PRODUCT_COMPONENT");
            return;
        }
        if (!fns.productComponent.validate.productComponent(productComponent, productComponents)._isValid) {
            console.log(fns.productComponent.validate.productComponent(productComponent, productComponents))
            openGenericDialog("Product Component is not valid, please check the input values");
        } else {
            handleConfirmFn.current && productComponent && handleConfirmFn.current(productComponent);
            closeModal("MODAL_PRODUCT_COMPONENT");
        }
    };

    return {
                productComponent,
                isEdited,
                isNew,
                handleDelete,
                isEditable,
                setIsEditable,
                openModalProductComponent: wrappedOpenModal,
                setCode,
                setCategories,
                setD,
                setDescription,
                setH,
                setPrice,
                setDiscount,
                setMargin,
                setSpecification,
                setSupplierId,
                setW,
                setPriceType,
                setPriceExpression,
                cancel,
                confirm,
                validate,
                validationResult,
            }
    
};
