
import { fns } from "@holta/lib";
import { getEditOrder } from "./getEditOrder";


/**
 * Returns the merged order by combining the editOrder.current.order and editOrder.newValues.order.
 * 
 * @returns The merged order object.
 */

export function getMergedOrder() {
    return fns.order.merge(
        getEditOrder().currentValues.order,
        getEditOrder().newValues.order
    );
}