import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { deleteLinkProduct } from "../../actions/linkProducts";
import { useStore } from "../../config/store";
import React from "react";


// NOTE: deleteLinkProduct shouldn'ty be imported directly. Should follow the same pattern as save etc.


export const ModalLinkProductContext = React.createContext<
    | {
          linkProduct: libTypes.LinkProduct | null;
          isEdited: boolean;
          isNew: boolean;
          isEditable: boolean;
          setIsEditable: (isEditable: boolean) => void;
          handleDelete: () => void;
          openModalLinkProduct: (
              linkProduct: libTypes.LinkProduct,
              isNew: boolean,
              handleConfirm: (linkProduct: libTypes.LinkProduct) => void,
              handleCancel?: () => void
          ) => void;
          setName: (name: string) => void;
          setCategories: (categories: string[]) => void;
          setD: (d: number) => void;
          setDescription: (description: string) => void;
          setH: (h: number) => void;
          setW: (w: number) => void;
          cancel: () => void;
          confirm: () => void;
          validate: () => void;
          validationResult: ReturnType<typeof fns.linkProduct.validate.linkProduct> | null;
      }
    | undefined
>(undefined);

export const useModalLinkProductContext = () => {
    const context = React.useContext(ModalLinkProductContext);
    if (context === undefined) {
        throw new Error("useModalLinkProductContext must be within the correct provider");
    }
    return context;
};

export const ModalLinkProductContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [linkProduct, setLinkProduct] = React.useState<libTypes.LinkProduct | null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.linkProduct.validate.linkProduct
    > | null>(null);
    const handleConfirmFn = React.useRef<(linkProduct: libTypes.LinkProduct) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const linkProducts = useStore((store) => store.linkProducts);

    const validate = () => {
        setValidationResult(
            linkProduct &&
                fns.linkProduct.validate.linkProduct(
                    linkProduct,
                    linkProducts.filter((c) => c.id !== linkProduct.id)
                )
        );
    };

    

    const setName = (name: string) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.name(linkProduct, name));
        setIsEdited(true);
    };

    const setCategories = (categories: string[]) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.categories(linkProduct, categories));
        setIsEdited(true);
    };

    const setD = (d: number) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.d(linkProduct, d));
        setIsEdited(true);
    };

    const setDescription = (description: string) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.description(linkProduct, description));
        setIsEdited(true);
    };

    const setH = (h: number) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.h(linkProduct, h));
        setIsEdited(true);
    };

    const setW = (w: number) => {
        linkProduct && setLinkProduct(fns.linkProduct.set.w(linkProduct, w));
        setIsEdited(true);
    };



    const handleDelete = () => {
        if(!linkProduct) return;
        openGenericDialog(
            "Ths will permenantly remove this link product.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteLinkProduct(linkProduct)
                    .then(() => {
                        closeModal("MODAL_LINK_PRODUCT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting link Product", "Error", false, true);
                    });
            }
        );
    };

    const wrappedOpenModal = (
        linkProduct: libTypes.LinkProduct,
        isNew: boolean,
        handleConfirm: (linkProduct: libTypes.LinkProduct) => void,
        handleCancel?: () => void
    ) => {
        setLinkProduct(linkProduct);
        setIsEdited(false);
        setIsNew(isNew);
        // TODO: Enable this
        //setIsEditable(isNew);
        setIsEditable(true);
        openModal("MODAL_LINK_PRODUCT");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        setLinkProduct(null);
        setIsEdited(false);
        setIsEditable(false);
        setIsNew(false);
        setValidationResult(null);
        closeModal("MODAL_LINK_PRODUCT");
    };

    const confirm = () => {
        if (!linkProduct) {
            closeModal("MODAL_LINK_PRODUCT");
            return;
        }
        if (!fns.linkProduct.validate.linkProduct(linkProduct, linkProducts)._isValid) {
            openGenericDialog("Link product is not valid, please check the input values");
        } else {
            handleConfirmFn.current && linkProduct && handleConfirmFn.current(linkProduct);
            closeModal("MODAL_LINK_PRODUCT");
        }
    };

    return (
        <ModalLinkProductContext.Provider
            value={{
                linkProduct,
                isEdited,
                isNew,
                handleDelete,
                isEditable,
                setIsEditable,
                openModalLinkProduct: wrappedOpenModal,
                setName,
                setCategories,
                setD,
                setDescription,
                setH,
                setW,
                cancel,
                confirm,
                validate,
                validationResult,
            }}
        >
            {children}
        </ModalLinkProductContext.Provider>
    );
};
