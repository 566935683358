import { createAction } from "../fns/createAction";
import { fns, libTypes, staticData } from "@holta/lib";
import { Supplier } from "@holta/lib/types";
import { user } from "../fns/user";
import { StoreEditSupplier } from "../config/store";
import { useStore } from "../hooks/useStore";
import { feathersApp } from "../config/feathers";
import { navigate } from "raviger";
import { saveContactsToDb } from "./contacts";
import { saveAddressesToDb } from "./addresses";

// Clear the editSupplier state
export const clearAll = createAction("EDIT_SUPPLIER_CLEAR_ALL", (newState) => {
    newState.editSupplier = null;
});

// Initialise the state with a new supplier
export const initialiseNewSupplier = createAction("EDIT_SUPPLIER_INITIALISE_NEW", (newState) => {
    if (user.id === null) {
        throw new Error("User is not logged in");
    }

    newState.editSupplier = {
        type: "NEW",
        newValues: {
            supplier: {},
            addresses: [],
            contacts: [],
            ranges: [],
            baseProducts: [],
            products: [],
        },
        currentValues: {
            supplier: fns.supplier.create(user.id),
            addresses: [],
            contacts: [],
            ranges: [],
            baseProducts: [],
            products: [],
        },
    };
});

// Load supplier from the server
export const setSupplier = createAction(
    "EDIT_SUPPLIER_LOAD_SUPPLIER",
    (
        newState,
        supplier: libTypes.Supplier,
        addresses: libTypes.Address[],
        contacts: libTypes.Contact[],
        ranges: libTypes.SupplierRange[],
        baseProducts: libTypes.SupplierBaseProduct[],
        products: libTypes.SupplierComputedProduct[]
    ) => {
        newState.editSupplier = {
            type: "EDIT",
            newValues: {
                supplier: {},
                addresses: [],
                contacts: [],
                ranges: [],
                baseProducts: [],
                products: [],
            },
            currentValues: {
                supplier,
                addresses,
                contacts,
                ranges,
                baseProducts,
                products,
            },
        };
    }
);

export const loadSupplier = async (id: string) => {
    const supplier = await feathersApp.service("suppliers").get(id);
    const addresses = await feathersApp.service("addresses").find({ query: { parentId: id } });
    const contacts = await feathersApp.service("contacts").find({ query: { parentId: id } });
    const ranges = await feathersApp.service("supplier-ranges").find({ query: { supplierId: id } });
    const baseProducts = await feathersApp
        .service("supplier-base-products")
        .find({ query: { supplierId: id } });
    const products = await feathersApp
        .service("supplier-products")
        .find({ query: {supplierId: id, $eager: "supplierBaseProduct" }});

    return setSupplier(
        supplier,
        addresses.data,
        contacts.data,
        ranges.data,
        baseProducts.data,
        products.data
    );
};

function getSupplierFromStore() {
    const editedSupplier = useStore.getState().editSupplier;
    if (editedSupplier === null) {
        throw new Error("No supplier to edit");
    }
    return editedSupplier;
}

// Set the editSupplier Name
export const setSupplierName = createAction("EDIT_SUPPLIER_SET_NAME", (newState, name: string) => {
    try {
        const editedSupplier = getSupplierFromStore();
        const newName = fns.supplier.set.name(name);

        if (editedSupplier.currentValues.supplier.name === newName) {
            delete (newState.editSupplier?.newValues.supplier as any).name;
            return;
        }

        (newState.editSupplier?.newValues.supplier as libTypes.Supplier).name = newName;
    } catch (e) {}
});

// Set the editSupplier notes
export const setSupplierNotes = createAction(
    "EDIT_SUPPLIER_SET_NOTES",
    (newState, notes: string) => {
        try {
            const editedSupplier = getSupplierFromStore();
            const newNotes = fns.supplier.set.notes(notes);
            if (editedSupplier.currentValues.supplier.notes === newNotes) {
                delete (newState.editSupplier?.newValues.supplier as any).notes;
                return;
            }

            (newState.editSupplier?.newValues.supplier as libTypes.Supplier).notes = newNotes;
        } catch (error) {}
    }
);

export const setSupplierDiscount = createAction(
    "EDIT_SUPPLIER_SET_DISCOUNT",
    (newState, discount: string) => {
        try {
            const editedSupplier = getSupplierFromStore();
            const discountAsNumber = parseFloat(discount);
            const newdiscount = fns.supplier.set.discount(discountAsNumber);

            if (editedSupplier.currentValues.supplier.discount === newdiscount) {
                delete (newState.editSupplier?.newValues.supplier as any).discount;
                return;
            }

            (newState.editSupplier?.newValues.supplier as libTypes.Supplier).discount = newdiscount;
        } catch (error) {}
    }
);

export const setSupplierMargin = createAction(
    "EDIT_SUPPLIER_SET_MARGIN",
    (newState, discount: string) => {
        try {
            const editedSupplier = getSupplierFromStore();
            const discountAsNumber = parseFloat(discount);
            const newMargin = fns.supplier.set.margin(discountAsNumber);

            if (editedSupplier.currentValues.supplier.margin === newMargin) {
                delete (newState.editSupplier?.newValues.supplier as any).margin;
                return;
            }

            (newState.editSupplier?.newValues.supplier as libTypes.Supplier).margin = newMargin;
        } catch (error) {}
    }
);

export const saveContact = createAction(
    "EDIT_SUPPLIER_SAVE_CONTACT",
    (newState, contact: StoreEditSupplier["newValues"]["contacts"][number], isDefault) => {
        // Get the edited supplier from the store
        const editedSupplier = getSupplierFromStore();

        // If this contact already exists in an edited state...
        if (editedSupplier.newValues.contacts.find((c) => c.id === contact.id)) {
            // get its array index
            const index = editedSupplier.newValues.contacts.findIndex((c) => c.id === contact.id);

            // if it's a new contact and its being deleted...
            if (contact._deleted && contact._dbUnsaved)
                delete (newState.editSupplier as StoreEditSupplier).newValues.contacts[index];
            // otherwise, update it
            else (newState.editSupplier as StoreEditSupplier).newValues.contacts[index] = contact;

            // If this is a newly edited contact...
        } else {
            // Add the contact to the edited contacts array
            (newState.editSupplier as StoreEditSupplier).newValues.contacts.push(contact);
        }

        if (isDefault && !contact._deleted) {
            (newState.editSupplier as StoreEditSupplier).newValues.supplier.defaultContact =
                contact.id;
        }
    }
);

export const saveAddress = createAction(
    "EDIT_SUPPLIER_SAVE_ADDRESS",
    (newState, address: StoreEditSupplier["newValues"]["addresses"][number], placeholder, isDefaultInvoice) => {
        // Get the edited supplier from the store
        const editedSupplier = getSupplierFromStore();
        const mergedSupplier = fns.supplier.merge(
            editedSupplier?.currentValues.supplier,
            editedSupplier?.newValues.supplier
        );

        // If this address already exists in an edited state...
        if (editedSupplier.newValues.addresses.find((c) => c.id === address.id)) {
            // get its array index
            const index = editedSupplier.newValues.addresses.findIndex((c) => c.id === address.id);

            // if it's a new address and its being deleted...
            if (address._deleted && address._dbUnsaved)
                delete (newState.editSupplier as StoreEditSupplier).newValues.addresses[index];
            // otherwise, update it
            else (newState.editSupplier as StoreEditSupplier).newValues.addresses[index] = address;

            // If this is a newly edited address...
        } else {
            // Add the address to the edited addresses array
            (newState.editSupplier as StoreEditSupplier).newValues.addresses.push(address);
        }

        if (isDefaultInvoice && !address._deleted && mergedSupplier.defaultAddress !== address.id) {
            (newState.editSupplier)!.newValues.supplier.defaultAddress =
                address.id;
        } else if ((mergedSupplier.defaultAddress === address.id && address._deleted) || (!isDefaultInvoice && mergedSupplier.defaultAddress === address.id )) {
            (newState.editSupplier)!.newValues.supplier.defaultAddress = null;
        }
    }
);

export const saveRange = createAction(
    "EDIT_SUPPLIER_SAVE_RANGE",
    (newState, range: StoreEditSupplier["newValues"]["ranges"][number]) => {
        // Get the edited supplier from the store
        const editedSupplier = getSupplierFromStore();

        // If this range already exists in an edited state...
        if (editedSupplier.newValues.ranges.find((c) => c.id === range.id)) {
            // get its array index
            const index = editedSupplier.newValues.ranges.findIndex((c) => c.id === range.id);

            // if it's a new range and its being deleted...
            if (range._deleted && range._dbUnsaved)
                delete (newState.editSupplier as StoreEditSupplier).newValues.ranges[index];
            // otherwise, update it
            else (newState.editSupplier as StoreEditSupplier).newValues.ranges[index] = range;

            // If this is a newly edited range...
        } else {
            // Add the range to the edited ranges array
            (newState.editSupplier as StoreEditSupplier).newValues.ranges.push(range);
        }
    }
);

export const saveBaseProduct = createAction(
    "EDIT_SUPPLIER_SAVE_BASE_PRODUCT",
    (newState, range: StoreEditSupplier["newValues"]["baseProducts"][number]) => {
        // Get the edited supplier from the store
        const editedSupplier = getSupplierFromStore();

        // If this range already exists in an edited state...
        if (editedSupplier.newValues.baseProducts.find((c) => c.id === range.id)) {
            // get its array index
            const index = editedSupplier.newValues.baseProducts.findIndex((c) => c.id === range.id);

            // if it's a new range and its being deleted...
            if (range._deleted && range._dbUnsaved)
                delete (newState.editSupplier as StoreEditSupplier).newValues.baseProducts[index];
            // otherwise, update it
            else (newState.editSupplier as StoreEditSupplier).newValues.baseProducts[index] = range;

            // If this is a newly edited range...
        } else {
            // Add the range to the edited baseProducts array
            (newState.editSupplier as StoreEditSupplier).newValues.baseProducts.push(range);
        }
    }
);

export const saveSupplier = async () => {
    const editedSupplier = useStore.getState().editSupplier;

    if (!editedSupplier?.currentValues.supplier || !editedSupplier?.newValues.supplier)
        throw new Error("No supplier to edit");

    const mergedSupplier = fns.supplier.merge(
        editedSupplier?.currentValues.supplier,
        editedSupplier?.newValues.supplier
    );

    const supplierNames = await feathersApp
        .service("suppliers")
        .find({ query: { $limit: 1000000000, $select: ["name", "id"] } });

    const filtereredSupplierNames = supplierNames.data
        .filter((c: libTypes.Supplier) => c.id !== mergedSupplier.id)
        .map((c: libTypes.Supplier) => c.name);

    const isValid = fns.supplier.validate.supplier(
        mergedSupplier,
        filtereredSupplierNames,
        Object.keys(staticData.taxGroups)
    )._isValid;

    if (!isValid) throw new Error("Invalid supplier details");

    if (editedSupplier?.type === "NEW") {
        const returnedSupplier = await feathersApp.service("suppliers").create(mergedSupplier);
        await saveContactsToDb(editedSupplier.newValues.contacts);
        await saveAddressesToDb(editedSupplier.newValues.addresses);
        await saveRangesToDb(editedSupplier.newValues.ranges);
        await saveBaseProductsToDb(editedSupplier.newValues.baseProducts);
        await saveProductsToDb(editedSupplier.newValues.products);
        navigate(`/suppliers/${returnedSupplier.id}`);
    }

    if (editedSupplier?.type === "EDIT") {
        const returnedSupplier = await feathersApp
            .service("suppliers")
            .update(mergedSupplier.id, mergedSupplier);
        await saveContactsToDb(editedSupplier.newValues.contacts);
        await saveAddressesToDb(editedSupplier.newValues.addresses);
        await saveRangesToDb(editedSupplier.newValues.ranges);
        await saveBaseProductsToDb(editedSupplier.newValues.baseProducts);
        await saveProductsToDb(editedSupplier.newValues.products);
        await loadSupplier(returnedSupplier.id);
        return;
    }
};

async function saveRangesToDb(ranges: StoreEditSupplier["newValues"]["ranges"]) {
    const newRanges = ranges.filter((c) => c._dbUnsaved);
    const updatedRanges = ranges.filter((c) => !c._dbUnsaved && !c._deleted);
    const deletedRanges = ranges.filter((c) => c._deleted);

    if (newRanges.length > 0) {
        await feathersApp
            .service("supplier-ranges")
            .create(newRanges.map(({ _dbUnsaved, ...rest }) => ({ ...rest })));
    }
    if (updatedRanges.length > 0) {
        for (const range of updatedRanges) {
            await feathersApp.service("supplier-ranges").update(range.id, range);
        }
    }
    if (deletedRanges.length > 0)
        await feathersApp
            .service("supplier-ranges")
            .remove(null, { query: { id: { $in: deletedRanges.map(({ id }) => id) } } });
}

async function saveBaseProductsToDb(baseProducts: StoreEditSupplier["newValues"]["baseProducts"]) {
    const newBaseProducts = baseProducts.filter((c) => c._dbUnsaved);
    const updatedBaseProducts = baseProducts.filter((c) => !c._dbUnsaved && !c._deleted);
    const deletedBaseProducts = baseProducts.filter((c) => c._deleted);

    if (newBaseProducts.length > 0)
        await feathersApp
            .service("supplier-base-products")
            .create(newBaseProducts.map(({ _dbUnsaved, ...rest }) => ({ ...rest })));
    if (updatedBaseProducts.length > 0) {
        for (const baseProduct of updatedBaseProducts) {
            await feathersApp.service("supplier-base-products").update(baseProduct.id, baseProduct);
        }
    }
    if (deletedBaseProducts.length > 0)
        await feathersApp
            .service("supplier-base-products")
            .remove(null, { query: { id: { $in: deletedBaseProducts.map(({ id }) => id) } } });
}

async function saveProductsToDb(products: StoreEditSupplier["newValues"]["products"]) {
    const newProducts = products.filter((c) => c._dbUnsaved);
    const updatedProducts = products.filter((c) => !c._dbUnsaved && !c._deleted);
    const deletedProducts = products.filter((c) => c._deleted);

    if (newProducts.length > 0)
        await feathersApp
            .service("supplier-products")
            .create(newProducts.map(({ _dbUnsaved, ...rest }) => ({ ...rest })));
    if (updatedProducts.length > 0) {
        for (const product of updatedProducts) {
            await feathersApp.service("supplier-products").update(product.id, product);
        }
    }
    if (deletedProducts.length > 0)
        await feathersApp
            .service("supplier-products")
            .remove(null, { query: { id: { $in: deletedProducts.map(({ id }) => id) } } });
}

export const deleteSupplierAndAddresses = async (supplierId: string) => {
    await feathersApp.service("suppliers").remove(supplierId);
    navigate(`/suppliers`);
};
