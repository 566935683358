import { ModalFooter, useModal, Spacer } from "@holta/ui";

import { useSupplierProductBrowserContext } from "../context";
import React from "react";

export const Footer = () => {
    const { closeModal } = useModal();
    const { modalId, handleConfirm, selectedProduct } = useSupplierProductBrowserContext();

    return (
        <ModalFooter>
            <button onClick={() => closeModal(modalId)}>Cancel</button>
            {selectedProduct && (
                <>
                    <Spacer xs={[0.5]} />
                    <button className="primary" onClick={handleConfirm}>
                        Add '{selectedProduct.code}'
                    </button>
                </>
            )}
        </ModalFooter>
    );
};
