import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
    OptionCard,
    CircleButton,
} from "@holta/ui";
import { Link } from "raviger";
import { Header } from "./parts/Header";

export const Products = () => {

    const {openModal} = useModal();
    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
            </div>
            <Spacer xs={[1]}>
                <Grid.Row xs={{wrap: 'wrap'}}>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/holta_products"><OptionCard  heading="Holta Products" icon="hammer" /></Link>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/product_components"><OptionCard  heading="Components" icon="shape-2" /></Link>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/suppliers"><OptionCard heading="Suppliers" icon="archive" /></Link>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/categories"><OptionCard  heading="Product Categories" icon="price-tag" /></Link>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/link_products"><OptionCard  heading="Product Links" icon="links" /></Link>
                        </Spacer>
                    </Grid.Col>
                    { /* 
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/mfc_board_colours"><OptionCard  heading="MFC Board Colours" icon="checkbox-multiple-blank" type="fill" /></Link>
                        </Spacer>
                    </Grid.Col>
                    
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/mfc_pricing_groups"><OptionCard  heading="MFC Pricing Groups" icon="money-pound-circle" /></Link>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 2 }}>
                        <Spacer xs={[1]}>
                            <Link href="/products/sheet_materials"><OptionCard  heading="Sheet Materials" icon="checkbox-multiple-blank" /></Link>
                        </Spacer>
                    </Grid.Col>
                    */}
                </Grid.Row>
            </Spacer>
        </Container>
    );
};
