import {
    FormInput,
    FormLabel,
    Grid,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    FormNotice,
    FormNumber
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import {
    setCustomerName,
    setCustomerNotes,
    setCustomerDiscount,
    setCustomerSupplierDiscount,
    setCustomerPaymentTerms,
    setCustomerTaxGroup,
} from "../../../actions/editCustomer";
import { useMergedCustomer } from "../hooks/useMergedCustomerValues";
import { libTypes, staticData } from "@holta/lib";
import { useCustomerContext } from "../context";

interface Props {
    children?: React.ReactNode;
}

export const CustomerDetails: React.FC<Props> = ({ children }) => {
    const customer = useMergedCustomer();
    const {validate, validation } = useCustomerContext();

    return (
        <div>
            <Spacer xs={[2]}>
                <Text size="xl">General</Text>

                <Spacer xs={[2, 0, 1, 0]}>
                    <FormLabel>Customer Name</FormLabel>
                    <FormInput
                        style={{ width: "100%" }}
                        onChange={(e) => setCustomerName(e.target.value)}
                        onBlur={validate}
                        value={customer?.name}
                    ></FormInput>
                    {validation?.name && !validation.name._isValid && <FormNotice>{validation.name._errors[0]}</FormNotice>}
                </Spacer>

                <Spacer xs={[1, 0, 1, 0]}>
                    <FormLabel>Customer Notes</FormLabel>
                    <FormTextarea
                        rows={5}
                        style={{ width: "100%" }}
                        onChange={(e) => setCustomerNotes(e.target.value)}
                        value={customer?.notes}
                    />
                </Spacer>
            </Spacer>
            <hr />
            <Spacer xs={[2]}>
                <Text size="xl">Pricing</Text>

                <Grid.Row>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 1, 1, 0]}>
                            <FormLabel>Discount (%)</FormLabel>
                            <FormNumber 
                                style={{ width: "100%" }}
                                onBlur={(e) => {setCustomerDiscount(e.target.value || "0")} }
                                value={customer?.discount}
                                isFloat
                                placeholder="0"
                             />
                            
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 0, 1, 1]}>
                            <FormLabel>Supplier Discount (%)</FormLabel>
                            <FormInput
                                style={{ width: "100%" }}
                                onChange={(e) => setCustomerSupplierDiscount(e.target.value)}
                                value={customer?.supplierDiscount}
                            ></FormInput>
                        </Spacer>
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 1, 1, 0]}>
                            <FormLabel>Payment Terms</FormLabel>
                            <FormInput
                                style={{ width: "100%" }}
                                onChange={(e) => setCustomerPaymentTerms(e.target.value)}
                                value={customer?.paymentTerms}
                            ></FormInput>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 0, 1, 1]}>
                            <FormLabel>Tax Group</FormLabel>
                            <FormSelect
                                style={{ width: "100%" }}
                                onChange={(option :any) => setCustomerTaxGroup(option.value)}
                                value={Object.values(staticData.taxGroups).find(tg => tg.key === customer?.taxGroup) || null}
                                options={Object.values(staticData.taxGroups).map(
                                    (taxGroup: libTypes.TaxGroups[libTypes.TaxGroup]) => (
                                        {value: taxGroup.key, label: taxGroup.label}
                                    )
                                )}
                            />
                        </Spacer>
                    </Grid.Col>
                </Grid.Row>
            </Spacer>
        </div>
    );
};
