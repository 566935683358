import { createAction } from "../fns/createAction";
import { fns, libTypes } from "@holta/lib";
import { ProductComponent } from "@holta/lib/types";
import { feathersApp } from "../config/feathers";
import toast from 'react-hot-toast';

export const setProductComponents = createAction("SET_PRODUCT_PRODUCT_COMPONENTS", (newState, productComponents: ProductComponent[]) => {
    newState.productComponents = fns.shared.sortByString(productComponents, 'code', 'ASC');
});

export const saveProductComponent = (productComponent: libTypes.ProductComponent) => {
    return toast.promise(feathersApp.service("product-components").create(productComponent), {
        loading: 'Creating Product Component',
        success: 'Product Component Created',
        error: 'Error Creating Product Component'
    })
    
}

export const updateProductComponent = (productComponent: libTypes.ProductComponent) => {
    return toast.promise(feathersApp.service("product-components").patch(productComponent.id, productComponent), {
        loading: 'Saving Product Component',
        success: 'Product Component Saved',
        error: 'Error Saving Product Component'
    })
}




export const deleteProductComponent = async (productComponent: libTypes.ProductComponent) => {
    if(!productComponent) throw new Error("Product Component is required");

    return toast.promise(feathersApp.service('product-components').remove(productComponent.id), {
        loading: 'Deleting Product Component',
        success: 'Product Component Deleted',
        error: 'Error Deleting Product Component'
    })
}
