import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";


export const setOrderItemsSortOrder = (movingItemId: string, toItemId: string | 0) => {
    if (movingItemId === toItemId) return;

    const editOrder = getEditOrder();
    const mergedOrder = getMergedOrder();
    const sortedItems = fns.order_items.sortItems(mergedOrder.items);

    // insert the moving item into the sorted list after the to item using splice
    const movingItemIndex = sortedItems.findIndex((item) => item.id === movingItemId);
    const movingItem = sortedItems[movingItemIndex];
    sortedItems.splice(movingItemIndex, 1);

    const toItemIndex = toItemId === 0 ? 0 : sortedItems.findIndex((item) => item.id === toItemId);
    sortedItems.splice(toItemIndex + 1, 0, movingItem);

    // update the sort order of each item
    const newItems = sortedItems.map((item, index) => ({
        id: item.id,
        sortOrder: index + 1,
    }));

    useStore.setState({
        editOrder: create(editOrder, (draft) => {
            draft.newValues.order.items = fns.order_items.combineEdits(
                editOrder.newValues.order.items || [],
                newItems
            );
        }),
    });
};
