import { libTypes, fns } from "@holta/lib";
import React, { useRef } from "react";
import { useDialog, useLoading, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { sessionId } from "../../config/sessionId";
import {loadSupplier} from "../../actions/editSupplier";

export const ModalSupplierProductImport =
    React.createContext<
        | {
              openModalSupplierProductImport: (supplier: libTypes.Supplier) => void;
              tab: number;
              setTab: (tab: number) => void;
              uploadFile: (file: HTMLInputElement["files"]) => void;
              setMatchedHeading: (ours: string, theirs: string) => void;
              matchedHeadings: {
                  [key: string]: string;
              };
              availableHeadings: string[];
              toggleSaveHeadingsToDb: () => void;
              saveHeadingsToDb: boolean;
              bulkMatchedUniqueProducts: string[];
              toggleBulkMatchedUniqueProduct: (code: string) => void;
              toggleAllBulkMatchedUniqueProducts: () => void;
              allHeadingsMatch: boolean;
              submitHeadings: () => void;
              unmatchedProducts: {
                  noMatch: libTypes.SupplierProduct[];
                  hasRangeOnly: libTypes.SupplierProduct[];
              };
              supplier: libTypes.Supplier | undefined;
              saveRangeToDb: (range: libTypes.SupplierRange) => void;
              saveBaseProductToDb: (product: libTypes.SupplierBaseProduct| string[]) => void;
              chosenImportType: "UPDATE" | "REPLACE" | null;
              setChosenImportType: (type: "UPDATE" | "REPLACE") => void;
              doImport: () => void;
          }
        | undefined
    >(undefined);

export const useModalSupplierProductImport = () => {
    const context = React.useContext(ModalSupplierProductImport);
    if (context === undefined) {
        throw new Error("useModalSupplierProductImport must be within the correct provider");
    }
    return context;
};

export function ModalSupplierProductImportProvider({ children }: { children: React.ReactNode }) {
    const supplierRef = useRef<libTypes.Supplier>();
    const { openModal, closeModal } = useModal();
    const { openLoading, closeLoading } = useLoading();
    const [tab, setTab] = React.useState(0);
    const { openGenericDialog } = useDialog();
    const [returnedHeadings, setReturnedHeadings] = React.useState<string[]>([]);
    const [chosenImportType, setChosenImportType] =
        React.useState<"UPDATE" | "REPLACE" | null>(null);
    const [unmatchedProducts, setUnmatchedProducts] = React.useState<{
        noMatch: libTypes.SupplierProduct[];
        hasRangeOnly: libTypes.SupplierProduct[];
    }>({ noMatch: [], hasRangeOnly: [] });
    const [matchedHeadings, setMatchedHeadings] = React.useState<{
        code?: string;
        description?: string;
        price?: string;
        h?: string;
        w?: string;
        d?: string;
    }>({});
    const [saveHeadingsToDb, setSaveHeadingsToDb] = React.useState(false);
    const [bulkMatchedUniqueProducts, setBulkMatchedUniqueProducts] = React.useState<string[]>([]);

    const toggleBulkMatchedUniqueProduct = (code: string) => {
        if (bulkMatchedUniqueProducts.includes(code)) {
            setBulkMatchedUniqueProducts(bulkMatchedUniqueProducts.filter((c) => c !== code));
        } else {
            setBulkMatchedUniqueProducts([...bulkMatchedUniqueProducts, code]);
        }
    };

    const toggleAllBulkMatchedUniqueProducts = () => {
        if (bulkMatchedUniqueProducts.length === unmatchedProducts.noMatch.length) {
            setBulkMatchedUniqueProducts([]);
        } else {
            setBulkMatchedUniqueProducts(unmatchedProducts.noMatch.map((p) => p.code));
        }
    };


    const toggleSaveHeadingsToDb = () => {
        setSaveHeadingsToDb(!saveHeadingsToDb);
    };

    const requiredHeadings = ["code", "description", "h", "w", "d", "price"];

    const wrappedOpenModal = (supplier: libTypes.Supplier) => {
        supplierRef.current = supplier;
        setMatchedHeadings(supplier.matchedImportHeadings);
        openModal("MODAL_SUPPLIER_PRODUCT_IMPORT");
    };

    const uploadFile = (file: HTMLInputElement["files"]): void => {
        feathersApp.get("authentication").then(({ accessToken }: any) => {
            if (file) {
                const formData = new FormData();
                formData.append("importfile", file[0]);
                formData.append("sessionId", sessionId);
                formData.append("step", "1");

                fetch(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}/supplier-upload`, {
                    method: "POST",
                    body: formData,
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                    .then((res) => {
                        res.json().then((data) => {
                            if (Array.isArray(data)) {
                                setReturnedHeadings(data);
                                Object.entries(
                                    (supplierRef.current as libTypes.Supplier).matchedImportHeadings
                                ).forEach(([key, value]) => {
                                    if (data.includes(value)) {
                                        setMatchedHeading(key, value);
                                    }
                                });
                                setTab(1);
                            } else {
                                openGenericDialog(data.message, "Error");
                            }
                        });
                    })
                    .catch((err) => {
                        openGenericDialog("An error occurred. Please try again.", "Error");
                    });
            }
        });
    };

    const setMatchedHeading = (ours: string, theirs: string) => {
        setMatchedHeadings({ ...matchedHeadings, [ours]: theirs });
    };

    const saveRangeToDb = async (range: libTypes.SupplierRange) => {
        try {
            openLoading("Saving range...");
            await feathersApp.service("supplier-ranges").create(range);
            submitHeadings();
            closeLoading();
        } catch (err) {
            console.error(err);
        }
    };

    const saveBaseProductToDb = async (product: libTypes.SupplierBaseProduct | string[]) => {
        

        if(Array.isArray(product)) {
            (product as unknown) = product.map((pcode) => {
                return {
                    ...fns.supplierBaseProduct.create(supplierRef.current?.id!, {
                        type: "UNIQUE",
                        code: pcode,
                    }),
                };
            });
        } 
        try {
            openLoading("Saving...");
            await feathersApp.service("supplier-base-products").create(product);
            submitHeadings();
            closeLoading();
        } catch (err) {
            console.error(err);
        }
    };

    const allHeadingsMatch =
        Object.keys(matchedHeadings).length === requiredHeadings.length &&
        matchedHeadings?.code !== "none";

    const submitHeadings = async () => {
        if (saveHeadingsToDb) {
            try {
                await feathersApp
                    .service("suppliers")
                    .patch(supplierRef.current?.id, { matchedImportHeadings: matchedHeadings });
            } catch (error) {
                openGenericDialog("An error occurred. Please try again.", "Error");
            }
        }
        feathersApp.get("authentication").then(({ accessToken }: any) => {
            const formData = new FormData();
            formData.append("sessionId", sessionId);
            formData.append("supplierId", (supplierRef.current as libTypes.Supplier).id);
            formData.append("step", "2");
            formData.append("headings", JSON.stringify(matchedHeadings));

            fetch(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}/supplier-upload`, {
                method: "POST",
                body: formData,
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then((res) => {
                    res.json().then((data) => {
                        setUnmatchedProducts(data);
                        if (data.noMatch.length > 0) {
                            setTab(2);
                        } else if (data.hasRangeOnly.length > 0) {
                            setTab(3);
                        } else {
                            setTab(4);
                        }
                    });
                })
                .catch((err) => {
                    openGenericDialog("An error occurred. Please try again.", "Error");
                });
        });
    };

    const availableHeadings = [
        ...returnedHeadings.filter((heading) => {
            return !Object.values(matchedHeadings).includes(heading);
        }),
        "None",
    ];

    const doImport = async () => {
        openLoading("Importing...");
        
        if(!chosenImportType) throw new Error("No import type chosen");
        
        const formData = new FormData();
        formData.append("sessionId", sessionId);
        formData.append("supplierId", (supplierRef.current as libTypes.Supplier).id);
        formData.append("step", "3");
        formData.append("importType", chosenImportType);
        formData.append("headings", JSON.stringify(matchedHeadings));

        feathersApp.get("authentication").then(({ accessToken }: any) => {
        fetch(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}/supplier-upload`, {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then((res) => {
                res.json().then(async (data) => {
                    
                    if(data.type === "success") {
                        supplierRef.current && await loadSupplier(supplierRef.current?.id);
                        closeModal("MODAL_SUPPLIER_PRODUCT_IMPORT");
                        closeLoading();
                        openGenericDialog("Import complete.", "Success", true);
                    } else {
                        openGenericDialog("An error occurred. Please try again.", "Error");
                    }
                    
                });
            })
            .catch((err) => {
                closeLoading()
                openGenericDialog("An error occurred. Please try again.", "Error");
            });
        });
       
    };


    return (
        <ModalSupplierProductImport.Provider
            value={{
                openModalSupplierProductImport: wrappedOpenModal,
                tab,
                setTab,
                uploadFile,
                setMatchedHeading,
                matchedHeadings,
                availableHeadings,
                toggleSaveHeadingsToDb,
                saveHeadingsToDb,
                allHeadingsMatch,
                submitHeadings,
                toggleBulkMatchedUniqueProduct,
                toggleAllBulkMatchedUniqueProducts,
                bulkMatchedUniqueProducts,
                unmatchedProducts,
                supplier: supplierRef.current,
                saveRangeToDb,
                saveBaseProductToDb,
                chosenImportType,
                setChosenImportType,
                doImport
            }}
        >
            {children}
        </ModalSupplierProductImport.Provider>
    );
}

/*
    1. -> Upload File
    -
    2. <- Get headings
    3. Match headings
    4. -> Submit Headings
    -
    5. <- Get unmatched products
    6. -> Submit Ranges + Unique Products
    -
    7. <- Get unmatched base products
    8. -> Submit Base Products
    -
    9. Choose import Type
    10. -> Submit Import Type
*/
