import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
    NoResults
} from "@holta/ui";
import { Header } from "./parts/Header";
import { LinkProductsTable } from "./parts/LinkProductsTable";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import {FiltersNotificationBar} from "../../components/FiltersNotificationBar";
import {clearAllLinkProductsFilters} from "../../actions/ui";
import {useLinkProductsFilterCount} from '../../hooks/useLinkProductsFilterCount';
import {useFilteredLinkProducts} from '../../hooks/useFilteredLinkProducts';

export const LinkProducts = () => {

    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useLinkProductsFilterCount();
    const productComponents = useFilteredLinkProducts();

    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
                <div ref={filtersAnimationParent}>
                        {activeFilterCount > 0 && (
                            <Spacer xs={[0, 2, 2, 2]}>
                                <FiltersNotificationBar filtersCount={activeFilterCount} handleClear={clearAllLinkProductsFilters} />
                            </Spacer>
                        )}
                    </div>
            </div>
            <div style={{ flex: 1, overflow: "auto" }}>
                    {productComponents.length > 0 ? (
                        <LinkProductsTable />
                    ) : (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <NoResults text="No product Product Components found." />
                        </Spacer>
                    )}
                </div>

        </Container>
    );
};
