import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedSingleProducts = () : libTypes.SupplierComputedProduct[] => {
    const existingSingleProducts = useStore(state => state.editSupplier?.currentValues.products);
    return existingSingleProducts || [];

    /**
     * Currenty you cant edit products directly so this is redundant
    
    const editedSingleProducts = useStore(state => state.editSupplier?.newValues.products);
    

    const merged = [
        ...(editedSingleProducts?.filter(product => !product._deleted) || [])
    ];

    existingSingleProducts?.forEach(c => {
        if(!merged.find(c2 => c2.id === c.id)) {
            merged.push(c);
        }
    });

    return merged.sort((a,b) => a.code.localeCompare(b.code));
     */
}