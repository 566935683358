import { fns } from "@holta/lib";
import { FormSelect } from "@holta/ui";
import { useStore } from "../../config/store";
import React from "react";
import { createFilter } from "react-select";
import { feathersApp } from "../../config/feathers";

export const InputSupplierRangeSelect = React.forwardRef(({
    value,
    valueFallbackLabel,
    onAddNew,
    supplierId,
    onChange,
    ...rest
}: {
    value?: { id: string; name: string } | null;
    valueFallbackLabel?: string;
    supplierId?: string | null;
    onChange?: (value: { id: string; name: string }) => void;
} & Parameters<typeof FormSelect>[0], ref) => {

    const [ranges, setRanges] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (supplierId) {
            feathersApp.service("supplier-ranges").find({
                query: {
                    supplierId,
                    $limit: 1000,
                },
            }).then((res : any) => {
                setRanges(res.data);
            });
        }
    }, [supplierId]);


    const options = fns.shared.formSelect.createOptions("id", "name", ranges);
    const matchedOption = value ? options.find((option) => option.value === value.id) : null;
    const valueMatch = matchedOption || null;

    return (
        <FormSelect
            options={options}
            value={valueMatch}
            filterOption={createFilter({
                "matchFrom": "start",
            })}
            onChange={(option) => onChange && onChange({ id: option.value, name: option.label })}
            ref={ref}
            {...rest}
        />
    ); 
});
