import React from "react";

export const DashboardContext = React.createContext<{}>({});

export const useDashboardContext = () => {
    const context = React.useContext(DashboardContext);
    if (context === undefined) {
        throw new Error("useDashboardContext must be within the correct provider");
    }
    return context;
};
