import React from "react";
import styled from "styled-components";
import { Grid, Icon, Spacer, Text } from "@holta/ui";
import { Colors } from '@holta/ui';

interface Props {
    heading: string;
    value: string;
    icon: string;
    color?: keyof Colors,
    onClick? : () => void;
}

export const HeaderStatusTab: React.FC<Props> = ({color, heading, value, icon, onClick}) => {
    return (
        <Grid.Row xs={{ align: "center" }} style={{width: 'auto', flexShrink: 0}} onClick={onClick}>
            <Spacer xs={[1, 1.5]}>
                <Grid.Row xs={{ align: "center" }}>
                    <S.IconWrapper color={color || 'accent1'}>
                        <Icon name={icon} />
                    </S.IconWrapper>
                    <Spacer xs={[0.5]}></Spacer>
                    <div>
                        <div>
                            <Text bold size="s" color="accent3">{heading}</Text>
                        </div>
                        <div>
                            <Text size="l">{value}</Text>
                        </div>
                    </div>
                </Grid.Row>
            </Spacer>
            <S.Divider></S.Divider>
        </Grid.Row>
    );
};

const S = {
    IconWrapper: styled.div<{color: keyof Colors}>`
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;
        background-color: ${(p) => p.theme.color[p.color]};
    `,
    Divider: styled.div`
        border-right: 1px solid ${(p) => p.theme.color.accent2};
        align-self: stretch;
    `,
};
