import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalAddressContext } from "./context";
import { useStore } from "../../hooks/useStore";

interface Props {
    children?: React.ReactNode;
}

export const ModalAddress: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        address,
        isDefaultDelivery,
        isDefaultInvoice,
        toggleIsDefaultDelivery,
        toggleIsDefaultInvoice,
        isEdited,
        isNew,
        isEditable,
        setIsEditable,
        handleDelete,
        type,
        setAddressLine1,
        setAddressLine2,
        setAddressLine3,
        setCity,
        setCountry,
        setCounty,
        setName,
        setNote,
        setPostcode,
        cancel,
        confirm,
    } = useModalAddressContext();

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Are you sure you want to delete this address?",
            "Delete Address?",
            false,
            true,
            () => {
                handleDelete();
            }
        );
    };

    if (!address) {
        return null;
    }
    return (
        <Modal id="MODAL_ADDRESS" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>
                            Address &nbsp;{isDefaultDelivery && <Tag>Default Delivery Address</Tag>}
                            &nbsp;{isDefaultInvoice && <Tag>Default Invoice Address</Tag>}
                        </Grid.Row>
                    </div>
                    {!isNew && !isEdited && (
                        <div onClick={() => setIsEditable(!isEditable)}>
                            <Text size="s" color="base_contrast">
                                {isEditable ? "CANCEL EDITING" : "EDIT"}
                            </Text>
                        </div>
                    )}
                </ModalHeader>
                <ModalSubHeader style={{ padding: 0 }}>
                    <Grid.Row>
                        <Spacer xs={[0, 2]}>
                            <Tabs height={6}>
                                <Tab
                                    height={6}
                                    isSelected={tab === 0}
                                    onClick={() => setTab(0)}
                                    inline
                                >
                                    Details
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 1}
                                    inline
                                    onClick={() => setTab(1)}
                                >
                                    Settings
                                </Tab>
                            </Tabs>
                        </Spacer>
                    </Grid.Row>
                </ModalSubHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        {tab === 0 && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Name / Label</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.name}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.name}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 1</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.addressLine1}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setAddressLine1(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.addressLine1}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 2</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.addressLine2}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setAddressLine2(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.addressLine2}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Address Line 3</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.addressLine3}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setAddressLine3(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.addressLine3}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>City</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.city}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.city}</FormValue>
                                    )}
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>County</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.county}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setCounty(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.county}</FormValue>
                                    )}
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Postcode</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.postcode}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setPostcode(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.postcode}</FormValue>
                                    )}
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Country</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={address.country}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setCountry(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{address.country}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Notes</FormLabel>

                                    <FormTextarea
                                        disabled={!isEditable && !isNew}
                                        value={address.note}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setNote(e.target.value)}
                                    ></FormTextarea>
                                </Spacer>
                            </Island>
                        )}
                        {tab === 1 && (
                            <>
                                {type === "CUSTOMER" && (
                                    <Island>
                                        <Spacer xs={[1, 0]}>
                                            <FormLabel>Default Delivery Address</FormLabel>
                                            {isEditable || isNew ? (
                                                <FormToggle
                                                    checked={isDefaultDelivery}
                                                    onChange={toggleIsDefaultDelivery}
                                                ></FormToggle>
                                            ) : (
                                                <FormToggle
                                                    disabled
                                                    checked={isDefaultDelivery}
                                                ></FormToggle>
                                            )}
                                        </Spacer>
                                        <Spacer xs={[1, 0]}>
                                            <FormLabel>Default Invoice Address</FormLabel>
                                            {isEditable || isNew ? (
                                                <FormToggle
                                                    checked={isDefaultInvoice}
                                                    onChange={toggleIsDefaultInvoice}
                                                ></FormToggle>
                                            ) : (
                                                <FormToggle
                                                    disabled
                                                    checked={isDefaultInvoice}
                                                ></FormToggle>
                                            )}
                                        </Spacer>
                                    </Island>
                                )}
                                {type === "SUPPLIER" && (
                                    <Island>
                                    <Spacer xs={[1, 0]}>
                                        <FormLabel>Default Invoice Address</FormLabel>
                                        {isEditable || isNew ? (
                                            <FormToggle
                                                checked={isDefaultInvoice}
                                                onChange={toggleIsDefaultInvoice}
                                            ></FormToggle>
                                        ) : (
                                            <FormToggle
                                                disabled
                                                checked={isDefaultInvoice}
                                            ></FormToggle>
                                        )}
                                    </Spacer>
                                </Island>
                                )}
                                <Spacer xs={[1]}></Spacer>
                                {!isNew && (
                                    <Island>
                                        <Spacer xs={[1, 0]}>
                                            <FormLabel>Delete Address</FormLabel>
                                            {isEditable ? (
                                                <button
                                                    className="danger"
                                                    onClick={wrappedHandleDelete}
                                                >
                                                    Delete
                                                </button>
                                            ) : (
                                                <button
                                                    className={isEditable ? "danger" : "secondary"}
                                                    disabled
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </Spacer>
                                    </Island>
                                )}
                            </>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_ADDRESS")}> Ok</button>
                    )}

                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
