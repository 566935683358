import {
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Text,
    useDialog,
    Island,
    FormLabel,
    FormSelect,
    FormNumber,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    TdInput,
    Ellipsis,
    Icon,
    Notice,
    FormToggle,
} from "@holta/ui";
import React from "react";
import { useStore } from "../../hooks/useStore";
import { toggleMerge, useModalBulkProductState } from "./state";
import { setComponentIdToAdd, setComponentQtyToAdd, addComponent, removeComponent, updateComponent, clearComponents } from "./state";
import { useModalProductComponentContext } from "../ModalProductComponent/context";
import { fns } from "@holta/lib";
import { user } from "../../fns/user";
import {saveProductComponent} from "../../actions/productComponents";
import { updateProducts } from "../../actions/products";
import { cloneDeep } from "lodash";
import { Product, ProductComponent } from "@holta/lib/types";

interface Props {
    children?: React.ReactNode;
}



export const ModalBulkProduct: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const productComponents = useStore((state) => state.productComponents);
    const productsToUpdate = useStore((state) => state.ui.products.selectedProducts);
    const { openModalProductComponent } = useModalProductComponentContext();

    const state = useModalBulkProductState();

    const handleCancel = () => {
        closeModal('MODAL_BULK_PRODUCTS');  
        clearComponents();
    };

    const handleConfirm = () => {
        updateProducts(productsToUpdate, {components: JSON.parse(JSON.stringify(state.components))}, state.merge);
        closeModal('MODAL_BULK_PRODUCTS');
        clearComponents();
    };

    return (
        <Modal id="MODAL_BULK_PRODUCTS" style={{ width: "800px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Bulk Edit Products</Grid.Row>
                    </div>
                    
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                    <Island>
                    <Spacer xs={[0, 0, 1, 0]}>
                        <Text size="xl">Components</Text>
                    </Spacer>
                    <Notice color="danger">
                        
                    </Notice>
                    <Spacer xs={[0, 0, 1, 0]}> </Spacer>
                    <Spacer xs={[1, 0]}>
                        <FormLabel>Add Component</FormLabel>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[0, 1, 0, 0]}>
                                    <FormSelect
                                        options={productComponents
                                            .filter(
                                                (pc) =>
                                                    !state.components.find(
                                                        (ppc) => ppc.id === pc.id
                                                    )
                                            )
                                            .map((component) => {
                                                return {
                                                    value: component.id,
                                                    label: component.code,
                                                };
                                            })}
                                        onChange={(option: { id: string; value: string }) => {
                                            setComponentIdToAdd(option.value);
                                        }}
                                        value={{
                                            id: state.componentIdToAdd,
                                            label:
                                                productComponents.find(
                                                    (pc) => pc.id === state.componentIdToAdd
                                                )?.code || "",
                                        }}
                                        onAddNew={() => openModalProductComponent(fns.productComponent.create(user.id!), true, (productComponent) => {saveProductComponent(productComponent); setComponentIdToAdd(productComponent.id);} )}
                                    />
                                </Spacer>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 3 }}>
                                <Spacer xs={[0, 1, 0, 0]}>
                                    <FormNumber
                                        style={{ width: "100%" }}
                                        value={state.componentQtyToAdd}
                                        onChange={(e) =>
                                            setComponentQtyToAdd(
                                                !isNaN(parseFloat(e.target.value))
                                                    ? parseFloat(e.target.value)
                                                    : 1
                                            )
                                        }
                                        isFloat
                                    ></FormNumber>
                                </Spacer>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 3 }}>
                                <button
                                    className="primary"
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                    disabled={!state.componentIdToAdd || !state.componentQtyToAdd}
                                    onClick={addComponent}
                                >
                                    Add
                                </button>
                            </Grid.Col>
                        </Grid.Row>
                        <Spacer xs={[1, 0]}>
                            <hr />
                        </Spacer>
                        {state.components.length > 0 && (
                            <>
                            <Table cols={18}>
                                <Thead>
                                    <Tr>
                                        <Th colspan={4}>Code</Th>
                                        <Th colspan={4}>Qty</Th>
                                        <Th colspan={4}>Unit Price</Th>
                                        <Th colspan={4}>Total</Th>
                                        <Th colspan={2}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {state.components.map((component, index) => {
                                        const foundComponent = productComponents.find(
                                            (pc) => pc.id === component.id
                                        );
                                        if (!foundComponent) return null;

                                        return (
                                            <Tr key={component.id}>
                                                <Td colspan={4}>{foundComponent.code}</Td>

                                                <TdInput
                                                    colspan={4}
                                                    value={component.qty}
                                                    component={FormNumber}
                                                    isFloat
                                                    onChange={(e) => {
                                                        !isNaN(
                                                            parseFloat(
                                                                (e.target as HTMLInputElement).value
                                                            )
                                                        ) &&
                                                            updateComponent(index, {
                                                                qty: parseFloat(
                                                                    (e.target as HTMLInputElement)
                                                                        .value
                                                                ),
                                                                id: component.id,
                                                            });
                                                    }}
                                                />
                                                <Td colspan={4}>
                                                    <Ellipsis
                                                        tooltipText={String(
                                                            fns.productComponent.getPrice(
                                                                foundComponent
                                                            )
                                                        )}
                                                    >
                                                        {fns.productComponent.getPrice(
                                                            foundComponent
                                                        )}
                                                    </Ellipsis>
                                                </Td>
                                                <Td colspan={4}>
                                                    <Ellipsis
                                                        tooltipText={String(
                                                            fns.shared.getQuantityPrice(
                                                                fns.productComponent.getPrice(
                                                                    foundComponent
                                                                ),
                                                                component.qty
                                                            )
                                                        )}
                                                    >
                                                        {fns.shared.getQuantityPrice(
                                                            fns.productComponent.getPrice(
                                                                foundComponent
                                                            ),
                                                            component.qty
                                                        )}
                                                    </Ellipsis>
                                                </Td>
                                                <Td colspan={2} align="c">
                                                    <Icon
                                                        name="delete-bin"
                                                        onClick={() => removeComponent(index)}
                                                    ></Icon>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                            <Spacer xs={[1, 0]}></Spacer>
                            <FormLabel>Merge with current product values?</FormLabel>
                            <FormToggle checked={state.merge} onChange={toggleMerge} />
                            </>
                        )}
                    </Spacer>
                </Island>

                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    
                    
                <button onClick={handleCancel}> {"Cancel"}</button>


                    <Spacer xs={[0.5]} />
                    {state.components.length > 0 && (
                        <button className="primary" onClick={handleConfirm}>
                            Save
                        </button>
                    )}
                    
                </ModalFooter>
            </>
        </Modal>
    );
};
