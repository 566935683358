import { Grid, Modal, ModalBody, ModalFooter, ModalHeader, Spacer, Step, Text } from '@holta/ui';
import React from 'react';
import styled from 'styled-components';
import { FileUpload } from './parts/FileUpload';
import { MatchHeadings } from './parts/MatchHeadings';
import { UnmatchedProducts } from './parts/UnmatchedProducts';
import {useModalSupplierProductImport} from './context';
import { UnmatchedBaseProducts } from './parts/UnmatchedBaseProducts';
import { FinishImport } from './parts/FinishImport';

interface Props {
    children?:React.ReactNode
}

export const ModalSupplierProductImport : React.FC<Props> = ({children}) =>{

    const {tab,setTab} = useModalSupplierProductImport();

    function getStepStatus(tabIndex:number){
        if(tabIndex < tab + 1){
            return 2;
        }
        if(tabIndex === tab + 1){
            return 1;
        }
        return 0;
    }

    function createClickFunction(tabIndex:number){
        if(tabIndex < tab + 1){
            return ()=>setTab(tabIndex);
        }
    }

    return (
        <Modal id="MODAL_SUPPLIER_PRODUCT_IMPORT" width="90vw" style={{height: '90vh'}}>
            <>
                <ModalHeader>Import Products</ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Grid.Row xs={{align: 'center', justify: 'center'}}>    
                            <Step stepNumber={1} status={getStepStatus(1)} text="Upload CSV" onClick={createClickFunction(0)}></Step>
                            <Spacer xs={[2]} />
                            <Step stepNumber={2} status={getStepStatus(2)} text="Match Headings" onClick={createClickFunction(1)}></Step>
                            <Spacer xs={[2]} />
                            <Step stepNumber={3} status={getStepStatus(3)} text="Unmatched Products" onClick={createClickFunction(2)}></Step>
                            <Spacer xs={[2]} />
                            <Step stepNumber={4} status={getStepStatus(4)} text="Unmatched Base Products" onClick={createClickFunction(3)}></Step>
                            <Spacer xs={[2]} />
                            <Step stepNumber={5} status={getStepStatus(5)} text="Import" onClick={createClickFunction(4)}></Step>
                        </Grid.Row>
                    </Spacer>

                    <hr />

                    {tab === 0 && <FileUpload />}
                    {tab === 1 && <MatchHeadings />}
                    {tab === 2 && <UnmatchedProducts />}
                    {tab === 3 && <UnmatchedBaseProducts />}
                    {tab === 4 && <FinishImport />}

                </ModalBody>
                <ModalFooter></ModalFooter>
            </>
        </Modal>
    )

};

