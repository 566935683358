import React from "react";

export const OrdersContext = React.createContext<{}>({});

export const useOrdersContext = () => {
    const context = React.useContext(OrdersContext);
    if (context === undefined) {
        throw new Error("useOrdersContext must be within the correct provider");
    }
    return context;
};
