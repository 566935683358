import { createAction } from "../fns/createAction";
import { fns } from "@holta/lib";
import { Customer } from "@holta/lib/types";
import { useStore } from "../config/store";
import { feathersApp } from "../config/feathers";
import { setOrdersSearch } from "./ordersSearch";
import { setActiveOrders } from "./orders";
import { libTypes } from "@holta/lib";
import {sub, add, startOfISOWeek, endOfISOWeek, startOfDay, endOfDay} from "date-fns";

let searchTermPollingTimer: ReturnType<typeof setTimeout> | null = null;

// CUSTOMERS

export const setCustomersSearchFilter = createAction(
    "SET_UI_CUSTOMERS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.customers.searchFilter = filter;
    }
);

export const handleCustomerTableHeadingClick = createAction(
    "HANDLE_CUSTOMER_TABLE_HEADING_CLICK",
    (newState, field: "name" | "dateCreated") => {
        if (useStore.getState().ui.customers.sortField !== field) {
            newState.ui.customers.sortDirection = "ASC";
            newState.ui.customers.sortField = field;
        } else {
            newState.ui.customers.sortDirection =
                useStore.getState().ui.customers.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

export const toggleCustomersSortDirection = createAction(
    "TOGGLE_UI_CUSTOMERS_SORT_DIRECTION",
    (newState) => {
        const currentDirection = useStore.getState().ui.customers.sortDirection;
        newState.ui.customers.sortDirection = currentDirection === "ASC" ? "DESC" : "ASC";
    }
);

export const setCustomerSortField = createAction(
    "SET_UI_CUSTOMERS_SORT_FIELD",
    (newState, field: "name" | "dateCreated") => {
        newState.ui.customers.sortField = field;
    }
);

export const clearAllCustomerFilters = createAction("CLEAR_ALL_CUSTOMER_FILTERS", (newState) => {
    newState.ui.customers.searchFilter = "";
});

// CATEGORIES

export const setCategoriesSearchFilter = createAction(
    "SET_UI_PRODUCT_CATEGORIES_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.categories.searchFilter = filter;
    }
);

export const clearAllCategoriesFilters = createAction(
    "CLEAR_ALL_PRODUCT_CATEGORIES_FILTERS",
    (newState) => {
        newState.ui.categories.searchFilter = "";
    }
);

export const handleCategoriesTableHeadingClick = createAction(
    "HANDLE_CATEGORY_TABLE_HEADING_CLICK",
    (newState, field: "fullname") => {
        if (useStore.getState().ui.categories.sortField !== field) {
            newState.ui.categories.sortDirection = "ASC";
            newState.ui.categories.sortField = field;
        } else {
            newState.ui.categories.sortDirection =
                useStore.getState().ui.categories.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

// PRODUCT_COMPONENTS

export const setProductComponentsSearchFilter = createAction(
    "SET_UI_PRODUCT_PRODUCT_COMPONENTS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.productComponents.searchFilter = filter;
    }
);

export const clearAllProductComponentsFilters = createAction(
    "CLEAR_ALL_PRODUCT_PRODUCT_COMPONENTS_FILTERS",
    (newState) => {
        newState.ui.productComponents.searchFilter = "";
    }
);

export const handleProductComponentsTableHeadingClick = createAction(
    "HANDLE_PRODUCT_COMPONENT_TABLE_HEADING_CLICK",
    (newState, field: "code") => {
        if (useStore.getState().ui.productComponents.sortField !== field) {
            newState.ui.productComponents.sortDirection = "ASC";
            newState.ui.productComponents.sortField = field;
        } else {
            newState.ui.productComponents.sortDirection =
                useStore.getState().ui.productComponents.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

// PRODUCTS

export const clearAllProductsFilters = createAction("CLEAR_ALL_PRODUCTS_FILTERS", (newState) => {
    newState.ui.products.searchFilter = "";
    newState.ui.products.categoriesFilter = [];
    newState.ui.products.componentsFilter = [];
});

export const setProductsSearchFilter = createAction(
    "SET_UI_PRODUCTS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.products.searchFilter = filter;
        newState.ui.products.selectedProducts = [];
    }
);

export const handleProductsTableHeadingClick = createAction(
    "HANDLE_PRODUCTS_TABLE_HEADING_CLICK",
    (newState, field: "code" | "description" | "dateCreated") => {
        if (useStore.getState().ui.products.sortField !== field) {
            newState.ui.products.sortDirection = "ASC";
            newState.ui.products.sortField = field;
        } else {
            newState.ui.products.sortDirection =
                useStore.getState().ui.products.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

export const toggleSelectedProduct = createAction(
    "TOGGLE_UI_PRODUCTS_SELECTED_PRODUCT",
    (newState, productId: string) => {
        const currentSelectedProducts = useStore.getState().ui.products.selectedProducts;
        if (currentSelectedProducts.includes(productId)) {
            newState.ui.products.selectedProducts = currentSelectedProducts.filter(
                (id) => id !== productId
            );
        } else {
            newState.ui.products.selectedProducts = [...currentSelectedProducts, productId];
        }
    }
);

export const setSelectedProducts = createAction(
    "SET_UI_PRODUCTS_SELECTED_PRODUCTS",
    (newState, products: string[]) => {
        newState.ui.products.selectedProducts = products;
    }
);

export const clearSelectedProducts = createAction(
    "CLEAR_UI_PRODUCTS_SELECTED_PRODUCTS",
    (newState) => {
        newState.ui.products.selectedProducts = [];
    }
);

// set component filter
export const setComponentFilter = createAction(
    "SET_UI_PRODUCTS_COMPONENT_FILTER",
    (newState, components: { label: string; value: string }[] | null) => {
        newState.ui.products.componentsFilter = components || [];
        newState.ui.products.selectedProducts = [];
    }
);

export const setCategoryFilter = createAction(
    "SET_UI_PRODUCTS_CATEGORY_FILTER",
    (newState, categories: { label: string; value: string }[] | null) => {
        newState.ui.products.categoriesFilter = categories || [];
        newState.ui.products.selectedProducts = [];
    }
);

// SUPPLIERS

export const setSuppliersSearchFilter = createAction(
    "SET_UI_SUPPLIERS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.suppliers.searchFilter = filter;
    }
);

export const handleSupplierTableHeadingClick = createAction(
    "HANDLE_SUPPLIER_TABLE_HEADING_CLICK",
    (newState, field: "name" | "dateCreated") => {
        if (useStore.getState().ui.suppliers.sortField !== field) {
            newState.ui.suppliers.sortDirection = "ASC";
            newState.ui.suppliers.sortField = field;
        } else {
            newState.ui.suppliers.sortDirection =
                useStore.getState().ui.suppliers.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

export const toggleSuppliersSortDirection = createAction(
    "TOGGLE_UI_SUPPLIERS_SORT_DIRECTION",
    (newState) => {
        const currentDirection = useStore.getState().ui.suppliers.sortDirection;
        newState.ui.suppliers.sortDirection = currentDirection === "ASC" ? "DESC" : "ASC";
    }
);

export const setSupplierSortField = createAction(
    "SET_UI_SUPPLIERS_SORT_FIELD",
    (newState, field: "name" | "dateCreated") => {
        newState.ui.suppliers.sortField = field;
    }
);

export const clearAllSupplierFilters = createAction("CLEAR_ALL_SUPPLIER_FILTERS", (newState) => {
    newState.ui.suppliers.searchFilter = "";
});

// LINK PRODUCTS

export const setLinkProductsSearchFilter = createAction(
    "SET_UI_LINK_PRODUCTS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.linkProducts.searchFilter = filter;
    }
);

export const clearAllLinkProductsFilters = createAction(
    "CLEAR_ALL_LINK_PRODUCTS_FILTERS",
    (newState) => {
        newState.ui.linkProducts.searchFilter = "";
    }
);

export const handleLinkProductsTableHeadingClick = createAction(
    "HANDLE_LINK_PRODUCTS_TABLE_HEADING_CLICK",
    (newState, field: "name") => {
        if (useStore.getState().ui.linkProducts.sortField !== field) {
            newState.ui.linkProducts.sortDirection = "ASC";
            newState.ui.linkProducts.sortField = field;
        } else {
            newState.ui.linkProducts.sortDirection =
                useStore.getState().ui.linkProducts.sortDirection === "ASC" ? "DESC" : "ASC";
        }
    }
);

// ORDERS

export const setOrdersSearchFilter = createAction(
    "SET_UI_ORDERS_SEARCH_FILTER",
    (newState, filter: string) => {
        newState.ui.orders.searchFilter = filter;
    }
);


export const clearOrdersSearchFilter = createAction("CLEAR_UI_ORDERS_SEARCH_FILTER", (newState) => {
    newState.ui.orders.searchFilter = "";
});

export const setOrdersDateFrom = createAction(
    "SET_UI_ORDERS_DATE_FROM",
    (newState, date: number) => {
        newState.ui.orders.dateFrom = startOfDay(date).getTime();
    }
);

export const setOrdersCustomerFilter = createAction(
    "SET_UI_ORDERS_CUSTOMER_FILTER", (newState, customer: { label: string; value: string } | null) => {
        
        newState.ui.orders.customerFilter = customer || null;
    }
);

export const setOrdersAccountingStatusFilter = createAction(
    "SET_UI_ORDERS_STATUS_FILTER",
    (newState, status: { label: string; value: libTypes.AccountingStatus } | null) => {
        newState.ui.orders.accountingStatus = status?.value || "ALL";
    }
);

export const setOrdersDateTo = createAction("SET_UI_ORDERS_DATE_TO", (newState, date: number | null) => {
    newState.ui.orders.dateTo = date ? endOfDay(date).getTime() : null;
});

export const setOrdersType = createAction(
    "SET_UI_ORDERS_TYPE",
    (newState, type: "ALL" | "QUOTE" | "ORDER" | "INVOICE") => {
        newState.ui.orders.type = type;
    }
);

export const setOrdersDateType = createAction(
    "SET_UI_ORDERS_DATE_TYPE",
    (newState, type: "CURRENT_STATUS" | "CREATED" | "ORDERED" | "INVOICED" | "DELIVERY" | "PRODUCTION" | "MODIFIED") => {
        newState.ui.orders.dateType = type;
    }
);

export const clearAllOrdersFilters = createAction("CLEAR_ALL_ORDERS_FILTERS", (newState) => {
    newState.ui.orders.searchFilter = "";
    newState.ui.orders.type = "ALL";
    newState.ui.orders.dateType = "CURRENT_STATUS";
    newState.ui.orders.customerFilter = null;
    newState.ui.orders.accountingStatus = "ALL";
});

export const handleOrdersTableHeadingClick = createAction(
    "HANDLE_ORDERS_TABLE_HEADING_CLICK",
    (newState, field: "orderRef" | "quoteRef" | "customerRef" | "date") => {
        if (useStore.getState().ui.orders.sortField !== field) {
            newState.ui.orders.sortDirection = "ASC";
            newState.ui.orders.sortField = field;
        } else if (useStore.getState().ui.orders.sortField === field && useStore.getState().ui.orders.sortDirection === "ASC"){
            newState.ui.orders.sortDirection = "DESC";
        }
        else {
            newState.ui.orders.sortDirection = "ASC";
            newState.ui.orders.sortField = 'none';
        }
    }
);



// PRODUCTION

export const goToNextProductionWeek = createAction("GO_TO_NEXT_PRODUCTION_WEEK", (newState) => {
    newState.ui.production.weekStart = add(useStore.getState().ui.production.weekStart, { days: 7 }).getTime();
})

export const goToPrevProductionWeek = createAction("GO_TO_PREV_PRODUCTION_WEEK", (newState) => {
    newState.ui.production.weekStart = sub(useStore.getState().ui.production.weekStart, { days: 7 }).getTime();
})

export const goToProductionWeek = createAction("GO_TO_PRODUCTION_WEEK", (newState, date: number) => {
    newState.ui.production.weekStart = startOfISOWeek(date).getTime();
})


// DELIVERY


export const goToNextDeliveryWeek = createAction("GO_TO_NEXT_DELIVERY_WEEK", (newState) => {
    newState.ui.delivery.weekStart = add(useStore.getState().ui.delivery.weekStart, { days: 7 }).getTime();
})

export const goToPrevDeliveryWeek = createAction("GO_TO_PREV_DELIVERY_WEEK", (newState) => {
    newState.ui.delivery.weekStart = sub(useStore.getState().ui.delivery.weekStart, { days: 7 }).getTime();
})

export const goToDeliveryWeek = createAction("GO_TO_DELIVERY_WEEK", (newState, date: number) => {
    newState.ui.delivery.weekStart = startOfISOWeek(date).getTime();
})