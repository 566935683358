import { code } from "@holta/lib/functions/supplierBaseProduct/set";
import {
    FormInput,
    FormLabel,
    FormNotice,
    FormSelect,
    Island,
    Spacer,
    useDialog,
    useModal,
} from "@holta/ui";
import { useStore } from "../../../hooks/useStore";
import React from "react";
import {fns} from "@holta/lib";
import { useModalSupplierBaseProductContext } from "../context";

interface Props {
    children?: React.ReactNode;
}

export const DetailsTab: React.FC<Props> = () => {
    const { supplierBaseProduct, setCode, setLinkProduct, validationResult, setCategories, setType } =
        useModalSupplierBaseProductContext();
    const categories = useStore((store) => store.categories);

    const linkProducts = useStore((state) => state.linkProducts);

    if (!supplierBaseProduct) {
        return null;
    }

    const currentLinkProductOption = linkProducts.find(
        (linkProduct) => linkProduct.id === supplierBaseProduct.linkProductId
    );

    return (
        <Island>
            <Spacer xs={[1, 0]}>
                <FormLabel>Code</FormLabel>
                <FormInput
                    value={supplierBaseProduct.code}
                    style={{ width: "100%" }}
                    onChange={(e) => setCode(e.target.value)}
                />
                {validationResult && !validationResult.code._isValid && (
                    <FormNotice>{validationResult.code._errors[0]}</FormNotice>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Type</FormLabel>
                <FormSelect
                    options={[
                        { label: "Unique", value: "UNIQUE" },
                        { label: "Range", value: "RANGE" },
                    ]}
                    value={
                        supplierBaseProduct.type === "UNIQUE" ? {
                            label: "Unique",
                            value: "UNIQUE",
                        } : {
                            label: "Range",
                            value: "RANGE",
                        }}
                    onChange={(option: { label: string; value: 'UNIQUE' | 'RANGE' }) =>
                        setType(option.value)
                    }
                />
                {validationResult && !validationResult.type._isValid && (
                    <FormNotice>{validationResult.type._errors[0]}</FormNotice>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
                <FormLabel>Link Product</FormLabel>
                <FormSelect
                    options={linkProducts.map((linkProduct) => ({
                        label: linkProduct.name,
                        value: linkProduct.id,
                    }))}
                    value={
                        currentLinkProductOption
                            ? {
                                  label: currentLinkProductOption.name,
                                  value: currentLinkProductOption.id,
                              }
                            : null
                    }
                    onChange={(option: { label: string; value: string } | null) =>
                        setLinkProduct(option?.value || null)
                    }
                    isClearable
                />
                {validationResult && !validationResult.linkProductId._isValid && (
                    <FormNotice>{validationResult.linkProductId._errors[0]}</FormNotice>
                )}
            </Spacer>
            <Spacer xs={[1, 0]}>
            <FormLabel style={{opacity: currentLinkProductOption ? 0.25 : 1}}>Categories</FormLabel>
            {currentLinkProductOption ? (<FormNotice type="notice">Categories will be inherited from the assigned link product.</FormNotice>) : (<FormSelect
                 options={[...categories].reverse().map((category) => ({
                    label: fns.category.getHeirachicalName(category, categories),
                    value: category.id,
                }))}
                value={
                    supplierBaseProduct.categories &&
                    supplierBaseProduct.categories.length > 0 &&
                    supplierBaseProduct.categories.sort().map((id) => {
                        const category = categories.find((c) => c.id === id);
                        if (category)
                            return {
                                label: fns.category.getHeirachicalName(
                                    category,
                                    categories
                                ),
                                value: category.id,
                            };
                    })
                }
                isDisabled={currentLinkProductOption}
                isMulti={true}
                onChange={(options: { label: string; value: string }[]) => {
                    setCategories(
                        options && options.length > 0 ? options.map((option) => option.value) : []
                    );
                }}
            />)}
            
            </Spacer>
        </Island>
    );
};
