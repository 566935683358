import { fns } from "@holta/lib";
import { FormSelect, Grid, Island, Spacer, Text, theme} from "@holta/ui";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { useStore } from "../../../hooks/useStore";
import { endOfWeek, startOfWeek, add } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
    },
};

const labels = ["Last Week", "This Week", "Next Week"];

export const Chart1 = () => {
    const orders = useStore((store) => store.orders);
    const today = new Date();
    
    function getTotalForWeek(
        weekDifference: number,
        type: "PRODUCTION" | "DELIVEREDORCOMPLETE" | null
    ) {
        const weekStart = add(startOfWeek(today, { weekStartsOn: 0 }), {
            weeks: weekDifference,
        }).getTime();
        const weekEnd = add(endOfWeek(today, { weekStartsOn: 0 }), {
            weeks: weekDifference,
        }).getTime();
        const deliveries = fns.orders.filterByDateRange(orders, "DELIVERY", weekStart, weekEnd);
        const filteredDeliveries =
            type === "PRODUCTION"
                ? fns.orders.filterByProductionStatus(
                      fns.orders.filterByProductionStatus(deliveries, "COMPLETE", true),
                      "DELIVERED",
                      true
                  )
                : type === "DELIVEREDORCOMPLETE"
                ? [
                      ...fns.orders.filterByProductionStatus(deliveries, "DELIVERED", false),
                      ...fns.orders.filterByProductionStatus(deliveries, "COMPLETE", false),
                  ]
                : deliveries;
        const total = fns.orders.getTotalValue(filteredDeliveries, { excludeTax: true });
        return total;
    }

    

    const data = {
        labels,
        datasets: [
            {
                label: "In Production",
                data: [getTotalForWeek(-1, 'PRODUCTION'), getTotalForWeek(0, 'PRODUCTION'), getTotalForWeek(1, 'PRODUCTION')],
                backgroundColor: theme.colorLight.highlight,
            },
            {
                label: "Complete",
                data: [getTotalForWeek(-1, 'DELIVEREDORCOMPLETE'), getTotalForWeek(0, 'DELIVEREDORCOMPLETE'), getTotalForWeek(1, 'DELIVEREDORCOMPLETE')],
                backgroundColor: theme.colorLight.production_COMPLETE,
            },
            {
                label: "Total",
                data: [getTotalForWeek(-1, null), getTotalForWeek(0, null), getTotalForWeek(1, null)],
                backgroundColor: theme.colorLight.accent3,
            },
        ],
    };

    return (
        <>
            <Text size="l" bold>
                Weekly Deliveries
            </Text>
            <Spacer xs={[1, 0]}></Spacer>
            <hr />
            <Bar options={options} data={data} />
        </>
    );
};
