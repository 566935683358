import { libTypes } from "@holta/lib";
import { feathersApp } from "../config/feathers";
import {toast} from 'react-hot-toast';
import {user} from '../fns/user';

export const updateAccountingStatus = (order: libTypes.Order, status: libTypes.AccountingStatus) => {
    toast.promise(feathersApp.service('orders').patch(order.id, {accountingStatus: status}), {
        loading: 'Updating',
        success: 'Saved',
        error: 'Error updating',
    });
    
}

export const updateProductionStatus = (order: libTypes.Order, status: libTypes.ProductionStatus) => {
    toast.promise(feathersApp.service('orders').patch(order.id, {productionStatus: status}), {
        loading: 'Updating',
        success: (data) => {
            feathersApp.service('history').create({
                userId: user.id,
                parentId: order.id,
                data: {productionStats: status}
            })
            return `Saved`;
            
        },
        error: 'Error updating',
    });
}

export const updateDeliveryDate = (order: libTypes.Order, date: libTypes.Order['deliveryDate'], dateType: libTypes.Order['deliveryDateType']) => {
    toast.promise(feathersApp.service('orders').patch(order.id, {deliveryDate: date, deliveryDateType: dateType}), {
        loading: 'Updating',
        success: (data) => {
            feathersApp.service('history').create({
                userId: user.id,
                parentId: order.id,
                data:{deliveryDate: date, deliveryDateType: dateType}
            })
            return `Saved`;
            
        },
        error: 'Error updating',
    });
}