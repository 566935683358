import React from "react";
import { feathersApp } from "../config/feathers";
import { setContacts } from "../actions/contacts";
import { libTypes } from "@holta/lib";

export const useLoadContacts = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);
    if (!hasRun.current) {
        feathersApp
            .service("contacts")
            .watch()
            .find({
                query: {
                    $limit: 10000,
                },
            })
            .subscribe(({ data }: { data: libTypes.Contact[] }) => {
                setContacts(data);
                setIsLoaded(true);
            });
        hasRun.current = true;
    }
    return isLoaded;
};
