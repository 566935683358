import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    HighlightedText
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useFilteredCategories } from "../../../hooks/useFilteredCategories";
import { useStore } from "../../../config/store";
import {useModalCategoryContext} from "../../../components/ModalCategory/context";
import {fns, libTypes} from "@holta/lib";
import {updateCategory} from "../../../actions/categories";
import { SortHighlight } from "../../../components/SortHighlight";
import {handleCategoriesTableHeadingClick} from "../../../actions/ui";

interface Props {}

const Row = ({ style, index, data }: { style: React.CSSProperties, index: number, data : {categories: libTypes.Category[], searchTerm: string,  filteredCategories : libTypes.Category[]} }) => {

    const {categories, filteredCategories, searchTerm} = data;
    const {openModalCategory} = useModalCategoryContext();

    if(!filteredCategories[index]) return null;
    
    return (
        <Tr style={style}  onClick={() => openModalCategory(filteredCategories[index], false, updateCategory, () => {})} hasHover>

            <Td colspan={2}>
                <Text size="m"><HighlightedText text={fns.category.getHeirachicalName(filteredCategories[index], categories)} highlight={searchTerm}/></Text>
            </Td>
        </Tr>
    );
};

export const CategoriesTable: React.FC<Props> = () => {
    const filteredCategories = useFilteredCategories();
    const categories = useStore(store => store.categories)
    const ui = useStore((store) => store.ui);

    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={1}>
                <Thead>
                    <Tr onClick={() => handleCategoriesTableHeadingClick('fullname')}>
                    
                        <Th colspan={1}><SortHighlight direction={ui.categories.sortDirection} thisField="fullname" uiField={ui.categories.sortField}>Name</SortHighlight></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List
                                height={height - 2}
                                itemCount={filteredCategories.length}
                                itemSize={46}
                                width={width - 1}
                                overscanCount={30}
                                itemData={{filteredCategories, searchTerm: ui.categories.searchFilter, categories}}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
