import { fns, libTypes, staticData } from "@holta/lib";
import { ModalSidebar, TreeNav } from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useSupplierProductBrowserContext } from "../context";

export const Sidebar = () => {
    const { suppliers, expandedSupplier, setExpandedSupplier} = useSupplierProductBrowserContext();

    return (
        <S.StyledModalSidebar>
            <S.ModalSidebarTop>
                <TreeNav
                    options={{hasAll:false}}
                    items={suppliers}
                    expandedItems={expandedSupplier ? [expandedSupplier] : []}
                    selectedBranch={expandedSupplier ? [expandedSupplier] : []}
                    toggleItem={setExpandedSupplier}
                />
            </S.ModalSidebarTop>
        </S.StyledModalSidebar>
    );
};

/* --------------------------------- Styles --------------------------------- */

const S = {
    StyledModalSidebar: styled(ModalSidebar)`
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
    `,

    ModalSidebarTop: styled.div`
        flex-grow: 1;
        text-align: left;
    `,

    ModalSidebarBottom: styled.div`
        padding: ${({ theme }) => theme.spacing(2)};
        button {
            width: 100%;
            text-align: center;
        }
    `,
};
