import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
} from "@holta/ui";
import { Header } from "./parts/Header";
import { ContactsTable } from "./parts/ContactsTable";

export const Contacts = () => {
    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
                <Spacer xs={[0, 2, 2, 2]}>
                    <Notice color="accent5">
                        <Grid.Row xs={{ justify: "space-between" }}>
                            <div>3 Filters Applied</div>
                            <div>
                                <Grid.Row xs={{ align: "center" }}>
                                    <Icon name="close-circle"></Icon>
                                    &nbsp;Clear Filters
                                </Grid.Row>
                            </div>
                        </Grid.Row>
                    </Notice>
                </Spacer>
            </div>
            <div style={{ flex: 1, overflow: "auto" }}>
                <ContactsTable />
            </div>

            <Modal id="ORDERS_FILTER" sideBar>
                <>
                    <ModalHeader>Apply Filters</ModalHeader>
                    <ModalBody>
                        <Spacer xs={[2]}>
                            <FormLabel>Quote Ref</FormLabel>
                            <FormInput />
                        </Spacer>
                    </ModalBody>
                </>
            </Modal>
        </Container>
    );
};
