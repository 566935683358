

import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const savePayment = (payment: libTypes.Order["payments"][number]) => {

    const editOrder = getEditOrder();

    const existingPayment = editOrder.newValues.order.payments?.find((p) => p.id === payment.id);

    let newPayments : libTypes.Order['payments'] = [];

    if (existingPayment) {
        newPayments = editOrder.newValues.order.payments!.map((p) => {
            if (p.id === payment.id) return payment;
            return p;
        });
    } else {
        newPayments = [...(editOrder.newValues.order.payments || []), payment];
    }

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.payments = newPayments;
        })
    });
    
};

