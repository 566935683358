import React from "react";
import { InputCustomerSelect } from "../../../components/InputCustomerSelect";
import {
    Island,
    Text,
    Icon,
    useModal,
    hooks,
    useDialog,
    Spacer,
    Modal,
    Popover,
    Grid,
    IconButton,
} from "@holta/ui";
import { fns, libTypes } from "@holta/lib";
import editOrderActions from "../../../actions/editOrder";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { ModalConfirmUpdateOrderCustomer } from "./ModalConfirmCustomerChange";
import { useStore } from "../../../config/store";
import { useElementSize } from "usehooks-ts";
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import { ModalAddressSelect } from "../../../components/ModalAddressSelect";
import { feathersApp } from "../../../config/feathers";
import { findAllByTestId } from "@testing-library/react";

export const ChooseCustomer = () => {
    const { openModal } = useModal();
    const editOrder = useMergedEditOrder();
    const handleAdressSelectConfirm = React.useRef((address: libTypes.Address) => {});
    const addressesLoaded = React.useRef<boolean>(false);
    const addressSubscription = React.useRef<any>();
    const defualtAddressId = React.useRef<string | null>(null);
    const [addresses, setAddresses] = React.useState<libTypes.Address[]>([]);
    const { openModalAddress } = useModalAddressContext();
    const [customerChangePopoverOpen, setCustomerChangePopoverOpen] = hooks.useToggle(false);
    const handleCustomerWarningCB = React.useRef(
        (
            updateDiscount: boolean,
            updateTaxValue: boolean,
            updateSupplierDiscount: boolean,
            updateItemDiscounts: boolean,
            updateInvoiceAddress: boolean,
            updateDeliveryAddress: boolean
        ) => {}
    );
    const customers = useStore((state) => state.customers);

    async function getAddresses(customerId: string) {
        addressSubscription.current = feathersApp
            .service("addresses")
            .watch()
            .find({
                query: {
                    parentId: customerId,
                    $limit: 10000,
                },
            })
            .subscribe(({ data }: { data: libTypes.Address[] }) => {
                addressesLoaded.current = true;
                setAddresses(data);
            });
    }

    React.useEffect(() => {
        if (editOrder?.customerId) {
            getAddresses(editOrder.customerId);
        }
        return () => {
            addressesLoaded.current = false;
            if (addressSubscription.current) addressSubscription.current.unsubscribe();
        };
    }, [editOrder?.customerId]);


    if (!editOrder) return null;

    const handleCustomerWarning = (
        cb: (
            updateDiscount: boolean,
            updateTaxValue: boolean,
            updateSupplierDiscount: boolean,
            updateItemDiscounts: boolean,
            updateInvoiceAddress: boolean,
            updateDeliveryAddress: boolean
        ) => void
    ) => {
        handleCustomerWarningCB.current = cb;
        openModal("MODAL_CONFIRM_UPDATE_ORDER_CUSTOMER");
    };

    const handleCustomerChange = ({ value }: { value: string }) => {
        setCustomerChangePopoverOpen(false);
        editOrderActions.setCustomerId(customers.find((c) => c.id === value)!, handleCustomerWarning);
    };

    const openInvoiceAddressSelect = () => {
        handleAdressSelectConfirm.current = editOrderActions.setInvoiceAddress;
        defualtAddressId.current =
            customers.find((c) => c.id === editOrder.customerId)?.defaultInvoiceAddress || null;
        openModal("MODAL_ADDRESS_SELECT");
    };

    const openDeliveryAddressSelect = () => {
        handleAdressSelectConfirm.current = editOrderActions.setDeliveryAddress;
        defualtAddressId.current =
            customers.find((c) => c.id === editOrder.customerId)?.defaultDeliveryAddress || null;
        openModal("MODAL_ADDRESS_SELECT");
    };

    const openInvoiceAddressEdit = () => {
        openModalAddress(
            editOrder.invoiceAddress! as libTypes.Address,
            false,
            false,
            true,
            editOrderActions.setInvoiceAddress,
            "CUSTOMER",
            () => {},
            "ADDRESS_FIELDS"
        );
    };

    const openDeliveryAddressEdit = () => {
        openModalAddress(
            editOrder.deliveryAddress! as libTypes.Address,
            false,
            false,
            true,
            editOrderActions.setDeliveryAddress,
            "CUSTOMER",
            () => {},
            "ADDRESS_FIELDS"
        );
    };

    if (!editOrder.customerId) {
        return (
            <S.InitialContainer>
                <Icon name="user-add" color="accent2" size="2x" />
                <Text size="l" color="accent4" bold>
                    Choose Customer
                </Text>

                <Spacer xs={[1]} />
                <InputCustomerSelect
                    onChange={handleCustomerChange}
                    style={{ width: "100%" }}
                    value={editOrder.customerId}
                    valueFallbackLabel={fns.order.getCustomerName(editOrder, customers)}
                />

            </S.InitialContainer>
        );
    } else {
        return (
            <S.HasCustomerContainer>
                <Spacer xs={[2]}>
                    <Grid.Row xs={{ justify: "space-between" }}>
                        <Grid.Col xs={{ cols: 8 }}>
                            <Text size="l" bold color="text">
                                {customers.find((c) => c.id === editOrder.customerId)?.name ||
                                    editOrder.deletedCustomerName ||
                                    "Customer not found"}
                            </Text>
                        </Grid.Col>
                        <Grid.Row xs={{ direction: "column", align: "flex-end" }}>
                            <Text size="s" color="highlight">
                                <Popover.Root
                                    open={customerChangePopoverOpen}
                                    onOpenChange={setCustomerChangePopoverOpen}
                                >
                                    <Popover.Trigger asChild>
                                        <div>
                                            <IconButton icon="user-add">Change Customer</IconButton>
                                        </div>
                                    </Popover.Trigger>
                                    <Popover.Content asChild>
                                        <Spacer
                                            xs={[1]}
                                            grow
                                            style={{
                                                width: "300px",
                                                top: "-50px",
                                                position: "relative",
                                            }}
                                        >
                                            <S.InitialContainer>
                                                <Icon name="user-add" color="accent2" size="2x" />
                                                <Text size="l" color="accent4" bold>
                                                    Choose Customer
                                                </Text>

                                                <Spacer xs={[1]} />
                                                <InputCustomerSelect
                                                    onChange={handleCustomerChange}
                                                    style={{ width: "100%" }}
                                                    value={editOrder.customerId}
                                                    valueFallbackLabel={fns.order.getCustomerName(
                                                        editOrder,
                                                        customers
                                                    )}
                                                />

                                                
                                            </S.InitialContainer>
                                        </Spacer>
                                    </Popover.Content>
                                </Popover.Root>
                            </Text>
                        </Grid.Row>
                    </Grid.Row>
                </Spacer>
                <hr />
                <Spacer xs={[2]}>
                    <Grid.Row xs={{ justify: "space-between" }}>
                        <Grid.Col xs={{ cols: 8 }}>
                            <Text size="m" color="text" bold>
                                Invoice Address
                            </Text>
                            <Spacer xs={[0.25]} />
                            <Text size="s">
                                <div style={{ whiteSpace: "pre-line" }}>
                                    {fns.address.createAddressString(editOrder.invoiceAddress)}
                                </div>
                            </Text>
                        </Grid.Col>
                        <Grid.Row xs={{ direction: "column", align: "flex-end" }}>
                            <Text size="s" color="highlight">
                                <IconButton icon="function" onClick={openInvoiceAddressSelect}>
                                    Choose Address
                                </IconButton>
                            </Text>
                            <Spacer xs={[0.25]} />
                            <Text size="s" color="highlight">
                                <IconButton icon="edit" onClick={openInvoiceAddressEdit}>
                                    Edit
                                </IconButton>
                            </Text>
                        </Grid.Row>
                    </Grid.Row>
                </Spacer>
                <hr />
                <Spacer xs={[2]}>
                    <Grid.Row xs={{ justify: "space-between" }}>
                        <Grid.Col xs={{ cols: 8 }}>
                            <Text size="m" color="text" bold>
                                Delivery Address
                            </Text>
                            <Spacer xs={[0.25]} />
                            <Text size="s">
                                <div style={{ whiteSpace: "pre-line" }}>
                                    {fns.address.createAddressString(editOrder.deliveryAddress)}
                                </div>
                            </Text>
                        </Grid.Col>
                        <Grid.Row xs={{ direction: "column", align: "flex-end" }}>
                            <Text size="s" color="highlight">
                                <IconButton icon="function" onClick={openDeliveryAddressSelect}>
                                    Choose Address
                                </IconButton>
                            </Text>
                            <Spacer xs={[0.25]} />
                            <Text size="s" color="highlight">
                                <IconButton icon="edit" onClick={openDeliveryAddressEdit}>
                                    Edit
                                </IconButton>
                            </Text>
                        </Grid.Row>
                    </Grid.Row>
                </Spacer>
                <ModalAddressSelect
                    handleConfirm={handleAdressSelectConfirm.current}
                    addresses={addresses}
                    defaultAddressId={defualtAddressId.current || undefined}
                />
                <ModalConfirmUpdateOrderCustomer
                                                    handleConfirm={handleCustomerWarningCB.current}
                                                />
            </S.HasCustomerContainer>
            
        );
    }
};

const S = {
    InitialContainer: styled(Island)`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${(props) => props.theme.spacing(2)};
    `,
    HasCustomerContainer: styled(Island)`
        width: 100%;
        padding: ${(props) => props.theme.spacing(0)};
    `,
};
