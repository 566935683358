import { fns } from '@holta/lib';
import {useStore} from './useStore';

export const useFilteredLinkProducts = () => {
    const {linkProducts, ui} = useStore();
    let filteredLinkProducts = [...linkProducts];

    if(ui.linkProducts.sortField === 'name'){
        filteredLinkProducts = filteredLinkProducts.sort((a, b) => a.name.localeCompare(b.name));
        if(ui.linkProducts.sortDirection === 'DESC'){
            filteredLinkProducts = filteredLinkProducts.reverse();
        }
    }

    if(ui.linkProducts.searchFilter){
        filteredLinkProducts = fns.shared.filterByString(filteredLinkProducts, 'name', ui.linkProducts.searchFilter);  
    }
    return filteredLinkProducts.sort((a, b) => fns.shared.naturalSort(a.name, b.name));

}
