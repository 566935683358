import { FormLabel, FormSelect, FormToggle, Spacer, useDialog, useModal } from "@holta/ui";
import { deleteProduct } from "../../../actions/products";
import { useStore } from "../../../hooks/useStore";
import React from "react";

import { useModalProductContext } from "../context";

interface Props {
    children?: React.ReactNode;
}

export const CustomerAccessTab: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        setCode,
        setW,
        setH,
        setD,
        setDescription,
        setSpecification,
        addComponent,
        removeComponent,
        updateComponent,
        setCategories,
        toggleCustomerRestricted,
        toggleSoldByM2,
        setCustomerWhitelist,
        updateLinkProductQty,
        setDrawers,
        setImage,
        setPrice,
        setPriceExpression,
        setPriceType,
        componentIdToAdd,
        setComponentIdToAdd,
        setComponentQtyToAdd,
        componentQtyToAdd,
        isEdited,
        isNew,
        isEditable,
        setIsEditable,
        cancel,
        confirm,
    } = useModalProductContext();

    const productComponents = useStore((state) => state.productComponents);
    const categories = useStore((state) => state.categories);
    const customers = useStore((state) => state.customers);

    if (!product) {
        return null;
    }

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Ths will completely remove this product and can't be undone'.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteProduct(product.id)
                    .then(() => {
                        closeModal("MODAL_PRODUCT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting product", "Error", false, true);
                    });
            }
        );
    };

    return (
        <>
            <Spacer xs={[1, 0]}>
                <FormLabel>Customer Resticted</FormLabel>
                <FormToggle
                    checked={product.customerRestricted}
                    onChange={toggleCustomerRestricted}
                ></FormToggle>
            </Spacer>

            {product.customerRestricted && (
                <Spacer xs={[1, 0]}>
                    <FormLabel>Grant access for these customers:</FormLabel>
                    <FormSelect
                        options={customers.map((customer) => ({
                            label: customer.name,
                            value: customer.id,
                        }))}
                        value={
                            product &&
                            product.customerWhitelistIds.map((id) => {
                                const customer = customers.find((c) => c.id === id);
                                if (customer)
                                    return {
                                        label: customer.name,
                                        value: customer.id,
                                    };
                            })
                        }
                        isMulti={true}
                        onChange={(options: { label: string; value: string }[]) => {
                            setCustomerWhitelist(
                                options && options.length > 0
                                    ? options.map((option) => option.value)
                                    : []
                            );
                        }}
                    />
                </Spacer>
            )}
        </>
    );
};
