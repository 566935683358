import React from "react";
import { useModalPayment } from './hooks/useModalPayment';
import {useTabs} from '../../hooks/useTabs';
import {useDragOrderItems} from './hooks/useDragOrderItems';

export const OrderContext = React.createContext<
    | {
          tabs: ReturnType<typeof useTabs>;
          modalPayment: ReturnType<typeof useModalPayment>;
          dragOrderItems: ReturnType<typeof useDragOrderItems>;
      }
    | undefined
>(undefined);

export const useOrderContext = () => {
    const context = React.useContext(OrderContext);
    if (context === undefined) {
        throw new Error("useOrderContext must be within the correct provider");
    }
    return context;
};

export const OrderContextProvider = ({ children }: { children: React.ReactElement }) => {
    const tabs = useTabs("details");
    const modalPayment = useModalPayment();
    const dragOrderItems = useDragOrderItems();
    return <OrderContext.Provider value={{ tabs, modalPayment, dragOrderItems }}>{children}</OrderContext.Provider>;
};

