import {useDialog} from '@holta/ui';

export const useLogout = () => {

    const {openGenericDialog} = useDialog();

    return () => {
        openGenericDialog("Are you sure you want to logout?", "Logout", false, true, () => {
            window.localStorage.removeItem('orderId');
            window.localStorage.removeItem('feathers-jwt');
            window.location.replace("http://holta.co.uk");
        })
    }

    
}