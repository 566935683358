import styled from 'styled-components';

export const AppLayoutContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    flex-direction: column;
    min-width: 1200px;
`;

export const AppLayoutSidebar = styled.div`
    display: flex;
    overflow-y:auto;
    flex-shrink: 0;
`;

export const AppLayoutHeader = styled.div`
    width: 100%;
`;

export const AppLayoutBody = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const AppLayoutMain = styled.div`
    display: flex;
    flex-grow: 1;
    overflow: auto;
`;