import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

interface Props {}

const Row = () => (
    <Tr >
        <Td colspan={0.5} align="c">
           <FormCheckbox />
        </Td>
        <Td colspan={4}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={2}>
            <Ellipsis tooltipText="asdaa sdaasda asdaasda">
                <Text size="m">asdaa sdaasda asdaasda</Text>
            </Ellipsis>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
        <Td colspan={2}>
            <Text size="m">asda</Text>
        </Td>
    </Tr>
);

export const TodoTable: React.FC<Props> = () => {
    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={12.5}>
                <Thead>
                    <Tr>
                        <Th colspan={0.5} align="c"></Th>
                        <Th colspan={4}>Todo</Th>
                        <Th colspan={2}>Due</Th>
                        <Th colspan={2}>Date Created</Th>
                        <Th colspan={2}>Assigned To</Th>
                        <Th colspan={2}>Created By</Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                        <Row />
                        <hr />
                </Tbody>
            </Table>
        </Spacer>
    );
};
