
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';
import { getMergedOrder } from "./getMergedOrder";
import { feathersApp } from "../../config/feathers";
import { setLoadedDoorRange } from "./setLoadedDoorRange";



export const setDiscount = (discount: number, updateItemDiscounts?: boolean) => {
        // 1. Merge the current and edited order
        const mergedOrder = getMergedOrder();
        const editOrder = getEditOrder();

        // 2. Create a provisional updated order
        const dryRunOrderChange = fns.order.set.discount(discount, mergedOrder, {
            updateItemDiscounts,
            returnUpdatesOnly: true,
        });

        // 3. Set the main order discount if different
        useStore.setState({
            editOrder : create(editOrder, draft => {
                draft.newValues.order.discount = discount;
            })
        });

        // 4. Update the order items if necessary
        if (updateItemDiscounts && dryRunOrderChange.items && dryRunOrderChange.items?.length > 0) {
            
            const updatedItems = fns.order_items.combineEdits(editOrder.newValues.order.items || [], dryRunOrderChange.items);

            useStore.setState({
                editOrder : create(editOrder, draft => {
                    draft.newValues.order.items = updatedItems;
                })
            });
        }
    };