import { fns } from "@holta/lib";
import { FormSelect, Grid, Island, Spacer, Text, theme } from "@holta/ui";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useStore } from "../../../hooks/useStore";
import {
    endOfWeek,
    startOfWeek,
    add,
    startOfMonth,
    endOfMonth,
    format,
    subMonths,
    addMonths,
} from "date-fns";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


export const Chart3 = () => {
    const orders = useStore((store) => store.orders);
    const today = new Date();
    const currentMonth = format(new Date(), "MMMM");
    const lastMonth = format(subMonths(new Date(), 1), "MMMM");
    const twoMonthsAgo = format(subMonths(new Date(), 2), "MMMM");
    const threeMonthsAgo = format(subMonths(new Date(), 3), "MMMM");
    const fourMonthsAgo = format(subMonths(new Date(), 4), "MMMM");
    const fiveMonthsAgo = format(subMonths(new Date(), 5), "MMMM");
    const sixMonthsAgo = format(subMonths(new Date(), 6), "MMMM");
    const sevenMonthsAgo = format(subMonths(new Date(), 7), "MMMM");
    const eightMonthsAgo = format(subMonths(new Date(), 8), "MMMM");
    const nextMonth = format(addMonths(new Date(), 1), "MMMM");
    const twoMonthsFromNow = format(addMonths(new Date(), 2), "MMMM");
    const threeMonthsFromNow = format(addMonths(new Date(), 3), "MMMM");
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        scales: {
            x: {
                grid: {
                    color: (context: any) => {
                        console.log(context)
                        return context.tick.label === currentMonth
                            ? theme.colorLight.highlight
                            : "rgba(0, 0, 0, 0.1)";
                    },
                },
                ticks: {
                    color: (context: any) => {
                        return context.tick.label === currentMonth ? theme.colorLight.highlight : "black";
                    },
                },
            },
        },
    };

    function getTotalForMonth(monthDiff: number) {
        const monthStart = add(startOfMonth(today), {
            months: monthDiff,
        }).getTime();
        const monthEnd = add(endOfMonth(today), {
            months: monthDiff,
        }).getTime();

        const deliveries = fns.orders.filterByDateRange(orders, "DELIVERY", monthStart, monthEnd);

        const total = fns.orders.getTotalValue(deliveries, { excludeTax: true });

        return total;
    }

    

    const labels = [
        eightMonthsAgo,
        sevenMonthsAgo,
        sixMonthsAgo,
        fiveMonthsAgo,
        fourMonthsAgo,
        threeMonthsAgo,
        twoMonthsAgo,
        lastMonth,
        currentMonth,
        nextMonth,
        twoMonthsFromNow,
        threeMonthsFromNow,
    ];
    const data = {
        labels,
        datasets: [
            {
                label: "This Year",
                data: [
                    getTotalForMonth(-8),
                    getTotalForMonth(-7),
                    getTotalForMonth(-6),
                    getTotalForMonth(-5),
                    getTotalForMonth(-4),
                    getTotalForMonth(-3),
                    getTotalForMonth(-2),
                    getTotalForMonth(-1),
                    getTotalForMonth(0),
                    getTotalForMonth(1),
                    getTotalForMonth(2),
                    getTotalForMonth(3),
                ],
                borderColor: theme.colorLight.highlight,
                backgroundColor: theme.colorLight.highlight,
            },
            {
                label: "Last Year",
                data: [
                    getTotalForMonth(-20),
                    getTotalForMonth(-19),
                    getTotalForMonth(-18),
                    getTotalForMonth(-17),
                    getTotalForMonth(-16),
                    getTotalForMonth(-15),
                    getTotalForMonth(-14),
                    getTotalForMonth(-13),
                    getTotalForMonth(-12),
                    getTotalForMonth(-11),
                    getTotalForMonth(-10),
                    getTotalForMonth(-9),
                ],
                borderColor: theme.colorLight.accent2,
                backgroundColor: theme.colorLight.accent2,
            },
            {
                label: "2 Years Ago",
                data: [
                    getTotalForMonth(-32),
                    getTotalForMonth(-31),
                    getTotalForMonth(-30),
                    getTotalForMonth(-29),
                    getTotalForMonth(-28),
                    getTotalForMonth(-27),
                    getTotalForMonth(-26),
                    getTotalForMonth(-25),
                    getTotalForMonth(-24),
                    getTotalForMonth(-23),
                    getTotalForMonth(-22),
                    getTotalForMonth(-21),
                ],
                borderColor: theme.colorLight.accent1,
                backgroundColor: theme.colorLight.accent1,
            },
        ],
    };

    return (
        <>
            <Text size="l" bold>
                Delivered this month
            </Text>
            <Spacer xs={[1, 0]}></Spacer>
            <hr />
            <Line options={options} data={data} />
        </>
    );
};
