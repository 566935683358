import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormTags,
    FormCheckbox,
    FormSelect,
    FormToggle,
    FormNotice,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalLinkProductContext } from "./context";
import { useStore } from "../../hooks/useStore";
import { fns, libTypes } from "@holta/lib";
import { FormNumber, FormTextarea } from "@holta/ui";

interface Props {
    children?: React.ReactNode;
}

export const ModalLinkProduct: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const categories = useStore((store) => store.categories);
    const customers = useStore((store) => store.customers);
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);

    const {
        linkProduct,
        isEdited,
        isEditable,
        setIsEditable,
        isNew,
        handleDelete,
        cancel,
        confirm,
        validationResult,
        validate,
        setName,
        setW,
        setH,
        setD,
        setCategories,
        setDescription,
    } = useModalLinkProductContext();

    if (!linkProduct) {
        return null;
    }
    return (
        <Modal id="MODAL_LINK_PRODUCT" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Link Product</Grid.Row>
                    </div>
                    {/** TODO: Enable this*/}
                    {false && !isNew && !isEdited && (
                        <div onClick={() => setIsEditable(!isEditable)}>
                            <Text size="s" color="base_contrast">
                                {isEditable ? "CANCEL EDITING" : "EDIT"}
                            </Text>
                        </div>
                    )}
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Name</FormLabel>
                                {isEditable ? (
                                    <FormInput
                                        value={linkProduct.name}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setName(e.target.value)}
                                        onBlur={validate}
                                    />
                                ) : (
                                    <FormValue>{linkProduct.name}</FormValue>
                                )}
                                {isEditable &&
                                    validationResult &&
                                    !validationResult.name._isValid && (
                                        <FormNotice>{validationResult.name._errors[0]}</FormNotice>
                                    )}
                            </Spacer>
                            <hr />
                            <Spacer xs={[1, 0]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 0]} style={{ width: "100%" }}>
                                            <FormLabel>Width</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={linkProduct.w || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setW(parseFloat(e.target.value))
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {linkProduct.w}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Height</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={linkProduct.h || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setH(parseFloat(e.target.value))
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {linkProduct.h}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 0, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Depth</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={linkProduct.d || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setD(parseFloat(e.target.value))
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {linkProduct.d}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <hr />
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Categories</FormLabel>
                                {isEditable ? (
                                    <FormSelect
                                    options={[...categories].reverse().map((category) => ({
                                        label: fns.category.getHeirachicalName(category, categories),
                                        value: category.id,
                                    }))}
                                    value={
                                        linkProduct.categories &&
                                        linkProduct.categories.length > 0 &&
                                        linkProduct.categories.sort().map((id) => {
                                            const category = categories.find((c) => c.id === id);
                                            if (category)
                                                return {
                                                    label: fns.category.getHeirachicalName(
                                                        category,
                                                        categories
                                                    ),
                                                    value: category.id,
                                                };
                                        })
                                    }
                                        isMulti={true}
                                        onChange={(options: { label: string; value: string }[]) => {
                                            setCategories(
                                                options && options.length > 0
                                                    ? options.map((option) => option.value)
                                                    : []
                                            );
                                        }}
                                    />
                                ) : (
                                    linkProduct.categories &&
                                    linkProduct.categories
                                        .filter((id) => categories.find((c) => c.id === id))
                                        .map((id) => {
                                            const category = categories.find((c) => c.id === id);
                                            return (
                                                <Tag type={5} size="l" key={category?.id}>
                                                    {category?.name}
                                                </Tag>
                                            );
                                        })
                                )}
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Description</FormLabel>
                                {isEditable ? (
                                    <FormTextarea
                                        value={linkProduct.description}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                ) : (
                                    <FormValue>{linkProduct.description}&nbsp;</FormValue>
                                )}
                            </Spacer>
                            
                        </Island>

                        <Spacer xs={[1]}></Spacer>
                        {!isNew && isEditable && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Delete Link Product</FormLabel>
                                    <button className="danger" onClick={handleDelete}>
                                        Delete
                                    </button>
                                </Spacer>
                            </Island>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_LINK_PRODUCT")}> Ok</button>
                    )}
                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
