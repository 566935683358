import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
interface Props {}

export const Header: React.FC<Props> = () => {

    const {openModal} = useModal();
    
    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Customers</Text>
                </Grid.Col>
                <Grid.Col>
                    <Link href="/orders/new"><button className="primary">New Customer</button></Link>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        
                        <div>
                            <FormInput placeholder="Search..." />
                        </div>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    
                    <Grid.Row>
                        <div>
                            <IconButton icon="function" className="tertiary" disabled>
                            <Spacer xs={[1, 0.25]}>Actions</Spacer>
                            </IconButton>
                        </div>
                        <Spacer xs={[0, 0.5]}></Spacer>
                        
                        <div>
                            <IconButton icon="settings" className="tertiary">
                            <Spacer xs={[1, 0.25]}>Settings</Spacer>
                            </IconButton>
                        </div>
                        <Spacer xs={[0, 0.5]}></Spacer>
                        <div>
                            <IconButton icon="filter" className="tertiary"  onClick={() => openModal('ORDERS_FILTER')}>
                                <Spacer xs={[1, 0.25]}>Filter</Spacer>
                            </IconButton>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
