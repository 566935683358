import {
    Container,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Vr,
} from "@holta/ui";
import { Header } from "./parts/Header";
import styled from "styled-components";

import { DeliveryContext } from "./context";
import { libTypes, staticData, fns } from "@holta/lib";
import { JobCard } from "../../components/JobCard";
import { useStore } from "../../config/store";
import { add, getISODay } from "date-fns";
import { navigate } from "raviger";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { DropZone } from "./parts/DropZone";
import { user } from "../../fns/user";

export const Deliveries = () => {
    const orders = useStore((store) => store.deliveryOrders);
    const weekStartDate = useStore((store) => store.ui.delivery.weekStart);
    const thisDay = getISODay(new Date());
    const weekCommencingOrders = fns.orders.filterUndelivered(
        fns.orders.filterWCDeliveries(orders)
    );
    const asapOrders = fns.orders.filterUndelivered(fns.orders.filterAsapDeliveries(orders));
    const mondayOrders = fns.orders.filterByDeliveryDay(orders, weekStartDate);
    const tuesdayOrders = fns.orders.filterByDeliveryDay(
        orders,
        add(new Date(weekStartDate), { days: 1 }).getTime()
    );
    const wednesdayOrders = fns.orders.filterByDeliveryDay(
        orders,
        add(new Date(weekStartDate), { days: 2 }).getTime()
    );
    const thursdayOrders = fns.orders.filterByDeliveryDay(
        orders,
        add(new Date(weekStartDate), { days: 3 }).getTime()
    );
    const fridayOrders = fns.orders.filterByDeliveryDay(
        orders,
        add(new Date(weekStartDate), { days: 4 }).getTime()
    );

    return (
        <DeliveryContext.Provider value={{}}>
            <Container style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Header />
                <Spacer
                    xs={[0, 1, 1, 1]}
                    style={{ width: "100%", display: "flex", flexDirection: "column", flex: 1 }}
                >
                    <DndProvider backend={HTML5Backend}>
                        <Island p={0} style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                            <Grid.Row style={{ flex: 1 }}>
                                <Grid.Col xs={{ cols: 3 }}>
                                    <DropZone date={weekStartDate} dateType="WEEK">
                                        <Spacer xs={[1]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Day>WC</Day>
                                                <Text size="l">
                                                    Week Commencing{" "}
                                                    {fns.shared.formatDate(weekStartDate)}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>
                                        <Spacer xs={[0, 1, 1, 1]}>
                                            {weekCommencingOrders.map((order) => (
                                                <div
                                                    key={order.id}
                                                    onClick={() => user.roles.includes('admin') && navigate("/orders/" + order.id)}
                                                >
                                                    <JobCard order={order} isDraggable />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                    <DropZone date={null} dateType="ASAP">
                                        <Spacer xs={[1]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Day>ASAP</Day>
                                                <Text size="l">Asap Deliveries</Text>
                                            </Grid.Row>
                                        </Spacer>
                                        <Spacer xs={[0, 1, 1, 1]}>
                                            {asapOrders.map((order) => (
                                                <div
                                                    key={order.id}
                                                    onClick={() => user.roles.includes('admin') && navigate("/orders/" + order.id)}
                                                >
                                                    <JobCard order={order} isDraggable />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                </Grid.Col>
                                <Vr />
                                <Grid.Col xs={{ cols: 9 }}>
                                    <DropZone date={weekStartDate} dateType="DAY">
                                        <Spacer xs={[3]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Text size="l">
                                                    Monday - {fns.shared.formatDate(weekStartDate)}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>

                                        <Spacer xs={[0, 3,3,3]}>
                                            {mondayOrders.map((order) => (
                                                <div key={order.id}>
                                                    <JobCard
                                                        isWide
                                                        isDraggable
                                                        order={order}
                                                        onClick={() =>
                                                            user.roles.includes('admin') && navigate("/orders/" + order.id)
                                                        }
                                                    />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                    <hr />
                                    <DropZone
                                        date={add(new Date(weekStartDate), { days: 1 }).getTime()}
                                        dateType="DAY"
                                    >
                                        <Spacer xs={[3]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Text size="l">
                                                    Tuesday -{" "}
                                                    {fns.shared.formatDate(
                                                        add(weekStartDate, { days: 1 })
                                                    )}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>

                                        <Spacer xs={[0, 3, 3, 3]}>
                                            {tuesdayOrders.map((order) => (
                                                <div key={order.id}>
                                                    <JobCard
                                                        isWide
                                                        isDraggable
                                                        order={order}
                                                        onClick={() =>
                                                            user.roles.includes('admin') && navigate("/orders/" + order.id)
                                                        }
                                                    />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                    <hr />
                                    <DropZone
                                        date={add(new Date(weekStartDate), { days: 2 }).getTime()}
                                        dateType="DAY"
                                    >
                                        <Spacer xs={[3]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Text size="l">
                                                    Wednesday -{" "}
                                                    {fns.shared.formatDate(
                                                        add(weekStartDate, { days: 2 })
                                                    )}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>

                                        <Spacer xs={[0, 3, 3, 3]}>
                                            {wednesdayOrders.map((order) => (
                                                <div key={order.id}>
                                                    <JobCard
                                                        isWide
                                                        isDraggable
                                                        order={order}
                                                        onClick={() =>
                                                            user.roles.includes('admin') && navigate("/orders/" + order.id)
                                                        }
                                                    />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                    <hr />
                                    <DropZone
                                        date={add(new Date(weekStartDate), { days: 3 }).getTime()}
                                        dateType="DAY"
                                    >
                                        <Spacer xs={[3]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Text size="l">
                                                    Thursday -{" "}
                                                    {fns.shared.formatDate(
                                                        add(weekStartDate, { days: 3 })
                                                    )}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>

                                        <Spacer xs={[0, 3, 3, 3]}>
                                            {thursdayOrders.map((order) => (
                                                <div key={order.id}>
                                                    <JobCard
                                                        isWide
                                                        isDraggable
                                                        order={order}
                                                        onClick={() =>
                                                            user.roles.includes('admin') && navigate("/orders/" + order.id)
                                                        }
                                                    />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                    <hr />
                                    <DropZone
                                        date={add(new Date(weekStartDate), { days: 4 }).getTime()}
                                        dateType="DAY"
                                    >
                                        <Spacer xs={[3]}>
                                            <Grid.Row xs={{ align: "center" }}>
                                                <Text size="l">
                                                    Friday -{" "}
                                                    {fns.shared.formatDate(
                                                        add(weekStartDate, { days: 4 })
                                                    )}
                                                </Text>
                                            </Grid.Row>
                                        </Spacer>

                                        <Spacer xs={[0, 3, 3, 3]}>
                                            {fridayOrders.map((order) => (
                                                <div key={order.id}>
                                                    <JobCard
                                                        isWide
                                                        isDraggable
                                                        order={order}
                                                        onClick={() =>
                                                            user.roles.includes('admin') && navigate("/orders/" + order.id)
                                                        }
                                                    />
                                                    <Spacer xs={[0.5]} />
                                                </div>
                                            ))}
                                        </Spacer>
                                    </DropZone>
                                </Grid.Col>
                            </Grid.Row>
                        </Island>
                    </DndProvider>
                </Spacer>
            </Container>
        </DeliveryContext.Provider>
    ); 
};

const Day = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.theme.spacing(4)};
    height: ${(props) => props.theme.spacing(4)};
    background-color: ${(props) => props.theme.color.base_contrast};
    border: 1px solid ${(props) => props.theme.color.accent2};
    border-radius: 1000px;
    margin-right: ${(props) => props.theme.spacing(1)};
    ${(props) => props.theme.type.s};
    ${(props) => props.theme.type.bold}
`;
