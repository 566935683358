import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";



export function getMergedOrderItem() : libTypes.OrderItem {
    const editOrder = getEditOrder();
    const mergedOrder = getMergedOrder();

    const orderItem = mergedOrder.items.find((item) => item.id === editOrder.newValues.orderItem?.id);
    const orderItemEdits = editOrder.newValues.orderItem;

    if (!orderItem) {
        return orderItemEdits as libTypes.OrderItem;
    }

    return fns.order_item.combineEdits(orderItem, orderItemEdits as typeof orderItem) as libTypes.OrderItem;
}

