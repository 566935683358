import { fns, libTypes } from "@holta/lib";
import { SupplierRange } from "@holta/lib/types";
import { useDialog, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { StoreEditSupplier, useStore } from "../../config/store";
import React from "react";

export const ModalSupplierRangeContext = React.createContext<
    | {
          supplierRange: StoreEditSupplier['newValues']['ranges'][number] | null;
          isEdited: boolean;
          isNew: boolean;
          isEditable: boolean;
          setIsEditable: (isEditable: boolean) => void;
          openModalSupplierRange: (
              supplierRange: StoreEditSupplier['newValues']['ranges'][number],
              isNew: boolean,
              handleConfirm: (supplierRange: StoreEditSupplier['newValues']['ranges'][number]) => void,
              handleCancel?: () => void
          ) => void;
          cancel: () => void;
          confirm: () => void;
          validate: () => void;
          validationResult: ReturnType<typeof fns.supplierRange.validate.supplierRange> | null;
          handleDelete: () => void;
          setName: (name: libTypes.SupplierRange['name']) => void;
          setPrefix: (prefix: libTypes.SupplierRange['prefix']) => void;
          setDiscount: (discount: libTypes.SupplierRange['discount']) => void;
          setMargin: (margin: libTypes.SupplierRange['margin']) => void;
          setNotes: (notes: libTypes.SupplierRange['notes']) => void;
          createSubDiscount: () => void;
          addSubDiscountBaseProduct: () => void;
          removeSubDiscountBaseProduct: (subDiscountIndex: number, baseProductId: string) => void;
          setSubDiscountMargin: (margin: libTypes.SupplierRange['subDiscounts'][number]['margin'], subDiscountIndex: number) => void;
          setSubDiscountDiscount: (discount: libTypes.SupplierRange['subDiscounts'][number]['discount'], subDiscountIndex: number) => void;
          removeSubDiscount: (index: number) => void;
          setSubDiscountBaseProductToAdd: (baseProduct: string, index: number) => void;
          subDiscountBaseProductToAdd: {id: string, index: number} | null;
      }
    | undefined
>(undefined);

export const useModalSupplierRangeContext = () => {
    const context = React.useContext(ModalSupplierRangeContext);
    if (context === undefined) {
        throw new Error("useModalSupplierRangeContext must be within the correct provider");
    }
    return context;
};

export const ModalSupplierRangeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [supplierRange, setSupplierRange] = React.useState<StoreEditSupplier['newValues']['ranges'][number]| null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const [subDiscountBaseProductToAdd, setSubDiscountBaseProductToAdd] = React.useState<{id: string, index: number} | null>(null);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.supplierRange.validate.supplierRange
    > | null>(null);
    const handleConfirmFn = React.useRef<(supplierRange: StoreEditSupplier['newValues']['ranges'][number]) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const supplierRanges = useStore((store) => store.editSupplier?.currentValues.ranges);
    const otherRanges = supplierRanges?.filter(({ id }) => id !== supplierRange?.id) || [];


    const _setSubDiscountBaseProductToAdd = (id: string, index: number) => {
        setSubDiscountBaseProductToAdd({id, index});
    }

    const validate = () => {
        
        setValidationResult(
            supplierRange && fns.supplierRange.validate.supplierRange(supplierRange, otherRanges)
        );
    };

    const setName = (name: string) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.name(supplierRange, name));
        setIsEdited(true);
    };

    const setPrefix = (setPrefix: string) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.prefix(supplierRange, setPrefix));
        setIsEdited(true);
    };

    const setDiscount = (discount: number | null) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.discount(supplierRange, discount));
        setIsEdited(true);
    };

    const setMargin = (margin: number | null) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.margin(supplierRange, margin));
        setIsEdited(true);
    };

    const setNotes = (notes: string) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.notes(supplierRange, notes));
        setIsEdited(true);
    };

    const createSubDiscount = () => {
        supplierRange && setSupplierRange(fns.supplierRange.set.createSubDiscount(supplierRange));
        setIsEdited(true);
    };

    const removeSubDiscount = (index: number) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.removeSubDiscount(supplierRange, index));
        setIsEdited(true);
    };

    const addSubDiscountBaseProduct = () => {
        supplierRange && subDiscountBaseProductToAdd && setSupplierRange(fns.supplierRange.set.addSubDiscoundBaseProduct(supplierRange, subDiscountBaseProductToAdd.index, subDiscountBaseProductToAdd.id));
        setIsEdited(true);
        setSubDiscountBaseProductToAdd(null);
    };

    const removeSubDiscountBaseProduct = ( subDiscountIndex: number, baseProduct: string) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.removeSubDiscoundBaseProduct(supplierRange, subDiscountIndex, baseProduct));
        setIsEdited(true);
    };

    const setSubDiscountMargin = (margin: number, subDiscountIndex: number) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.subDiscountMargin(supplierRange, subDiscountIndex, margin));
        setIsEdited(true);
    };

    const setSubDiscountDiscount = (discount: number, subDiscountIndex: number) => {
        supplierRange && setSupplierRange(fns.supplierRange.set.subDiscountDiscount(supplierRange, subDiscountIndex, discount));
        setIsEdited(true);
    };


    const handleDelete = () => {
        if(!supplierRange) return;
        openGenericDialog(
            "Ths will remove this product range.",
            "Confirm Delete?",
            false,
            true,
            () => {
                handleConfirmFn.current && supplierRange && handleConfirmFn.current({...supplierRange, _deleted: true});
                closeModal("MODAL_SUPPLIER_RANGE");
            }
        );
    };

    const wrappedOpenModal = (
        supplierRange: libTypes.SupplierRange,
        isNew: boolean,
        handleConfirm: (supplierRange: libTypes.SupplierRange) => void,
        handleCancel?: () => void
    ) => {
        setSupplierRange(supplierRange);
        setIsEdited(false);
        setIsNew(isNew);
        setIsEditable(isNew);
        openModal("MODAL_SUPPLIER_RANGE");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;

    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        setSupplierRange(null);
        setIsEdited(false);
        setIsEditable(false);
        setIsNew(false);
        setValidationResult(null);
        closeModal("MODAL_SUPPLIER_RANGE");
    };

    const confirm = () => {
        if (!supplierRange) {
            closeModal("MODAL_SUPPLIER_RANGE");
            return;
        }
        if (!fns.supplierRange.validate.supplierRange(supplierRange, otherRanges)._isValid) {
            validate();
            openGenericDialog("Product Component is not valid, please check the input values");
        } else {
            handleConfirmFn.current && supplierRange && handleConfirmFn.current(supplierRange);
            closeModal("MODAL_SUPPLIER_RANGE");
        }
    };

    return (
        <ModalSupplierRangeContext.Provider
            value={{
                supplierRange,
                isEdited,
                isNew,
                handleDelete,
                isEditable,
                setIsEditable,
                openModalSupplierRange: wrappedOpenModal,
                cancel,
                confirm,
                validate,
                validationResult,
                setName,
                setPrefix,
                setDiscount,
                setMargin,
                setNotes,
                createSubDiscount,
                removeSubDiscount,
                addSubDiscountBaseProduct,
                removeSubDiscountBaseProduct,
                setSubDiscountMargin,
                setSubDiscountDiscount,
                setSubDiscountBaseProductToAdd : _setSubDiscountBaseProductToAdd,
                subDiscountBaseProductToAdd
            }}
        >
            {children}
        </ModalSupplierRangeContext.Provider>
    );
};
