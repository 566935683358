import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormTags,
    FormCheckbox,
    FormSelect,
    FormToggle,
    FormNotice,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalCategoryContext } from "./context";
import { useStore } from "../../hooks/useStore";
import { fns, libTypes } from "@holta/lib";

interface Props {
    children?: React.ReactNode;
}

export const ModalCategory: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const categories = useStore((store) => store.categories);
    const customers = useStore((store) => store.customers);
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);

    const {
        category,
        isEdited,
        isNew,
        handleDelete,
        setName,
        setParentId,
        setCustomerWhitelist,
        toggleCustomerRestricted,
        addToCustomerWhitelist,
        removeFromCustomerWhitelist,
        cancel,
        confirm,
        validationResult,
        validate,
    } = useModalCategoryContext();

    if (!category) {
        return null;
    }
    return (
        <Modal id="MODAL_CATEGORY" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Category</Grid.Row>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Name</FormLabel>
                                <FormInput
                                    value={category.name}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setName(e.target.value)}
                                    onBlur={validate}
                                />
                                {validationResult && !validationResult.name._isValid && (
                                    <FormNotice>{validationResult.name._errors[0]}</FormNotice>
                                )}
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Parent Category</FormLabel>
                                <FormSelect
                                    options={categories
                                        .filter((cat) => cat.id !== category.id)
                                        .filter(
                                            (cat) =>
                                                !fns.category
                                                    .getChildren(categories, category.id)
                                                    .some((c) => c.id === cat.id)
                                        )
                                        .map((cat) => ({
                                            label: fns.category.getHeirachicalName(cat, categories),
                                            value: cat.id,
                                        }))}
                                    value={
                                        categories.find((cat) => category.parentId === cat.id)
                                            ? {
                                                  label: fns.category.getHeirachicalName(
                                                      categories.find(
                                                          (cat) => category.parentId === cat.id
                                                      ) as libTypes.Category,
                                                      categories
                                                  ),
                                                  id: category.parentId,
                                              }
                                            : null
                                    }
                                    onChange={(option: { label: string; value: string }) =>
                                        setParentId(option.value)
                                    }
                                />
                            </Spacer>

                            <Spacer xs={[1, 0]}>
                                <FormLabel>Customer Resticted</FormLabel>
                                <FormToggle
                                    checked={category.customerRestricted}
                                    onChange={toggleCustomerRestricted}
                                ></FormToggle>
                            </Spacer>

                            {category.customerRestricted && (
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Only accessible for these customers:</FormLabel>
                                    <FormSelect
                                        options={customers.map((customer) => ({
                                            label: customer.name,
                                            value: customer.id,
                                        }))}
                                        value={
                                            category &&
                                            category.customerWhitelistIds.map((id) => {
                                                const customer = customers.find((c) => c.id === id);
                                                if (customer)
                                                    return {
                                                        label: customer.name,
                                                        value: customer.id,
                                                    };
                                            })
                                        }
                                        isMulti={true}
                                        onChange={(options: { label: string; value: string }[]) => {
                                            setCustomerWhitelist(
                                                options && options.length > 0 ? options.map((option) => option.value) : []
                                            );
                                        }}
                                    />
                                </Spacer>
                            )}
                        </Island>

                        <Spacer xs={[1]}></Spacer>
                        {!isNew && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Delete Contact</FormLabel>
                                    <button className="danger" onClick={handleDelete}>
                                        Delete
                                    </button>
                                </Spacer>
                            </Island>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_CATEGORY")}> Ok</button>
                    )}
                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
