import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
    OptionCard,
    FormCheckbox,
} from "@holta/ui";
import { libTypes } from "@holta/lib";
import React from "react";

interface Props {
    children?: React.ReactNode;
    handleConfirm: (choseDoors: libTypes.LinkedDoorOrderItem[]) => void;
    duplicateDoors: libTypes.LinkedDoorOrderItem[][];
}

export const ModalDuplicateLinkedDoors: React.FC<Props> = ({ handleConfirm, duplicateDoors }) => {
    const { closeModal } = useModal();
    const [chosenDoors, setChosenDoors] = React.useState<(string|null)[]>(duplicateDoors.map(() => null));

    React.useEffect(() => {
        setChosenDoors(duplicateDoors.map(() => null));
    }, [duplicateDoors]);

    const id = "MODAL_DUPLICATE_LINKED_DOORS";

    const wrappedHandleConfirm = () => {
        const selected = duplicateDoors.map(
            (doors, index) => doors.find((door) => door.id === chosenDoors[index])!
        );
        handleConfirm(selected);
        closeModal(id);
    };

    return (
        <Modal id={id} style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>Multiple matching doors</ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Text>Choose the doors you want to add</Text>
                            <Spacer xs={[1]} />
                            {duplicateDoors.map((doors, index) => (
                                <Island>
                                    {doors.map((door) => (
                                        <>
                                        <Grid.Row key={door.id}>
                                            <FormCheckbox onClick={() => setChosenDoors(chosenDoors.map((d, i) => i === index ? door.id : d))} checked={chosenDoors[index] === door.id}/>
                                            <div>&nbsp;{door.code}</div>
                                        </Grid.Row>
                                        <Spacer xs={[0.5]} />
                                        </>
                                    ))}
                                </Island>
                            ))}
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal(id)}>Cancel</button>
                    <Spacer xs={[0.5]} />
                    {chosenDoors.every((door) => door) && (
                        <button className="primary" onClick={wrappedHandleConfirm}>
                            Confirm
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
