import React from 'react';
import autoAnimate from '@formkit/auto-animate';

export const useAutoAnimate = () => {
    const parent = React.useRef(null);

    React.useEffect(() => {
        parent.current && autoAnimate(parent.current, {duration: 100})
    }, [parent])

    return parent
}