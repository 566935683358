import { createAction } from "../fns/createAction";
import { fns, libTypes } from "@holta/lib";
import { Category } from "@holta/lib/types";
import { feathersApp } from "../config/feathers";

export const setcategories = createAction("SET_PRODUCT_CATEGORIES", (newState, categories: Category[]) => {
    newState.categories = fns.categories.sortByHeirachicalName(categories, 'ASC');
});

export const saveCategory = (category: libTypes.Category) => {
    try {
       return feathersApp.service("categories").create(category);
    }
    catch (e) {
        throw e;
    }
}

export const updateCategory = (category: libTypes.Category) => {
    try {
       return feathersApp.service("categories").patch(category.id, category);
    }
    catch (e) {
        throw e;
    }
}



export const deleteCategory = async (category: libTypes.Category | null) => {
    if(!category) throw new Error("Category is required");

    try {
        return await feathersApp.service('categories').remove(category.id)
    } catch (error) {
        throw error;
    }
}

