import { fns } from "@holta/lib";
import { code } from "@holta/lib/functions/product/set";
import { ProductComponent } from "@holta/lib/types";
import {
    FormInput,
    FormLabel,
    FormSelect,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spacer,
    TreeNav
} from "@holta/ui";
import { setComponentFilter, setCategoryFilter, clearAllProductsFilters} from "../../../actions/ui";
import { useStore } from "../../../config/store";
import { useProductBrowserContext } from "../context";

export const FilterSidebar = () => {
    const productComponents = useStore((state) => state.productComponents);
    const ui = useStore((state) => state.ui);
    const {toggleCategory, categories, selectedCategoryBranch, expandedCategories} = useProductBrowserContext()
    return (
        <Modal id="PRODUCTS_FILTER" sideBar>
            <>
                <ModalHeader>Apply Filters</ModalHeader>
                <ModalBody style={{ minWidth: "300px" }}>
                    <Spacer xs={[2]}>
                        <FormLabel>Components</FormLabel>
                        <FormSelect
                            options={productComponents
                                .filter(
                                    (component) =>
                                        !ui.products.componentsFilter.map(c => c.value).includes(component.id)
                                )
                                .map((component) => {
                                    return {
                                        value: component.id,
                                        label: component.code,
                                    };
                                })}
                            value={ui.products.componentsFilter}
                            isMulti
                            onChange={setComponentFilter}
                        />
                    </Spacer>
                    <hr />
                    <Spacer xs={[1, 1]}>
                        <TreeNav expandedItems={expandedCategories} selectedBranch={selectedCategoryBranch} toggleItem={toggleCategory} items={categories}/>
                        
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button className="tertiary" onClick={clearAllProductsFilters}>Clear All</button>
                </ModalFooter>
            </>
        </Modal>
    );
};
