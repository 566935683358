import { fns } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedCustomer = () => {
    const editedCustomerValues = useStore(state => state.editCustomer?.newValues.customer);
    const existingCustomerValues = useStore(state => state.editCustomer?.currentValues.customer);

    if(!existingCustomerValues && !editedCustomerValues) return {}
    if(!existingCustomerValues) return editedCustomerValues;
    if(!editedCustomerValues) return existingCustomerValues;
    
    return fns.customer.merge(existingCustomerValues, editedCustomerValues);

}