import React from "react";

export const CustomerContext = React.createContext<{
    tab : number,
    setTab : (tab : number) => void
} | undefined>(undefined);

export const useCustomerContext = () => {
    const context = React.useContext(CustomerContext);
    if (context === undefined) {
        throw new Error("useCustomerContext must be within the correct provider");
    }
    return context;
};

export const useCustomerContextProps = () => {
    const [tab, setTab] = React.useState(0);

    return {
        tab,
        setTab,
    }   
}