import React from "react";
import { feathersApp } from "../config/feathers";
import { setLinkProducts } from "../actions/linkProducts";
import { libTypes } from "@holta/lib";

export const useLoadLinkProducts = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);
    if (!hasRun.current) {
        feathersApp
            .service("link-products")
            .watch()
            .find({
                query: {
                    $limit: 100000,
                },
                $sort: {
                    name: 1,
                },
            })
            .subscribe(({ data }: { data: libTypes.LinkProduct[] }) => {
                setLinkProducts(data);
                setIsLoaded(true);
            });
        hasRun.current = true;
    }
    return isLoaded;
};
