
interface User {
    id : string | null,
    email : string | null,
    roles : string[]
}

export let user: User & {isAdmin : boolean}= {
    id : null,
    email : null,
    roles : [],
    isAdmin: false
}


export const setUser = (userUpdate: User) => {
    const isAdmin = userUpdate.roles?.includes("admin");
    user = {
        ...userUpdate,
        isAdmin
    }
}