import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { create } from "mutative";

export const setDeliveryDate = (
    deliveryDate: libTypes.Order["deliveryDate"],
    deliveryDateType: libTypes.Order["deliveryDateType"],
    warningFn: (confirmFn: () => void) => void
) => {
    if (deliveryDate && Date.now() > deliveryDate && deliveryDateType !== "NONE") {
        warningFn(() => {
            _deliveryDate(deliveryDate, deliveryDateType);
        });
    } else {
        _deliveryDate(deliveryDate, deliveryDateType);
    }
};

const _deliveryDate = (
    deliveryDate: libTypes.Order["deliveryDate"],
    deliveryDateType: libTypes.Order["deliveryDateType"]
) => {
    useStore.setState({
        editOrder: create(getEditOrder(), (draft) => {
            draft.newValues.order.deliveryDate = fns.order.set.deliveryDate(deliveryDate);
            draft.newValues.order.deliveryDateType =
                fns.order.set.deliveryDateType(deliveryDateType);
        }),
    });
};
