import { feathersApp } from '../config/feathers';
import { EditedContact } from '../config/store';
import { createAction } from "../fns/createAction";
import { libTypes, fns } from '@holta/lib';


export const setContacts = createAction("SET_CONTACTS", (newState, contacts: libTypes.Contact[]) => {
    newState.contacts = fns.shared.sortByString(contacts, 'firstName', 'ASC');
});


export const saveContactsToDb = async (contacts : EditedContact[]) => {

    const newContacts = contacts.filter((c) => c._dbUnsaved);
    const updatedContacts = contacts.filter((c) => !c._dbUnsaved && !c._deleted);
    const deletedContacts = contacts.filter((c) => c._deleted);

    if (newContacts.length > 0)
        await feathersApp
            .service("contacts")
            .create(newContacts.map(({ _dbUnsaved, ...rest }) => ({ ...rest })));
    if (updatedContacts.length > 0)
        for (let index = 0; index < updatedContacts.length; index++) {
            await feathersApp.service("contacts").update(updatedContacts[index].id, updatedContacts[index]);
        }
    if (deletedContacts.length > 0)
        await feathersApp.service("contacts").remove(null, {query: {id: { $in : deletedContacts.map(({ id }) => id)}}});

    return true;
};