import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Island,
    Notice,
    Icon,
    Grid,
    Vr,
    IconButton,
} from "@holta/ui";
import { SidebarButton } from "../../components/SidebarButton";
import { Orders } from "./parts/Orders";
import { Header } from "./parts/Header";
import {CustomerContext, useCustomerContextProps} from './context';
import {Sidebar} from "./parts/Sidebar";
import { Contacts } from "./parts/Contacts";
import {Addresses} from './parts/Addresses';

export const Settings = () => {
    const contextProps = useCustomerContextProps()
    return (
        <CustomerContext.Provider value={{...contextProps}}>
            <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
                <div>
                    <Header />
                </div>
                <div style={{flex: 1}}>
                    <Spacer xs={[0, 2, 2, 2]} style={{ height:'100%'}}>
                        <Island p={0} style={{height:'100%', overflow: 'auto'}}>
                            <Grid.Row xs={{align: 'stretch'}} style={{height: '100%'}}>
                                <Grid.Col xs={{cols:3}}>
                                    <Sidebar />
                                </Grid.Col>
                                <Vr />
                                <Grid.Col xs={{cols:9}}>
                                    {contextProps.tab === 0 && <Orders />}
                                </Grid.Col>
                            </Grid.Row>
                        </Island>
                    </Spacer>
                </div>
            </Container>
        </CustomerContext.Provider>
    );
};
