
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setAccountingStatus = (accountingStatus: libTypes.Order['accountingStatus']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.accountingStatus = fns.order.set.accountingStatus(accountingStatus);
        })
    });
};