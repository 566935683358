import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    useModal,
    HighlightedText,
    Tag,
    Menu,
    Icon,
    MenuItem,
    Grid,
    useDialog,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useFilteredProducts } from "../../../hooks/useFilteredProducts";
import { useModalProductContext } from "../../../components/ModalProduct/context";
import { AdminStore, useStore } from "../../../config/store";
import { saveProduct, updateProduct } from "../../../actions/products";
import { toggleSelectedProduct, setSelectedProducts } from "../../../actions/ui";
import { fns, libTypes } from "@holta/lib";
import { handleProductsTableHeadingClick } from "../../../actions/ui";
import { SortHighlight } from "../../../components/SortHighlight";
import { user } from "../../../fns/user";
import { deleteProduct } from "../../../actions/products";
import { useProductBrowserContext } from "../context";

interface Props {}

const Row = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: { products: libTypes.Product[]; ui: AdminStore["ui"]; categories: libTypes.Category[], components: libTypes.ProductComponent[] };
}) => {
    const { ui, products, categories, components } = data;
    const { openGenericDialog } = useDialog();
    const product = products[index];
    const { openModalProduct } = useModalProductContext();

    const categoryNames = product.categories ? product.categories
        .filter((categoryId) => categories.find((category) => category.id === categoryId))
        .map((categoryId) =>
            fns.category.getHeirachicalName(
                categories.find((cat) => cat.id === categoryId) as libTypes.Category,
                categories
            )
        ) : [];

    if (!product) return null;

    const handleDeleteProduct = () => {
        openGenericDialog(
            `Are you sure you want to remove this product?`,
            "Delete Product",
            false,
            true,
            () => {
                deleteProduct(product.id).then(() => {
                    toggleSelectedProduct(product.id);
                });
            }
        );
    };
    return (
        <Tr style={style} onClick={() => openModalProduct(product, false, updateProduct)} hasHover>
            <Td colspan={0.25} align="c">
                <div
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    <FormCheckbox
                        onChange={(e: any) => {
                            e.stopPropagation();
                            toggleSelectedProduct(product.id);
                        }}
                        checked={ui.products.selectedProducts.includes(product.id)}
                    />
                </div>
            </Td>
            <Td colspan={2}>
                <Ellipsis tooltipText={product.code}>
                    <Text size="m">
                        <HighlightedText text={product.code} highlight={ui.products.searchFilter} />
                    </Text>
                </Ellipsis>
            </Td>
            <Td colspan={2}>
                <Ellipsis
                    tooltipText={
                        categoryNames.toString()
                    }
                >
                    {categoryNames.map((name) => (
                            <Tag key={name} type={5}>
                                {name}
                            </Tag>
                        ))}
                </Ellipsis>
            </Td>
            <Td colspan={4}>
                <Ellipsis tooltipText={product.description}>
                    <Text size="m">
                        <HighlightedText
                            text={product.description}
                            highlight={ui.products.searchFilter}
                        />
                    </Text>
                </Ellipsis>
            </Td>
            <Td colspan={1}>
                <Ellipsis tooltipText={fns.shared.formatDate(product.dateCreated)}>
                    <Text size="m">£{fns.product.getPrice(product, components)}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={1}>
                <Ellipsis tooltipText={fns.shared.formatDate(product.dateCreated)}>
                    <Text size="m">{fns.shared.formatDate(product.dateCreated)}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={0.5} align="c">
                <div onClick={(e) => e.stopPropagation()}>
                    <Menu button={<Icon name="menu" />}>
                        <MenuItem
                            onClick={() =>
                                openModalProduct(
                                    fns.product.create(user.id as string, product),
                                    true,
                                    saveProduct,
                                    () => {},
                                    true
                                )
                            }
                        >
                            <Grid.Row>
                                <Icon name="file-copy"></Icon>&nbsp;Duplicate
                            </Grid.Row>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteProduct}>
                            <Grid.Row>
                                <Icon name="delete-bin"></Icon>&nbsp;Delete
                            </Grid.Row>
                        </MenuItem>
                    </Menu>
                </div>
            </Td>
        </Tr>
    );
};

export const ProductsTable: React.FC<Props> = () => {
    const {filteredProducts} = useProductBrowserContext()
    const ui = useStore((store) => store.ui);
    const categories = useStore((store) => store.categories);
    const components = useStore((store) => store.productComponents);
    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={8.75}>
                <Thead>
                    <Tr>
                        <Th colspan={0.25} align="c">
                            <FormCheckbox
                                onChange={(e: any) => {
                                    e.stopPropagation();
                                    setSelectedProducts(
                                        ui.products.selectedProducts.length > 0 &&
                                            filteredProducts.every((product) =>
                                                ui.products.selectedProducts.includes(product.id)
                                            )
                                            ? []
                                            : filteredProducts.map((product) => product.id)
                                    );
                                }}
                                checked={
                                    ui.products.selectedProducts.length > 0 &&
                                    filteredProducts.every((product) =>
                                        ui.products.selectedProducts.includes(product.id)
                                    )
                                }
                            />
                        </Th>
                        <Th colspan={2} onClick={() => handleProductsTableHeadingClick("code")}>
                            <SortHighlight
                                direction={ui.products.sortDirection}
                                uiField={ui.products.sortField}
                                thisField="code"
                            >
                                Code
                            </SortHighlight>
                        </Th>
                        <Th colspan={2}>Categories</Th>
                        <Th
                            colspan={4}
                            onClick={() => handleProductsTableHeadingClick("description")}
                        >
                            <SortHighlight
                                direction={ui.products.sortDirection}
                                uiField={ui.products.sortField}
                                thisField="description"
                            >
                                Description
                            </SortHighlight>
                        </Th>
                        <Th
                            colspan={1}
                            
                        >
                                Price
                            
                        </Th>
                        <Th
                            colspan={1}
                            onClick={() => handleProductsTableHeadingClick("dateCreated")}
                        >
                            <SortHighlight
                                direction={ui.products.sortDirection}
                                uiField={ui.products.sortField}
                                thisField="dateCreated"
                            >
                                Date Created
                            </SortHighlight>
                        </Th>
                        <Th colspan={0.5}></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List
                                height={height - 2}
                                itemCount={filteredProducts.length}
                                itemSize={46}
                                width={width - 1}
                                overscanCount={30}
                                itemData={{ products: filteredProducts, ui, categories, components }}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
