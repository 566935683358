import {
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    FormLabel,
    FormTextarea,
    FormSelect,
    useModal,
    useDialog,
} from "@holta/ui";
import { useStore } from "../../../config/store";
import React from "react";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { feathersApp } from "../../../config/feathers";
import editOrdeActions from "../../../actions/editOrder";
interface Props {
    id: string;
    supplierId: string;
}

export const ModalPOEmail: React.FC<Props> = ({ id, supplierId }) => {
    const { closeModal } = useModal();
    const mergedEditOrder = useMergedEditOrder();
    const contacts = useStore((state) => state.contacts).filter((contact) => contact.parentId === supplierId);
    const supplier = useStore((state) => state.suppliers).find(s => s.id === supplierId);
    const [selectedContactId, setSelectedContactId] = React.useState<string | null>(null);
    const [addressField, setAddressField] = React.useState<string>("");
    const [messageField, setMessageField] = React.useState<string>("");
    const [subjectField, setSubjectField] = React.useState<string>("");
    const { openGenericDialog } = useDialog();
    const defaultContact = supplier
    ? contacts.find((contact) => contact.id === supplier.defaultContact)
    : null;

    const selectedContact = selectedContactId
        ? contacts.find((contact) => contact.id === selectedContactId)
        : null;

    React.useEffect(() => {
        setSelectedContactId(defaultContact?.id || null);
        setAddressField(defaultContact?.email || "");
    }, [defaultContact]);


    function changeSelectedContact(value: any) {
        setSelectedContactId(value.value);
        setAddressField(contacts.find((contact) => contact.id === value.value)?.email || "");
    }


    function send() {
        feathersApp.get("authentication").then(({ accessToken }: any) => {
            fetch(`${import.meta.env.VITE_PROTOCOL as string}${import.meta.env.VITE_SERVER_URL as string}/actions`, {
                method: "POST",
                body: JSON.stringify({
                    action: 'SEND_PO_EMAIL',
                    id: mergedEditOrder?.id,
                    supplierId: supplierId,
                    to: addressField,
                    subject: subjectField,
                    message: messageField,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res: any) => {
                    res.json().then((r: any) => {
                        if (r.id) {
                            closeModal(id);
                            editOrdeActions.loadOrder(mergedEditOrder!.id);
                        } else
                            openGenericDialog(
                                `There was an error sending the PO`,
                                "Error",
                                false
                            );
                    });
                })
                .catch((err) => {
                    openGenericDialog(`There was an error sending the PO`, "Error", false);
                });
        });
    }

    if (!mergedEditOrder) return null;

    return (
        <Modal id={id} style={{ width: "600px" }}>
            <>
                <ModalHeader>Send Purchase Order</ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Choose Contact</FormLabel>
                                <FormSelect
                                    style={{ width: "100%" }}
                                    options={contacts.map((c) => ({
                                        label: c.firstName + " " + c.lastName,
                                        value: c.id,
                                    }))}
                                    value={
                                        selectedContact && {
                                            value: selectedContact.id,
                                            label:
                                                selectedContact.firstName +
                                                " " +
                                                selectedContact.lastName,
                                        }
                                    }
                                    onChange={changeSelectedContact}
                                />
                            </Spacer>
                        </Island>
                        <Spacer xs={[1, 0]} />
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>To</FormLabel>
                                <FormInput
                                    placeholder="Email"
                                    style={{ width: "100%" }}
                                    value={addressField}
                                    onChange={(e) => setAddressField(e.target.value)}
                                />
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Subject</FormLabel>
                                <FormInput
                                    placeholder="Subject"
                                    style={{ width: "100%" }}
                                    value={subjectField}
                                    onChange={(e) => setSubjectField(e.target.value)}
                                />
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Message</FormLabel>
                                <FormTextarea
                                    style={{ width: "100%" }}
                                    value={messageField}
                                    onChange={(e) => setMessageField(e.target.value)}
                                />
                            </Spacer>
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal(id)}>Cancel</button>
                    <Spacer xs={[0.5]} />
                    <button className="primary" onClick={send}>
                        Send
                    </button>
                </ModalFooter>
            </>
        </Modal>
    );
};
