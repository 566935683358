import { createAction } from "../fns/createAction";
import { fns, libTypes } from "@holta/lib";
import { Product } from "@holta/lib/types";
import { feathersApp } from "../config/feathers";
import toast from "react-hot-toast";

export const setProducts = createAction("SET_PRODUCT_PRODUCTS", (newState, products: Product[]) => {
    newState.products = fns.shared.sortByString(products, "code", "ASC");
});

export const deleteProduct = (productId: string | string[]) => {
    if (typeof productId === "string") {
        return toast.promise(feathersApp.service("products").remove(productId), {
            loading: "Deleting Product",
            success: "Product Deleted",
            error: "Error Deleting Product",
        });
    } else if (Array.isArray(productId)) {
        return toast.promise(
            feathersApp.service("products").remove(null, { query: { id: { $in: productId } } }),
            {
                loading: "Deleting Products",
                success: "Products Deleted",
                error: "Error Deleting Products",
            }
        );
    }
    return Promise.reject("Invalid Product ID");
};

// saveProduct

export const saveProduct = (product: Product) => {
    return toast.promise(feathersApp.service("products").create(product), {
        loading: "Creating Product",
        success: "Product Created",
        error: "Error Creating Product",
    });
};

// updateProduct
export const updateProduct = (product: Product) => {
    return toast.promise(feathersApp.service("products").patch(product.id, product), {
        loading: "Saving Product",
        success: "Product Saved",
        error: "Error Saving Product",
    });
};

export const updateProducts = (ids: string[], product: Partial<Product>, merge = false) => {
    return toast.promise(
        feathersApp
            .service("products")
            .patch(null, product, { query: { id: { $in: ids }, options : {merge} } }),
        {
            loading: "Saving Products",
            success: "Products Saved",
            error: "Error Saving Products",
        }
    );
};
