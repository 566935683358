import { FormSelect } from "@holta/ui";
import { staticData, fns, libTypes } from "@holta/lib";

const options = staticData.mfcColours
    .map((colour) => ({
        label: colour.code + " " + colour.texture + " " + colour.decor_name,
        value: colour,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const InputCarcaseColour = ({
    value,
    onChange,
}: {
    value: libTypes.EditedOrderValues["carcaseColour"];
    onChange: (value: libTypes.MFCColour | null) => void;
}) => {
    const selected =
        value?.code && value?.texture
            ? { label: value.code + " " + value.texture + " " + value.decor_name, value: value }
            : null;

    return (
        <FormSelect
            options={options}
            style={{ width: "100%" }}
            onChange={(val: { value: libTypes.MFCColour }) => {
                val?.value ? onChange(val.value) : onChange(null);
            }}
            value={selected}
            isClearable
            
        />
    );
};
