import {
    FormValue,
    FormSelect,
    Grid,
    Icon,
    Spacer,
    Text,
    Table,
    Th,
    Td,
    Tr,
    Thead,
    Tbody,
    Ellipsis,
    Menu,
    MenuItem,
    VirtualisedTable
} from "@holta/ui";
import React from "react";
import { useModalSupplierProductImport } from "../context";
import { libTypes, fns } from "@holta/lib";
import { useModalSupplierRangeContext } from "../../../components/ModalSupplierRange/context";
import { useModalSupplierBaseProductContext } from "../../../components/ModalSupplierBaseProduct/context";

interface Props {
    children?: React.ReactNode;
}

export const UnmatchedBaseProducts: React.FC<Props> = ({ children }) => {
    const { unmatchedProducts, supplier, saveRangeToDb } = useModalSupplierProductImport();
    const { openModalSupplierRange } = useModalSupplierRangeContext();

    if (!supplier) return null;
    return (
        <Spacer xs={[2]} style={{ height: "calc(100% - 100px)" }}>

            <div style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                <Table cols={18.5}>
                    <Thead>
                        <Tr>
                            <Th colspan={3}>Range Prefix</Th>
                            <Th colspan={3}>Base Product</Th>
                            <Th colspan={5}>Description</Th>
                            <Th colspan={1.5}>Width</Th>
                            <Th colspan={1.5}>Height</Th>
                            <Th colspan={1.5}>Depth</Th>
                            <Th colspan={1.5}>Price</Th>
                            
                        </Tr>
                    </Thead>
                    <Tbody style={{ flex: 1 }}>
                        <VirtualisedTable Row={Row} items={unmatchedProducts.hasRangeOnly} />
                    </Tbody>
                </Table>
            </div>
        </Spacer>
    );
};

export const Row = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: libTypes.SupplierImportProduct[];
}) => {
    const product = data[index];
    const { supplier, saveBaseProductToDb } = useModalSupplierProductImport();
    const { openModalSupplierBaseProduct } = useModalSupplierBaseProductContext();

    if (!supplier) return null;

    return (
        <Tr style={style}>
            <Td colspan={3}>
                <Text size="m">{product.matchedRangePrefix}</Text>
            </Td>
            <Td colspan={3}>
                <Text size="m">{product.code.substring(product.matchedRangePrefix?.length || 0, product.code.length)}</Text>
            </Td>
            <Td colspan={5}>
                <Ellipsis tooltipText={product.description}>
                    <Text size="m">{product.description}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.w}</Text>
            </Td>
            <Td colspan={1.5}>
                <Text size="m">{product.h}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.d}</Text>
            </Td>
            <Td colspan={1.5}>
                {" "}
                <Text size="m">{product.price}</Text>
            </Td>
            <Td colspan={1.5} align="c">
                <Menu portal={false} style={{ right: "-10px", top: "-10px", position: "absolute" }}>
                    <MenuItem
                        onClick={() =>
                            openModalSupplierBaseProduct(
                                {
                                    ...fns.supplierBaseProduct.create(supplier.id as string, {
                                        type: "RANGE",
                                        code: product.code.substring(product.matchedRangePrefix?.length || 0, product.code.length),
                                    }),
                                },
                                true,
                                saveBaseProductToDb,
                                true
                            )
                        }
                    >
                        Add as Base Product
                    </MenuItem>
                </Menu>
            </Td>
        </Tr>
    );
};
