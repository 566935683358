
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';
import { getMergedOrder } from "./getMergedOrder";
import { feathersApp } from "../../config/feathers";
import { setLoadedDoorRange } from "./setLoadedDoorRange";

export const _setDoorRange = (doorRange: libTypes.Order['doorRange']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.doorRange = fns.order.set.doorRange(doorRange);
        })
    });
};


export const setDoorRange = async (doorRange: libTypes.Order["doorRange"]) => {
    if (!doorRange) return;
    _setDoorRange(doorRange);
    const dr = await feathersApp.service("supplier-composite-products").find({
        query: {
            supplierRangeId: doorRange.id,
        },
        paginate: false,
        $limit: 10000000,
    });
    setLoadedDoorRange(dr.data);
};