import React from "react";
import { feathersApp } from "../config/feathers";
import { setProducts } from "../actions/products";
import { libTypes, constants } from "@holta/lib";

export const useLoadProducts = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);
    if (!hasRun.current) {

        function subscribeToProducts() {
            return feathersApp
            .service("products")
            .watch()
            .find({
                query: {
                    $limit: 100000,
                    $sort: {
                        code: 1,
                    },
                    customerRestricted: false,
                },
            })
            .subscribe((res: { data: libTypes.Product[] }) => {
                //console.log(res)
                setProducts(res.data);
                setIsLoaded(true);
            });
        }
        let productsSub = subscribeToProducts()
        hasRun.current = true;

        feathersApp.service('products').on(constants.EVENT_RELOAD_PRODUCTS, () => {
            //console.log('products bulk event')
            productsSub.unsubscribe();
            productsSub = subscribeToProducts()
        })

    }
    return isLoaded;
};
