import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
} from "@holta/ui";
import {useStore} from "../../config/store";
import { Header } from "./parts/Header";
import { OrdersTable } from "./parts/OrdersTable";
import { FilterSidebar } from "./parts/FilterSidebar";
import { OrdersContext } from "./context";
import { BulkActions } from "./parts/BulkActions";
import { FiltersNotificationBar } from "../../components/FiltersNotificationBar";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import { useOrdersFilterCount } from "../../hooks/useOrdersFilterCount";
import {clearAllOrdersFilters} from '../../actions/ui';

export const Orders = () => {
    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useOrdersFilterCount();
    const ordersUi = useStore(store => store.ui.orders);
    return (
        <OrdersContext.Provider value={{}}>
            <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
                <Header />
                <div ref={filtersAnimationParent}>
                    {activeFilterCount > 0 && ordersUi.searchFilter.length === 0 &&  (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <FiltersNotificationBar
                                filtersCount={activeFilterCount}
                                handleClear={clearAllOrdersFilters}
                            />
                        </Spacer>
                    )}
                </div>
                <OrdersTable />
                <FilterSidebar />
            </Container>
        </OrdersContext.Provider>
    );
};
