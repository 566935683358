import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
import { useStore } from "../../../hooks/useStore";
import {setLinkProductsSearchFilter} from '../../../actions/ui';
import {useModalLinkProductContext} from '../../../components/ModalLinkProduct/context';
import { fns } from "@holta/lib";
import {user} from '../../../fns/user';
import {saveLinkProduct} from '../../../actions/linkProducts';
interface Props {}

export const Header: React.FC<Props> = () => {

    const { openModalLinkProduct } = useModalLinkProductContext();

    const searchFilter = useStore(store => store.ui.linkProducts.searchFilter);

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Link Products</Text>
                </Grid.Col>
                <Grid.Col>
                    <button className="primary" onClick={() => openModalLinkProduct(fns.linkProduct.create(), true, saveLinkProduct, () => {})}>New Link Product</button>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormInput placeholder="Search..." value={searchFilter} onChange={e => setLinkProductsSearchFilter(e.target.value)}/>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
 