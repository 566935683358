import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormNumber,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import { MenuList } from "react-select/src/components/Menu";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { libTypes } from "@holta/lib";
import { InputProductSelect } from "../../../components/InputProductSelect";
import editOrderActions from "../../../actions/editOrder";
import { InputHandingSelect } from "../../../components/InputHandingSelect";
import { useTabs } from "../../../hooks/useTabs";
import React from "react";
import FocusLock from "react-focus-lock";

interface Props {
    orderItem: libTypes.EditedNotMissingOrderItem;
}

export const OrderItemsEditSupplierItemRow = ({ orderItem }: Props) => {
    const editOrder = useMergedEditOrder();
    const firstInputRef = React.useRef<HTMLInputElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const tabs = useTabs("basic");

    React.useEffect(() => {
        firstInputRef.current?.focus();
    }, [orderItem.id]);

    React.useEffect(() => {
        setTimeout(() => {
            if (!containerRef.current) return;
            if(containerRef.current.getBoundingClientRect().bottom < (window.innerHeight - 80)) return;
            containerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }, 300);
    }, [orderItem.code]);


    if (!editOrder) return null;
    return (
        <Tr ref={containerRef} style={{ scrollMarginBottom: "60px" }}>
            <div
                style={{
                    width: "calc(100% + 16px)",
                    backgroundColor: "white",
                    margin: "-1px -8px",
                }}
            >
                <EditingRow>
                    <FocusLock>
                        <Grid.Row>
                            <Spacer xs={[0, 2]}>
                                <Tabs height={6}>
                                    <Tab
                                        height={6}
                                        isSelected={tabs.current === "basic"}
                                        onClick={() => tabs.setCurrent("basic")}
                                        inline
                                    >
                                        Basic
                                    </Tab>
                                    <Tab
                                        height={6}
                                        isSelected={tabs.current === "advanced"}
                                        inline
                                        onClick={() => tabs.setCurrent("advanced")}
                                    >
                                        Advanced
                                    </Tab>
                                </Tabs>
                            </Spacer>
                        </Grid.Row>
                        <hr />
                        {tabs.current === "basic" && (
                            <Grid.Row>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 1, 2, 2]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Quantity
                                                    </FormLabel>
                                                </Grid.Col>
                                                <Grid.Col xs={{ cols: 8 }}>
                                                    <FormNumber
                                                        onChange={(e) =>
                                                            editOrderActions.setCarcaseItemQuantity(
                                                                Number(e.target.value)
                                                            )
                                                        }
                                                        disabled={!orderItem.code}
                                                        isFloat
                                                        placeholder=""
                                                        value={orderItem.quantity}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Spacer>
                                        
                                    </Spacer>
                                </Grid.Col>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 2, 2, 1]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <FormLabel type="contained">Description</FormLabel>
                                            <FormTextarea
                                                style={{ width: "100%" }}
                                                disabled={!orderItem.code}
                                                value={orderItem.description}
                                                onChange={(e) =>
                                                    editOrderActions.setCarcaseItemDescription(
                                                        e.target.value
                                                    )
                                                }
                                            ></FormTextarea>
                                        </Spacer>
                                        
                                        <Spacer xs={[0.25, 0]}>
                                            <FormLabel type="contained">Notes</FormLabel>
                                            <FormTextarea
                                                style={{ width: "100%" }}
                                                disabled={!orderItem.code}
                                                value={orderItem.notes}
                                                onChange={(e) =>
                                                    editOrderActions.setCarcaseItemNotes(
                                                        e.target.value
                                                    )
                                                }
                                            ></FormTextarea>
                                        </Spacer>
                                    </Spacer>
                                </Grid.Col>
                            </Grid.Row>
                        )}

                        {tabs.current === "advanced" && (
                            <Grid.Row>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 1, 2, 2]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Price
                                                    </FormLabel>
                                                </Grid.Col>

                                                <FormNumber
                                                    onBlur={(e) =>
                                                        editOrderActions.setCarcaseItemPrice(
                                                            Number(e.target.value)
                                                        )
                                                    }
                                                    allowNegative
                                                    disabled={!orderItem.code}
                                                    isFloat
                                                    placeholder=""
                                                    value={orderItem.price}
                                                    style={{ width: "100%" }}
                                                />
                                            </Grid.Row>
                                        </Spacer>
                                    </Spacer>
                                </Grid.Col>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <Spacer xs={[2, 2, 2, 1]}>
                                        <Spacer xs={[0.25, 0]}>
                                            <Grid.Row>
                                                <Grid.Col xs={{ cols: 4 }}>
                                                    <FormLabel type="contained" position="left">
                                                        Discount
                                                    </FormLabel>
                                                </Grid.Col>
                                                <FormNumber
                                                    onBlur={(e) =>
                                                        editOrderActions.setCarcaseItemDiscount(
                                                            Number(e.target.value)
                                                        )
                                                    }
                                                    disabled={!orderItem.code}
                                                    isFloat
                                                    placeholder=""
                                                    value={orderItem.discount}
                                                    style={{ width: "100%" }}
                                                />
                                            </Grid.Row>
                                        </Spacer>
                                    </Spacer>
                                </Grid.Col>
                            </Grid.Row>
                        )}

                        <Spacer xs={[2]}>
                            <Grid.Row xs={{ justify: "end" }}>
                                <button onClick={editOrderActions.clearEditOrderItem} tabIndex={-1}>
                                    Cancel
                                </button>
                                <Spacer xs={[0.25]}></Spacer>
                                <button
                                    disabled={!orderItem.code}
                                    onClick={() => editOrderActions.saveEditOrderItem(() =>{})}
                                    tabIndex={-1}
                                >
                                    Save
                                </button>
                                
                            </Grid.Row>
                        </Spacer>
                    </FocusLock>
                </EditingRow>
            </div>
        </Tr>
    );
};

const EditingRow = styled.div`
    border: 1px solid ${(props) => props.theme.color.highlight};
    border-radius: 4px;
    box-shadow: 0px 4px 15px 5px #cccbcb;
`;
