import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import { MenuList } from "react-select/src/components/Menu";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import {fns, libTypes} from '@holta/lib';
import { useStore } from "../../../config/store";
import {OrderItemsEditCarcaseRow}   from './OrderItemsEditCarcaseRow';
import editOrderActions from '../../../actions/editOrder';
import { OrderItemsEmptyRow } from "./OrderItemsEmptyRow";
import {OrderItemsCarcaseRow} from './OrderItemsCarcaseRow';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrop } from 'react-dnd';
import {useOrderContext} from '../context';
import { OrderItemsSortDropArea } from "./OrderItemsSortDropArea";
import {OrderItemsSupplierRow} from './OrderItemsSupplierRow';
import { useAutoAnimate } from '@formkit/auto-animate/react';

export const OrderItemsInner = () => {

    const editOrder = useMergedEditOrder();
    const editItem = useStore((state) => state.editOrder?.newValues?.orderItem);
    const editItemIsNew = editItem?.id && !editOrder?.items.find((item) => item.id === editItem?.id);
    const {dragOrderItems} = useOrderContext();
    const [parent, enable] = useAutoAnimate<any>(/* optional config */) as any
    const {openModal} = useModal();

    if(!editOrder) return null;
    return (
        <> 
            <hr />
            <Container xl="fixed">
            <Spacer xs={[3, 2]}>
                <Spacer xs={[1, 0, 2, 0]}>
                    <Text size="xl">Order Items</Text>
                </Spacer>

                    <>
                <Table cols={68}>
                    <Thead>
                        <Tr>
                            <Th colspan={3}>Nº</Th>
                            <Th colspan={3}>Qty</Th>
                            <Th colspan={3}>Hnd</Th>
                            <Th colspan={8}>Item Code</Th>
                            <Th colspan={16}>Desc.</Th>
                            <Th colspan={4}>Notes</Th>
                            <Th colspan={4}>H</Th>
                            <Th colspan={4}>W</Th> 
                            <Th colspan={4}>D</Th>
                            <Th colspan={8}>List Price</Th>
                            <Th colspan={4}>Disc. %</Th>
                            <Th colspan={6}>Line Total</Th>
                            <Th colspan={2}></Th>
                        </Tr>
                    </Thead>
                    <Tbody ref={parent}>
                    
                    {editOrder.items.length < 1 && <OrderItemsEmptyRow />}
                    <OrderItemsSortDropArea id={'TOP'} />
                        {fns.order_items.sortItems(editOrder.items).filter(item => !fns.order_item.isMissingLinkedDoorItem(item)).filter(item => !fns.order_item.isLinkedDoorItem(item)).map((item, index) => {
                            if(fns.order_item.isCarcaseItem(item)) return (<OrderItemsCarcaseRow key={item.id} item={item} index={index + 1} isDraggingOver={dragOrderItems.dragId === item.id}/> )
                            if(fns.order_item.isManuallyAddedSupplierItem(item)) return (<OrderItemsSupplierRow key={item.id} item={item} index={index + 1} isDraggingOver={dragOrderItems.dragId === item.id}/> )
                            return (<></>);
                        })}
                        {editItemIsNew && editItem && fns.order_item.isCarcaseItem(editItem as libTypes.OrderItem) && <OrderItemsEditCarcaseRow orderItem={editItem as libTypes.EditedCarcaseOrderItem}/>}

                    </Tbody>
                </Table>
                
                </>
            
                <Spacer xs={[1,0]}>
                <Grid.Row>
                    <button className="primary" onClick={editOrderActions.createCarcaseItem}>Add Item</button>
                    &nbsp;
                    <button className="secondary" onClick={() => openModal("SUPPLIER_PRODUCT_BROWSER")}>Add Supplier Item</button>
                </Grid.Row>
                </Spacer>
            </Spacer> 
            </Container>
        </>
    );
};

export const OrderItems = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <OrderItemsInner />
        </DndProvider>
    )
};