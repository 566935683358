import React from "react";
import styled from "styled-components";
import {
    Spacer,
    Grid,
    Text,
    FormSelect,
    FormLabel,
    FormDate,
    IconButton,
    useModal,
    FormInput,
    Menu,
    MenuItem,
    Icon,
    useDialog,
} from "@holta/ui";
import { Link } from "raviger";
import { useStore } from "../../../hooks/useStore";
import { setProductsSearchFilter, clearSelectedProducts } from "../../../actions/ui";
import { useModalProductContext } from "../../../components/ModalProduct/context";
import { fns } from "@holta/lib";
import { user } from "../../../fns/user";
import { deleteProduct, saveProduct } from "../../../actions/products";
import { Badge } from "../../../components/Badge";
import { useProductsFilterCount } from "../../../hooks/useProductsFilterCount";
interface Props {}

export const Header: React.FC<Props> = () => {
    const { openModal } = useModal();
    const { openModalProduct } = useModalProductContext();
    const {openGenericDialog} = useDialog();
    const searchFilter = useStore((store) => store.ui.products.searchFilter);
    const ui = useStore((store) => store.ui);
    const filterCount = useProductsFilterCount();

    const sidebarFilterCount = ui.products.searchFilter ? filterCount - 1 : filterCount;

    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];


    const handleDeleteProducts = () => {
        openGenericDialog(`Are you sure you want to remove ${ui.products.selectedProducts.length} products?`, 'Delete Products', false, true, () => {
            deleteProduct(ui.products.selectedProducts).then(() => {
                clearSelectedProducts();
            })
        })
    }

    const handleBulkEdit = () => {
        openModal("MODAL_BULK_PRODUCTS")
    }

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Holta Products</Text>
                </Grid.Col>
                <Grid.Col>
                    <button
                        className="primary"
                        onClick={() =>
                            openModalProduct(
                                fns.product.create(user.id as string),
                                true,
                                saveProduct
                            )
                        }
                    >
                        New Product
                    </button>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormInput
                                placeholder="Search..."
                                value={searchFilter}
                                onChange={(e) => setProductsSearchFilter(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            {ui.products.selectedProducts.length < 1 ? (
                                <IconButton icon="function" className="tertiary" disabled>
                                    <Spacer xs={[1, 0.25]}>Actions</Spacer>
                                </IconButton>
                            ) : (
                                <Menu
                                    button={
                                        <IconButton as="div"  icon="function" className="tertiary">
                                            <Spacer xs={[1, 0.25]}>Actions</Spacer>
                                        </IconButton>
                                    }
                                >
                                    <MenuItem onClick={handleBulkEdit}>
                                        <Grid.Row>
                                            <Icon name="edit"></Icon>&nbsp;Bulk Edit
                                        </Grid.Row>
                                    </MenuItem>
                                    <MenuItem onClick={handleDeleteProducts}>
                                        <Grid.Row>
                                            <Icon name="delete-bin"></Icon>&nbsp;Delete
                                        </Grid.Row>
                                    </MenuItem>
                                </Menu>
                            )}
                        </div>
                        <Spacer xs={[0, 0.5]}></Spacer>

                        <Spacer xs={[0, 0.5]}></Spacer>
                        <div>
                            <IconButton
                                icon="filter"
                                className="tertiary"
                                onClick={() => openModal("PRODUCTS_FILTER")}
                                style={{ position: "relative" }}
                            >
                                {sidebarFilterCount > 0 && <Badge>{sidebarFilterCount}</Badge>}
                                <Spacer xs={[1, 0.25]}>Filter</Spacer>
                            </IconButton>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
