import React from "react";

export const ProductionContext = React.createContext<{}>({});

export const useProductionContext = () => {
    const context = React.useContext(ProductionContext);
    if (context === undefined) {
        throw new Error("useProductionContext must be within the correct provider");
    }
    return context;
};
