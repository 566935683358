import styled from "styled-components";
import { Text, Menu, MenuItem, Icon } from "@holta/ui";
import { useStore } from "../../../config/store";
import { user } from "../../../fns/user";
import { ActiveLink } from "raviger";
type Props = {
    current?: boolean;
    icon: string;
    count?:string;
    requiredRoles? : string[];
} & Parameters<typeof ActiveLink>[0]



export const NavItem: React.FC<Props> = ({ children, current, icon, count, requiredRoles, ...rest }) => {

    if(requiredRoles && requiredRoles.length > 0 && !user.roles.some(role => requiredRoles?.includes(role))) return null;

    return (
        <ActiveLink {...rest}>
        <Wrapper>
            <IconWrapper>
                {count && <Badge>{count}</Badge>}
                <Icon name={icon}></Icon>
            </IconWrapper>
            <Text size="s" bold>
                {children}
            </Text>
        </Wrapper>
        </ActiveLink>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background-color: ${(p) => p.theme.color.accent1};
    color: ${(p) => p.theme.color.accent4};
    width: ${(p) => p.theme.spacing(4)};
    height: ${(p) => p.theme.spacing(4)};
    margin-bottom: ${(p) => p.theme.spacing(0.25)};
    border: 2px solid ${(p) => p.theme.color.accent1};
    transition: all 0.2s;
    font-size: 1.25em;
   

    .navItemCurrent & {
        background-color: ${(p) => p.theme.color.highlight};
        border: 2px solid ${(p) => p.theme.color.highlight};
        color: ${(p) => p.theme.color.base_contrast};
    }
`;

const Wrapper = styled.div`
    margin-bottom: ${(p) => p.theme.spacing(1.5)};
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    span {
        color: ${(p) => p.theme.color.accent3} ;
        transition: all 0.2s;
    }

    &:hover {
        cursor: pointer;
        ${IconWrapper} {
            border: 2px solid ${(p) => p.theme.color.highlight};
            color: ${(p) => p.theme.color.highlight} ;
        }
        span {
            color: ${(p) => p.theme.color.highlight} ;
        }
    }

    .navItemCurrent &:hover {
        ${IconWrapper} {
            border: 2px solid ${(p) => p.theme.color.highlight};
            color: ${(p) => p.theme.color.base_contrast};
        }
        span {
            color: ${(p) => p.theme.color.text} ;
        }
    }

    .navItemCurrent & span {
        color: ${(p) => p.theme.color.text} ;
    }
`;


const Badge = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 1000px;
    font-size: 10px;
    background-color: ${(p) => p.theme.color.highlight};
    color: white;
    padding: ${(p) => p.theme.spacing(0.25)} ${(p) => p.theme.spacing(0.5)};
`;