import { libTypes } from "@holta/lib";
import { FormDate, FormSelect, Grid, Spacer } from "@holta/ui";
import React from "react";
import { staticData, fns } from "@holta/lib";
import { getDay } from "date-fns";
import { isMonday } from "date-fns";
import { nextMonday } from "date-fns";

export function ProductionDate({
    initialProductionDate,
    initialProductionDateType,
    handleSave,
    handleCancel,
}: {
    initialProductionDate: libTypes.Order["productionDate"];
    initialProductionDateType: libTypes.Order["productionDateType"];
    handleSave: (
        productionDate: libTypes.Order["productionDate"],
        productionDateType: libTypes.Order["productionDateType"]
    ) => void;
    handleCancel: () => void;
}): React.ReactElement {
    const [productionDate, setProductionDate] = React.useState(initialProductionDate);
    const [productionDateType, setProductionDateType] = React.useState(initialProductionDateType);

    React.useEffect(() => {
        setProductionDate(initialProductionDate);
        setProductionDateType(initialProductionDateType);
    }, [initialProductionDate, initialProductionDateType]);

    // If the production date type is WEEK, force the date to a Monday
    if (productionDateType === "WEEK" && productionDate && !isMonday(productionDate))
        setProductionDate(nextMonday(productionDate).getTime());

    // If the production date type is 'DAY' or 'WEEK' and no date is set, set it to the next Monday
    if ((productionDateType === "DAY" || productionDateType === "WEEK") && !productionDate)
        setProductionDate(nextMonday(new Date()).getTime());

    const options = fns.shared.formSelect.createOptions(
        "key",
        "label",
        Object.values(staticData.productionDateTypes)
    );
    const currentValue = fns.shared.formSelect.createValueOption(
        productionDateType,
        "key",
        "label",
        Object.values(staticData.productionDateTypes)
    );

    const dateVal = productionDate ? new Date(productionDate) : new Date();

    const hasChanged =
        productionDate !== initialProductionDate || productionDateType !== initialProductionDateType;

    return (
        <>
            <FormSelect
                options={options}
                isSearchable={false}
                value={currentValue}
                onChange={({ value }: { value: libTypes.ProductionDateType }) =>
                    setProductionDateType(value)
                }
            />

            {productionDateType === "WEEK" && (
                <>
                    <Spacer xs={[0.5]} />
                    <FormDate
                        onChange={(d) => d && !Array.isArray(d) && setProductionDate(d.getTime())}
                        filterDate={(date) => getDay(date) === 1}
                        dateFormat="dd/MM/yyyy"
                        selected={dateVal}
                    />
                </>
            )}
            {productionDateType === "DAY" && (
                <>
                    <Spacer xs={[0.5]} />
                    <FormDate
                        onChange={(d) => d && !Array.isArray(d) && setProductionDate(d.getTime())}
                        dateFormat="dd/MM/yyyy"
                        selected={dateVal}
                        showWeekNumbers
                        filterDate={(date) => {
                            const day = new Date(date).getDay();
                            return day !== 0 && day !== 6;
                        }}
                    />
                </>
            )}
            <Spacer xs={[1]} />
            <Grid.Row>
                <button onClick={handleCancel}>Cancel</button>
                <Spacer xs={[0.5]} />
                {hasChanged && (
                    <button
                        className="primary"
                        onClick={() => handleSave(productionDate, productionDateType)}
                    >
                        Confirm
                    </button>
                )}
            </Grid.Row>
        </>
    );
}
