import styled from 'styled-components';

export const SidebarButton = styled.button`
    width: 100%;
    border-radius: 0!important;
    border: none !important;
    border-bottom: 1px solid ${(p) => p.theme.color.accent2} !important;
    background-color: transparent !important;

    &:hover {
        background-color: ${(p) => p.theme.color.highlight} !important;
        color: ${(p) => p.theme.color.base_contrast} !important;
        border-bottom: 1px solid ${(p) => p.theme.color.highlight} !important;
    }

    &.isCurrent {
        background-color: ${(p) => p.theme.color.highlight2} !important;
        color: ${(p) => p.theme.color.text} !important;
        border-bottom: 1px solid ${(p) => p.theme.color.highlight2} !important;
    }

    &:focus {
        box-shadow: none !important;
    }
`;