import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";



export const setCarcaseItemHanding = (handing: libTypes.CarcaseOrderItem["handing"]) => {
    
    const editOrder = getEditOrder();
    const mergedOrder = getMergedOrder();
    const mergedOrderItem = getMergedOrderItem();

    if (!mergedOrderItem) return;

    if (fns.order_item.isCarcaseItem(mergedOrderItem) || fns.order_item.isAnySupplierItem(mergedOrderItem)) {

        const currentOrderItem = mergedOrder.items.find((item) => item.id === mergedOrderItem.id) as libTypes.CarcaseOrderItem;

        if (currentOrderItem?.handing === handing) {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    delete (draft.newValues.orderItem as any).handing;
                })
            });
        } else {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    (draft.newValues.orderItem as any).handing = fns.order_item.set.handing(handing);
                })
            });
        }

    }

};

