import React from "react";
import styled from "styled-components";
import {
    Spacer,
    Grid,
    Text,
    FormSelect,
    FormLabel,
    FormDate,
    IconButton,
    useModal,
    FormInput,
    Ellipsis,
    Tag
} from "@holta/ui";
import { Link } from "raviger";
import * as uiActions from "../../../actions/ui";
import { Badge } from "../../../components/Badge";
import { useStore } from "../../../config/store";
import { useOrdersFilterCount } from "../../../hooks/useOrdersFilterCount";
import { useFilteredOrders } from "../../../hooks/useFilteredOrders";
import {fns} from "@holta/lib";
interface Props {}

function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = React.useState<T>(value);

    React.useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export const Header: React.FC<Props> = () => {
    const { openModal } = useModal();
    const ordersUi = useStore((store) => store.ui.orders);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 200);
    const ordersFilterCount = useOrdersFilterCount();
    const filteredOrders = useFilteredOrders();

    const sidebarFilterCount = ordersUi.searchFilter ? ordersFilterCount - 1 : ordersFilterCount;


    const orderTypes = [
        { value: "ALL", label: "All" },
        { value: "QUOTE", label: "Quotes" },
        { value: "ORDER", label: "Orders" },
        { value: "INVOICE", label: "Invoices" },
    ];



    React.useEffect(() => {
        uiActions.setOrdersSearchFilter(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    React.useEffect(() => {
        // In case the search filter is cleared, we need to clear the internal variable
        if(ordersUi.searchFilter.length === 0) {
            setSearchTerm('');
        }
    }, [ordersUi.searchFilter])

    const isLoading = searchTerm !== debouncedSearchTerm;

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl" bold>Orders</Text>
                    <Grid.Row>
                        <Spacer xs={[0,0.5,1,0]}>
                            <Tag size="l" type={1}>Showing <Text size="s" bold>{filteredOrders.length}</Text> order(s). Total value (ex VAT): <Text size="s" bold>£{fns.orders.getTotalValue(filteredOrders, {excludeTax: true})}</Text></Tag>
                        </Spacer>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    <Link href="/orders/new">
                        <button className="primary">New Order</button>
                    </Link>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormLabel isDisabled={!!ordersUi.searchFilter}>Type</FormLabel>
                            <FormSelect
                                options={orderTypes}
                                value={orderTypes.find((option) => option.value === ordersUi.type)}
                                isSearchable={false}
                                style={{ minWidth: "200px" }}
                                onChange={({ value }: { value: any }) =>
                                    uiActions.setOrdersType(value)
                                }
                                isDisabled={!!ordersUi.searchFilter}
                                
                            />
                        </div>
                        
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <FormLabel isDisabled={!!ordersUi.searchFilter}>Date From</FormLabel>
                            <FormDate
                                onChange={(d) => d && !Array.isArray(d) && uiActions.setOrdersDateFrom(d.getTime())}
                                dateFormat="dd/MM/yyyy"
                                selected={new Date(ordersUi.dateFrom)}
                                disabled={!!ordersUi.searchFilter}
                            />
                        </div>
                        
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <FormLabel isDisabled={!!ordersUi.searchFilter}>Date To</FormLabel>
                            <FormDate
                                onChange={(d) => !Array.isArray(d) && uiActions.setOrdersDateTo(d ? d.getTime() : null)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Today"
                                isClearable
                                selected={ordersUi.dateTo ? new Date(ordersUi.dateTo) : undefined}
                                disabled={!!ordersUi.searchFilter}
                            />
                        </div>

                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                        <FormLabel>Search {isLoading && <Text size="s" bold={false} color="accent4">(Loading...)</Text>}</FormLabel>
                            <FormInput
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                
                            />
                        </div>
                        
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                
                    <Spacer xs={[1]}></Spacer>
                    <Grid.Row>
                        <div>
                            <IconButton
                                icon="filter"
                                disabled={!!ordersUi.searchFilter}
                                className="tertiary"
                                onClick={() => openModal("ORDERS_FILTER")}
                                style={{ position: "relative" }}
                            >
                                {sidebarFilterCount > 0 && <Badge style={{opacity: !!ordersUi.searchFilter ? 0.5 : 1}}>{sidebarFilterCount}</Badge>}
                                <Spacer xs={[1, 0.25]}>Filter</Spacer>
                            </IconButton>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
