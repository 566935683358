import { fns } from "@holta/lib";
import { FormSelect } from "@holta/ui";
import { useStore } from "../../config/store";
import React from "react";
import { createFilter } from "react-select";

export const InputProductSelect = React.forwardRef(({
    value,
    valueFallbackLabel,
    onAddNew,
    ...rest
}: {
    value?: { code?: string; productId?: string };
    valueFallbackLabel?: string;
} & Parameters<typeof FormSelect>[0], ref) => {
    const products = useStore((state) => state.products);
    const options = fns.shared.formSelect.createOptions("id", "code", products);
    const matchedProduct = value ? options.find((option) => option.value === value.productId && option.label === value.code) : null;
    const valueMatch = matchedProduct || null;

    return (
        <FormSelect
            options={options}
            value={valueMatch}
            filterOption={createFilter({
                "matchFrom": "start",
            })}
            ref={ref}
            {...rest}
        />
    ); 
});
