import {
    Grid,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Tab,
    Tabs,
    useDialog,
    useModal,
} from '@holta/ui';
import React from 'react';

import { useModalSupplierRangeContext } from './context';
import { DetailsTab } from './parts/DetailsTab';
import { PricingTab } from './parts/PricingTab';
import { SettingsTab } from './parts/SettingsTab';

interface Props {
    children?: React.ReactNode;
}

export const ModalSupplierRange: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);

    const {
        supplierRange,
        isEdited,
        isNew,
        cancel,
        confirm,
    } = useModalSupplierRangeContext();

    if (!supplierRange) {
        return null;
    }
    return (
        <Modal id="MODAL_SUPPLIER_RANGE" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Range</Grid.Row>
                    </div>
                </ModalHeader>
                <ModalSubHeader style={{ padding: 0 }}>
                    <Grid.Row>
                        <Spacer xs={[0, 2]}>
                            <Tabs height={6}>
                                <Tab
                                    height={6}
                                    isSelected={tab === 0}
                                    onClick={() => setTab(0)}
                                    inline
                                >
                                    Details
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 1}
                                    inline
                                    onClick={() => setTab(1)}
                                >
                                    Pricing
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 2}
                                    inline
                                    onClick={() => setTab(2)}
                                >
                                    Settings
                                </Tab>
                            </Tabs>
                        </Spacer>
                    </Grid.Row>
                </ModalSubHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        {tab === 0 && <DetailsTab />}
                        {tab === 1 && <PricingTab />}
                        {tab === 2 && <SettingsTab />}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_SUPPLIER_RANGE")}> Ok</button>
                    )}
                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
