import { fns } from "@holta/lib";
import { FormSelect, Grid, Island, Spacer, Text, theme } from "@holta/ui";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useStore } from "../../../hooks/useStore";
import { endOfWeek, startOfWeek, add, startOfMonth, endOfMonth, format, subMonths, addMonths  } from "date-fns";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Bar Chart",
        },
    },
};



export const Chart4 = () => {
    const orders = useStore((store) => store.orders);
    const today = new Date();

    const weekStart = add(startOfWeek(today, { weekStartsOn: 0 }), {
        weeks: 0,
    }).getTime();
    const weekEnd = add(endOfWeek(today, { weekStartsOn: 0 }), {
        weeks: 0,
    }).getTime();

    const deliveries = fns.orders.filterByDateRange(orders, "DELIVERY", weekStart, weekEnd);

    const boards = fns.orders.getCarcaseColours(deliveries);

    const datasets = boards.map((board) => {
        return {
            label: board.code + ' ' + board.texture,
            value: fns.orders.getTotalValue(deliveries.filter(order => fns.order.isCarcaseColour(order, board)), { excludeTax: true }),
        }
    });


    const data = {
        labels: [...datasets.map(dataset => dataset.label)],
        datasets: [
            {
                data: [...datasets.map(dataset => dataset.value)],
                backgroundColor: [theme.colorLight.accent3]
            },
        ],
    };


    return (
        <>
            <Text size="l" bold>
               Board Colours
            </Text>
            <Spacer xs={[1, 0]}>
           
            </Spacer>
            <hr />
            <Pie options={options} data={data} />
        </>
    );
};
