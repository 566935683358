import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormCheckbox,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalContactContext } from "./context";
import { useStore } from "../../hooks/useStore";

interface Props {
    children?: React.ReactNode;
}

export const ModalContact: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        contact,
        isDefault,
        toggleIsDefault,
        isEdited,
        isNew,
        isEditable,
        handleDelete,
        setIsEditable,
        setFirstName,
        setLastName,
        setPhone1,
        setPhone2,
        setEmail,
        setFax,
        cancel,
        confirm,
    } = useModalContactContext();

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Are you sure you want to handleDelete this contact?",
            "Delete Contact?",
            false,
            true,
            () => {
                handleDelete();
            }
        );
    };

    if (!contact) {
        return null;
    }
    return (
        <Modal id="MODAL_CONTACT" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>
                            Contact &nbsp;{isDefault && <Tag>Default Contact</Tag>}
                        </Grid.Row>
                    </div>
                    {!isNew && !isEdited && (
                        <div onClick={() => setIsEditable(!isEditable)}>
                            <Text size="s" color="base_contrast">
                                {isEditable ? "CANCEL EDITING" : "EDIT"}
                            </Text>
                        </div>
                    )}
                </ModalHeader>
                <ModalSubHeader style={{ padding: 0 }}>
                    <Grid.Row>
                        <Spacer xs={[0, 2]}>
                            <Tabs height={6}>
                                <Tab
                                    height={6}
                                    isSelected={tab === 0}
                                    onClick={() => setTab(0)}
                                    inline
                                >
                                    Details
                                </Tab>
                                <Tab
                                    height={6}
                                    isSelected={tab === 1}
                                    inline
                                    onClick={() => setTab(1)}
                                >
                                    Settings
                                </Tab>
                            </Tabs>
                        </Spacer>
                    </Grid.Row>
                </ModalSubHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        {tab === 0 && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>First Name</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.firstName}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.firstName}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Last Name</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.lastName}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.lastName}</FormValue>
                                    )}
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Email</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.email}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.email}</FormValue>
                                    )}
                                </Spacer>

                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Phone 1</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.phone1}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setPhone1(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.phone1}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Phone 2</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.phone2}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setPhone2(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.phone2}</FormValue>
                                    )}
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Fax</FormLabel>
                                    {isEditable || isNew ? (
                                        <FormInput
                                            value={contact.fax}
                                            style={{ width: "100%" }}
                                            onChange={(e) => setFax(e.target.value)}
                                        />
                                    ) : (
                                        <FormValue>{contact.fax}</FormValue>
                                    )}
                                </Spacer>
                            </Island>
                        )}
                        {tab === 1 && (
                            <>
                                <Island>
                                    <Spacer xs={[1, 0]}>
                                        <FormLabel>Default Contact</FormLabel>
                                        {isEditable || isNew ? (
                                            <FormCheckbox
                                                checked={isDefault}
                                                onChange={toggleIsDefault}
                                            ></FormCheckbox>
                                        ) : (
                                            <FormCheckbox
                                                disabled
                                                checked={isDefault}
                                            ></FormCheckbox>
                                        )}
                                    </Spacer>
                                </Island>
                                <Spacer xs={[1]}></Spacer>
                                {!isNew && (
                                    <Island>
                                        <Spacer xs={[1, 0]}>
                                            <FormLabel>Delete Contact</FormLabel>
                                            {isEditable ? (
                                                <button className="danger" onClick={wrappedHandleDelete}>
                                                    Delete
                                                </button>
                                            ) : (
                                                <button
                                                    className={isEditable ? "danger" : "secondary"}
                                                    disabled
                                                >
                                                    Delete
                                                </button>
                                            )}
                                        </Spacer>
                                    </Island>
                                )}
                            </>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_CONTACT")}> Ok</button>
                    )}

                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
