import { Spacer, FormInput, Grid } from "@holta/ui";
import React from "react";
import {useSupplierProductBrowserContext} from "../context";


export const FilterInput = () => {
    const { filter, setFilter } = useSupplierProductBrowserContext();

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value.trim());
    };

    const handleClear = () => {
        setFilter('');
    };

    return (
        <Spacer xs={[2, 2, 0, 2]}>
            <Grid.Row>
                <FormInput value={filter} onChange={handleFilterChange} placeholder="Filter products" style={{width: '100%', textAlign: 'left'}} />
                <Spacer xs={[0.5]}></Spacer>
                <button onClick={handleClear}>Clear</button>
            </Grid.Row>
        </Spacer>
    );
};