

import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';
import { getMergedOrder } from "./getMergedOrder";

export const deletePayment = (id: string) => {

    const mergedOrder = getMergedOrder();
    const editOrder = getEditOrder();

    const deletedPayment = {
        ...mergedOrder.payments?.find((p) => p.id === id)!,
        _deleted: true
    }

    const updatedOrder = fns.order.combineEdits(editOrder.newValues.order, {payments: [deletedPayment]});
    

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order = updatedOrder;
        })
    });
    
};



