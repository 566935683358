import {
    FormInput,
    FormLabel,
    Grid,
    Icon,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    NoResults,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Ellipsis,
    useModal,
} from "@holta/ui";
import { useModalContactContext } from "../../../components/ModalContact/context";
import React from "react";
import styled from "styled-components";
import { fns } from "@holta/lib";
import { user } from "../../../fns/user";
import { useMergedCustomer } from "../hooks/useMergedCustomerValues";
import { useMergedContacts } from "../hooks/useMergedContacts";
import { saveContact } from "../../../actions/editCustomer";

interface Props {
    children?: React.ReactNode;
}

export const Contacts: React.FC<Props> = ({ children }) => {
    const { openModalContact } = useModalContactContext();
    const customer = useMergedCustomer();
    const contacts = useMergedContacts();

    if (!customer || !user.id) {
        return null;
    }
    return (
        <div>
            <Spacer xs={[2]}>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Text size="xl">Contacts</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <button
                            className="primary"
                            onClick={() =>
                                openModalContact(
                                    { ...fns.contact.create(user.id as string, customer.id as string), _dbUnsaved: true, },
                                    false,
                                    true,
                                    saveContact
                                )
                            }
                        >
                            Add Contact
                        </button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1]}></Spacer>

                {contacts.length > 0 ? (
                    <Table cols={6}>
                        <Thead>
                            <Tr>
                                
                                <Th colspan={4}>
                                    <Ellipsis>
                                        <Text>Name</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={4}>
                                    <Ellipsis>
                                        <Text>Phone 1</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={4}>
                                    <Ellipsis>
                                        <Text>Email</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={1} align="c">
                                    <Ellipsis>
                                        <Text>Default</Text>
                                    </Ellipsis>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {contacts.map((contact, index) => (
                                <Tr hasHover key={contact.id} onClick={() => openModalContact(
                                    contact,
                                    false,
                                    customer.defaultContact === contact.id,
                                    saveContact
                                )}>
                                    
                                    <Td colspan={4}>
                                        <Ellipsis>
                                            <Text>
                                                {contact.firstName} {contact.lastName}
                                            </Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={4}>
                                        <Ellipsis>
                                            <Text>{contact.phone1}</Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={4}>
                                        <Ellipsis>
                                            <Text>{contact.email}</Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={1} align={"c"}>
                                        <Ellipsis>
                                            <Text>
                                            {customer.defaultContact === contact.id && (<Icon name="checkbox" color="highlight" type="fill"/>)}
                                            </Text>
                                        </Ellipsis>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Spacer xs={[0, 2, 2, 2]}>
                        <NoResults text="No contacts." />
                    </Spacer>
                )}
            </Spacer>
        </div>
    );
};
