import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { StoreEditCustomer } from "../../config/store";
import React from "react";

export const ModalContactContext = React.createContext<
    | {
          contact: StoreEditCustomer['newValues']['contacts'][number] | null;
          isDefault: boolean;
          toggleIsDefault: () => void;
          isEdited: boolean;
          isNew: boolean;
          isEditable: boolean;
          handleDelete: () => void;
          setIsEditable: (isEditable: boolean) => void;
          openModalContact: (
              contact: StoreEditCustomer['newValues']['contacts'][number],
              isDefault: boolean,
              isNew: boolean,
              handleConfirm: (contact: StoreEditCustomer['newValues']['contacts'][number], isDefault: boolean) => void,
              handleCancel?: () => void
          ) => void;
          setFirstName: (firstName: string) => void;
          setLastName: (lastName: string) => void;
          setPhone1: (phone1: string) => void;
          setPhone2: (phone2: string) => void;
          setEmail: (email: string) => void;
          setFax: (fax: string) => void;
          cancel: () => void;
          confirm: () => void;
      }
    | undefined
>(undefined);

export const useModalContactContext = () => {
    const context = React.useContext(ModalContactContext);
    if (context === undefined) {
        throw new Error("useModalContactContext must be within the correct provider");
    }
    return context;
};

export const ModalContactContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [contact, setContact] = React.useState<StoreEditCustomer['newValues']['contacts'][number] | null>(null);
    const [isDefault, setIsDefault] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const handleConfirmFn = React.useRef<(contact: StoreEditCustomer['newValues']['contacts'][number], isDefault: boolean) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const toggleIsDefault = () => {
        setIsDefault(!isDefault);
        setIsEdited(true);
    }

    const setFirstName = (firstName: string) => {
        contact && setContact(fns.contact.set.firstName(contact, firstName));
        setIsEdited(true);
    };

    const setLastName = (lastName: string) => {
        contact && setContact(fns.contact.set.lastName(contact, lastName));
        setIsEdited(true);
    };

    const setPhone1 = (phone1: string) => {
        contact && setContact(fns.contact.set.phone1(contact, phone1));
        setIsEdited(true);
    };

    const setPhone2 = (phone2: string) => {
        contact && setContact(fns.contact.set.phone2(contact, phone2));
        setIsEdited(true);
    };

    const setEmail = (email: string) => {
        contact && setContact(fns.contact.set.email(contact, email));
        setIsEdited(true);
    };

    const setFax = (fax: string) => {
        contact && setContact(fns.contact.set.fax(contact, fax));
        setIsEdited(true);
    };

    const handleDelete = () => {
        
        handleConfirmFn.current && contact && handleConfirmFn.current({...contact, _deleted: true}, false);
        closeModal("MODAL_CONTACT");
    };

    const wrappedOpenModal = (
        contact: StoreEditCustomer['newValues']['contacts'][number],
        isDefault: boolean,
        isNew: boolean,
        handleConfirm: (contact: StoreEditCustomer['newValues']['contacts'][number], isDefault: boolean) => void,
        handleCancel?: () => void
    ) => {
        setContact(contact);
        setIsDefault(isDefault);
        setIsEdited(false);
        setIsNew(isNew);
        setIsEditable(isNew);
        openModal("MODAL_CONTACT");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        closeModal("MODAL_CONTACT");
    };

    const confirm = () => {
        if (!contact) {
            closeModal("MODAL_CONTACT");
            return;
        }
        if (!fns.contact.validate.contact(contact)._isValid) {
            openGenericDialog("Contact is not valid, please check the input values");
        } else {
            handleConfirmFn.current && contact && handleConfirmFn.current(contact, isDefault);
            closeModal("MODAL_CONTACT");
        }
    };

    return (
        <ModalContactContext.Provider
            value={{
                contact,
                isDefault,
                toggleIsDefault,
                isEdited,
                isNew,
                isEditable,
                setIsEditable,
                handleDelete,
                openModalContact: wrappedOpenModal,
                setFirstName,
                setLastName,
                setPhone1,
                setPhone2,
                setEmail,
                setFax,
                cancel,
                confirm,
            }}
        >
            {children}
        </ModalContactContext.Provider>
    );
};
