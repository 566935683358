import {
    FormInput,
    FormLabel,
    Grid,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    FormNotice
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import {
    setSupplierName,
    setSupplierNotes,
    setSupplierDiscount,
    setSupplierMargin
} from "../../../actions/editSupplier";
import { useStore } from "../../../hooks/useStore";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";
import { libTypes, staticData } from "@holta/lib";
import { useSupplierContext } from "../context";
import { FormNumber } from "@holta/ui";

interface Props {
    children?: React.ReactNode;
}

export const SupplierDetails: React.FC<Props> = ({ children }) => {
    const supplier = useMergedSupplier();
    const {validate, validation } = useSupplierContext();

    return (
        <div>
            <Spacer xs={[2]}>
                <Text size="xl">General</Text>

                <Spacer xs={[2, 0, 1, 0]}>
                    <FormLabel>Supplier Name</FormLabel>
                    <FormInput
                        style={{ width: "100%" }}
                        onChange={(e) => setSupplierName(e.target.value)}
                        onBlur={validate}
                        value={supplier?.name}
                    ></FormInput>
                    {validation?.name && !validation.name._isValid && <FormNotice>{validation.name._errors[0]}</FormNotice>}
                </Spacer>

                <Spacer xs={[1, 0, 1, 0]}>
                    <FormLabel>Supplier Notes</FormLabel>
                    <FormTextarea
                        rows={5}
                        style={{ width: "100%" }}
                        onChange={(e) => setSupplierNotes(e.target.value)}
                        value={supplier?.notes}
                    />
                </Spacer>
            </Spacer>
            <hr />
            <Spacer xs={[2]}>
                <Text size="xl">Pricing</Text>

                <Grid.Row>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 1, 1, 0]}>
                            <FormLabel>Discount (%)</FormLabel>
                            <FormNumber 
                                style={{ width: "100%" }}
                                onBlur={(e) => {setSupplierDiscount(e.target.value || "0")} }
                                value={supplier?.discount}
                                isFloat
                                placeholder="0"
                             />
                            
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 0, 1, 1]}>
                            <FormLabel>Margin (%)</FormLabel>
                            <FormInput
                                style={{ width: "100%" }}
                                onChange={(e) => setSupplierMargin(e.target.value)}
                                value={supplier?.margin}
                            ></FormInput>
                        </Spacer>
                    </Grid.Col>
                </Grid.Row>
                
            </Spacer>
        </div>
    );
};
