import { fns, libTypes, staticData } from "@holta/lib";
import { FormSelect } from "@holta/ui";
import { useStore } from "../../config/store";

export const InputHandingSelect = ({
    value,
    ...rest
}: {
    value?: libTypes.Handing; 
} & Parameters<typeof FormSelect>[0]) => {
    
    const options = fns.shared.formSelect.createOptions("value", "label", staticData.orderItemHanding as any);
    const valueMatch = value
        ? options.find((option) => option.value === value)
        : null;

    return (
        <FormSelect
            options={options}
            value={valueMatch}
            {...rest}
        />
    );
};
