import React from "react";
import styled from "styled-components";
import {
    Spacer,
    Grid,
    Text,
    FormSelect,
    FormLabel,
    FormDate,
    IconButton,
    useModal,
    FormInput,
    WeekSwitcher,
    Notice,
    Tag,
} from "@holta/ui";
import { Link } from "raviger";
import { DiaryNotes } from "../../../components/DiaryNotes";
import { ProductionStatusBar } from "../../../components/ProductionStatusBar";
import { useStore } from "../../../config/store";
import { getISOWeek, isSameISOWeek } from "date-fns";
import { goToDeliveryWeek, goToNextDeliveryWeek, goToPrevDeliveryWeek } from "../../../actions/ui";
import { useDetectClickOutside } from "react-detect-click-outside";
import { fns } from "@holta/lib";

interface Props {}

export const Header: React.FC<Props> = () => {
    const { openModal } = useModal();
    const orders = useStore((store) => store.deliveryOrders);
    const weekStartDate = useStore((store) => store.ui.delivery.weekStart);
    const weekNumber = getISOWeek(weekStartDate);
    const thisWeek = getISOWeek(new Date());

    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];

    return (
        <>
            <Spacer xs={[2, 2, 1, 2]}>
                <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                    <Grid.Col>
                        <Text size="xl" bold>
                            Delivery
                        </Text>
                        {/* 
                    <Grid.Row>
                        <Spacer xs={[0,0.5,1,0]}>
                            <Tag type={2}>120% Booked</Tag>
                        </Spacer>
                        <Spacer xs={[0,0.5,1,0]}>
                            <Tag type={1}>Delivered Same week: 35%</Tag>
                        </Spacer>
                    </Grid.Row>
                    */}
                    </Grid.Col>
                    <Grid.Col>
                        {/*
                    <Link href="/orders/new"><button className="primary">Add...</button></Link>
                    */}
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[0.5]}></Spacer>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Grid.Row>
                            <WeekSwitcher
                                onClickNext={goToNextDeliveryWeek}
                                onClickPrev={goToPrevDeliveryWeek}
                                onClickNow={() => {}}
                                week={weekNumber}
                            />
                            <Spacer xs={[0.5]} />
                            <div className="weekselecter">
                                <FormDate
                                    selected={new Date(weekStartDate)}
                                    showWeekNumbers
                                    onChange={(e) => {
                                        !Array.isArray(e) && goToDeliveryWeek(e!.getTime());
                                    }}
                                    customInput={<button className="tertiary">Jump to...</button>}
                                />
                            </div>
                            <Spacer xs={[0.5]} />
                            {thisWeek !== weekNumber && (
                                <button className="primary" onClick={() => goToDeliveryWeek(new Date().getTime())}>Current Week</button>
                            )}
                        </Grid.Row>
                    </Grid.Col>
                    {/** 
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormInput placeholder="Search..." />
                        </div>
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <IconButton icon="eye" className="tertiary">
                            <Spacer xs={[1, 0.25]}>View Options</Spacer>
                            </IconButton>
                        </div>
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <IconButton icon="filter" className="tertiary"  onClick={() => openModal('ORDERS_FILTER')}>
                                <Spacer xs={[1, 0.25]}>Filter</Spacer>
                            </IconButton>
                        </div>
                    </Grid.Row>
                </Grid.Col>
                */}
                </Grid.Row>
            </Spacer>
            {/** <Spacer xs={[0.5, 1, 0, 1]}><DiaryNotes>dasd</DiaryNotes></Spacer>*/}
            <Spacer xs={[0.5, 1]}>
                <ProductionStatusBar orders={[...fns.orders.filterDayDeliveries(orders)]} />
            </Spacer>
        </>
    );
};
