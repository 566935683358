import { Spacer } from '@holta/ui';
import { SidebarButton } from '../../../components/SidebarButton';
import { useSupplierContext } from '../context';

export const Sidebar = () => {

    const {tab, setTab} = useSupplierContext();

    return (
        <>
            <SidebarButton className={tab === 0 ? 'isCurrent' : ''} onClick={() => setTab(0)}>Supplier Details</SidebarButton>
            <SidebarButton className={tab === 1 ? 'isCurrent' : ''} onClick={() => setTab(1)}>Contacts</SidebarButton>
            <SidebarButton className={tab === 2 ? 'isCurrent' : ''} onClick={() => setTab(2)}>Addresses</SidebarButton>
            <SidebarButton className={tab === 3 ? 'isCurrent' : ''} onClick={() => setTab(3)}>Ranges</SidebarButton>
            <SidebarButton className={tab === 4 ? 'isCurrent' : ''} onClick={() => setTab(4)}>Base Products</SidebarButton>
            <SidebarButton className={tab === 5 ? 'isCurrent' : ''} onClick={() => setTab(5)}>Products</SidebarButton>
            <SidebarButton className={tab === 6 ? 'isCurrent' : ''} onClick={() => setTab(6)}>Settings</SidebarButton>
        </>
    );
};
