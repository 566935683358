import { useStore } from "../../../hooks/useStore"

export const useIsEdited = () => {
    const editCustomer = useStore(state => state.editCustomer);

    if(editCustomer?.newValues.customer && Object.keys(editCustomer?.newValues.customer).length > 0) {
        return true;
    }
    if(editCustomer?.newValues.addresses && editCustomer?.newValues.addresses.length > 0) {
        return true;
    }
    if(editCustomer?.newValues.contacts && editCustomer?.newValues.contacts.length > 0) {
        return true;
    }
}