import { createAction } from "../fns/createAction";
import { fns, libTypes, staticData } from "@holta/lib";
import { Customer, TaxGroup } from "@holta/lib/types";
import { user } from "../fns/user";
import { StoreEditCustomer } from "../config/store";
import { useStore } from "../hooks/useStore";
import { feathersApp } from "../config/feathers";
import { navigate } from "raviger";
import { saveContactsToDb } from "./contacts";
import { saveAddressesToDb } from "./addresses";

// Clear the editCustomer state
export const clearAll = createAction("EDIT_CUSTOMER_CLEAR_ALL", (newState) => {
    newState.editCustomer = null;
});

// Initialise the state with a new customer
export const initialiseNewCustomer = createAction("EDIT_CUSTOMER_INITIALISE_NEW", (newState) => {
    if (user.id === null) {
        throw new Error("User is not logged in");
    }

    newState.editCustomer = {
        type: "NEW",
        newValues: {
            customer: {},
            addresses: [],
            contacts: [],
        },
        currentValues: {
            customer: fns.customer.create(user.id),
            addresses: [],
            contacts: [],
        },
    };
});

// Load customer from the server
export const setCustomer = createAction(
    "EDIT_CUSTOMER_LOAD_CUSTOMER",
    async (
        newState,
        customer: libTypes.Customer,
        addresses: libTypes.Address[],
        contacts: libTypes.Contact[]
    ) => {
        newState.editCustomer = {
            type: "EDIT",
            newValues: {
                customer: {},
                addresses: [],
                contacts: [],
            },
            currentValues: {
                customer: customer,
                addresses: addresses,
                contacts: contacts,
            },
        };
    }
);

export const loadCustomer = async (id: string) => {
    const customer = await feathersApp.service("customers").get(id);
    const addresses = await feathersApp.service("addresses").find({ query: { parentId: id } });
    const contacts = await feathersApp.service("contacts").find({ query: { parentId: id } });

    setCustomer(customer, addresses.data, contacts.data);
};

function getCustomerFromStore() {
    const editedCustomer = useStore.getState().editCustomer;
    if (editedCustomer === null) {
        throw new Error("No customer to edit");
    }
    return editedCustomer;
}

function getMergedCustomer() {
    const editedCustomer = getCustomerFromStore();
    return fns.customer.merge(
        editedCustomer?.currentValues.customer,
        editedCustomer?.newValues.customer
    );
}

// Set the editCustomer Name
export const setCustomerName = createAction("EDIT_CUSTOMER_SET_NAME", (newState, name: string) => {
    try {
        const editedCustomer = getCustomerFromStore();
        const newName = fns.customer.set.name(name);

        if (editedCustomer.currentValues.customer.name === newName) {
            delete (newState.editCustomer?.newValues.customer as any).name;
            return;
        }

        (newState.editCustomer?.newValues.customer as libTypes.Customer).name = newName;
    } catch (e) {}
});

// Set the editCustomer notes
export const setCustomerNotes = createAction(
    "EDIT_CUSTOMER_SET_NOTES",
    (newState, notes: string) => {
        try {
            const editedCustomer = getCustomerFromStore();
            const newNotes = fns.customer.set.notes(notes);
            if (editedCustomer.currentValues.customer.notes === newNotes) {
                delete (newState.editCustomer?.newValues.customer as any).notes;
                return;
            }

            (newState.editCustomer?.newValues.customer as libTypes.Customer).notes = newNotes;
        } catch (error) {}
    }
);

export const setCustomerDiscount = createAction(
    "EDIT_CUSTOMER_SET_DISCOUNT",
    (newState, discount: string) => {
        try {
            const editedCustomer = getCustomerFromStore();
            const discountAsNumber = parseFloat(discount);
            const newdiscount = fns.customer.set.discount(discountAsNumber);

            if (editedCustomer.currentValues.customer.discount === newdiscount) {
                delete (newState.editCustomer?.newValues.customer as any).discount;
                return;
            }

            (newState.editCustomer?.newValues.customer as libTypes.Customer).discount = newdiscount;
        } catch (error) {}
    }
);

// Set the editCustomer discount
export const setCustomerSupplierDiscount = createAction(
    "EDIT_CUSTOMER_SET_SUPPLIER_DISCOUNT",
    (newState, discount: string) => {
        try {
            const editedCustomer = getCustomerFromStore();
            const discountAsNumber = parseFloat(discount);
            const newSupplierDiscount = fns.customer.set.supplierDiscount(discountAsNumber);

            if (editedCustomer.currentValues.customer.supplierDiscount === newSupplierDiscount) {
                delete (newState.editCustomer?.newValues.customer as any).supplierDiscount;
                return;
            }

            (newState.editCustomer?.newValues.customer as libTypes.Customer).supplierDiscount =
                newSupplierDiscount;
        } catch (error) {}
    }
);

export const setCustomerPaymentTerms = createAction(
    "EDIT_CUSTOMER_SET_PAYMENT_TERMS",
    (newState, terms: string) => {
        try {
            const editedCustomer = getCustomerFromStore();
            const newTerms = fns.customer.set.paymentTerms(terms);
            if (editedCustomer.currentValues.customer.paymentTerms === newTerms) {
                delete (newState.editCustomer?.newValues.customer as any).paymentTerms;
                return;
            }

            (newState.editCustomer?.newValues.customer as libTypes.Customer).paymentTerms =
                newTerms;
        } catch (e) {}
    }
);

export const setCustomerTaxGroup = createAction(
    "EDIT_CUSTOMER_SET_TAX_GROUP",
    (newState, taxGroup: TaxGroup) => {
        try {
            const editedCustomer = getCustomerFromStore();
            const newTaxGroup = fns.customer.set.taxGroup(taxGroup);
            if (editedCustomer.currentValues.customer.taxGroup === newTaxGroup) {
                delete (newState.editCustomer?.newValues.customer as any).taxGroup;
                return;
            }

            (newState.editCustomer?.newValues.customer as libTypes.Customer).taxGroup = newTaxGroup;
        } catch (e) {}
    }
);

export const saveContact = createAction(
    "EDIT_CUSTOMER_SAVE_CONTACT",
    (newState, contact: StoreEditCustomer["newValues"]["contacts"][number], isDefault) => {
        // Get the edited customer from the store
        const editedCustomer = getCustomerFromStore();
        const mergedCustomer = getMergedCustomer();

        const indexOfExistingEdit = editedCustomer.newValues.contacts.findIndex((c) => c.id === contact.id);
        
        // If this contact already exists in an edited state...
        if (indexOfExistingEdit > -1) {

            // if it's a db unsaved contact and its being deleted...
            if (contact._deleted && contact._dbUnsaved)
                delete (newState.editCustomer as StoreEditCustomer).newValues.contacts[indexOfExistingEdit];
            // otherwise, update it
            else (newState.editCustomer as StoreEditCustomer).newValues.contacts[indexOfExistingEdit] = contact;

            // If this is a newly edited contact...
        } else {
            // Add the contact to the edited contacts array
            (newState.editCustomer as StoreEditCustomer).newValues.contacts.push(contact);
        }


        // Update default contact if necessary

        if (isDefault && !contact._deleted && mergedCustomer.defaultContact !== contact.id) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultContact =
                contact.id;
        }

        if ((isDefault && contact._deleted) || (!isDefault && mergedCustomer.defaultContact === contact.id )) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultContact = null;
        }
    }
);

export const saveAddress = createAction(
    "EDIT_CUSTOMER_SAVE_ADDRESS",
    (
        newState,
        address: StoreEditCustomer["newValues"]["addresses"][number],
        isDefaultDelivery,
        isDefaultInvoice
    ) => {
        // Get the edited customer from the store
        const editedCustomer = getCustomerFromStore();
        const mergedCustomer = getMergedCustomer();

        const indexOfExistingEdit = editedCustomer.newValues.addresses.findIndex((c) => c.id === address.id);

        // Add or updtate the contact
        if (indexOfExistingEdit > -1) {

            // if it's a db unsaved address and its being deleted...
            if (address._deleted && address._dbUnsaved) {
                delete (newState.editCustomer as StoreEditCustomer).newValues.contacts[indexOfExistingEdit];
            } else {
                (newState.editCustomer as StoreEditCustomer).newValues.addresses[
                    editedCustomer.newValues.addresses.findIndex((c) => c.id === address.id)
                ] = address;
            }
            
        } else {
            (newState.editCustomer as StoreEditCustomer).newValues.addresses.push(address);
        }


        if (isDefaultDelivery && !address._deleted && mergedCustomer.defaultDeliveryAddress !== address.id) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultDeliveryAddress =
                address.id;
        } else if ((mergedCustomer.defaultDeliveryAddress === address.id && address._deleted) || (!isDefaultDelivery && mergedCustomer.defaultDeliveryAddress === address.id )) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultDeliveryAddress =
                null;
        }

        if (isDefaultInvoice && !address._deleted && mergedCustomer.defaultInvoiceAddress !== address.id) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultInvoiceAddress =
                address.id;
        } else if ((mergedCustomer.defaultInvoiceAddress === address.id && address._deleted) || (!isDefaultInvoice && mergedCustomer.defaultInvoiceAddress === address.id )) {
            (newState.editCustomer as StoreEditCustomer).newValues.customer.defaultInvoiceAddress =
                null;
        }
    }
);

export const saveCustomer = async () => {
    const editedCustomer = useStore.getState().editCustomer;

    if (!editedCustomer?.currentValues.customer || !editedCustomer?.newValues.customer)
        throw new Error("No customer to edit");

    const mergedCustomer = fns.customer.merge(
        editedCustomer?.currentValues.customer,
        editedCustomer?.newValues.customer
    );

    const customerNames = await feathersApp
        .service("customers")
        .find({ query: { $limit: 1000000000, $select: ["name", "id"] } });

    const filtereredCustomerNames = customerNames.data
        .filter((c: libTypes.Customer) => c.id !== mergedCustomer.id)
        .map((c: libTypes.Customer) => c.name);

    const isValid = fns.customer.validate.customer(
        mergedCustomer,
        filtereredCustomerNames,
        Object.keys(staticData.taxGroups)
    )._isValid;

    if (!isValid) throw new Error("Invalid customer details");

    if (editedCustomer?.type === "NEW") {
        const returnedCustomer = await feathersApp.service("customers").create(mergedCustomer);
        await saveContactsToDb(editedCustomer?.newValues.contacts);
        await saveAddressesToDb(editedCustomer?.newValues.addresses);
        navigate(`/customers/${returnedCustomer.id}`);
    }

    if (editedCustomer?.type === "EDIT") {
        const returnedCustomer = await feathersApp
            .service("customers")
            .update(mergedCustomer.id, mergedCustomer);
        await saveContactsToDb(editedCustomer?.newValues.contacts);
        await saveAddressesToDb(editedCustomer?.newValues.addresses);
        await loadCustomer(returnedCustomer.id);
        return;
    }
};



export const deleteCustomerAndAddresses = async (customerId: string) => {
    await feathersApp.service("customers").remove(customerId);
    navigate(`/customers`);
};
