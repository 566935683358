import { fns } from '@holta/lib';
import {FormSelect} from '@holta/ui';
import { useStore } from '../../config/store';


export const InputCustomerSelect = ({value, valueFallbackLabel, onAddNew, ...rest} : {value? : string | null, valueFallbackLabel?: string, onAddNew?: () => void } & Parameters<typeof FormSelect>[0]) => {

    const customers = useStore(state => state.customers);
    const options = fns.shared.formSelect.createOptions('id', 'name', customers);
    const valueMatch = value ? fns.shared.formSelect.createValueOption(value, 'id', 'name', customers) : null;

    return (
        <FormSelect
            options={options}
            value={valueMatch}
            onAddNew={onAddNew}
            getOptionLabel={(option : {value : string}) => customers.find((item) => item.id === option.value)?.name || (option.value === value && valueFallbackLabel) || ''}
            {...rest}
        />
    );
};
