import React from "react";
import {
    ModalHeader,
    Modal,
    ModalBody,
    ModalFooter,
    useModal,
    Spacer,
    Island,
    Text,
} from "@holta/ui";

const changelogVersion = 202;

export const ChangelogNotification = () => {
    const { openModal, closeModal } = useModal();
    React.useEffect(() => {
        if (
            !window?.localStorage?.getItem("changelog") ||
            Number(window.localStorage.getItem("changelog"))! < changelogVersion
        ) {
            window.localStorage.setItem("changelog", changelogVersion.toString());
            openModal("MODAL_CHANGELOG");
        }
    }, []);

    return (
        <Modal id="MODAL_CHANGELOG" style={{ width: "600px" }}>
            <>
                <ModalHeader>
                    <h3>Updates</h3>
                </ModalHeader>
                <ModalBody>
                <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                2.2
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Order Items
                            </Text>
                            <br />
                            <Text size="m">• Fixed deleted order items reappearing when items are sorted.</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                2.1
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Doors
                            </Text>
                            <br />
                            <Text size="m">• Several Fixes</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                2
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Doors
                            </Text>
                            <br />
                            <Text size="m">• 🚪 🚪 🚪 🚪 🚪 🚪 🚪 🚪</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.6
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Orders
                            </Text>
                            <br />
                            <Text size="m">• Changing product codes retains other edits</Text>
                            <br />
                            <br />
                            <Text size="m">• Table rows now alternate in colour</Text>
                            <br />
                            <br />
                            <Text size="m">• Negative prices possible</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Production
                            </Text>
                            <br />
                            <Text size="m">• Order / invoice confirmation listed on jobs</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                PDFS
                            </Text>
                            <br />
                            <Text size="m">• Receipt spelled right</Text>
                            <br />
                            <br />
                            <Text size="m">
                                • Edited dimensions no longer show 'null' if no initial value is set
                            </Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.5
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Orders
                            </Text>
                            <br />
                            <Text size="m">• Fixed email subject / message editing</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.4
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Orders
                            </Text>
                            <br />
                            <Text size="m">• Fixed filter by customer crashing</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.3
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Products
                            </Text>
                            <br />
                            <Text size="m">• Folder category navigation added</Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1, 1, 0, 1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.2
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Deliveries
                            </Text>
                            <br />
                            <Text size="m">• Tuesday shown as correct day!</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Orders
                            </Text>
                            <br />
                            <Text size="m">• Discounts editable</Text>
                            <br />
                            <Text size="m">
                                • Order ref + quote ref no longer presserved when duplicating.
                            </Text>
                            <br />
                            <Text size="m">
                                • Order items duplicate directly below the original item.
                            </Text>
                            <br />
                            <Text size="m">
                                • Decimal places enabled on payements & item quantities.
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Production
                            </Text>
                            <br />
                            <Text size="m">• Dates of week added to header</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                General
                            </Text>
                            <br />
                            <Text size="m">• Added error tracking </Text>
                            <br />
                            <br />
                        </Island>
                    </Spacer>
                    <Spacer xs={[1]}>
                        <Island>
                            <Text size="l" bold>
                                1.0.1
                            </Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Production
                            </Text>
                            <br />
                            <Text size="m">• Week order total includes estimates.</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                Customer Orders
                            </Text>
                            <br />
                            <Text size="m">• Sends pdf to customer & holta.</Text>
                            <br />
                            <Text size="m">• Copy saved as file to server.</Text>
                            <br />
                            <Text size="m">• Error warnings for customers.</Text>
                            <br />
                            <br />
                            <Text size="l" bold>
                                PDFs
                            </Text>
                            <br />
                            <Text size="m">• Quote / order ref in file name.</Text>
                            <br />
                            <Text size="m">• Carcase no longer required for labels. </Text>
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal("MODAL_CHANGELOG")}>Close</button>
                </ModalFooter>
            </>
        </Modal>
    );
};
