import { libTypes } from '@holta/lib'
import { proxy, useSnapshot } from 'valtio'

const stateObect: {
    components: libTypes.Product['components']
    componentIdToAdd: string | null
    componentQtyToAdd: number,
    merge: boolean
} = {
    components: [],
    componentIdToAdd: null,
    componentQtyToAdd: 1,
    merge: false
}

const state = proxy<typeof stateObect>(stateObect)

export const addComponent = () => {
    if(state.componentIdToAdd === null) return
    state.components.push({
        id: state.componentIdToAdd,
        qty: state.componentQtyToAdd,
    })
    state.componentQtyToAdd = 1;
    state.componentIdToAdd = null;
}

export const removeComponent = (index: number) => {
    state.components.splice(index, 1)
}

export const toggleMerge = () => {
    state.merge = !state.merge;
}

export const clearComponents = () => {
    state.components = [];
    state.componentIdToAdd = null;
    state.componentQtyToAdd = 1;
}

export const updateComponent = (index: number, component: libTypes.Product['components'][0]) => {
    state.components[index] = component;
}

export const setComponentIdToAdd = (componentIdToAdd: string | null) => {
    state.componentIdToAdd = componentIdToAdd
}

export const setComponentQtyToAdd = (componentQtyToAdd: number) => {
    state.componentQtyToAdd = componentQtyToAdd
}


export const useModalBulkProductState = () => useSnapshot(state);