import { SidebarButton } from '../../../components/SidebarButton';
import { useCustomerContext } from '../context';

export const Sidebar = () => {

    const {tab, setTab} = useCustomerContext();

    return (
        <>
            <SidebarButton className={tab === 0 ? 'isCurrent' : ''} onClick={() => setTab(0)}>Orders</SidebarButton>
        </>
    );
};
