import { fns, staticData } from "@holta/lib";
import { useStore } from "../../config/store";
import React from "react";
import { useMergedCustomer } from "./hooks/useMergedCustomerValues";

export const CustomerContext = React.createContext<{
    tab : number,
    setTab : (tab : number) => void,
    validation : ReturnType<typeof fns.customer.validate.customer> | null,
    validate : () => void,
} | undefined>(undefined);

export const useCustomerContext = () => {
    const context = React.useContext(CustomerContext);
    if (context === undefined) {
        throw new Error("useCustomerContext must be within the correct provider");
    }
    return context;
};

export const useCustomerContextProps = () => {


    const [validation, setValidation] = React.useState<ReturnType<typeof fns.customer.validate.customer> | null>(null)
    const customer = useMergedCustomer();
    const customers = useStore(store => store.customers);

    function validate() {
        setValidation(fns.customer.validate.customer(customer, customers.filter(c => c.id !== customer?.id).map(c => c.name), Object.keys(staticData.taxGroups)));
    }

    const [tab, setTab] = React.useState(0);

    return {
        tab,
        setTab,
        validation, 
        validate
    }   
}