import { feathersApp } from "../../config/feathers";
import { setOrder } from "./setOrder";

export const loadOrder = async (orderId: string) => {
    const order = await feathersApp.service("orders").get(orderId);

    const doorRange =
        order.hasDoors && order.doorRange
            ? await feathersApp.service("supplier-composite-products").find({
                  query: {
                      supplierRangeId: order.doorRange.id,
                  },
                  paginate: false,
                  $limit: 10000000,
              })
            : null;
    setOrder(order, doorRange?.data || []);
};