import { fns } from "@holta/lib";
import { code } from "@holta/lib/functions/product/set";
import { staticData } from "@holta/lib";
import {
    FormInput,
    FormLabel,
    FormSelect,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spacer,
} from "@holta/ui";
import {
    setOrdersCustomerFilter,
    clearAllProductsFilters,
    setOrdersAccountingStatusFilter
} from "../../../actions/ui";
import { useStore } from "../../../config/store";
import * as uiActions from "../../../actions/ui";

export const FilterSidebar = () => {

    const ui = useStore((state) => state.ui);
    const customers = useStore((state) => state.customers);
    const ordersUi = useStore((store) => store.ui.orders);

    const dateTypes = [
        { value: "CURRENT_STATUS", label: "Current Status" },
        { value: "CREATED", label: "Created" },
        { value: "ORDERED", label: "Ordered" },
        { value: "INVOICED", label: "Invoiced" },
        { value: "DELIVERY", label: "Delivery" },
        { value: "PRODUCTION", label: "Production" },
        { value: "MODIFIED", label: "Modified" },
    ];


    return (
        <Modal id="ORDERS_FILTER" sideBar>
            <>
                <ModalHeader>Apply Filters</ModalHeader>
                <ModalBody style={{ minWidth: "300px" }}>
                    
                    <Spacer xs={[2, 2, 1, 2]}>
                        
                            <FormLabel>Date Type</FormLabel>
                            <FormSelect
                                options={dateTypes}
                                value={dateTypes.find(
                                    (option) => option.value === ordersUi.dateType
                                )}
                                isSearchable={false}
                                style={{ minWidth: "200px" }}
                                onChange={({ value }: { value: any }) =>
                                    uiActions.setOrdersDateType(value)
                                }
                            />
                        
                    </Spacer>
                    <Spacer xs={[1, 2]}>
                        <FormLabel>Customer</FormLabel>
                        <FormSelect
                            options={customers
                                .map((customer) => {
                                    return {
                                        value: customer.id,
                                        label: customer.name,
                                    };
                                })}
                            value={ui.orders.customerFilter}
                            onChange={setOrdersCustomerFilter}
                            isClearable
                        />
                    </Spacer>

                    <Spacer xs={[1, 2]}>
                        <FormLabel>Accounting Status</FormLabel>
                        <FormSelect
                            options={[
                                { value: "ALL", label: "All" },
                                ...Object.values(staticData.accountingStatuses).map(
                                    (status) => {
                                        return {
                                            value: status.key,
                                            label: status.label
                                        }
                                    }
                                ),
                            ]}
                            value={fns.shared.formSelect.createValueOption(ui.orders.accountingStatus, 'value', 'label', [
                                { value: "ALL", label: "All" },
                                ...Object.values(staticData.accountingStatuses).map(
                                    (status) => {
                                        return {
                                            value: status.key,
                                            label: status.label
                                        }
                                    }
                                ),
                            ])}
                            onChange={setOrdersAccountingStatusFilter}
                            isClearable
                        />
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button className="tertiary" onClick={clearAllProductsFilters}>
                        Clear All
                    </button>
                </ModalFooter>
            </>
        </Modal>
    );
};
