import React from "react";
import { fns, libTypes } from "@holta/lib";

export const ProductBrowserContext =
    React.createContext<ReturnType<typeof useProductBrowserContextValue> | undefined>(undefined);

export const useProductBrowserContext = () => {
    const context = React.useContext(ProductBrowserContext);
    if (context === undefined) {
        throw new Error("useProductBrowserContextmust be within the correct provider");
    }
    return context;
};

const useProductBrowserContextValue = (
    categories: libTypes.Category[],
    products: libTypes.Product[],
    handleConfirm: (product: libTypes.Product) => void,
    modalId: string,
) => {

    // state for holding the expanded categories
    const [expandedCategories, setExpandedCategories] = React.useState<string[]>([]);

    // state for holding the selected category branch
    const [selectedCategoryBranch, setSelectedCategoryBranch] = React.useState<string[]>([]);

    // state for holding the selected product
    const [selectedProduct, setSelectedProduct] = React.useState<libTypes.Product | null>(null);

    // state for holding the filter term
    const [filter, setFilter] = React.useState("");

    // Wrapped handle confirm function
    const wrappedHandleConfirm = React.useCallback(() => {
        if (selectedProduct) {
            handleConfirm(selectedProduct);
        }
    }, [selectedProduct, handleConfirm]);


    // The last chosen category in the selectedCategoryBranch
    const lastSelectedCategory = selectedCategoryBranch[selectedCategoryBranch.length - 1];


    // handle the toggling of categories
    const toggleCategory = (id: string | null) => {

        // if the id is null, it means the 'all products' category has been clicked: reset the selectedCategoryBranch
        if (id === null) {
            setSelectedCategoryBranch([]);
            return;
        }

        // if it's a category in the current branch (but not the last), remove all categories after it from the selectedCategoryBranch
        if (selectedCategoryBranch.indexOf(id) !== -1 && id !== lastSelectedCategory) {
            setSelectedCategoryBranch(
                selectedCategoryBranch.slice(0, selectedCategoryBranch.indexOf(id) + 1)
            );
            setExpandedCategories(
                expandedCategories.filter(
                    (ec) =>
                        !selectedCategoryBranch
                            .slice(selectedCategoryBranch.indexOf(id) + 1)
                            .includes(ec)
                )
            );
            return;
        }

        // if it's the last selected category, remove it from the selectedCategoryBranch
        if (id === lastSelectedCategory) {
            setSelectedCategoryBranch(
                selectedCategoryBranch.slice(0, selectedCategoryBranch.length - 1)
            );
            setExpandedCategories(expandedCategories.filter((e) => e !== id));
            return;
        }

        // if it's 'uncategorised'...
        if (id === 'uncategorised') {
            setSelectedCategoryBranch(['uncategorised']);
            return;
        }

        // if its a any other cateory, including descendants of the current branch, reset the branch and add all parents
        const branch = fns.category.getParents(categories.find((c) => c.id === id)!, categories);
        setSelectedCategoryBranch([...branch.map((c) => c.id), id]);
        setExpandedCategories([...expandedCategories, id]);

    };

    const sortedProducts = React.useMemo(() => {
        return fns.shared.sortByString(products, 'code');
    }, [products]);

    // Filtered products
    const filteredProducts = React.useMemo(() => {
        if (!lastSelectedCategory && !filter) return sortedProducts;
        let ps = sortedProducts;
        if (lastSelectedCategory && lastSelectedCategory !== 'uncategorised') ps = fns.products.filterByCategories(sortedProducts, [lastSelectedCategory], categories);
        if (lastSelectedCategory && lastSelectedCategory === 'uncategorised') ps = sortedProducts.filter((p) => !p.categories || p.categories.length === 0);
        if (filter) ps = fns.shared.filterByString(ps, 'code', filter, true);
        return ps;
    }, [sortedProducts, lastSelectedCategory, categories, filter]);

    return {
        expandedCategories,
        selectedCategoryBranch,
        selectedProduct,
        toggleCategory,
        setSelectedProduct,
        categories,
        handleConfirm : wrappedHandleConfirm,
        filteredProducts,
        modalId,
        filter,
        setFilter
    };
};

export const ProductBrowserContextProvider = ({
    children,
    categories,
    products,
    handleConfirm,
    modalId
}: {
    children: React.ReactNode;
    categories: libTypes.Category[];
    products: libTypes.Product[];
    handleConfirm: (product: libTypes.Product) => void;
    modalId: string;
}) => {
    const contextValue = useProductBrowserContextValue(categories, products, handleConfirm, modalId);
    return (
        <ProductBrowserContext.Provider value={contextValue}>
            {children}
        </ProductBrowserContext.Provider>
    );
};
