
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setGluedWallUnits = (gluedWallUnits: libTypes.Order['gluedWallUnits']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.gluedWallUnits = fns.order.set.gluedWallUnits(gluedWallUnits);
        })
    });
};