import {
    FormLabel,
    FormNotice,
    FormNumber,
    FormSelect,
    Grid,
    Island,
    Spacer,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDialog,
    useModal,
    Icon
} from '@holta/ui';
import { useStore } from '../../../hooks/useStore';
import React from 'react';
import { useMergedBaseProducts } from '../../../routes/Supplier/hooks/useMergedBaseProducts';

import { useModalSupplierRangeContext } from '../context';

interface Props {
    children?: React.ReactNode;
}

export const PricingTab: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const mergedBaseProducts = useMergedBaseProducts();

    

    const {
        supplierRange,
        setDiscount,
        setMargin,
        validationResult,
        createSubDiscount,
        setSubDiscountMargin,
        setSubDiscountDiscount,
        subDiscountBaseProductToAdd,
        setSubDiscountBaseProductToAdd,
        addSubDiscountBaseProduct,
        removeSubDiscount,
        removeSubDiscountBaseProduct
    } = useModalSupplierRangeContext();

    if (!supplierRange) {
        return null;
    }

    let usedBaseProducts : string[] = [];

    supplierRange.subDiscounts.forEach(subDiscount => {
        usedBaseProducts = [...usedBaseProducts, ...subDiscount.baseProducts];
    });

    const availableBaseProducts = mergedBaseProducts.filter(
        baseProduct => !usedBaseProducts.includes(baseProduct.id)
    );



    return (
        <>
            <Island>
                <Spacer xs={[1, 0]}>
                    <FormLabel>Discount</FormLabel>
                    <FormNumber
                        value={supplierRange.discount || ""}
                        style={{ width: "100%" }}
                        placeholder=""
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        isFloat
                    />
                    {validationResult && !validationResult.discount._isValid && (
                        <FormNotice>{validationResult.discount._errors[0]}</FormNotice>
                    )}
                </Spacer>
                <Spacer xs={[1, 0]}>
                    <FormLabel>Margin</FormLabel>
                    <FormNumber
                        value={supplierRange.margin || ""}
                        placeholder=""
                        style={{ width: "100%" }}
                        onChange={(e) => setMargin(Number(e.target.value))}
                        isFloat
                    />
                    {validationResult && !validationResult.margin._isValid && (
                        <FormNotice>{validationResult.margin._errors[0]}</FormNotice>
                    )}
                </Spacer>
            </Island>
            <Spacer xs={[1, 0]}></Spacer>
            <Island>
                <Spacer xs={[1, 0]}>
                    <Text size="l">Sub Discounts</Text>
                </Spacer>

                {supplierRange.subDiscounts.map((subDiscount, index) => (
                    <Spacer xs={[1, 0]} >
                        <Island>
                            <Grid.Row><Grid.Col xs={{ cols: 6 }}>
                                    <FormLabel>Discount</FormLabel>
                                    <FormNumber
                                        value={subDiscount.discount}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setSubDiscountDiscount(Number(e.target.value), index)}
                                        isFloat
                                    />
                                    {validationResult && !validationResult.margin._isValid && (
                                        <FormNotice>
                                            {validationResult.margin._errors[0]}
                                        </FormNotice>
                                    )}
                                </Grid.Col>
                                
                                <Spacer xs={[1]}></Spacer>
                                <Grid.Col xs={{ cols: 6 }}>
                                    <FormLabel>Margin</FormLabel>
                                    <FormNumber
                                        value={subDiscount.margin}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setSubDiscountMargin(Number(e.target.value), index)}
                                        isFloat
                                    />
                                    {validationResult && !validationResult.margin._isValid && (
                                        <FormNotice>
                                            {validationResult.margin._errors[0]}
                                        </FormNotice>
                                    )}
                                </Grid.Col>
                            </Grid.Row>
                            <Spacer xs={[1, 0]}>
                                <hr />
                            </Spacer>
                            <Grid.Row>
                                <Grid.Col xs={{ cols: 10 }}>
                                    <FormSelect placeholder="Select product"
                                        options={availableBaseProducts.map((baseProduct) => ({
                                            label: baseProduct.code,
                                            value: baseProduct.id,
                                        }))}
                                        value={!subDiscountBaseProductToAdd || subDiscountBaseProductToAdd.index !== index || !availableBaseProducts.find(baseProduct => baseProduct.id === subDiscountBaseProductToAdd.id) ? "" : {
                                            label: availableBaseProducts.find(baseProduct => baseProduct.id === subDiscountBaseProductToAdd.id)?.code || "",
                                            value: subDiscountBaseProductToAdd.id
                                        }}
                                        onChange={(option : {label:string, value: string}) => setSubDiscountBaseProductToAdd(option.value, index)}
                                    />
                                </Grid.Col>
                                <Spacer xs={[1]}></Spacer>
                                <Grid.Col xs={{ cols: 4 }}>
                                    <button className="success" onClick={() => {subDiscountBaseProductToAdd && subDiscountBaseProductToAdd.id && subDiscountBaseProductToAdd.index === index && addSubDiscountBaseProduct()}} disabled={!subDiscountBaseProductToAdd || !subDiscountBaseProductToAdd.id || subDiscountBaseProductToAdd.index !== index}>Add product</button>
                                </Grid.Col>
                            </Grid.Row>
                            <Spacer xs={[0.5, 0]}></Spacer>
                            <Table cols={5}>
                                <Thead>
                                    <Tr>
                                        <Th colspan={4}>Code</Th>
                                        <Th colspan={1}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {subDiscount.baseProducts.map((bp) => (
                                        <Tr key={bp}>
                                            <Td colspan={4}>{mergedBaseProducts.find(mbp => mbp.id === bp)?.code}</Td>
                        
                                            <Td colspan={1}><Icon name="delete-bin" onClick={() => removeSubDiscountBaseProduct(index, bp)}></Icon></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            <Spacer xs={[2, 0]}>
                                <hr />
                            </Spacer>
                            <Grid.Row xs={{ justify: "center" }}>
                                <Grid.Col>
                                    <Text color="danger"><span onClick={() => removeSubDiscount(index)}>Delete Group</span></Text>
                                </Grid.Col>
                            </Grid.Row>
                        </Island>
                    </Spacer>
                ))}

                <Spacer xs={[1, 0]}>
                    <button
                        style={{ width: "100%", textAlign: "center" }}
                        className="primary"
                        onClick={createSubDiscount}
                    >
                        Add Sub Discount
                    </button>
                </Spacer>
            </Island>
        </>
    );
};
