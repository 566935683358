import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedBaseProducts = () : libTypes.SupplierBaseProduct[] => {
    const editedBaseProducts = useStore(state => state.editSupplier?.newValues.baseProducts);
    const existingBaseProducts = useStore(state => state.editSupplier?.currentValues.baseProducts);

    const merged = [...editedBaseProducts || []];

    existingBaseProducts?.forEach(c => {
        if(!merged.find(c2 => c2.id === c.id)) {
            merged.push(c);
        }
    });

    return merged.filter(bp => !bp._deleted).sort((a,b) => a.code.localeCompare(b.code));;

}