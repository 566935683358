import { FormInput, FormLabel, Grid, Spacer, Text, FormTextarea, FormSelect, useDialog } from "@holta/ui";
import { deleteCustomerAndAddresses } from "../../../actions/editCustomer";
import React from "react";
import styled from "styled-components";
import {useMergedCustomer} from "../hooks/useMergedCustomerValues";

interface Props {
    children?: React.ReactNode;
}

export const Settings: React.FC<Props> = ({ children }) => {

    const {openGenericDialog} = useDialog();
    const customer = useMergedCustomer();

    if(!customer?.id) return null;

    return (
        <div>
            
            <Spacer xs={[2]}>
                <Text size="xl">Archive Company</Text>
                <Grid.Row>
                    <Grid.Col xs={{cols: 6}}>
                        <Spacer xs={[1, 1, 1, 0]}>
                            <button className="danger" onClick={() => {
                                openGenericDialog('Are you sure you want to archive this customer and associated contacts and addresses?', 'Archive Customer', false, true, () => deleteCustomerAndAddresses(customer.id as string), false)
                            }}>Archive Customer</button>
                        </Spacer>
                    </Grid.Col>
                    
                </Grid.Row>
            </Spacer>
            
        </div>
    );
};
