import { feathersApp } from "../../config/feathers";
import { createNewOrder } from "./createNewOrder";

export async function createDuplicateOrder(id: string) {
    const order = await feathersApp.service("orders").get(id);
    if (!order) throw new Error("Order not found");

    delete order.id;
    delete order.quoteRef;
    delete order.orderRef;
    delete order.dateOrdered;
    delete order.dateInvoiced;
    createNewOrder(order);
}