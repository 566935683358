import { Grid, Spacer, Tag, Text, Icon, Colors } from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import { libTypes, fns } from "@holta/lib";
import { useStore } from "../../config/store";
import { useDrag } from "react-dnd";
interface Props {
    isDraggable?: boolean;
    order: libTypes.Order;
    style?: React.CSSProperties;
    isWide?: boolean;
    onClick?: () => void;
}

export const JobCard: React.FC<Props> = ({ isDraggable, order, onClick, style, isWide }) => {
    if (isDraggable && !isWide) return <DraggableJobCard order={order} onClick={onClick} style={style} component={BasicJobCard} />;
    if (isDraggable && isWide) return <DraggableJobCard order={order} onClick={onClick} style={style} component={WideJobCard} />;
    return <BasicJobCard order={order} onClick={onClick} style={style} />;
};

export const DraggableJobCard: React.FC<Props & {component : typeof BasicJobCard | typeof WideJobCard}> = ({ order, onClick, style, component }) => {
    const Component = component;
    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: "card",
            item: { ...order },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        []
    );

    return (
        <Component ref={dragRef} style={{ opacity: opacity, ...style }} order={order} onClick={onClick} />
    );
};

interface BasicJobCardProps {
    style?: React.CSSProperties;
    order: libTypes.Order;
    onClick?: () => void;
}

export const BasicJobCard = React.forwardRef<HTMLDivElement, BasicJobCardProps>(
    ({ style, order, onClick }, ref) => {
        const customers = useStore((store) => store.customers);

        return (
            <S.Wrapper
                ref={ref}
                style={style}
                productionStatus={order.productionStatus}
                onClick={onClick}
            >
                <Spacer xs={[1]}>
                    <Grid.Row xs={{ justify: "space-between" }}>
                        <Grid.Col>
                            
                            <Spacer xs={[0, 0, 0.5, 0]}>
                                {order.orderConfirmationSent ? (<><Tag type={1}>Confirmation sent</Tag>&nbsp;</>) : ''}
                                
                                {order.invoiceSent ? (<Tag type={1}>Invoice sent</Tag>) : ''}
                            </Spacer>

                            <Spacer xs={[0, 0, 0.5, 0]}>
                                <Text size="m" bold>
                                    {
                                        customers.find(
                                            (customer) => customer.id === order.customerId
                                        )?.name
                                    } -  {order.customerRef}
                                </Text>
                            </Spacer>
                            <Spacer xs={[0, 0, 0.5, 0]}>
                                <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    {order.quoteRef && "Q."}
                                </Text>
                                <Text size="m" bold>{order.quoteRef}</Text>
                                <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    &nbsp;&nbsp;{order.orderRef && "#"}
                                </Text>
                                <Text size="m" bold>{order.orderRef}</Text>
                            </Spacer>
                        </Grid.Col>
                        <Grid.Col>
                            <Text size="s" bold>
                            
                            </Text>
                            <div>
                            <Text size="s" bold>
                            {order.orderStatus} {order.accountingStatus === "ORDERED" ? (
                        <Icon name="check" />
                    ) : order.accountingStatus === "INVOICED" ? (
                        <Icon name="check-double" />
                    ) : order.accountingStatus === "PAID" ? (
                        <Icon name="money-pound-circle" />
                    ) : (
                        ""
                    )}
                            </Text>
                            </div>
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>
                <hr />
                <Spacer xs={[1]}>
                    <Text
                        size="m"
                        color={
                            "text"
                        }
                    >
                        Job Size:
                    </Text>
                    <Text size="m" bold> {fns.order.getOrderSize(order) }</Text>
                    <br />
                    <Text
                        size="m"
                        color={
                            "text"
                        }
                    >
                        Delivery:
                    </Text>
                    <Text size="m" bold> {fns.order.formatDeliveryDate(order) }</Text>
                    <br />
                </Spacer>

                {order.productionNotes && (
                    <>
                        <hr />
                        <Spacer xs={[0.5, 1]}>
                            <div>
                                <Text size="s" bold>
                                    {order.productionNotes}
                                </Text>
                            </div>
                        </Spacer>
                    </>
                )}
            </S.Wrapper>
        );
    }
);

export const WideJobCard = React.forwardRef<HTMLDivElement, BasicJobCardProps>(
    ({ style, order, onClick }, ref) => {
        const customers = useStore((store) => store.customers);
        return (
            <S.Wrapper
                ref={ref}
                style={style}
                productionStatus={order.productionStatus}
                onClick={onClick}
            >
                <Spacer xs={[1]}>
                    <Grid.Row xs={{ justify: "space-between" }}>
                        <Grid.Col>
                            {/** 
                            <Spacer xs={[0, 0, 0.5, 0]}>
                                <Tag type={4}>Delayed Delivery</Tag>
                            </Spacer>
                            */}
                            <Spacer xs={[0, 0, 0.5, 0]}>
                                <Text size="m" bold>
                                    {
                                        customers.find(
                                            (customer) => customer.id === order.customerId
                                        )?.name
                                    }
                                </Text>
                                <Spacer xs={[0.5]} as="span"></Spacer>
                                <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    {order.customerRef}
                                </Text>
                            </Spacer>
                            <Spacer xs={[0, 0, 0.5, 0]}>
                            <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    {order.quoteRef && "Q."}
                                </Text>
                                <Text size="m">{order.quoteRef}</Text>
                                <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    &nbsp;&nbsp;{order.orderRef && "#"}
                                </Text>
                                <Text size="m">{order.orderRef}</Text>
                                <Text
                                    size="m"
                                    color={
                                        'text'
                                    }
                                >
                                    &nbsp;&nbsp;Job Size:
                                </Text>
                                <Text size="m"> {fns.order.getOrderSize(order) }</Text>
                            </Spacer>
                        </Grid.Col>
                        <Grid.Col>
                            <Text size="s" bold>
                            {order.orderStatus} {order.accountingStatus === "ORDERED" ? (
                        <Icon name="check" />
                    ) : order.accountingStatus === "INVOICED" ? (
                        <Icon name="check-double" />
                    ) : order.accountingStatus === "PAID" ? (
                        <Icon name="money-pound-circle" />
                    ) : (
                        ""
                    )}
                            </Text>
                            {/** 
                            <div>
                                <Icon name="check" />
                            </div>
                            */}
                        </Grid.Col>
                    </Grid.Row>
                </Spacer>

                {order.deliveryNotes && (
                    <>
                        <hr />
                        <Spacer xs={[0.5, 1]}>
                            <div>
                                <Text size="s" bold>
                                    {order.deliveryNotes}
                                </Text>
                            </div>
                        </Spacer>
                    </>
                )}
            </S.Wrapper>
        );
    }
);

const S = {
    Wrapper: styled.div<{ productionStatus: libTypes.ProductionStatus }>`
        border-radius: 4px;
        border: 2px solid
            ${(p) =>
                (p.theme.color as { [index: string]: string })["production_" + p.productionStatus]};
        border-left-width: 14px;
        background-color: ${(p) =>
            (p.theme.color as { [index: string]: string })["production_bg_" + p.productionStatus]};

        hr {
            border-color: ${(p) =>
                (p.theme.color as { [index: string]: string })["production_" + p.productionStatus]};
        }
    `,
};
