import { FormInput, FormLabel, Grid, Spacer, Text, FormTextarea, FormSelect, FormNumber } from "@holta/ui";
import React from "react";
import styled from "styled-components";

interface Props {
    children?: React.ReactNode;
}

export const Orders: React.FC<Props> = ({ children }) => {
    return (
        <div>
            <Spacer xs={[2]}>
                <Text size="xl">General</Text>
                <Grid.Row>
                    <Grid.Col xs={{ cols: 6 }}>
                    <Spacer xs={[1, 1, 1, 0]}>
                    <FormLabel>Last Quote Ref.</FormLabel>
                    <FormNumber style={{ width: "100%" }}></FormNumber>
                </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 6 }}>
                    <Spacer xs={[1, 0, 1, 1]}>
                    <FormLabel>Last Order Ref.</FormLabel>
                    <FormNumber style={{ width: "100%" }}></FormNumber>
                </Spacer>
                </Grid.Col></Grid.Row>
            </Spacer>
            <hr />
            <Spacer xs={[2]}>
                <Text size="xl">Invoicing</Text>

                
                    <Grid.Col xs={{ cols: 6 }}>
                        <Spacer xs={[1, 0, 1, 0]}>
                            <FormLabel>Defualt email subject</FormLabel>
                            <FormInput style={{ width: "100%" }} value="Invoice {{orderNumber}}"></FormInput>
                        </Spacer>
                    </Grid.Col>
                    <Grid.Col xs={{ cols: 6 }}>
                    <Spacer xs={[1, 0, 1, 0]}>
                            <FormLabel>Default email message</FormLabel>
                            <FormTextarea style={{ width: "100%" }} value="Hi {{customerName}}">
                                
                            </FormTextarea>
                        </Spacer>
                    </Grid.Col>
                
                
            </Spacer>
        </div>
    );
};
