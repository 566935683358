import {
    Container,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
} from "@holta/ui";
import { useOrderContext } from "../context";

export const OrderTabs = () => {

    const { tabs } = useOrderContext();
    return (
        <Grid.Row>
            <Spacer xs={[0, 2]}>
                <Tabs height={6}>
                    <Tab height={6} isSelected={tabs.current==='details'} onClick={() => tabs.setCurrent('details')} inline>
                        Details
                    </Tab>
                    <Tab height={6} inline  isSelected={tabs.current==='settings'} onClick={() => tabs.setCurrent('settings')} >
                        Settings
                    </Tab>
                </Tabs>
            </Spacer>
        </Grid.Row>
    );
};
