import { fns } from '@holta/lib';
import {useStore} from './useStore';

export const useFilteredCategories = () => {
    const {categories, ui} = useStore();
    let filteredCategories = [...categories];

    if (ui.categories.searchFilter) {
        filteredCategories = filteredCategories.filter(category => {
            const searchTerms = ui.categories.searchFilter.split(" ");
            return searchTerms.every(term => {
                return fns.category.getHeirachicalName(category, categories).toLowerCase().includes(term.toLowerCase());
            });
        });
    };

    if(ui.categories.sortField === "fullname"){
        filteredCategories = fns.categories.sortByHeirachicalName(filteredCategories, ui.categories.sortDirection);
    }

    return filteredCategories;

}
