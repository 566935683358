import { fns } from '@holta/lib';
import {useStore} from './useStore';
import React from 'react';

export const useFilteredProducts = () => {
    const {products, ui, categories} = useStore();
    let filteredProducts = [...products];

    filteredProducts = React.useMemo(() => {
        if(ui.products.searchFilter){
            filteredProducts = fns.shared.filterByString(filteredProducts, ['code', 'description'], ui.products.searchFilter);
        }
    
        if(ui.products.categoriesFilter.length > 0){
            filteredProducts = fns.products.filterByCategories(filteredProducts, ui.products.categoriesFilter.map(c => c.value), categories);
        }
    
        if(ui.products.componentsFilter.length > 0){ 
            filteredProducts = fns.products.filterByComponents(filteredProducts, ui.products.componentsFilter.map(c => c.value));
        }
    
        if(ui.products.sortField === 'code') filteredProducts = fns.shared.sortByString(filteredProducts, 'code', ui.products.sortDirection);
        if(ui.products.sortField === 'description') filteredProducts = fns.shared.sortByString(filteredProducts, 'description', ui.products.sortDirection);
        if(ui.products.sortField === 'dateCreated') filteredProducts = fns.shared.sortByNumerical(filteredProducts, 'dateCreated', ui.products.sortDirection);
        return filteredProducts;
    }, [ui, products])

    return filteredProducts;
    

}
