import React from 'react';

export function HighlightedText({text, highlight, fromStart = false} : {text : string, highlight : string, fromStart? : boolean}) {
    // Split on highlight term and include term into parts, ignore case
    if(!highlight) return <>{text}</>;
    const parts = fromStart ? text.split(new RegExp(`(^${highlight})`, 'gi')) : text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : ''}>
            { part }
        </span>)
    } </span>;
}