import {
    Container,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
} from "@holta/ui";
import { Header } from "./parts/Header";
import styled from "styled-components";

import { ProductionContext } from "./context";
import { Column } from "./parts/Column";
import { libTypes, staticData, fns } from "@holta/lib";
import { JobCard } from "../../components/JobCard";
import { useStore } from "../../config/store";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { navigate } from "raviger";
import { user } from "../../fns/user";

export const Production = () => {
    const orders = useStore((store) => store.productionOrders);
    return (
        <ProductionContext.Provider value={{}}>
            <Container style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Header />
                <ColumnsWrapperWrapper>
                    <DndProvider backend={HTML5Backend}>
                        <ColumnsWrapper>
                            <Column status={staticData.productionStatuses.PROVISIONAL}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.PROVISIONAL.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.IN_PRODUCTION}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.IN_PRODUCTION.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.CUT}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.CUT.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.EDGED}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.EDGED.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.MACHINED}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.MACHINED.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.BUILT}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.BUILT.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.COMPLETE}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.COMPLETE.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                            <Column status={staticData.productionStatuses.DELIVERED}>
                                {fns.orders
                                    .filterByProductionStatus(
                                        orders,
                                        staticData.productionStatuses.DELIVERED.key
                                    )
                                    .map((order) => (
                                        <JobCard
                                            style={{ cursor: "pointer" }}
                                            key={order.id}
                                            isDraggable
                                            order={order}
                                            onClick={() => user.roles.includes('admin') && navigate("orders/" + order.id)}
                                        />
                                    ))}
                            </Column>
                        </ColumnsWrapper>
                    </DndProvider>
                </ColumnsWrapperWrapper>
            </Container>
        </ProductionContext.Provider>
    );
};

const ColumnsWrapperWrapper = styled.div`
    width: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ColumnsWrapper = styled(Island)`
    display: flex;
    flex: 1;
    //overflow: auto;
    margin: 0 ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(1)};
    padding: 0;
    width: fit-content;
`;
