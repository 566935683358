import { fns, libTypes } from "@holta/lib";
import { SupplierBaseProduct } from "@holta/lib/types";
import { useDialog, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { StoreEditSupplier, useStore } from "../../config/store";
import React from "react";

export const ModalSupplierBaseProduct = React.createContext<
    | {
          supplierBaseProduct: StoreEditSupplier['newValues']['baseProducts'][number] | null;
          isEdited: boolean;
          isNew: boolean;
          isEditable: boolean;
          setIsEditable: (isEditable: boolean) => void;
          openModalSupplierBaseProduct: (
              supplierBaseProduct: StoreEditSupplier['newValues']['baseProducts'][number],
              isNew: boolean,
              handleConfirm: (supplierBaseProduct: StoreEditSupplier['newValues']['baseProducts'][number]) => void,
              markAsEdited?: boolean,
              handleCancel?: () => void
          ) => void;
          setType: (type: 'UNIQUE' | 'RANGE') => void;
          setCategories: (categories: string[]) => void;
          cancel: () => void;
          confirm: () => void;
          validate: () => void;
          validationResult: ReturnType<typeof fns.supplierBaseProduct.validate.supplierBaseProduct> | null;
          handleDelete: () => void;
          setCode: (code: libTypes.SupplierBaseProduct['code']) => void;
          setLinkProduct: (linkProductId: libTypes.SupplierBaseProduct['linkProductId']) => void;
      }
    | undefined
>(undefined);

export const useModalSupplierBaseProductContext = () => {
    const context = React.useContext(ModalSupplierBaseProduct);
    if (context === undefined) {
        throw new Error("useModalSupplierBaseProductContext must be within the correct provider");
    }
    return context;
};

export const ModalSupplierBaseProductContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [supplierBaseProduct, setSupplierBaseProduct] = React.useState<StoreEditSupplier['newValues']['baseProducts'][number]| null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.supplierBaseProduct.validate.supplierBaseProduct
    > | null>(null);
    const handleConfirmFn = React.useRef<(supplierBaseProduct: StoreEditSupplier['newValues']['baseProducts'][number]) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const supplierBaseProducts = useStore((store) => store.editSupplier?.currentValues.baseProducts);
    const otherBaseProducts = supplierBaseProducts?.filter(({ id }) => id !== supplierBaseProduct?.id) || [];

    const validate = () => {
        setValidationResult(
            supplierBaseProduct && fns.supplierBaseProduct.validate.supplierBaseProduct(supplierBaseProduct, otherBaseProducts)
        );
    };

    
    const setType = (type: 'UNIQUE' | 'RANGE') => {
        supplierBaseProduct && setSupplierBaseProduct(fns.supplierBaseProduct.set.type(supplierBaseProduct, type));
        setIsEdited(true);
    };

    const setCategories = (categories: string[]) => {
        supplierBaseProduct && setSupplierBaseProduct(fns.supplierBaseProduct.set.categories(supplierBaseProduct, categories));
        setIsEdited(true);
    };

    const setCode = (code: string) => {
        supplierBaseProduct && setSupplierBaseProduct(fns.supplierBaseProduct.set.code(supplierBaseProduct, code));
        setIsEdited(true);
    };

    const setLinkProduct = (linkProductId: string | null) => {
        supplierBaseProduct && setSupplierBaseProduct(fns.supplierBaseProduct.set.linkProductId(supplierBaseProduct, linkProductId));
        setIsEdited(true);
    };


    const handleDelete = () => {
        if(!supplierBaseProduct) return;
        openGenericDialog(
            "Ths will remove this base product.",
            "Confirm Delete?",
            false,
            true,
            () => {
                handleConfirmFn.current && supplierBaseProduct && handleConfirmFn.current({...supplierBaseProduct, _deleted: true});
                closeModal("MODAL_SUPPLIER_BASE_PRODUCT");
            }
        );
    };

    const wrappedOpenModal = (
        supplierBaseProduct: libTypes.SupplierBaseProduct,
        isNew: boolean,
        handleConfirm: (supplierBaseProduct: libTypes.SupplierBaseProduct) => void,
        markAsEdited = false,
        handleCancel?: () => void
    ) => {
        setSupplierBaseProduct(supplierBaseProduct);
        setIsEdited(markAsEdited);
        setIsNew(isNew);
        setIsEditable(isNew);
        openModal("MODAL_SUPPLIER_BASE_PRODUCT");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;

    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        setSupplierBaseProduct(null);
        setIsEdited(false);
        setIsEditable(false);
        setIsNew(false);
        setValidationResult(null);
        closeModal("MODAL_SUPPLIER_BASE_PRODUCT");
    };

    const confirm = () => {
        if (!supplierBaseProduct) {
            closeModal("MODAL_SUPPLIER_BASE_PRODUCT");
            return;
        }
        if (!fns.supplierBaseProduct.validate.supplierBaseProduct(supplierBaseProduct, otherBaseProducts)._isValid) {
            validate();
            openGenericDialog("Product Component is not valid, please check the input values");
        } else {
            handleConfirmFn.current && supplierBaseProduct && handleConfirmFn.current(supplierBaseProduct);
            closeModal("MODAL_SUPPLIER_BASE_PRODUCT");
        }
    };

    return (
        <ModalSupplierBaseProduct.Provider
            value={{
                supplierBaseProduct,
                isEdited,
                isNew,
                handleDelete,
                isEditable,
                setIsEditable,
                openModalSupplierBaseProduct: wrappedOpenModal,
                cancel,
                confirm,
                validate,
                validationResult,
                setCode,
                setLinkProduct,
                setType,
                setCategories,
            }}
        >
            {children}
        </ModalSupplierBaseProduct.Provider>
    );
};
