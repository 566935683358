import React from "react";

export const DeliveryContext = React.createContext<{}>({});

export const useDeliveryContext = () => {
    const context = React.useContext(DeliveryContext);
    if (context === undefined) {
        throw new Error("useDeliveryContext must be within the correct provider");
    }
    return context;
};
