import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { create } from "mutative";

export const setProductionDate = (
    productionDate: libTypes.Order["productionDate"],
    productionDateType: libTypes.Order["productionDateType"],
    warningFn: (confirmFn: () => void) => void
) => {
    if (productionDate && Date.now() > productionDate && productionDateType !== "NONE") {
        warningFn(() => {
            _productionDate(productionDate, productionDateType);
        });
    } else {
        _productionDate(productionDate, productionDateType);
    }
};

const _productionDate = (
    productionDate: libTypes.Order["productionDate"],
    productionDateType: libTypes.Order["productionDateType"]
) => {
    useStore.setState({
        editOrder: create(getEditOrder(), (draft) => {
            draft.newValues.order.productionDate = fns.order.set.productionDate(productionDate);
            draft.newValues.order.productionDateType =
                fns.order.set.productionDateType(productionDateType);
        }),
    });
};
