import React from "react";
import { feathersApp } from "../config/feathers";
import { setSuppliers } from "../actions/suppliers";
import { libTypes } from "@holta/lib";

export const useLoadSuppliers = () => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const hasRun = React.useRef(false);
    if (!hasRun.current) {
        feathersApp
            .service("suppliers")
            .watch()
            .find({
                query: {
                    $limit: 10000,
                },
            })
            .subscribe(({ data }: { data: libTypes.Supplier[] }) => {
                setSuppliers(data);
                setIsLoaded(true);
            });
        hasRun.current = true;
    }
    return isLoaded;
};
