import { Grid, Spacer, Text } from '@holta/ui';
import React from 'react';
import { useModalSupplierProductImport } from '../context';

interface Props {
    children?: React.ReactNode;
}

export const FileUpload: React.FC<Props> = ({ children }) => {

    const uploadRef = React.useRef<HTMLInputElement>(null);
    const {uploadFile} = useModalSupplierProductImport();


    return (
        <Spacer xs={[10]}>
            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Row xs={{ align: "center", justify: "center", direction: "column" }}>
                    <div>
                        <Text size="l" bold>
                            Upload a supplier poroduct CSV
                        </Text>
                        <Spacer xs={[1]}></Spacer>
                    </div>
                    <input type="file" id="file" name="file" ref={uploadRef}/>
                    <Spacer xs={[1]}></Spacer>
                    <button className="primary" onClick={() => {
                            uploadRef.current?.files && uploadFile(uploadRef.current?.files);
                        }
                    }>Upload</button>
                </Grid.Row>
            </Grid.Row>
        </Spacer>
    );
};