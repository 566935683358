import { FormValue, FormSelect, Grid, Icon, Spacer, Text, FormToggle, FormLabel } from '@holta/ui';
import React from 'react';
import {useModalSupplierProductImport} from '../context';
interface Props {
    children?: React.ReactNode;
}

export const MatchHeadings: React.FC<Props> = ({ children }) => {

    const {matchedHeadings, setMatchedHeading, availableHeadings, toggleSaveHeadingsToDb, saveHeadingsToDb, allHeadingsMatch, submitHeadings} = useModalSupplierProductImport();

    return (
        <Spacer xs={[10]} style={{textAlign: 'center'}}>
            <Spacer xs={[1]}></Spacer>
            <Text size="l" bold>Match Headings</Text>
            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                    <Icon name='file-list'/>
                    <div></div>
                    Uploaded File Headings
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <Icon name='database-2'/>
                    <div></div>
                    Database Headings
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>

            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                    <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.code ? {label: matchedHeadings.code, value: matchedHeadings.code} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('code', value.value);
                    }}/>
                    
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Code</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>

            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.h ? {label: matchedHeadings.h, value: matchedHeadings.h} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('h', value.value);
                    }}/>
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Height</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>


            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.w ? {label: matchedHeadings.w, value: matchedHeadings.w} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('w', value.value);
                    }}/>
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Width</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>

            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.d ? {label: matchedHeadings.d, value: matchedHeadings.d} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('d', value.value);
                    }}/>
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Depth</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>

            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.price ? {label: matchedHeadings.price, value: matchedHeadings.price} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('price', value.value);
                    }}/>
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Price</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>

            <Grid.Row xs={{ align: "center", justify: "center" }}>
                <Grid.Col xs={{cols: 4}}>
                <FormSelect 
                    options={availableHeadings.map(item => ({label: item, value: item}))} 
                    value={matchedHeadings.description ? {label: matchedHeadings.description, value: matchedHeadings.description} : false} 
                    onChange={(value: any) => {
                        setMatchedHeading('description', value.value);
                    }}/>
                </Grid.Col>
                <Grid.Col xs={{cols: 3}}>
                    <Grid.Row xs={{ align: "center", justify: "center" }}>
                        <hr style={{flex: 1}}/>
                        <Spacer xs={[1]}>
                            <Icon name="arrow-right" />
                        </Spacer>
                        <hr style={{flex: 1}}/>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col xs={{cols: 4}}>
                    <FormValue>Description</FormValue>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>
<div>
<FormLabel>Save Matched headings</FormLabel>
            <FormToggle onChange={toggleSaveHeadingsToDb} checked={saveHeadingsToDb}/>
            </div>
            <Spacer xs={[2]}></Spacer>

            <button className='primary' disabled={!allHeadingsMatch} onClick={submitHeadings}>Continue</button>
        </Spacer>
    );
};
