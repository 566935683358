import { useStore } from "../../../hooks/useStore"

export const useIsEdited = () => {
    const editSupplier = useStore(state => state.editSupplier);

    if(editSupplier?.newValues.supplier && Object.keys(editSupplier?.newValues.supplier).length > 0) {
        return true;
    }
    if(editSupplier?.newValues.addresses && editSupplier?.newValues.addresses.length > 0) {
        return true;
    }
    if(editSupplier?.newValues.contacts && editSupplier?.newValues.contacts.length > 0) {
        return true;
    }
    if(editSupplier?.newValues.ranges && editSupplier?.newValues.ranges.length > 0) {
        return true;
    }
    if(editSupplier?.newValues.baseProducts && editSupplier?.newValues.baseProducts.length > 0) {
        return true;
    }
}
