import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
} from "@holta/ui";
import React from "react";

interface Props {
    children?: React.ReactNode;
    handleConfirm: (updateDiscount: boolean, updateTaxValue: boolean, updateSupplierDiscount: boolean, updateItemDiscounts: boolean,  updateInvoiceAddress: boolean, updateDeliveryAddress: boolean) => void;
}

export const ModalConfirmUpdateOrderCustomer: React.FC<Props> = ({handleConfirm}) => {
    const { closeModal } = useModal();
    const [updateDiscount, setUpdateDiscount] = React.useState(true);
    const [updateTaxValue, setUpdateTaxValue] = React.useState(true);
    const [updateSupplierDiscount, setUpateSupplierDiscount] = React.useState(true);
    const [updateItemDiscounts, setUpateItemDiscounts] = React.useState(true);
    const [updateInvoiceAddress, setUpateInvoiceAddress] = React.useState(true);
    const [updateDeliveryAddress, setUpateDeliveryAddress] = React.useState(true);

    const id = "MODAL_CONFIRM_UPDATE_ORDER_CUSTOMER";

    const wrappedHandleConfirm = () => {
        handleConfirm(updateDiscount, updateTaxValue, updateSupplierDiscount, updateItemDiscounts, updateInvoiceAddress, updateDeliveryAddress);
        closeModal(id);
    }

    return (
        <Modal id={id} style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                   Update customer discount
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                            <Island>
                                Update these discounts from the new customer?
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Order discount setting</FormLabel>
                                    <FormToggle checked={updateDiscount} onChange={() => setUpdateDiscount(!updateDiscount)}/>
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Order tax value</FormLabel>
                                    <FormToggle checked={updateTaxValue} onChange={() => setUpdateTaxValue(!updateTaxValue)}/>
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Order supplier discount setting</FormLabel>
                                    <FormToggle checked={updateSupplierDiscount} onChange={() => setUpateSupplierDiscount(!updateSupplierDiscount)} />
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Existing order item discounts</FormLabel>
                                    <FormToggle checked={updateItemDiscounts} onChange={() => setUpateItemDiscounts(!updateItemDiscounts)}/>
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Invoice Address</FormLabel>
                                    <FormToggle checked={updateDeliveryAddress} onChange={() => setUpateDeliveryAddress(!updateDeliveryAddress)}/>
                                </Spacer>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Delivery Address</FormLabel>
                                    <FormToggle checked={updateInvoiceAddress} onChange={() => setUpateInvoiceAddress(!updateInvoiceAddress)}/>
                                </Spacer>
                            </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal(id)}>Cancel</button>
                    <Spacer xs={[0.5]} />
                    <button className="primary" onClick={wrappedHandleConfirm}>
                        Confirm
                    </button>
                </ModalFooter>
            </>
        </Modal>
    );
};
