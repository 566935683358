import React from "react";
import styled from "styled-components";
import {
    Spacer,
    Grid,
    Text,
    FormSelect,
    FormLabel,
    FormDate,
    IconButton,
    useModal,
    FormInput,
    WeekSwitcher,
    Notice,
    Tag,
} from "@holta/ui";
import { Link } from "raviger";
import { DiaryNotes } from "../../../components/DiaryNotes";
import { ProductionStatusBar } from "../../../components/ProductionStatusBar";
import { goToNextProductionWeek, goToPrevProductionWeek, goToProductionWeek } from "../../../actions/ui";
import { useStore } from "../../../config/store";
import { add, getISOWeek } from "date-fns";
import { fns } from "@holta/lib";
import { createAndEditNewOrder } from "../../../actions/editOrder/createAndEditNewOrder";
interface Props {}

export const Header: React.FC<Props> = () => {
    const { openModal } = useModal();
    const weekStartDate = useStore((store) => store.ui.production.weekStart);
    const weekEndDate = add(weekStartDate, { days: 4 });
    const weekNumber = getISOWeek(weekStartDate);
    const orders = useStore((store) => store.productionOrders);

    const bookedPercentage = fns.orders.getWeekProductionCapacity(orders);
    const totalValue = fns.orders.getTotalValue(orders, {excludeTax: true, includeEstimates: true});
    const deliveredSameWeekPercentage = fns.orders.getDeliveredSameWeekPercentage(orders);
    const completedJobsPercentage = fns.orders.getProductionStatusPercentage(orders, "COMPLETE") + fns.orders.getProductionStatusPercentage(orders, "DELIVERED");

    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];

    return (
        <>
            <Spacer xs={[2, 2, 1, 2]}>
                <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                    <Grid.Col>
                        <Text size="xl" bold>
                            Production:  <Text size="m">{fns.shared.formatDate(weekStartDate)} - {fns.shared.formatDate(weekEndDate)}</Text>
                        </Text>
                        <Grid.Row>
                            <Spacer xs={[0, 0.5, 1, 0]}>
                                <Tag  size="l"
                                    type={
                                        bookedPercentage > 100 ? 2 : bookedPercentage > 50 ? 3 : 1
                                    }
                                >
                                    {parseFloat(bookedPercentage.toFixed(2))}% Booked (£{totalValue})
                                </Tag>
                            </Spacer>
                            <Spacer xs={[0, 0.5, 1, 0]}>
                                <Tag type={1} size="l">
                                    Delivered Same week:{" "}
                                    {parseFloat(deliveredSameWeekPercentage.toFixed(2))}%
                                </Tag>
                            </Spacer>
                            <Spacer xs={[0, 0.5, 1, 0]}>
                                <Tag type={completedJobsPercentage > 99 ? 3 : 1} size="l">
                                    Completed Jobs:{" "}
                                    {parseFloat(completedJobsPercentage.toFixed(2))}%
                                </Tag>
                            </Spacer>
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col>
                        <button
                            className="primary"
                            onClick={() =>
                                createAndEditNewOrder({
                                    productionDate: weekStartDate,
                                    productionDateType: "WEEK",
                                })
                            }
                        >
                            New Quote
                        </button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[0.5]}></Spacer>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Grid.Row>
                            <WeekSwitcher
                                onClickNext={goToNextProductionWeek}
                                onClickPrev={goToPrevProductionWeek}
                                onClickNow={() => {}}
                                week={weekNumber}
                            />
                            <Spacer xs={[0.5]} />
                            <div className="weekselecter">
                                <FormDate
                                    selected={new Date(weekStartDate)}
                                    showWeekNumbers
                                    onChange={(e) => {
                                        !Array.isArray(e) && goToProductionWeek(e!.getTime());
                                    }}
                                    customInput={<button className="tertiary">Jump to...</button>}
                                    
                                />
                            </div>
                            <Spacer xs={[0.5]} />
                            {weekNumber !== getISOWeek(new Date()) && (
                                <button
                                    className="primary"
                                    onClick={() => goToProductionWeek(new Date().getTime())}
                                >
                                    Current Week
                                </button>
                            )}
                        </Grid.Row>
                    </Grid.Col>
                    <Grid.Col>
                        {/*
                    <Grid.Row>
                        <div>
                            <FormInput placeholder="Search..." />
                        </div>
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <IconButton icon="eye" className="tertiary">
                            <Spacer xs={[1, 0.25]}>View Options</Spacer>
                            </IconButton>
                        </div>
                        <Spacer xs={[0.5]}></Spacer>
                        <div>
                            <IconButton icon="filter" className="tertiary"  onClick={() => openModal('ORDERS_FILTER')}>
                                <Spacer xs={[1, 0.25]}>Filter</Spacer>
                            </IconButton>
                        </div>
                    </Grid.Row>
    */}
                    </Grid.Col>
                </Grid.Row>
            </Spacer>
            {/* <Spacer xs={[0.5, 1, 0, 1]}><DiaryNotes>dasd</DiaryNotes></Spacer> */}

            <Spacer xs={[0.5, 1]}>
                <ProductionStatusBar orders={orders} />
            </Spacer>
        </>
    );
};
