import {
    Container,
    FormDate,
    FormInput,
    FormLabel,
    FormSelect,
    FormTextarea,
    FormValue,
    Grid,
    Island,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    Tab,
    Tabs,
    Text,
    useModal,
    Menu,
    Popover,
    useDialog,
    Icon,
    IconButton,
    ModalFooter,
    FormToggle,
    FormNumber
} from "@holta/ui";
import React from "react";
import { useMenuButtonContext } from "@holta/ui";
import { InputCustomerSelect } from "../../../components/InputCustomerSelect";
import editOrderActions from "../../../actions/editOrder";
import { useStore } from "../../../config/store";
import { ModalConfirmUpdateOrderCustomer } from "./ModalConfirmCustomerChange";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { fns, libTypes } from "@holta/lib";
import { DeliveryDate } from "../../../components/InputDeliveryDate";
import { ProductionDate } from "../../../components/InputProductionDate";
import { hooks } from "@holta/ui";
import { useElementSize } from "usehooks-ts";
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import { ChooseCustomer } from "./ChooseCustomer";

export const Settings = () => {
    const orderType = useStore((state) => state.editOrder?.type);
    const editOrder = useMergedEditOrder();
    const [deliveryDateOpen, setDeliveryDateOpen] = hooks.useToggle(false);
    const [productionDateOpen, setProductionDateOpen] = hooks.useToggle(false);
    const [deliveryDateRef, deliveryDateSize] = useElementSize();
    const [productionDateRef, productionDateSize] = useElementSize();
    const [updateExistingDiscounts, setUpdateExistingDiscounts] = React.useState(true);
    const { openGenericDialog } = useDialog();
    const { openModal, closeModal } = useModal();
    const [tempDiscountValue, setTempDiscountValue] = React.useState(0);

    React.useEffect(() => {
        editOrder?.discount && setTempDiscountValue(editOrder?.discount);
    }, [editOrder?.discount]);

    function handleUpdateDiscount(){
        closeModal("CONFIRM_DISCOUNT_CHANGE");
        editOrderActions.setDiscount(tempDiscountValue, updateExistingDiscounts);
    }

    if (!editOrder) return null;

    return (
        <>
            <hr />
            <Container xl="fixed">
                <Spacer xs={[3, 1]}>
                    <Spacer xs={[0.25, 1]}>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 4 }}>
                                <FormLabel type="contained" position="left">
                                    Quote Ref.
                                </FormLabel>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 8 }}>
                                <FormInput
                                    style={{ width: "100%" }}
                                    value={editOrder.quoteRef}
                                    onChange={(e) => editOrderActions.setQuoteRef(e.target.value)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Spacer>
                    <Spacer xs={[0.25, 1]}>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 4 }}>
                                <FormLabel type="contained" position="left">
                                    Order Ref.
                                </FormLabel>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 8 }}>
                                <FormInput
                                    style={{ width: "100%" }}
                                    value={editOrder.orderRef}
                                    onChange={(e) => editOrderActions.setOrderRef(e.target.value)}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 4 }}>
                                <FormLabel type="contained" position="left">
                                    Order Date
                                </FormLabel>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 8 }}>
                                <FormDate
                                    onChange={(d) => d && !Array.isArray(d) && editOrderActions.setDateOrdered(d.getTime())}
                                    dateFormat="dd/MM/yyyy"
                                    selected={editOrder.dateOrdered ? new Date(editOrder.dateOrdered) : undefined}
            
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 4 }}>
                                <FormLabel type="contained" position="left">
                                    Invoice Date
                                </FormLabel>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 8 }}>
                                <FormDate
                                    onChange={(d) => d && !Array.isArray(d) && editOrderActions.setDateInvoiced(d.getTime())}
                                    dateFormat="dd/MM/yyyy"
                                    selected={editOrder.dateInvoiced ? new Date(editOrder.dateInvoiced) : undefined}
            
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </Spacer>
                    <Spacer xs={[0.25, 1]}>
                        <Grid.Row>
                            <Grid.Col xs={{ cols: 4 }}>
                                <FormLabel type="contained" position="left">
                                    Order discount
                                </FormLabel>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 8 }}>
                                <FormValue
                                    style={{ width: "100%" }} onClick={() => openModal("CONFIRM_DISCOUNT_CHANGE")}>{editOrder.discount}</FormValue>
                            </Grid.Col>
                        </Grid.Row>
                    </Spacer>
                    {orderType === "EDIT" && (
                        <Spacer xs={[1]}>
                            <div>
                                {" "}
                                <Text size="xl">Delete</Text>
                            </div>
                            <div>
                                {" "}
                                <Text size="s">Delete this order?</Text>
                            </div>
                            <Spacer xs={[1, 0]}>
                                <button
                                    className="danger"
                                    onClick={() => {
                                        openGenericDialog(
                                            "Are you sure you want to delete this order?",
                                            "Delete Order",
                                            false,
                                            true,
                                            editOrderActions.deleteOrder
                                        );
                                    }}
                                >
                                    Delete
                                </button>
                            </Spacer>
                        </Spacer>
                    )}
                </Spacer>
            </Container>
            <Modal id="CONFIRM_DISCOUNT_CHANGE">
                <>
                    <ModalHeader>
                        Update Discount
                    </ModalHeader>
                    <ModalBody>
                        <Spacer xs={[2]}>
                            <Island>
                                <FormLabel>Discount</FormLabel>
                                <FormNumber isFloat value={tempDiscountValue} onChange={(e) => setTempDiscountValue(Number(e.target.value))}/>
                                <FormLabel> Update existing item discounts?</FormLabel>
                                <FormToggle checked={updateExistingDiscounts} onChange={() => setUpdateExistingDiscounts(!updateExistingDiscounts)}/>
                            </Island>
                        </Spacer>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={() => {setTempDiscountValue(editOrder?.discount); closeModal("CONFIRM_DISCOUNT_CHANGE")}}>Cancel</button>
                        <Spacer xs={[0.5]} />
                        <button className="primary" onClick={handleUpdateDiscount}>
                            Confirm
                        </button>
                    </ModalFooter>
                </>
            </Modal>
        </>
    );
};
