import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";


export const editOrderItem = (id: string) => {
    const editOrder = getEditOrder();
    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.orderItem = { id };
        }),
    });
}