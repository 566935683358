import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedContacts = () : libTypes.Contact[] => {
    const editedContacts = useStore(state => state.editCustomer?.newValues.contacts);
    const existingContacts = useStore(state => state.editCustomer?.currentValues.contacts);

    const merged : libTypes.Contact[] = [];

    existingContacts?.forEach(c => {
        if(!editedContacts?.find(c2 => c2.id === c.id)) {
            merged.push(c);
        }
    });

    editedContacts?.forEach(c => {
        if(!c._deleted) merged.push(c);
    })
    
    return merged;

}