import { Grid, Island, Spacer } from "@holta/ui";
import styled, { css } from "styled-components";
import { getDaysInMonth } from 'date-fns'



type Props = {
    date: Date | number,
    items: Array<any>,
}

export const Planner = ({date, items} : Props) => {

    const daysInMonth = getDaysInMonth(date);

    const Cols = [];
    for (let i = 0; i < daysInMonth; i++) {
        Cols.push(<S.Column key={i} style={{left: 100 / daysInMonth * i + '%'}}>{i + 1}</S.Column>)
    }
    
    return (
        <Spacer xs={[1]} style={{ width: "100%" }}>
            <Island style={{width: "100%", overflow: 'auto'}}>
                <S.Container style={{width: daysInMonth * 100 + 'px'}}>
                    <S.Header>
                    </S.Header>
                    <S.Body>
                        {Cols}
                        <S.Row>
                            <S.Item startsBefore style={{left:'0', right:'50%'}} />
                        </S.Row>
                        <S.Row>
                            <S.Item style={{left:'20%', right:'40%'}} />
                        </S.Row>
                    </S.Body>
                </S.Container>    
            </Island>
        </Spacer>
    );
};



const S = {
    Container: styled.div`
        width: 100%;
        position: relative;
        min-height: 500px;
    `,
    Header: styled.div``,
    Body: styled.div`
        padding-top: ${props => props.theme.spacing(3)};
    `,
    Column: styled.div`
        position: absolute;
        border-left: 1px solid #e2e2e2;
        top: 0;
        bottom: 0;
        width: 100px;
        text-align: center; 
    `,
    Row: styled.div`
        height: 20px;
        position: relative;
        margin-bottom: ${props => props.theme.spacing(1)};
    `,
    Item: styled.div<{startsBefore? : boolean, endsAfter? : boolean }>`
        height: 20px;
        border-radius: 1000px;
        background-color: blue; 
        position: absolute;

        ${p => {
            if (p.startsBefore) {
                return css`
                    border-radius: 0 1000px 1000px 0;
                `;
            }
            if (p.endsAfter) {
                return css`
                    border-radius:  1000px 0 0 1000px;
                `;
            }
        }}
    `
}
