import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
import { useStore } from "../../../hooks/useStore";
import {setSuppliersSearchFilter} from '../../../actions/ui';
interface Props {}

export const Header: React.FC<Props> = () => {

    const searchFilter = useStore(store => store.ui.suppliers.searchFilter);

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Suppliers</Text>
                </Grid.Col>
                <Grid.Col>
                    <Link href="/suppliers/new"><button className="primary">New Supplier</button></Link>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[0.5]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        <div>
                            <FormInput placeholder="Search..." value={searchFilter} onChange={e => setSuppliersSearchFilter(e.target.value)}/>
                        </div>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
