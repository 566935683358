import { Grid, Island, Spacer, Text } from "@holta/ui";
import { DashboardContext } from "./context";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { Chart1 } from "./parts/Chart1";
import { Chart2 } from "./parts/Chart2";
import { Chart3 } from "./parts/Chart3";
import { Chart4 } from "./parts/Chart4";
import { Chart5 } from "./parts/Chart5";
import { user } from "../../fns/user";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Bar Chart",
        },
    },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
    labels,
    datasets: [
        {
            label: "Dataset 1",
            data: [1, 5, 3, 6, 8, 3, 5],
            backgroundColor: "rgba(59, 255, 66, 0.5)",
        },
        {
            label: "Dataset 2",
            data: [1, 5, 3, 6, 8, 3, 5],
            backgroundColor: "rgba(153, 53, 235, 0.5)",
        },
    ],
};

export const Dashboard = () => {
    return (
        <DashboardContext.Provider value={{}}>
            <Spacer xs={[1]} style={{ width: "100%" }}>
                <Grid.Row>
                    {user.roles.includes("office") && (
                        <>
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[1]}>
                                    <Island>
                                        <Chart1 />
                                    </Island>
                                </Spacer>
                                <Spacer xs={[1]}>
                                    <Island>
                                        <Chart3 />
                                    </Island>
                                </Spacer>
                            </Grid.Col>
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[1]}>
                                    <Island>
                                        <Chart2 />
                                    </Island>
                                </Spacer>
                                <Spacer xs={[1]}>
                                    <Island>
                                        <Chart4 />
                                    </Island>
                                </Spacer>
                            </Grid.Col>
                        </>
                    )}
                    {user.roles.includes("workshop") && (
                        <>
                            <Grid.Col xs={{ cols: 6 }}>
                                <Spacer xs={[1]}>
                                    <Island>
                                        <Chart4 />
                                    </Island>
                                </Spacer>
                            </Grid.Col>
                        </>
                    )}
                </Grid.Row>
            </Spacer>
        </DashboardContext.Provider>
    );
};
