import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    HighlightedText
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useFilteredProductComponents } from "../../../hooks/useFilteredProductComponents";
import { useStore } from "../../../config/store";
import {useModalProductComponentContext} from "../../../components/ModalProductComponent/context";
import {fns} from "@holta/lib";
import {updateProductComponent} from "../../../actions/productComponents";
import { SortHighlight } from "../../../components/SortHighlight";
import {handleProductComponentsTableHeadingClick} from "../../../actions/ui";

interface Props {}

const Row = ({ style, index }: { style: React.CSSProperties, index: number }) => {

    const productComponents = useStore(store => store.productComponents);
    const searchTerm = useStore(store => store.ui.productComponents.searchFilter);
    const filteredProductComponents = useFilteredProductComponents();
    const {openModalProductComponent} = useModalProductComponentContext();

    if(!filteredProductComponents[index]) return null;

    return (
        <Tr style={style}  onClick={() => openModalProductComponent(filteredProductComponents[index], false, updateProductComponent)} hasHover>

            <Td colspan={2}>
                <Text size="m"><HighlightedText text={filteredProductComponents[index].code} highlight={searchTerm}/></Text>
            </Td>
        </Tr>
    );
};

export const ProductComponentsTable: React.FC<Props> = () => {
    const productComponents = useFilteredProductComponents();
    const ui = useStore((store) => store.ui);

    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={1}>
                <Thead>
                    <Tr onClick={() => handleProductComponentsTableHeadingClick('code')}>
                        <Th colspan={1}><SortHighlight direction={ui.productComponents.sortDirection} thisField="code" uiField={ui.productComponents.sortField}>Code</SortHighlight></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List
                                height={height - 2}
                                itemCount={productComponents.length}
                                itemSize={46}
                                width={width - 1}
                                overscanCount={30}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
