import { createNewOrder } from "./createNewOrder";
import { createAndEditNewOrder } from "./createAndEditNewOrder"
import { createAndEditDuplicateOrder } from "./createAndEditDuplicateOrder";
import { createDuplicateOrder } from "./createDuplicateOrder";
import { createNewOrderFromCustomerOrder } from "./createNewOrderFromCustomerOrder";
import { clearAll } from "./clearAll";
import { clearEditOrderItem } from "./clearEditOrderItem";
import { createCarcaseItem } from "./createCarcaseItem";
import { createLinkedDoors } from "./createLinkedDoors";
import { createSupplierItem } from "./createSupplierItem";
import { deleteOrder } from "./deleteOrder";
import { deleteOrderItem } from "./deleteOrderItem";
import { deletePayment } from "./deletePayment";
import { duplicateOrderItem } from "./duplicateOrderItem";
import { editOrderItem } from "./editOrderItem";
import { getEditOrder } from "./getEditOrder";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";
import { loadOrder } from "./loadOrder";
import { saveAndAddNewCarcaseItem } from "./saveAndAddNewCarcaseItem";
import { saveEditOrderItem } from "./saveEditOrderItem";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { saveOrder } from "./saveOrder";
import { setAccountingStatus } from "./setAccountingStatus";
import { savePayment } from "./savePayment";
import { setCarcaseColour } from "./setCarcaseColour";
import { setCarcaseItemCode } from "./setCarcaseItemCode";
import { setCarcaseItemDepth } from "./setCarcaseItemDepth";
import { setCarcaseItemDescription } from "./setCarcaseItemDescription";
import { setCarcaseItemDiscount } from "./setCarcaseItemDiscount";
import { setCarcaseItemHeight } from "./setCarcaseItemHeight";
import { setCarcaseItemHanding } from "./setCarcaseItemHanding";
import { setCarcaseItemPosition } from "./setCarcaseItemPosition";
import { setCarcaseItemPrice } from "./setCarcaseItemPrice";
import { setCarcaseItemQuantity } from "./setCarcaseItemQuantity";
import { setCarcaseItemWidth } from "./setCarcaseItemWidth";
import { setConstructionType } from "./setConstructionType";
import { setCustomerId } from "./setCustomerId";
import { setCustomerRef } from "./setCustomerRef";
import { setDateInvoiced } from "./setDateInvoiced";
import { setDateOrdered } from "./setDateOrdered";
import { setDeliveryAddress } from "./setDeliveryAddress";
import { setDeliveryDate } from "./setDeliveryDate";
import { setDeliveryNotes } from "./setDeliveryNotes";
import { setDiscount } from "./setDiscount";
import { setDoorRange } from "./setDoorRange";
import { setDoorSupplier } from "./setDoorSupplier";
import { setDrawerbox } from "./setDrawerbox";
import { setGluedWallUnits } from "./setGluedWallUnits";
import { setHasDoors } from "./setHasDoors";
import { setInvoiceAddress } from "./setInvoiceAddress";
import { setLoadedDoorRange } from "./setLoadedDoorRange";
import { setOrder } from "./setOrder";
import { setOrderItemSortOrder } from "./setOrderItemSortOrder";
import { setOrderItemsSortOrder } from "./setOrderItemsSortOrder";
import { setOrderRef } from "./setOrderRef";
import { setOrderStatus } from "./setOrderStatus";
import { setOrderValueEstimate } from "./setOrderValueEstimate";
import { setOrderValueType } from "./setOrderValueType";
import { setProductionDate } from "./setProductionDate";
import { setProductionNotes } from "./setProductionNotes";
import { setProductionStatus } from "./setProductionStatus";
import { setQuoteRef } from "./setQuoteRef";
import { setSupplierDeliveryDate } from "./setSupplierDeliveryDate";
import { setVoidSize } from "./setVoidSize";
import { setCarcaseItemNotes } from "./setCarcaseItemNotes";

export default {
    clearAll,
    clearEditOrderItem,
    createAndEditDuplicateOrder,
    createAndEditNewOrder,
    createCarcaseItem,
    createDuplicateOrder,
    createLinkedDoors,
    createNewOrder,
    createNewOrderFromCustomerOrder,
    createSupplierItem,
    deleteOrder,
    deleteOrderItem,
    deletePayment,
    duplicateOrderItem,
    editOrderItem,
    getEditOrder,
    getMergedOrder,
    getMergedOrderItem,
    loadOrder,
    saveAndAddNewCarcaseItem,
    saveEditOrderItem,
    saveLinkedDoorOrderItem,
    saveMissingLinkedDoorOrderItem,
    saveOrder,
    savePayment,
    setAccountingStatus,
    setCarcaseColour,
    setCarcaseItemCode,
    setCarcaseItemDepth,
    setCarcaseItemDescription,
    setCarcaseItemDiscount,
    setCarcaseItemHanding,
    setCarcaseItemHeight,
    setCarcaseItemNotes,
    setCarcaseItemPosition,
    setCarcaseItemPrice,
    setCarcaseItemQuantity,
    setCarcaseItemWidth,
    setConstructionType,
    setCustomerId,
    setCustomerRef,
    setDateInvoiced,
    setDateOrdered,
    setDeliveryAddress,
    setDeliveryDate,
    setDeliveryNotes,
    setDiscount,
    setDoorRange,
    setDoorSupplier,
    setDrawerbox,
    setGluedWallUnits,
    setHasDoors,
    setInvoiceAddress,
    setLoadedDoorRange,
    setOrder,
    setOrderItemSortOrder,
    setOrderItemsSortOrder,
    setOrderRef,
    setOrderStatus,
    setOrderValueEstimate,
    setOrderValueType,
    setProductionDate,
    setProductionNotes,
    setProductionStatus,
    setQuoteRef,
    setSupplierDeliveryDate,
    setVoidSize
}

