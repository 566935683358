import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedRanges = () : libTypes.SupplierRange[] => {
    const editedRanges = useStore(state => state.editSupplier?.newValues.ranges);
    const existingRanges = useStore(state => state.editSupplier?.currentValues.ranges);

    const merged = [...editedRanges || []];

    existingRanges?.forEach(c => {
        if(!merged.find(c2 => c2.id === c.id)) {
            merged.push(c);
        }
    });

    return merged.filter(r => !r._deleted).sort((a,b) => a.name.localeCompare(b.name));

}