import { libTypes } from "@holta/lib";
import { FormDate, FormSelect, Grid, Spacer } from "@holta/ui";
import React from "react";
import { staticData, fns } from "@holta/lib";
import { getDay } from "date-fns";
import { isMonday } from "date-fns";
import { nextMonday } from "date-fns";

export function DeliveryDate({
    initialDeliveryDate,
    initialDeliveryDateType,
    handleSave,
    handleCancel,
}: {
    initialDeliveryDate: libTypes.Order["deliveryDate"];
    initialDeliveryDateType: libTypes.Order["deliveryDateType"];
    handleSave: (
        deliveryDate: libTypes.Order["deliveryDate"],
        deliveryDateType: libTypes.Order["deliveryDateType"]
    ) => void;
    handleCancel: () => void;
}): React.ReactElement {
    const [deliveryDate, setDeliveryDate] = React.useState(initialDeliveryDate);
    const [deliveryDateType, setDeliveryDateType] = React.useState(initialDeliveryDateType);

    React.useEffect(() => {
        setDeliveryDate(initialDeliveryDate);
        setDeliveryDateType(initialDeliveryDateType);
    }, [initialDeliveryDate, initialDeliveryDateType]);

    // If the delivery date type is WEEK, force the date to a Monday
    if (deliveryDateType === "WEEK" && deliveryDate && !isMonday(deliveryDate))
        setDeliveryDate(nextMonday(deliveryDate).getTime());

    // If the delivery date type is 'DATE' or 'WEEK' and no date is set, set it to the next Monday
    if ((deliveryDateType === "DAY" || deliveryDateType === "WEEK") && !deliveryDate)
        setDeliveryDate(nextMonday(new Date()).getTime());

    const options = fns.shared.formSelect.createOptions(
        "key",
        "label",
        Object.values(staticData.deliveryDateTypes)
    );
    const currentValue = fns.shared.formSelect.createValueOption(
        deliveryDateType,
        "key",
        "label",
        Object.values(staticData.deliveryDateTypes)
    );

    const dateVal = deliveryDate ? new Date(deliveryDate) : new Date();

    const hasChanged =
        deliveryDate !== initialDeliveryDate || deliveryDateType !== initialDeliveryDateType;

    return (
        <>
            <FormSelect
                options={options}
                value={currentValue}
                isSearchable={false}
                onChange={({ value }: { value: libTypes.DeliveryDateType }) =>
                    setDeliveryDateType(value)
                }
                
            />

            {deliveryDateType === "WEEK" && (
                <>
                    <Spacer xs={[0.5]} />
                    <FormDate
                        onChange={(d) => d && !Array.isArray(d) && setDeliveryDate(d.getTime())}
                        filterDate={(date) => getDay(date) === 1}
                        dateFormat="dd/MM/yyyy"
                        selected={dateVal}
                        showWeekNumbers
                    />
                </>
            )}
            {deliveryDateType === "DAY" && (
                <>
                    <Spacer xs={[0.5]} />
                    <FormDate
                        onChange={(d) => d && !Array.isArray(d) && setDeliveryDate(d.getTime())}
                        dateFormat="dd/MM/yyyy"
                        selected={dateVal}
                        filterDate={(date) => {
                            const day = new Date(date).getDay();
                            return day !== 0 && day !== 6;
                        }}
                    />
                </>
            )}
            <Spacer xs={[1]} />
            <Grid.Row>
                <button onClick={handleCancel}>Cancel</button>
                <Spacer xs={[0.5]} />
                {hasChanged && (
                    <button
                        className="primary"
                        onClick={() => handleSave(deliveryDate, deliveryDateType)}
                    >
                        Confirm
                    </button>
                )}
            </Grid.Row>
        </>
    );
}
