import { Container, Modal, ModalBody, ModalFooter, ModalHeader, ModalSubHeader, Spacer, Text, Grid, Tabs, Tab, Island, FormInput, FormSelect } from '@holta/ui';
import React from 'react';
import styled from 'styled-components';
import { ProductionDate } from '../InputProductionDate';
import { DeliveryDate } from '../InputDeliveryDate';

interface Props {
    children?:React.ReactNode
}

export const ModalOrder : React.FC<Props> = ({children}) =>{

    return (
        <Modal id="MODAL_ORDER" style={{width: '600px'}}>
            <>
                <ModalHeader>Edit Order</ModalHeader>
                <ModalSubHeader style={{padding: 0}}>
                <Grid.Row>
                    <Spacer xs={[0, 2]}>
                        <Tabs height={6}>
                            <Tab height={6} isSelected={true} onClick={() => {}} inline>
                                Production
                            </Tab>
                            <Tab height={6} inline>
                                Delivery
                            </Tab>
                           
                        </Tabs>
                    </Spacer>
                </Grid.Row>
                </ModalSubHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Text size='l' bold>Order Details</Text>
                            <Spacer xs={[1,0]}>
                                <FormSelect placeholder="Name" style={{width: '100%'}} />
                            </Spacer>
                           {/* <Spacer xs={[1,0]}>
                                <ProductionDate />
                            </Spacer>
                            <Spacer xs={[1,0]}>
                                <DeliveryDate />
    </Spacer> */}
                            <Spacer xs={[1,0]}>
                            <FormInput placeholder="Name" style={{width: '100%'}} />
                            </Spacer>
                            <Spacer xs={[1,0]}>
                                <button className="tertiary" style={{width: '100%', textAlign: 'center'}}>Go to full order</button>
                            </Spacer>
                        </Island>
                    </Spacer>
                    
                </ModalBody>
                <ModalFooter>
                    <button >Cancel</button>
                    <Spacer xs={[0.5]} />
                    <button className="primary">Save</button>
                </ModalFooter>
            </> 
        </Modal>
    )

};

