import React from "react";
import styled from "styled-components";
import { Text, Menu, MenuItem, Icon } from "@holta/ui";
import {useLogout} from '../../../hooks/useLogout';
import {user} from '../../../fns/user';

export const ProfileButton = () => {

    
    const logout = useLogout();


    return (
        <Menu
            button={
                <S.Wrapper>
                    <S.Icon>
                        <Icon name="account-circle"></Icon>
                    </S.Icon>
                    <S.Text>
                        <S.Title><Text color="accent3" size="s" el="div">Logged in as</Text></S.Title>
                            
                            <Text color="base" el="div">{user.email}</Text>

                    </S.Text>
                    <S.Arrow></S.Arrow>
                </S.Wrapper>
            }
        >
<MenuItem onClick={logout}>Log Out</MenuItem>

        </Menu>
    );
};

const S = {
    Wrapper: styled.div`
        display: flex;
        align-items: center;
        background-color: ${p => p.theme.color.accent5};
        padding:  ${p => p.theme.spacing(0.5) } ${p => p.theme.spacing(2) } ${p => p.theme.spacing(0.5) } ${p => p.theme.spacing(1.5) };
        margin: ${p => p.theme.spacing(-1.5)};
    `,
    Icon: styled.div`
        width: ${p => p.theme.spacing(3)};
        height: ${p => p.theme.spacing(3)};
        background-color: ${p => p.theme.color.highlight};
        border-radius: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;
        line-height: 0;
        color: ${p => p.theme.color.base_contrast};
        margin-right: ${p => p.theme.spacing(1)};
    `,
    Text: styled.div``,
    Title: styled.div`
        margin-bottom:0.1em;
    `,
    Email: styled.div``,
    Arrow: styled.div``,
};
