import { Grid, Icon, Notice } from "@holta/ui";

export const FiltersNotificationBar = ({
    filtersCount,
    handleClear,
}: {
    filtersCount: number;
    handleClear?: () => void;
}) => {
    if (filtersCount > 0) {
        return (
            <Notice color="accent5">
                <Grid.Row xs={{ justify: "space-between" }}>
                    <div>
                        {filtersCount} Filter{filtersCount > 1 ? "s" : ""} Applied
                    </div>
                    <div>
                        {handleClear && (
                            <Grid.Row xs={{ align: "center" }} onClick={handleClear} style={{cursor: "pointer"}}>
                                <Icon name="close-circle"></Icon>
                                &nbsp;Clear Filters
                            </Grid.Row>
                        )}
                    </div>
                </Grid.Row>
            </Notice>
        );
    }

    return null;
};
