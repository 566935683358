
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setDateInvoiced = (dateInvoiced: libTypes.Order['dateInvoiced']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.dateInvoiced = fns.order.set.dateInvoiced(dateInvoiced);
        })
    });
};