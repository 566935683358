import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { StoreEditCustomer } from "../../config/store";
import React from "react";
import { useStore } from "../../hooks/useStore";

export const ModalProductContext =
    React.createContext<
        | {
              product: libTypes.Product | null;
              isEdited: boolean;
              isNew: boolean;
              isEditable: boolean;
              handleDelete: () => void;
              setIsEditable: (isEditable: boolean) => void;
              openModalProduct: (
                  product: libTypes.Product,
                  isNew: boolean,
                  handleConfirm: (product: libTypes.Product) => void,
                  handleCancel?: () => void,
                  markAsEdited?: boolean
              ) => void;

              setCode: (code: string) => void;
              setW: (w: number) => void;
              setH: (h: number) => void;
              setD: (d: number) => void;
              addComponent: () => void;
              setCategories: (categories: string[]) => void;
              removeComponent: (index: number) => void;
              updateComponent: (
                  index: number,
                  component: libTypes.Product["components"][number]
              ) => void;
              toggleCustomerRestricted: () => void;
              toggleSoldByM2: () => void;
              setCustomerWhitelist: (customerWhitelist: string[]) => void;
              setDrawers: (drawers: number) => void;
              setImage: (image: string) => void;
              setPrice: (price: number) => void;
              setPriceType: (priceType: libTypes.Product["priceType"]) => void;
              setPriceExpression: (priceExpression: string) => void;
              setDescription: (description: string) => void;
              setSpecification: (specification: string) => void;
              componentIdToAdd: string | null;
              setComponentIdToAdd: (componentIdToAdd: string) => void;
              setComponentQtyToAdd: (componentQtyToAdd: number) => void;
              componentQtyToAdd: number;

              linkProductIdToAdd: string | null;
              setLinkProductIdToAdd: (linkProductIdToAdd: string) => void;
              setLinkProductQtyToAdd: (linkProductQtyToAdd: number) => void;
              linkProductQtyToAdd: number;

              toggleActiveLinkProductGroup: (
                    linkProductGroup: libTypes.ConstructionType | 'DEFAULT') => void;
              addLinkProduct: (
                  group: libTypes.ConstructionType | "DEFAULT"
              ) => void;
              removeLinkProduct: (
                  group: libTypes.ConstructionType | "DEFAULT",
                  linkProductId: string
              ) => void;
              updateLinkProductQty: (
                  group: libTypes.ConstructionType,
                  productId: string,
                  qty: number
              ) => void;
              cancel: () => void;
              confirm: () => void;
              validate: () => void;
              validationResult: ReturnType<typeof fns.product.validate.product> | null;
          }
        | undefined
    >(undefined);

export const useModalProductContext = () => {
    const context = React.useContext(ModalProductContext);
    if (context === undefined) {
        throw new Error("useModalProductContext must be within the correct provider");
    }
    return context;
};

export const ModalProductContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [product, setProduct] = React.useState<libTypes.Product | null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isEditable, setIsEditable] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const handleConfirmFn = React.useRef<(product: libTypes.Product) => void>();
    const handleCancelFn = React.useRef<() => void>();
    const [componentIdToAdd, setComponentIdToAdd] = React.useState<string | null>(null);
    const [componentQtyToAdd, setComponentQtyToAdd] = React.useState<number>(1);

    const [linkProductIdToAdd, setLinkProductIdToAdd] = React.useState<string | null>(null);
    const [linkProductQtyToAdd, setLinkProductQtyToAdd] = React.useState<number>(1);

    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.product.validate.product
    > | null>(null);

    const products = useStore((store) => store.products);

    const validate = () => {
        setValidationResult(
            product &&
                fns.product.validate.product(
                    product,
                    products.filter((c) => c.id !== product.id)
                )
        );
    };

    const handleDelete = () => {
        handleConfirmFn.current && product && handleConfirmFn.current(product);
        closeModal("MODAL_PRODUCT");
    };

    const wrappedOpenModal = (
        product: libTypes.Product,
        isNew: boolean,
        handleConfirm: (product: libTypes.Product) => void,
        handleCancel = () => {},
        markAsEdited = false 
    ) => {
        setProduct(product);
        setIsEdited(markAsEdited);
        setIsNew(isNew);
        setValidationResult(null);
        //setIsEditable(isNew);
        // Temporarily only use edit mode
        // TODO: Enable edit mode
        setIsEditable(true);
        openModal("MODAL_PRODUCT");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        closeModal("MODAL_PRODUCT");
    };

    const confirm = () => {

        if (!product) {
            closeModal("MODAL_PRODUCT");
            return;
        }
        if (!fns.product.validate.product(product, products)._isValid) {
            openGenericDialog("Product is not valid, please check the input values");
        } else {
            handleConfirmFn.current && product && handleConfirmFn.current(product);
            closeModal("MODAL_PRODUCT");
        }
    };

    const setCategories = (categories: string[]) => {
        product && setProduct(fns.product.set.categories(product, categories));
        setIsEdited(true);
    };

    const setCode = (code: string) => {
        product && setProduct(fns.product.set.code(product, code));
        setIsEdited(true);
    };

    const setW = (w: number) => {
        product && setProduct(fns.product.set.w(product, w));
        setIsEdited(true);
    };

    const setH = (h: number) => {
        product && setProduct(fns.product.set.h(product, h));
        setIsEdited(true);
    };

    const setD = (d: number) => {
        product && setProduct(fns.product.set.d(product, d));
        setIsEdited(true);
    };

    const setDescription = (description: string) => {
        product && setProduct(fns.product.set.description(product, description));
        setIsEdited(true);
    };

    const setSpecification = (specification: string) => {
        product && setProduct(fns.product.set.specification(product, specification));
        setIsEdited(true);
    };

    const addComponent = () => {
        product &&
            componentIdToAdd &&
            setProduct(
                fns.product.set.addComponent(product, {
                    id: componentIdToAdd,
                    qty: componentQtyToAdd,
                })
            );
        setIsEdited(true);
        setComponentIdToAdd(null);
        setComponentQtyToAdd(1);
    };


    const addLinkProduct = (constructionType: libTypes.ConstructionType | "DEFAULT") => {

        product &&
            linkProductIdToAdd &&
            setProduct(
                fns.product.set.addLinkProduct(product, 
                    constructionType,
                    linkProductIdToAdd,
                    linkProductQtyToAdd,
                )
            );
        setIsEdited(true);
        setLinkProductIdToAdd(null);
        setLinkProductQtyToAdd(1);
    };

    const removeComponent = (index: number) => {
        product && setProduct(fns.product.set.removeComponent(product, index));
        setIsEdited(true);
    };

    const updateComponent = (index: number, component: libTypes.Product["components"][number]) => {
        product && setProduct(fns.product.set.updateComponent(product, index, component));
        setIsEdited(true);
    };

    const toggleCustomerRestricted = () => {
        product &&
            setProduct(fns.product.set.customerRestricted(product, !product.customerRestricted));
        setIsEdited(true);
    };

    const toggleSoldByM2 = () => {
        product && setProduct(fns.product.set.soldByM2(product, !product.soldByM2));
        setIsEdited(true);
    };

    const setCustomerWhitelist = (customers: string[]) => {
        product && setProduct(fns.product.set.customerWhitelist(product, customers));
        setIsEdited(true);
    };

    const setDrawers = (drawers: number) => {
        product && setProduct(fns.product.set.drawers(product, drawers));
        setIsEdited(true);
    };

    const setImage = (image: string) => {
        product && setProduct(fns.product.set.image(product, image));
        setIsEdited(true);
    };

    const setPrice = (price: number) => {
        product && setProduct(fns.product.set.price(product, price));
        setIsEdited(true);
    };

    const setPriceType = (priceType: libTypes.Product["priceType"]) => {
        product && setProduct(fns.product.set.priceType(product, priceType));
        setIsEdited(true);
    };

    const setPriceExpression = (priceExpression: string) => {
        product && setProduct(fns.product.set.priceExpression(product, priceExpression));
        setIsEdited(true);
    };

    const toggleActiveLinkProductGroup = (group: libTypes.ConstructionType | "DEFAULT") => {
        product && setProduct(fns.product.set.toggleActiveLinkProductGroup(product, group));
        setIsEdited(true);
    };

    const removeLinkProduct = (
        group: libTypes.ConstructionType | "DEFAULT",
        productId: string
    ) => {
        product &&
            setProduct(fns.product.set.removeLinkProduct(product, group, productId));
        setIsEdited(true);
    };

    const updateLinkProductQty = (
        group: libTypes.ConstructionType,
        productId: string,
        qty: number
    ) => {
        product && setProduct(fns.product.set.updateLinkProductQty(product, group, productId, qty));
        setIsEdited(true);
    };

    return (
        <ModalProductContext.Provider
            value={{
                product,
                isEdited,
                isNew,
                isEditable,
                setIsEditable,
                handleDelete,
                setCategories,
                openModalProduct: wrappedOpenModal,
                addComponent,
                removeComponent,
                updateComponent,
                addLinkProduct,
                removeLinkProduct,
                toggleActiveLinkProductGroup,
                updateLinkProductQty,
                toggleCustomerRestricted,
                toggleSoldByM2,
                setCustomerWhitelist,
                setDrawers,
                setImage,
                setPrice,
                setPriceExpression,
                setPriceType,
                setCode,
                setW,
                setH,
                setD,
                setDescription,
                setSpecification,
                componentIdToAdd,
                setComponentIdToAdd,
                setComponentQtyToAdd,
                componentQtyToAdd,
                linkProductIdToAdd,
                setLinkProductIdToAdd,
                setLinkProductQtyToAdd,
                linkProductQtyToAdd,
                cancel,
                confirm,
                validate,
                validationResult
            }}
        >
            {children}
        </ModalProductContext.Provider>
    );
};
