import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Island,
    Notice,
    Icon,
    Grid,
    Vr,
    IconButton,
    useLoading,
} from "@holta/ui";
import { CustomerDetails } from "./parts/CustomerDetails";
import { Header } from "./parts/Header";
import { CustomerContext, useCustomerContextProps } from "./context";
import { Sidebar } from "./parts/Sidebar";
import { Contacts } from "./parts/Contacts";
import { Addresses } from "./parts/Addresses";
import { Settings } from "./parts/Settings";
import { useMatch, usePathParams } from "raviger";
import React from "react";
import { initialiseNewCustomer, clearAll, loadCustomer } from "../../actions/editCustomer";

export const Customer = () => {
    const contextProps = useCustomerContextProps();
    const { closeAll } = useModal();
    const isNew = useMatch("/customers/new");
    const params = usePathParams('/customers/:id')
    const [initialised, setInitialised] = React.useState(false);
    const {openLoading, closeLoading} = useLoading()

    React.useEffect(() => {

        async function init(){
            if (isNew && !initialised) {
                initialiseNewCustomer();
                setInitialised(true);
            }
            else if(params?.id) {
                const to = setTimeout(() => {    
                    openLoading();
                }, 1000);
                await loadCustomer(params.id);
                clearTimeout(to);
                setInitialised(true);
                closeLoading(200);
            }
    
            else throw new Error("Customer page not found");
        }

        init();

        return () => {
            clearAll();
            setInitialised(false);
        }
        
    }, [isNew, params?.id]);
   

    React.useEffect(() => {
        return () => {
            closeAll();
            clearAll();
        };
    }, []);

    if(initialised) {
        return (
            <CustomerContext.Provider value={{ ...contextProps }}>
                <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
                    <div>
                        <Header />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Spacer xs={[0, 2, 2, 2]} style={{ height: "100%" }}>
                            <Island p={0} style={{ height: "100%", overflow: "auto" }}>
                                <Grid.Row xs={{ align: "stretch" }} style={{ height: "100%" }}>
                                    <Grid.Col xs={{ cols: 3 }}>
                                        <Sidebar />
                                    </Grid.Col>
                                    <Vr />
                                    <Grid.Col xs={{ cols: 9 }}>
                                        {contextProps.tab === 0 && <CustomerDetails />}
                                        {contextProps.tab === 1 && <Contacts />}
                                        {contextProps.tab === 2 && <Addresses />}
                                        {contextProps.tab === 3 && <Settings />}
                                    </Grid.Col>
                                </Grid.Row>
                            </Island>
                        </Spacer>
                    </div>
                </Container>
            </CustomerContext.Provider>
        );
    }

    return null;
};
