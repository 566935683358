import { FormLabel, Island, Spacer, useDialog, useModal } from '@holta/ui';
import { deleteProduct } from '../../../actions/products';
import { useStore } from '../../../hooks/useStore';
import React from 'react';

import { useModalProductContext } from '../context';

interface Props {
    children?: React.ReactNode;
}

export const SettingsTab: React.FC<Props> = () => {
    const { closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);
    const {
        product,
        isNew,
        isEditable,
    } = useModalProductContext();


    if (!product) {
        return null;
    }

    const wrappedHandleDelete = () => {
        openGenericDialog(
            "Ths will completely remove this product and can't be undone'.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteProduct(product.id)
                    .then(() => {
                        closeModal("MODAL_PRODUCT");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting product", "Error", false, true);
                    });
            }
        );
    };

    return (
        <>
            {!isNew && (
                <Spacer xs={[1, 0]}>
                    <Island>
                        <Spacer xs={[1, 0]}>
                            <FormLabel>Delete Product</FormLabel>
                            {isEditable ? (
                                <button className="danger" onClick={wrappedHandleDelete}>
                                    Delete
                                </button>
                            ) : (
                                <button className={isEditable ? "danger" : "secondary"} disabled>
                                    Delete
                                </button>
                            )}
                        </Spacer>
                    </Island>
                </Spacer>
            )}
        </>
    );
};
