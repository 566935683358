import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    NoResults,
    Notice,
    Icon,
    Grid,
} from "@holta/ui";
import { useFilteredProducts } from "../../hooks/useFilteredProducts";
import { Header } from "./parts/Header";
import { ProductsTable } from "./parts/ProductsTable";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import {clearAllProductsFilters} from '../../actions/ui';
import {FiltersNotificationBar} from "../../components/FiltersNotificationBar";
import {useProductsFilterCount} from "../../hooks/useProductsFilterCount";
import { FilterSidebar } from "./parts/FilterSidebar";
import {ProductBrowserContextProvider} from './context';
import {useStore} from "../../config/store";

export const HoltaProducts = () => {

    const products = useFilteredProducts();
    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useProductsFilterCount();
    const categories = useStore((state) => state.categories);

    return (
        <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
            <div>
                <Header />
                <div ref={filtersAnimationParent}>
                        {activeFilterCount > 0 && (
                            <Spacer xs={[0, 2, 2, 2]}>
                                <FiltersNotificationBar filtersCount={activeFilterCount} handleClear={clearAllProductsFilters} />
                            </Spacer>
                        )}
                    </div>
            </div>
            <ProductBrowserContextProvider categories={categories} products={products} handleConfirm={() => {}} modalId={"fff"}>
                <>
            <div style={{ flex: 1, overflow: "auto" }}>
                    {products.length > 0 ? (
                        <ProductsTable />
                    ) : (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <NoResults text="No products found." />
                        </Spacer>
                    )}
                </div>

            <FilterSidebar />
            </>
            </ProductBrowserContextProvider>
        </Container>
    );
};
