import { staticData, libTypes } from '@holta/lib';
import { ProductionStausDot } from '@holta/ui';
import React from 'react';
import styled from 'styled-components';
import { useDrop } from "react-dnd";
import { updateProductionStatus } from '../../../actions/order';

interface Props {
    status: typeof staticData.productionStatuses[keyof typeof staticData.productionStatuses];
}

export const Column : React.FC<Props> = ({status, children}) =>{

    const [collectedProps, drop] = useDrop(() => ({
        accept: 'card',
        drop : (dragItem, monitor) => updateProductionStatus((dragItem as libTypes.Order), status.key),
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            };
        },
    }), [status])

    const borderColor = collectedProps.canDrop && collectedProps.isOver ? "#4D4DDC4F" : "transparent";

    return (
        <Wrapper ref={drop} style={{ border: '2px solid', borderColor}}>
            <ColumnTitle>
                <ProductionStausDot size={1} status={status.key}/>&nbsp;{status.label}
            </ColumnTitle>
            {React.Children.map(children, child => (
                <ItemWrapper>
                    {child}
                </ItemWrapper>
            ))}
        </Wrapper>
    )

};

const Wrapper = styled.div`
    min-height: 100%;
    border-right: 1px solid ${(p) => p.theme.color.accent1};
    width: 300px;
    flex-shrink: 0;
`

const ItemWrapper = styled.div`
    padding: 0 ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)};
`;

const ColumnTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(p) => p.theme.spacing(1)};
`;