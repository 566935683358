import { FormInput, FormLabel, Grid, Spacer, Text, FormTextarea, FormSelect, Icon, NoResults, Table, Thead, Tr, Th, Td, Tbody, Ellipsis, useModal } from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { user } from "../../../fns/user";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";
import { useMergedAddresses } from "../hooks/useMergedAddresses";
import { saveAddress } from "../../../actions/editSupplier";
import { useModalAddressContext } from "../../../components/ModalAddress/context";
import {fns} from "@holta/lib";
interface Props {
    children?: React.ReactNode;
}

export const Addresses: React.FC<Props> = ({ children }) => {
    const { openModalAddress } = useModalAddressContext();
    const supplier = useMergedSupplier();
    const addresses = useMergedAddresses();

    if (!supplier || !user.id) {
        return null;
    }
    return (
        <div>
            <Spacer xs={[2]}>
                <Grid.Row xs={{justify: 'space-between'}}>
                    <Grid.Col>
                        <Text size="xl">Addresses</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <button className="primary" onClick={() => {
                            openModalAddress(
                                { ...fns.address.create(user.id as string, supplier.id as string), _dbUnsaved: true, },
                                false,
                                false,
                                true,
                                saveAddress,
                                "SUPPLIER"
                            )
                        }}>Add Address</button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1]}></Spacer>

                {addresses.length > 0 ? (
                    <Table cols={6}>
                        <Thead>
                            <Tr>
                                
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text>Name</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text>Address</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text>Postcode</Text>
                                    </Ellipsis>
                                </Th>
                                
                            </Tr>
                        </Thead>
                        <Tbody>
                            {addresses.map((address, index) => (
                                <Tr hasHover key={address.id} onClick={() => openModalAddress(
                                    address,
                                    false,
                                    supplier.defaultAddress === address.id,
                                    false,
                                    saveAddress,
                                    "SUPPLIER"
                                )}>
                                    
                                    <Td colspan={2}>
                                        <Ellipsis>
                                            <Text>
                                                {address.name} 
                                            </Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={2}>
                                        <Ellipsis>
                                            <Text>{address.addressLine1}{address.addressLine1 && '...'}</Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={2}>
                                        <Ellipsis>
                                            <Text>{address.postcode}</Text>
                                        </Ellipsis>
                                    </Td>
                                    
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Spacer xs={[0, 2, 2, 2]}>
                        <NoResults text="No addresses." />
                    </Spacer>
                )}
            </Spacer>
            
        </div>
    );
};