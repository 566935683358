import { usePathParams } from "raviger";
import {feathersApp} from '../config/feathers';
import React from 'react';
import {setUser} from '../fns/user';

export function useAuthentication() {

    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    React.useEffect(() => {
        feathersApp.reAuthenticate().then(() => {
            feathersApp.authenticate().then(({user}) => {
                setUser({
                    id: user.id,
                    email: user.email,
                    roles: user.roles
                });
                if(user.roles.includes('admin')) {
                    setIsAuthenticated(true)
                } else {
                    window.location.replace(`${import.meta.env.VITE_PROTOCOL}${import.meta.env.VITE_SERVER_URL}${import.meta.env.VITE_LOGIN_PATH}`);
                }
            })
            
        }).catch((err) => {     
            window.location.replace(`${import.meta.env.VITE_PROTOCOL}${import.meta.env.VITE_SERVER_URL}${import.meta.env.VITE_LOGIN_PATH}?redirect=${import.meta.env.VITE_LOGIN_REDIRECT}`);
        });
    }, [])
    
    
    return isAuthenticated;

}


