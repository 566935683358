import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
interface Props {}

export const Header: React.FC<Props> = () => {

    const {openModal} = useModal();
    
    const options = [
        { value: "all", label: "All" },
        { value: "toMe", label: "Assigned To Me" },
        { value: "byMe", label: "Created by me" },
    ];

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">To Do</Text>
                </Grid.Col>
                <Grid.Col>
                    <Link href="/orders/new"><button className="primary">New Todo</button></Link>
                </Grid.Col>
            </Grid.Row>
            <Spacer xs={[1]}></Spacer>
            <Grid.Row xs={{ justify: "space-between" }}>
                <Grid.Col>
                    <Grid.Row>
                        
                        <div>
                            <FormLabel>Type</FormLabel>
                            <FormSelect
                                options={options}
                                value={{ value: "all", label: "All" }}
                                isSearchable={false}
                                style={{ minWidth: "200px" }}
                            />
                        </div>
                        <div>
                            <FormLabel>Status</FormLabel>
                            <FormSelect
                                options={options}
                                value={{ value: "all", label: "All" }}
                                isSearchable={false}
                                style={{ minWidth: "200px" }}
                            />
                        </div>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    
                    
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
