import { Grid, Spacer, Text, useDialog } from "@holta/ui";
import { saveSupplier } from "../../../actions/editSupplier";
import { usePath } from "raviger";
import React from "react";

import { useIsEdited } from "../hooks/useIsEdited";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";

interface Props {}

export const Header: React.FC<Props> = () => {
    const path = usePath();
    const isEdited = useIsEdited();
    const supplier = useMergedSupplier();
    const {openGenericDialog} = useDialog();

    const wrappedSaveSupplier = () => {
        saveSupplier().catch(e => {
            console.log(e)
            openGenericDialog(e.message, 'An Error Occurred');
        });
    };

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Grid.Row xs={{ align: "center" }}>
                        <Text size="xl">Supplier</Text>&nbsp;→&nbsp;
                        <Text size="xl" color="highlight">
                            {path === "/suppliers/new" ? "New" : supplier?.name}
                        </Text>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    {isEdited && (
                        <button className="primary" onClick={wrappedSaveSupplier}>
                            Save
                        </button>
                    )}
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
