import {
    Tr,
    Td,
    Text,
    Ellipsis
} from "@holta/ui";
import React from "react";
import { fns } from "@holta/lib";
import { libTypes } from "@holta/lib";
import { useSupplierProductBrowserContext } from "../context";

export const ProductsTableRow = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: libTypes.SupplierComputedProduct[];
}) => {
    const product = data[index];

    const { selectedProduct, setSelectedProduct } = useSupplierProductBrowserContext()

    return (
        <Tr hasHover style={style} isSelected={selectedProduct?.id === product.id} onClick={() => setSelectedProduct(product)}>
            <Td colspan={3} spacing={0.5}>
                <Ellipsis tooltipText={product.code}><Text size="s">{product.code}</Text></Ellipsis>
            </Td>
            <Td colspan={4}  spacing={0.5}>
                <Ellipsis tooltipText={product.description}> <Text size="s">{product.description}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.h)}> <Text size="s">{product.h}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.w)}> <Text size="s">{product.w}</Text></Ellipsis>
            </Td>
            <Td colspan={1} align="c"  spacing={0.5}>
                <Ellipsis tooltipText={String(product.d)}> <Text size="s">{product.d}</Text></Ellipsis>
            </Td>
        </Tr>
    );
};
