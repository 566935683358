import { Ellipsis, Icon, Text } from "@holta/ui";
import React from "react";

export const SortHighlight = ({
    direction,
    thisField,
    uiField,
    title,
    children,
}: {
    direction: "ASC" | "DESC" | "NONE";
    thisField: string;
    uiField: string;
    title?: string;
    children?: string;
}) => {
    const color = thisField === uiField ? "highlight" : "text";
    if (direction !== "NONE" && thisField === uiField) {
        return (
            <Text size="s" color={color}>
                <Ellipsis tooltipText={title || children}>
                    <Icon name={direction === "ASC" ? "arrow-down" : "arrow-up"} />
                    &nbsp;{title || children}
                </Ellipsis>
            </Text>
        );
    } else {
        return (
            <Text size="s" color={color}>
                <Ellipsis tooltipText={title || children}>{title || children}</Ellipsis>
            </Text>
        );
    }
};
