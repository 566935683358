
import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import {create} from 'mutative';

export const setProductionNotes = (productionNotes: libTypes.Order['productionNotes']) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder : create(editOrder, draft => {
            draft.newValues.order.productionNotes = fns.order.set.productionNotes(productionNotes);
        })
    });
};