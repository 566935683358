import { libTypes } from "@holta/lib";
import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import { fns } from "@holta/lib";
import { MenuList } from "react-select/src/components/Menu";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { OrderItemsEditCarcaseRow } from "./OrderItemsEditCarcaseRow";
import { useStore } from "../../../config/store";
import editOrderActions from "../../../actions/editOrder";
import { useDialog } from "@holta/ui";
import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { useOrderContext } from "../context";
import { OrderItemsSortDropArea } from "./OrderItemsSortDropArea";
import { SupplierProductBrowser } from "../../../components/SupplierProductBrowser";
export const OrderItemsMissingLinkedRow = ({
    item,
    index,
    parentIndex,
}: {
    item: libTypes.MissingLinkedOrderItem;
    index: number;
    parentIndex: number;
}) => {
    const ref = React.useRef(null);
    const editOrder = useMergedEditOrder();
    const editItem = useStore((state) => state.editOrder?.newValues?.orderItem);
    const { openGenericDialog } = useDialog();
    const { dragOrderItems } = useOrderContext();
    const suppliers = useStore((state) => state.suppliers);
    const { closeAll, openModal } = useModal();
    const [collectedProps, drop] = useDrop(() => ({
        accept: "orderItemRow",
        drop: (dragItem, monitor) =>
            editOrderActions.setOrderItemsSortOrder((dragItem as any).id, item.id),
        hover: (dragItem, monitor) => {
            if (dragOrderItems.dragId !== item.id) {
                dragOrderItems.setDragId(item.id);
            }
        },
    }));
    const [{ opacity }, drag] = useDrag(
        () => ({
            type: "orderItemRow",
            item: { id: item.id },
            collect: (monitor) => {
                return {
                    opacity: monitor.isDragging() ? 0.5 : 1,
                };
            },
            end: (item, monitor) => {
                dragOrderItems.setDragId("");
            },
        }),
        []
    );

    function updateMissingItem(newItem: libTypes.SupplierComputedProduct) {
        const newOrderItem = fns.order_item.createLinkedDoorOrderItem(
            newItem,
            editOrder?.supplierDiscount || 0,
            item.parentId,
            item.quantity
        );
        console.log(newOrderItem);
        editOrderActions.saveLinkedDoorOrderItem({
            ...newOrderItem,
            id: item.id,
        });
    }

    const borderTop = dragOrderItems.dragId ? "1px solid #C2C6D0" : "none";

    drag(drop(ref));
    function handleDelete() {
        openGenericDialog(
            "Are you sure you want to remove this item?",
            "Delete Item",
            false,
            true,
            () => {
                editOrderActions.deleteOrderItem(item.id);
            }
        );
    }

    if (!editOrder) return null;
    return (
        <>
            <Tr
                hasHover
                onClick={() => editOrderActions.editOrderItem(item.id)}
                style={{ borderTop }}
                isStriped={parentIndex % 2 === 0}
            >
                <Td colspan={3}>
                    <Ellipsis>
                        <Text size="m" color="danger">
                            {parentIndex}.{index}
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={3}>
                    <Ellipsis>
                        <Text size="m" color="danger">
                            {item.quantity}
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={3}></Td>
                <Td colspan={8}>
                    <Ellipsis>
                        <Text size="m" color="danger">
                            {item?.linkProductName}
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={16}>
                    <Ellipsis>
                        <Text size="m" color="danger">
                            ⚠️ Missing Item
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={4}></Td>
                <Td colspan={4}></Td>
                <Td colspan={4}></Td>
                <Td colspan={4}></Td>
                <Td colspan={8}></Td>
                <Td colspan={4}></Td>
                <Td colspan={6}></Td>
                <Td colspan={2} align="c">
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Menu button={<Icon name="menu" />}>
                            <MenuItem
                                onClick={() =>
                                    openModal("SUPPLIER_PRODUCT_BROWSER_FIND_REPLACEMENT" + item.id)
                                }
                            >
                                <Grid.Row>
                                    <Icon name="refresh"></Icon>&nbsp;Find Replacement
                                </Grid.Row>
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>
                                <Grid.Row>
                                    <Icon name="delete-bin"></Icon>&nbsp;Delete
                                </Grid.Row>
                            </MenuItem>
                        </Menu>
                    </div>
                </Td>
            </Tr>
            <SupplierProductBrowser
                modalId={"SUPPLIER_PRODUCT_BROWSER_FIND_REPLACEMENT" + item.id} 
                products={[]}
                categories={[]}
                handleConfirm={(item) => {
                    updateMissingItem(item);
                    closeAll();
                }}
                suppliers={suppliers}
            />
        </>
    );
};
