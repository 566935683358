import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    HighlightedText
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useFilteredLinkProducts } from "../../../hooks/useFilteredLinkProducts";
import { useStore } from "../../../config/store";
import {useModalLinkProductContext} from "../../../components/ModalLinkProduct/context";
import {fns, libTypes} from "@holta/lib";
import {updateLinkProduct} from "../../../actions/linkProducts";
import { SortHighlight } from "../../../components/SortHighlight";
import {handleLinkProductsTableHeadingClick} from "../../../actions/ui";

interface Props {}

const Row = ({ style, index, data}: { style: React.CSSProperties, index: number, data : {filteredLinkProducts:libTypes.LinkProduct[], searchTerm: string} }) => {

    const {filteredLinkProducts, searchTerm} = data;
    const {openModalLinkProduct} = useModalLinkProductContext();

    if(!filteredLinkProducts[index]) return null;

    return (
        <Tr style={style}  onClick={() => openModalLinkProduct(filteredLinkProducts[index], false, updateLinkProduct)} hasHover>

            <Td colspan={2}>
                <Text size="m"><HighlightedText text={filteredLinkProducts[index].name} highlight={searchTerm}/></Text>
            </Td>
        </Tr>
    );
};

export const LinkProductsTable: React.FC<Props> = () => {
    const searchTerm = useStore(store => store.ui.linkProducts.searchFilter);
    const filteredLinkProducts = useFilteredLinkProducts();
    const ui = useStore((store) => store.ui);

    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={1}>
                <Thead>
                    <Tr onClick={() => handleLinkProductsTableHeadingClick('name')}>
                        <Th colspan={1}><SortHighlight direction={ui.linkProducts.sortDirection} thisField="name" uiField={ui.linkProducts.sortField}>Name</SortHighlight></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List
                                height={height - 2}
                                itemCount={filteredLinkProducts.length}
                                itemSize={46}
                                width={width - 1}
                                overscanCount={30}
                                itemData={{filteredLinkProducts, searchTerm}}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
