import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
} from "@holta/ui";

import React from "react";

import { libTypes } from "@holta/lib";
import styled from "styled-components";

interface Props {
    handleConfirm: (address: libTypes.Address) => void;
    addresses: libTypes.Address[];
    defaultAddressId?: string;
    initialSelectedAddressId?: string;
}

const MODAL_ID = "MODAL_ADDRESS_SELECT";

export const ModalAddressSelect: React.FC<Props> = ({ addresses, defaultAddressId,initialSelectedAddressId,handleConfirm }) => {
    const { closeModal } = useModal();
    const [selectedAddress, setSelectedAddress] = React.useState<string | null>(initialSelectedAddressId || null);
    const defaultAddress = addresses.find((address) => address.id === defaultAddressId);
    const otherAddresses = addresses.filter((address) => address.id !== defaultAddressId);

    const selectedAddressObj = addresses.find((address) => address.id === selectedAddress);

    const wrappedHandleConfirm = () => {
        if (selectedAddressObj)
            handleConfirm(selectedAddressObj);
        setSelectedAddress(null);
        closeModal(MODAL_ID);
    };



    return (
        <Modal id={MODAL_ID} style={{ width: "600px" }} onDismiss={() => {setSelectedAddress(null)}}>
            <>
                <ModalHeader>Choose Address</ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1]}>
                                {defaultAddress && (
                                    <>
                                        <S.Card
                                            style={{ position: "relative" }}
                                            selected={selectedAddress === defaultAddress.id}
                                            onClick={() => setSelectedAddress(defaultAddress.id)}
                                        >
                                            <S.Tag>
                                                <Text size="s" bold>
                                                    Default
                                                </Text>
                                            </S.Tag>
                                            <Text bold>{defaultAddress.name}</Text>
                                            <Text size="s">
                                                <div>{defaultAddress.addressLine1}</div>
                                                <div>{defaultAddress.addressLine2}</div>
                                            </Text>
                                        </S.Card>
                                        <Spacer xs={[0.25]} />
                                    </>
                                )}
                                {otherAddresses.map((address) => (
                                    <div key={address.id}>
                                        <S.Card
                                            onClick={() => setSelectedAddress(address.id)}
                                            selected={selectedAddress === address.id}
                                        >
                                            <Text bold>{address.name}</Text>
                                            <Text size="s">
                                                <div>{address.addressLine1}</div>
                                                <div>{address.addressLine2}</div>
                                            </Text>
                                        </S.Card>
                                        <Spacer xs={[0.25]} />
                                    </div>
                                ))}
                            </Spacer>
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    <button onClick={() => closeModal(MODAL_ID)}>Cancel</button>
                    <Spacer xs={[0.5]} />
                    {selectedAddressObj &&<button className="primary" onClick={wrappedHandleConfirm}>
                        Confirm
                    </button> }
                </ModalFooter>
            </>
        </Modal>
    );
};

const S = {
    Tag: styled.div`
        position: absolute;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.color.accent3};
        padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
        display: inline-flex;
        align-items: center;
        top: 0;
        right: ${({ theme }) => theme.spacing(4)};
        transform: translateY(-50%);
    `,
    Card: styled(Island)<{ selected?: boolean }>`
        ${({ selected, theme }) => {
            if (selected) {
                return `
                    border-color: ${theme.color.highlight};
                `;
            }
        }}
    `,
};

/*

addressLine1: string;
    addressLine2: string;
    addressLine3: string
    city: string;
    country: string;
    county: string;
    createdBy: string;
    dateCreated: number;
    id: string;
    name: string;
    note: string;
    parentId: string;
    postcode: string;

    */
