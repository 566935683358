import styled from "styled-components";
import { Text, Menu, MenuItem, Icon, Grid, useDialog } from "@holta/ui";
import { NavItem } from "./parts/NavItem";
import { ActiveLink, navigate, useQueryParams } from "raviger";
import { useStore } from "../../config/store";

export const Navigation = () => {
    const editOrder = useStore((store) => store.editOrder);
    const { openGenericDialog } = useDialog();

    const handleNavCheck = (e: React.MouseEvent, location: string) => {
        if (editOrder?.newValues && Object.values(editOrder.newValues.order).length > 0) {
            e.preventDefault();
            e.stopPropagation();
            openGenericDialog(
                "Navigating away will loose changes",
                "Unsaved Order",
                false,
                true,
                () => navigate(location),
                false
            );
        }
    };

    return (
        <S.Wrapper>
            <Grid.Row
                xs={{ justify: "space-between", align: "center", direction: "column" }}
                style={{ height: "100%" }}
            >
                <Grid.Col>
                    <NavItem
                        icon="dashboard"
                        href="/"
                        exactActiveClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/")}
                    >
                        Dashboard
                    </NavItem>

                    <NavItem
                        icon="draft"
                        href="/orders"
                        activeClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/orders")}
                        requiredRoles={["admin"]}
                    >
                        Orders
                    </NavItem>

                    <NavItem
                        icon="hammer"
                        href="/production"
                        activeClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/production")}
                    >
                        Production
                    </NavItem>

                    <NavItem
                        href="/deliveries"
                        activeClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/deliveries")}
                        icon="truck"
                    >
                        Deliveries
                    </NavItem>

                    <NavItem
                        href="/customers"
                        activeClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/customers")}
                        requiredRoles={["admin"]}
                        icon="user"
                    >
                        Customers
                    </NavItem>

                    {/** <ActiveLink href="/contacts" activeClass="navItemCurrent"  onClick={(e) => handleNavCheck(e, "/contacts")}>
                        <NavItem icon="phone" count="2">
                            Contacts
                        </NavItem>
                    </ActiveLink>
                    */}

                    <NavItem
                        icon="archive"
                        href="/products"
                        activeClass="navItemCurrent"
                        onClick={(e) => handleNavCheck(e, "/products")}
                        requiredRoles={["admin"]}
                    >
                        Products
                    </NavItem>

                    {/**
                    <ActiveLink href="/todo" activeClass="navItemCurrent"  onClick={(e) => handleNavCheck(e, "/todo")}> 
                        <NavItem icon="checkbox">To Do</NavItem>
                    </ActiveLink>
                    
                    <ActiveLink href="/planner" activeClass="navItemCurrent"  onClick={(e) => handleNavCheck(e, "/planner")}>
                        <NavItem icon="calendar">Planner</NavItem>
                    </ActiveLink>
                    */}
                </Grid.Col>

                <NavItem
                    icon="settings"
                    href="/settings"
                    activeClass="navItemCurrent"
                    onClick={(e) => handleNavCheck(e, "/settings")}
                    requiredRoles={["admin"]}
                >
                    Settings
                </NavItem>
            </Grid.Row>
        </S.Wrapper>
    );
};

const S = {
    Wrapper: styled.div`
        height: 100%;
        background-color: ${(p) => p.theme.color.base_contrast};
        border-right: 1px solid ${(p) => p.theme.color.accent2};
        padding: ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(1)};
    `,
};
