import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    ProductionStausDot,
    Icon,
    Menu,
    MenuItem,
    HighlightedText,
    Grid,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { fns, staticData } from "@holta/lib";
import { Order } from "@holta/lib/types";
import { Link } from "raviger";
import { useStore } from "../../../config/store";
import { updateAccountingStatus } from "../../../actions/order";
import { createAndEditDuplicateOrder } from "../../../actions/editOrder/createAndEditDuplicateOrder";

export const OrdersTableRow = ({
    style,
    index,
    data,
}: {
    style: React.CSSProperties;
    index: number;
    data: Order[];
}) => {
    const order = data[index];
    const ui = useStore((store) => store.ui.orders);
    const customer = useStore((store) => store.customers).find(
        (customer) => customer.id === order.customerId
    );

    return (
        <Link href={"orders/" + order.id}>
            {" "}
            <Tr hasHover style={style} isStriped={index % 2 === 0}>
                
                <Td colspan={0} minWidth="50px" align="c">
                    <ProductionStausDot status={order.productionStatus} size={1} />
                </Td>
                <Td colspan={4}>
                    <Ellipsis
                        tooltipText={fns.shared.formatDate(fns.order.getLatestDate(order) || 0)}
                    >
                        <Text size="m">
                            {ui.dateType === "CURRENT_STATUS" && ( fns.order.getLatestDate(order) === 0 ? '-' : fns.shared.formatDate(fns.order.getLatestDate(order) || 0))}
                            {ui.dateType === "CREATED" && fns.shared.formatDate(order.dateCreated)}
                            {ui.dateType === "ORDERED" && order.dateOrdered && fns.shared.formatDate(order.dateOrdered)}
                            {ui.dateType === "INVOICED" && order.dateInvoiced && fns.shared.formatDate(order.dateInvoiced)}
                            {ui.dateType === "DELIVERY" && fns.order.formatDeliveryDate(order)}
                            {ui.dateType === "PRODUCTION" && fns.order.formatProductionDate(order)}
                            {ui.dateType === "MODIFIED" && order.editDate && fns.shared.formatDate(order.editDate)}

                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={1.5} align="c">
                    <Text size="m">
                        {order.orderStatus === "QUOTE"
                            ? "Q"
                            : order.orderStatus === "ORDER"
                            ? "O"
                            : "I"}
                    </Text>
                </Td>
                <Td colspan={1.5} align="c">
                    {order.accountingStatus === "ORDERED" ? (
                        <Icon name="check" />
                    ) : order.accountingStatus === "INVOICED" ? (
                        <Icon name="check-double" />
                    ) : order.accountingStatus === "PAID" ? (
                        <Icon name="money-pound-circle" />
                    ) : (
                        ""
                    )}
                </Td>
                <Td colspan={5}>
                    <Ellipsis tooltipText={order.quoteRef}>
                        <Text size="m">
                            <HighlightedText
                                fromStart
                                text={order.quoteRef || ""}
                                highlight={ui.searchFilter}
                            />
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={5}>
                    <Ellipsis tooltipText={order.orderRef}>
                        <Text size="m">
                            <HighlightedText
                                fromStart
                                text={order.orderRef || ""}
                                highlight={ui.searchFilter}
                            />
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={6}>
                    <Ellipsis tooltipText={customer?.name || "-"}>
                        <Text size="m">
                            <HighlightedText
                                fromStart
                                text={customer?.name || "-"}
                                highlight={ui.searchFilter}
                            />
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={6}>
                    <Ellipsis tooltipText={order.customerRef}>
                        <Text size="m">
                            <HighlightedText
                                fromStart
                                text={order.customerRef || ""}
                                highlight={ui.searchFilter}
                            />
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={4}>
                    <Ellipsis
                        tooltipText={String(fns.order.getOrderTotalOrEstimate(order)) + (order.orderValueType === "ESTIMATE" ? " (e)" : "")}
                    >
                        <Text size="m">
                            £
                            {String(fns.order.getOrderTotalOrEstimate(order)) + (order.orderValueType === "ESTIMATE" ? " (e)" : "")}
                        </Text>
                    </Ellipsis>
                </Td>
                <Td colspan={2} align="c">
                    <Ellipsis tooltipText={fns.order.getProductionWeek(order)}>
                        <Text size="m">{fns.order.getProductionWeek(order)}</Text>
                    </Ellipsis>
                </Td>
                <Td colspan={4}>
                    <Ellipsis tooltipText={fns.order.formatDeliveryDate(order)}>
                        <Text size="m">{fns.order.formatDeliveryDate(order)}</Text>
                    </Ellipsis>
                </Td>
                <Td  colspan={0} minWidth="50px" align="c">
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                    >
                        <Menu button={<Icon name="menu" />}>
                            {order.accountingStatus === "NONE" && (
                                <MenuItem onClick={() => updateAccountingStatus(order, "ORDERED")}>
                                    <Grid.Row>
                                        <Icon name="check"></Icon>&nbsp;Mark as Ordered
                                    </Grid.Row>
                                </MenuItem>
                            )}
                            {order.accountingStatus === "ORDERED" && (
                                <MenuItem onClick={() => updateAccountingStatus(order, "INVOICED")}>
                                    <Grid.Row>
                                        <Icon name="check-double"></Icon>&nbsp;Mark as Invoiced
                                    </Grid.Row>
                                </MenuItem>
                            )}
                            {order.accountingStatus === "INVOICED" && (
                                <MenuItem onClick={() => updateAccountingStatus(order, "PAID")}>
                                    <Grid.Row>
                                        <Icon name="money-pound-circle"></Icon>&nbsp;Mark as Paid
                                    </Grid.Row>
                                </MenuItem>
                            )}

                            <MenuItem onClick={() => createAndEditDuplicateOrder(order.id)}>
                                <Grid.Row>
                                    <Icon name="file-copy"></Icon>&nbsp;Duplicate
                                </Grid.Row>
                            </MenuItem>
                        </Menu>
                    </div>
                </Td>
            </Tr>
        </Link>
    );
};
