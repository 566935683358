import { fns } from "@holta/lib";
import { FormSelect } from "@holta/ui";
import { useStore } from "../../config/store";
import React from "react";
import { createFilter } from "react-select";

export const InputSupplierSelect = React.forwardRef(({
    value,
    valueFallbackLabel,
    onAddNew,
    filterIds,
    onChange,
    ...rest
}: {
    value?: string | null;
    valueFallbackLabel?: string;
    filterIds?: string[];
    onChange?: (value: string) => void;
} & Parameters<typeof FormSelect>[0], ref) => {
    let suppliers = useStore((state) => state.suppliers);
    if(filterIds && filterIds.length > 0) suppliers = suppliers.filter(supplier => filterIds.includes(supplier.id));
    const options = fns.shared.formSelect.createOptions("id", "name", suppliers);
    const matchedSupplier = value ? options.find((option) => option.value === value) : null;
    const valueMatch = matchedSupplier || null;

    return (
        <FormSelect
            options={options}
            value={valueMatch}
            filterOption={createFilter({
                "matchFrom": "start",
            })}
            onChange={(option) => onChange && onChange(option?.value)}
            ref={ref}
            {...rest}
        />
    ); 
});
