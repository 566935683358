import { fns, libTypes } from "@holta/lib";
import { Table, Thead, Tr, Th, Tbody, VirtualisedTable, Spacer } from "@holta/ui";

import {ProductsTableRow} from './ProductsRow';
import { useSupplierProductBrowserContext } from "../context";


export const ProductsTable = () => {
    const { filteredProducts } = useSupplierProductBrowserContext()

    return (
        <Spacer
            xs={[2, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={15}>
                <Thead>
                    <Tr>
                        <Th colspan={3} minWidth="50px" >
                            Code
                        </Th>
                        <Th colspan={4}>Description</Th>
                        <Th colspan={1} align="c">
                            H
                        </Th>
                        <Th colspan={1} align="c">
                            W
                        </Th>
                        <Th colspan={1} align="c">D</Th>
                        <Th colspan={4}>Spec</Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <VirtualisedTable Row={ProductsTableRow} items={filteredProducts} rowHeight={38} />
                </Tbody>
            </Table>
        </Spacer>
    );
};
