import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";



export const setCarcaseItemHeight = (height: number) => {
    
    const editOrder = getEditOrder();
    const mergedOrder = getMergedOrder();
    const mergedOrderItem = getMergedOrderItem();

    
    if (mergedOrderItem && fns.order_item.isCarcaseItem(mergedOrderItem)) {

        const currentOrderItem = mergedOrder.items.find((item) => item.id === mergedOrderItem.id) as libTypes.CarcaseOrderItem;

        if (currentOrderItem && currentOrderItem?.hEdited === height || currentOrderItem?.h === height) {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    (draft.newValues.orderItem as libTypes.EditedCarcaseOrderItem).hEdited = null;
                })
            });
        } else if (mergedOrderItem.h === height) {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    (draft.newValues.orderItem as libTypes.EditedCarcaseOrderItem).hEdited = null;
                })
            });
        } else {
            useStore.setState({
                editOrder: create(editOrder, draft => {
                    (draft.newValues.orderItem as libTypes.EditedCarcaseOrderItem).hEdited = fns.order_item.set.hEdited(height, mergedOrderItem.soldByM2);
                })
            });
            
        }
    }

};