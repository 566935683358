import { fns } from '@holta/lib';
import {useStore} from './useStore';

export const useFilteredProductComponents = () => {
    const {productComponents, ui} = useStore();
    let filteredProductComponents = [...productComponents];

    if(ui.productComponents.sortField === 'code'){
        filteredProductComponents = filteredProductComponents.sort((a, b) => a.code.localeCompare(b.code));
        if(ui.productComponents.sortDirection === 'DESC'){
            filteredProductComponents = filteredProductComponents.reverse();
        }
    }

    if(ui.productComponents.searchFilter){
        filteredProductComponents = fns.productComponents.filterByCode(filteredProductComponents, ui.productComponents.searchFilter);
    }
    return filteredProductComponents;

}
