import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormToggle,
    FormTextarea,
    FormNumber,
    FormDate,
} from "@holta/ui";
import React from "react";
import { useOrderContext } from "../context";

interface Props {
    children?: React.ReactNode;
   
}

export const ModalAddPayment: React.FC<Props> = () => {
    const { modalPayment } = useOrderContext();

    const id = "MODAL_PAYMENT";

    return (
        <Modal id={id} style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>Payment</ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Payment Label</FormLabel>
                                <FormInput
                                    value={modalPayment.payment.label || ""}
                                    onChange={(e) => modalPayment.setLabel(e.target.value)}
                                    style={{ width: "100%" }}
                                />
                                
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Payment Amount</FormLabel>
                                <Grid.Row>
                                <FormNumber
                                    value={modalPayment.payment.amount}
                                    onChange={(e) => modalPayment.setAmount(Number(e.target.value))}
                                    isFloat
                                    style={{ width: "100%" }}
                                />
                                <Spacer xs={[0.5]}/>
                                <button onClick={modalPayment.setToRemainingBalance}>Remaining&nbsp;Balance</button>
                                </Grid.Row>
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Payment Date</FormLabel>
                                <FormDate
                                    onChange={(d) =>
                                        d && !Array.isArray(d) && modalPayment.setDate(d.getTime())
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    selected={new Date(modalPayment.payment.date)}
                                    portalId="root-portal"
                                />
                            </Spacer>
                        </Island>
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {!modalPayment.isNew && (<button className="danger" onClick={() => modalPayment.handleDelete()}>Delete</button>)}
                    <Spacer xs={[0.5]} />
                    <button onClick={modalPayment.handleCancel}>Cancel</button>
                    <Spacer xs={[0.5]} />
                    <button className="primary" onClick={modalPayment.handleSave}>
                        Save
                    </button>
                </ModalFooter>
            </>
        </Modal>
    );
};
