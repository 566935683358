import { createAction } from "../fns/createAction";
import { fns, libTypes } from "@holta/lib";
import { LinkProduct} from "@holta/lib/types";
import { feathersApp } from "../config/feathers";

export const setLinkProducts = createAction("SET_LINK_PRODUCTS", (newState, linkProducts: LinkProduct[]) => {
    newState.linkProducts = fns.shared.sortByString(linkProducts, 'name', 'ASC');
});

export const saveLinkProduct = (linkProduct: libTypes.LinkProduct) => {
    try {
       return feathersApp.service("link-products").create(linkProduct);
    }
    catch (e) {
        throw e;
    }
}

export const updateLinkProduct = (linkProduct: libTypes.LinkProduct) => {
    try {
       return feathersApp.service("link-products").patch(linkProduct.id, linkProduct);
    }
    catch (e) {
        throw e;
    }
}




export const deleteLinkProduct = async (linkProduct: libTypes.LinkProduct) => {
    if(!linkProduct) throw new Error("Product Component is required");

    try {
        return await feathersApp.service('link-products').remove(linkProduct.id)
    } catch (error) {
        throw error;
    }
}