import styled from "styled-components";
import { Text, Menu, MenuItem, Icon } from "@holta/ui";

export const Badge = styled.div`
    position: absolute;
    top: ${(p) => p.theme.spacing(-0.25)};
    right: ${(p) => p.theme.spacing(-0.25)};
    border-radius: 1000px;
    font-size: 12px;
    background-color: ${(p) => p.theme.color.highlight};
    color: white;
    padding: ${(p) => p.theme.spacing(0.5)} ${(p) => p.theme.spacing(0.9)};
`;