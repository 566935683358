import {
    Container,
    FormValue,
    useModal,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSubHeader,
    Spacer,
    Grid,
    Tabs,
    Tab,
    Island,
    FormInput,
    Tag,
    Text,
    useDialog,
    FormLabel,
    FormTags,
    FormCheckbox,
    FormSelect,
    FormToggle,
    Notice,
    FormNotice,
    FormNumber,
    FormTextarea,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { useModalProductComponentContext } from "./context";
import { useStore } from "../../hooks/useStore";
import { fns, libTypes } from "@holta/lib";

interface Props {
    children?: React.ReactNode;
}

export const ModalProductComponent: React.FC<Props> = ({ children }) => {
    const { closeModal } = useModal();
    const categories = useStore((store) => store.categories);
    const suppliers = useStore((store) => store.suppliers);
    const { openGenericDialog } = useDialog();
    const [tab, setTab] = React.useState(0);

    const {
        productComponent,
        isEdited,
        isEditable,
        setIsEditable,
        isNew,
        handleDelete,
        cancel,
        confirm,
        validationResult,
        validate,
        setCode,
        setW,
        setH,
        setD,
        setCategories,
        setDescription,
        setPrice,
        setDiscount,
        setMargin,
        setSpecification,
        setSupplierId,
        setPriceType,
        setPriceExpression,
    } = useModalProductComponentContext();

    if (!productComponent) {
        return null;
    }
    return (
        <Modal id="MODAL_PRODUCT_COMPONENT" style={{ width: "600px" }} onDismiss={() => {}}>
            <>
                <ModalHeader>
                    <div>
                        <Grid.Row xs={{ align: "center" }}>Product Component</Grid.Row>
                    </div>
                    {/* TODO: Enable this feature */}
                    {false && !isNew && !isEdited && (
                        <div onClick={() => setIsEditable(!isEditable)}>
                            <Text size="s" color="base_contrast">
                                {isEditable ? "CANCEL EDITING" : "EDIT"}
                            </Text>
                        </div>
                    )}
                </ModalHeader>
                <ModalBody>
                    <Spacer xs={[2]}>
                        <Island>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Code</FormLabel>
                                {isEditable ? (
                                    <FormInput
                                        value={productComponent.code}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setCode(e.target.value)}
                                        onBlur={validate}
                                    />
                                ) : (
                                    <FormValue>{productComponent.code}</FormValue>
                                )}
                                {isEditable &&
                                    validationResult &&
                                    !validationResult.code._isValid && (
                                        <FormNotice>{validationResult.code._errors[0]}</FormNotice>
                                    )}
                            </Spacer>
                            <hr />
                            <Spacer xs={[1, 0]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 0]} style={{ width: "100%" }}>
                                            <FormLabel>Width</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.w || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>{
                                                        setW(parseFloat(e.target.value))
                                                    }
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {productComponent.w}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Height</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.h || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setH(parseFloat(e.target.value))
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {productComponent.h}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 0, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Depth</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.d || ""}
                                                    placeholder=""
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setD(parseFloat(e.target.value))
                                                    }
                                                />
                                            ) : (
                                                <FormValue style={{ width: "100%" }}>
                                                    {productComponent.d}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                </Grid.Row>
                            </Spacer>
                            <hr />
                            <Spacer xs={[1, 0]}>
                                <Grid.Row>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 0]} style={{ width: "100%" }}>
                                            <FormLabel>Price</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.price}
                                                    placeholder="0"
                                                    style={{ width: "100%" }}
                                                    isFloat
                                                    onChange={(e) =>
                                                        setPrice(parseFloat(e.target.value))
                                                    }
                                                    onBlur={validate}
                                                />
                                            ) : (
                                                <FormValue>
                                                    {productComponent.price}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 1, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Discount</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.discount}
                                                    placeholder="0"
                                                    isFloat
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setDiscount(parseFloat(e.target.value))
                                                    }
                                                    onBlur={validate}
                                                />
                                            ) : (
                                                <FormValue>
                                                    {productComponent.discount}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                    <Grid.Col xs={{ cols: 4 }}>
                                        <Spacer xs={[0, 0, 1, 1]} style={{ width: "100%" }}>
                                            <FormLabel>Margin</FormLabel>
                                            {isEditable ? (
                                                <FormNumber
                                                    value={productComponent.margin}
                                                    placeholder="0"
                                                    isFloat
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        setMargin(parseFloat(e.target.value))
                                                    }
                                                    onBlur={validate}
                                                />
                                            ) : (
                                                <FormValue>
                                                    {productComponent.margin}&nbsp;
                                                </FormValue>
                                            )}
                                        </Spacer>
                                    </Grid.Col>
                                </Grid.Row>
                                {(productComponent.discount || productComponent.margin) ? (<Notice color="accent4">Calculated price: £{fns.productComponent.getPrice(productComponent)}</Notice>) : null}
                            </Spacer>
                            <hr />
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Supplier</FormLabel>
                                <FormSelect
                                    options={suppliers.map((supplier) => ({
                                        label: supplier.name,
                                        value: supplier.id,
                                    }))}
                                    value={
                                        productComponent.supplierId
                                            ? {
                                                  label: suppliers.find(
                                                      (s) => s.id === productComponent.supplierId
                                                  )?.name,
                                                  value: productComponent.supplierId,
                                              }
                                            : null
                                    }
                                    onChange={(option: { label: string; value: string } | null) =>
                                        setSupplierId(option?.value || null)
                                    }
                                    isClearable
                                />
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Categories</FormLabel>
                                {isEditable ? (
                                    <FormSelect
                                        options={categories.map((category) => ({
                                            label: category.name,
                                            value: category.id,
                                        }))}
                                        value={
                                            productComponent.categories &&
                                            productComponent.categories.length > 0 &&
                                            productComponent.categories.map((id) => {
                                                const category = categories.find(
                                                    (c) => c.id === id
                                                );
                                                if (category)
                                                    return {
                                                        label: category.name,
                                                        value: category.id,
                                                    };
                                            })
                                        }
                                        isMulti={true}
                                        onChange={(options: { label: string; value: string }[]) => {
                                            setCategories(
                                                options && options.length > 0
                                                    ? options.map((option) => option.value)
                                                    : []
                                            );
                                        }}
                                    />
                                ) : (
                                    productComponent.categories &&
                                    productComponent.categories
                                        .filter((id) => categories.find((c) => c.id === id))
                                        .map((id) => {
                                            const category = categories.find((c) => c.id === id);
                                            return (
                                                <Tag type={5} size="l" key={category?.id}>
                                                    {category?.name}
                                                </Tag>
                                            );
                                        })
                                )}
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Description</FormLabel>
                                {isEditable ? (
                                    <FormTextarea
                                        value={productComponent.description}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                ) : (
                                    <FormValue>{productComponent.description}&nbsp;</FormValue>
                                )}
                            </Spacer>
                            <Spacer xs={[1, 0]}>
                                <FormLabel>Specification</FormLabel>
                                {isEditable ? (
                                    <FormTextarea
                                        value={productComponent.specification}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setSpecification(e.target.value)}
                                    />
                                ) : (
                                    <FormValue>{productComponent.specification}&nbsp;</FormValue>
                                )}
                            </Spacer>
                        </Island>

                        <Spacer xs={[1]}></Spacer>
                        {!isNew && isEditable && (
                            <Island>
                                <Spacer xs={[1, 0]}>
                                    <FormLabel>Delete Component</FormLabel>
                                    <button className="danger" onClick={handleDelete}>
                                        Delete
                                    </button>
                                </Spacer>
                            </Island>
                        )}
                    </Spacer>
                </ModalBody>
                <ModalFooter>
                    {(isEdited || isNew) && <button onClick={cancel}> {"Cancel"}</button>}
                    {!isEdited && !isNew && (
                        <button onClick={() => closeModal("MODAL_PRODUCT_COMPONENT")}> Ok</button>
                    )}
                    <Spacer xs={[0.5]} />
                    {isEdited && (
                        <button className="primary" onClick={confirm}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </>
        </Modal>
    );
};
