import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    HighlightedText,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Link } from "raviger";
import { fns, libTypes } from "@holta/lib";
import { useCustomersContext } from "../context";
import { useFilteredCustomers } from "../../../hooks/useFilteredCustomers";
import {handleCustomerTableHeadingClick} from "../../../actions/ui";
import { useStore } from "../../../config/store";
import { SortHighlight } from "../../../components/SortHighlight";
interface Props {
    
}

const Row = ({style, index} : {style:React.CSSProperties, index: number}) => {
    const highlightVal = useStore(store => store.ui.customers.searchFilter)
    const customers = useFilteredCustomers();

    return (
        <Link href={"customers/" + customers[index].id} key={customers[index].id}>
            <Tr style={style} hasHover>
                <Td colspan={5}>
                    <Text size="m"><HighlightedText text={customers[index].name} highlight={highlightVal}/></Text>
                </Td>
                <Td colspan={1}>
                    <Text size="m">{fns.shared.formatDate(customers[index].dateCreated)}</Text>
                </Td>
            </Tr>
        </Link>
    )
};

export const CustomersTable: React.FC<Props> = () => {

    const customers = useFilteredCustomers();
    const ui = useStore(store => store.ui)

    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={6}>
                <Thead>
                    <Tr>
                        <Th colspan={5} onClick={() => {handleCustomerTableHeadingClick('name');}}>
                            <SortHighlight direction={ui.customers.sortDirection} thisField="name" uiField={ui.customers.sortField}>Name</SortHighlight>
                        </Th>

                        <Th colspan={1} onClick={() => handleCustomerTableHeadingClick('dateCreated')}>
                            <SortHighlight direction={ui.customers.sortDirection} thisField="dateCreated" uiField={ui.customers.sortField}>Date Created</SortHighlight>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => (
                            <List height={height - 2} itemCount={customers.length} itemSize={46} width={width - 1} overscanCount={30}>
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </Tbody>
            </Table>
        </Spacer>
    );
};
