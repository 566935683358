import { libTypes } from "@holta/lib";
import {
    Container,
    Ellipsis,
    FormInput,
    FormLabel,
    FormTextarea,
    Grid,
    Icon,
    Island,
    IslandAlt,
    Menu,
    MenuItem,
    Modal,
    ModalBody,
    ModalHeader,
    NoResults,
    Spacer,
    Tab,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useModal,
} from "@holta/ui";
import { fns } from "@holta/lib";
import { MenuList } from "react-select/src/components/Menu";
import styled from "styled-components";
import { useMergedEditOrder } from "../hooks/useMergedEditOrder";
import { OrderItemsEditCarcaseRow } from "./OrderItemsEditCarcaseRow";
import { useStore } from "../../../config/store";
import editOrderActions from "../../../actions/editOrder";
import {useDialog} from "@holta/ui";
import { useDrag, useDrop } from 'react-dnd'
import React from 'react';
import { useOrderContext } from "../context";
import { OrderItemsSortDropArea } from "./OrderItemsSortDropArea";
import { OrderItemsEditSupplierItemRow } from "./OrderItemsEditSupplierItemRow";
export const OrderItemsLinkedDoorRow = ({
    item,
    index,
    parentIndex
}: {
    item: libTypes.LinkedDoorOrderItem;
    index: number;
    parentIndex: number;
}) => {
    const ref = React.useRef(null)
    const editOrder = useMergedEditOrder();
    const editItem = useStore((state) => state.editOrder?.newValues?.orderItem);
    const {openGenericDialog} = useDialog();
    const {dragOrderItems} = useOrderContext()

    const [collectedProps, drop] = useDrop(() => ({
        accept: 'orderItemRow',
        drop : (dragItem, monitor) => editOrderActions.setOrderItemsSortOrder((dragItem as any).id, item.id),
        hover : (dragItem, monitor) => {
            if(dragOrderItems.dragId !== item.id){
                dragOrderItems.setDragId(item.id)
            }
        }
    }))
    const [{ opacity }, drag] = useDrag(
        () => ({
            type: "orderItemRow",
            item: { id: item.id },
            collect: (monitor) => {
                return ({
                opacity: monitor.isDragging() ? 0.5 : 1,
            })},
            end: (item, monitor) => {
                dragOrderItems.setDragId('')
            },
        }),
        []
    );

    const borderTop = dragOrderItems.dragId ? "1px solid #C2C6D0" : "none";

    drag(drop(ref))
    function handleDelete() {
        openGenericDialog("Are you sure you want to remove this item?", "Delete Item", false, true, () => {editOrderActions.deleteOrderItem(item.id)})
    }

    if (!editOrder) return null;
    if (editItem && editItem.id === item.id && fns.order_item.isAnySupplierItem(item)) {
        return (
            <OrderItemsEditSupplierItemRow
                orderItem={
                    fns.order_item.merge(
                        item,
                        editItem as libTypes.EditedLinkedDoorOrderItem
                    ) as libTypes.EditedLinkedDoorOrderItem
                }
            />
        );
    } else
        return (
            <>
                <Tr ref={ref} hasHover onClick={() => editOrderActions.editOrderItem(item.id)} style={{borderTop}} isStriped={parentIndex % 2 === 0}>
                    <Td colspan={3}>
                        <Ellipsis>
                            <Text size="m" >{parentIndex}.{index}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={3}>
                        <Ellipsis>
                            <Text size="m" >{item.quantity}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={3}>
                        <Ellipsis>
                            
                        </Ellipsis>
                    </Td>
                    <Td colspan={8}>
                        <Ellipsis>
                            <Text size="m" color="highlight">{item.code}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={16}>
                        <Ellipsis>
                            <Text size="m">{item.description}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis tooltipText={item.notes}>
                            <Text size="m">{item.notes}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            
                                <Text size="m">{item.h}</Text>
                            
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            
                                <Text size="m">{item.w}</Text>
                            
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            
                                <Text size="m">{item.d}</Text>
                            
                        </Ellipsis>
                    </Td>
                    <Td colspan={8}>
                        <Ellipsis>
                            <Text size="m">{fns.shared.formatPrice(item.price)}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={4}>
                        <Ellipsis>
                            <Text size="m">{item.discount}</Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={6}>
                        <Ellipsis>
                            <Text size="m">
                                {fns.shared.formatPrice(fns.order_item.getOrderItemTotal(item))}
                            </Text>
                        </Ellipsis>
                    </Td>
                    <Td colspan={2} align="c">
                        <div onClick={e => {e.preventDefault(); e.stopPropagation();}}>
                        <Menu button={<Icon name="menu" />}>
                            <MenuItem onClick={handleDelete}>
                                <Grid.Row>
                                    <Icon name="delete-bin"></Icon>&nbsp;Delete
                                </Grid.Row>
                            </MenuItem>
                        </Menu>
                        </div>
                    </Td>
                </Tr>
                <OrderItemsSortDropArea id={item.id}/>
                </>
            
        );
};
