import { fns, libTypes } from "@holta/lib";
import { useStore } from "../config/store";

export const useFilteredOrders = () => {
    // Get the unfiltered orders
    const orders = useStore((state) => state.orders);
    const searchOrders = useStore((state) => state.ordersSearch);
    const filters = useStore((state) => state.ui.orders);

    const currentOrders = filters.searchFilter.length > 0 ? searchOrders : orders;

    // Filtering

    let filteredOrders = [...currentOrders];
    
    // If searching, ignore other filters
    if(filters.searchFilter.length === 0) {
        filteredOrders =
            filters.type === "ALL"
                ? filteredOrders
                : fns.shared.filterByString(filteredOrders, "orderStatus", filters.type);

        filteredOrders = fns.orders.filterByDateRange(
            filteredOrders,
            filters.dateType,
            filters.dateFrom,
            filters.dateTo
        );

        filteredOrders = filters.customerFilter ? fns.shared.filterByString(filteredOrders, "customerId", filters.customerFilter.value) : filteredOrders;

        filteredOrders = filters.accountingStatus === "ALL" ? filteredOrders : fns.shared.filterByString(filteredOrders, "accountingStatus", filters.accountingStatus);
    }

    // Sorting

    if (filters.sortField === "none") return fns.orders.sortByDate(filteredOrders, 'currentStatusDate', 'DESC');

    if (filters.sortField === "date") {
        let orderDateType: Parameters<typeof fns.orders.sortByDate>[1] = "currentStatusDate";
        switch (filters.dateType) {
            case "DELIVERY":
                orderDateType = "deliveryDate";
                break;
            case "PRODUCTION":
                orderDateType = "productionDate";
                break;
            case "MODIFIED":
                orderDateType = "editDate";
                break;
            case "CREATED":
                orderDateType = "dateCreated";
                break;
            case "ORDERED":
                orderDateType = "dateOrdered";
                break;
            case "INVOICED":
                orderDateType = "dateInvoiced";
                break;
            default:
                orderDateType = "currentStatusDate";
                break;
        }

        return fns.orders.sortByDate(filteredOrders, orderDateType, filters.sortDirection);
    }
    return fns.shared.sortByString(filteredOrders, filters.sortField, filters.sortDirection);
};
