import { fns, staticData } from "@holta/lib";
import { useStore } from "../../config/store";
import React from "react";
import { useMergedSupplier } from "./hooks/useMergedSupplierValues";

export const SupplierContext = React.createContext<{
    tab : number,
    setTab : (tab : number) => void,
    validation : ReturnType<typeof fns.supplier.validate.supplier> | null,
    validate : () => void,
} | undefined>(undefined);

export const useSupplierContext = () => {
    const context = React.useContext(SupplierContext);
    if (context === undefined) {
        throw new Error("useSupplierContext must be within the correct provider");
    }
    return context;
};

export const useSupplierContextProps = () => {


    const [validation, setValidation] = React.useState<ReturnType<typeof fns.supplier.validate.supplier> | null>(null)
    const supplier = useMergedSupplier();
    const suppliers = useStore(store => store.suppliers);

    function validate() {
        setValidation(fns.supplier.validate.supplier(supplier, suppliers.filter(c => c.id !== supplier?.id).map(c => c.name), Object.keys(staticData.taxGroups)));
    }

    const [tab, setTab] = React.useState(0);

    return {
        tab,
        setTab,
        validation, 
        validate
    }   
}