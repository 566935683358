import { fns, libTypes } from "@holta/lib";
import { useDialog, useModal } from "@holta/ui";
import { feathersApp } from "../../config/feathers";
import { deleteCategory } from "../../actions/categories";
import { useStore } from "../../config/store";
import React from "react";

export const ModalCategoryContext = React.createContext<
    | {
          category: libTypes.Category | null;
          isEdited: boolean;
          isNew: boolean;
          handleDelete: () => void;
          openModalCategory: (
              category: libTypes.Category,
              isNew: boolean,
              handleConfirm: (category: libTypes.Category) => void,
              handleCancel?: () => void
          ) => void;
          setName: (name: string) => void;
          setParentId: (id: string) => void;
          toggleCustomerRestricted: () => void;
          setCustomerWhitelist: (customers: string[]) => void;
          addToCustomerWhitelist: (customerId: string) => void;
          removeFromCustomerWhitelist: (customerId: string) => void;
          cancel: () => void;
          confirm: () => void;
          validate: () => void;
          validationResult: ReturnType<typeof fns.category.validate.category> | null;
      }
    | undefined
>(undefined);

export const useModalCategoryContext = () => {
    const context = React.useContext(ModalCategoryContext);
    if (context === undefined) {
        throw new Error("useModalCategoryContext must be within the correct provider");
    }
    return context;
};

export const ModalCategoryContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [category, setCategory] = React.useState<libTypes.Category | null>(null);
    const [isEdited, setIsEdited] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const { openModal, closeModal } = useModal();
    const { openGenericDialog } = useDialog();
    const [validationResult, setValidationResult] = React.useState<ReturnType<
        typeof fns.category.validate.category
    > | null>(null);
    const handleConfirmFn = React.useRef<(category: libTypes.Category) => void>();
    const handleCancelFn = React.useRef<() => void>();

    const validate = () => {
        setValidationResult(
            category &&
                fns.category.validate.category(
                    category,
                    categories.filter((c) => c.id !== category.id)
                )
        );
    };

    const categories = useStore((store) => store.categories);

    const setName = (name: string) => {
        category && setCategory(fns.category.set.name(category, name));
        setIsEdited(true);
    };

    const setParentId = (parentId: string) => {
        category && setCategory(fns.category.set.parentId(category, parentId));
        setIsEdited(true);
    };

    const toggleCustomerRestricted = () => {
        category &&
            setCategory(
                fns.category.set.customerRestricted(category, !category.customerRestricted)
            );
        setIsEdited(true);
    };

    const setCustomerWhitelist = (customers: string[]) => {
        category && setCategory(fns.category.set.customerWhitelist(category, customers));
        setIsEdited(true);
    };

    const addToCustomerWhitelist = (id: string) => {
        category && setCategory(fns.category.set.addToCustomerWhitelist(category, id));
        setIsEdited(true);
    };

    const removeFromCustomerWhitelist = (id: string) => {
        category && setCategory(fns.category.set.removeFromCustomerWhitelist(category, id));
        setIsEdited(true);
    };

    const handleDelete = () => {
        openGenericDialog(
            "Ths will remove this and any ancestor categories.",
            "Confirm Delete?",
            false,
            true,
            () => {
                deleteCategory(category)
                    .then(() => {
                        closeModal("MODAL_CATEGORY");
                    })
                    .catch(() => {
                        openGenericDialog("Error deleting category", "Error", false, true);
                    });
            }
        );
    };

    const wrappedOpenModal = (
        category: libTypes.Category,
        isNew: boolean,
        handleConfirm: (category: libTypes.Category) => void,
        handleCancel?: () => void
    ) => {
        setCategory(category);
        setIsEdited(false);
        setIsNew(isNew);
        openModal("MODAL_CATEGORY");
        handleConfirmFn.current = handleConfirm;
        handleCancelFn.current = handleCancel;
    };

    const cancel = () => {
        handleCancelFn.current && handleCancelFn.current();
        setCategory(null);
        setIsEdited(false);
        setIsNew(false);
        setValidationResult(null);
        closeModal("MODAL_CATEGORY");
    };

    const confirm = () => {
        if (!category) {
            closeModal("MODAL_CATEGORY");
            return;
        }
        if (!fns.category.validate.category(category, categories)._isValid) {
            openGenericDialog("Category is not valid, please check the input values");
        } else {
            handleConfirmFn.current && category && handleConfirmFn.current(category);
            closeModal("MODAL_CATEGORY");
        }
    };

    return (
        <ModalCategoryContext.Provider
            value={{
                category,
                isEdited,
                isNew,
                handleDelete,
                openModalCategory: wrappedOpenModal,
                setName,
                setParentId,
                toggleCustomerRestricted,
                setCustomerWhitelist,
                addToCustomerWhitelist,
                removeFromCustomerWhitelist,
                cancel,
                confirm,
                validate,
                validationResult,
            }}
        >
            {children}
        </ModalCategoryContext.Provider>
    );
};
