import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";
import { saveLinkedDoorOrderItem } from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";
import { saveEditOrderItem } from "./saveEditOrderItem";
import { createCarcaseItem } from "./createCarcaseItem";

export const saveAndAddNewCarcaseItem = (
    handleDuplicates: (
        linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][],
        cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void
    ) => void
) => {
    saveEditOrderItem(handleDuplicates);
    createCarcaseItem();
};


