import { fns, libTypes } from "@holta/lib";
import { useStore } from "../../../hooks/useStore";

export const useMergedAddresses = () : libTypes.Address[] => {
    const editedAddresses = useStore(state => state.editCustomer?.newValues.addresses);
    const existingAddresses = useStore(state => state.editCustomer?.currentValues.addresses);

    const merged : libTypes.Address[] = [];

    existingAddresses?.forEach(c => {
        if(!editedAddresses?.find(c2 => c2.id === c.id)) {
            merged.push(c);
        }
    });

    editedAddresses?.forEach(c => {
        if(!c._deleted) merged.push(c);
    })
    
    return merged;

}