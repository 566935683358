import {
    Container,
    FormInput,
    FormLabel,
    Modal,
    ModalBody,
    ModalHeader,
    Spacer,
    useModal,
    Notice,
    Icon,
    Grid,
    NoResults,
} from "@holta/ui";
import { Header } from "./parts/Header";
import { CustomersTable } from "./parts/CustomersTable";
import { CustomersContext, useContextValue, useCustomersContext } from "./context";
import React from "react";
import { FiltersNotificationBar } from "../../components/FiltersNotificationBar";
import { useCustomersFilterCount } from "../../hooks/useCustomersFilterCount";
import { useFilteredCustomers } from "../../hooks/useFilteredCustomers";
import { useAutoAnimate } from "../../hooks/useAutoAnimate";
import {clearAllCustomerFilters} from '../../actions/ui';

export const Customers = () => {
    const filtersAnimationParent = useAutoAnimate();
    const activeFilterCount = useCustomersFilterCount();
    const customers = useFilteredCustomers();

    return (
        <CustomersContext.Provider value={undefined}>
            <Container style={{ width: "100%", flex: 1, display: "flex", flexDirection: "column" }}>
                <div>
                    <Header />
                    <div ref={filtersAnimationParent}>
                        {activeFilterCount > 0 && (
                            <Spacer xs={[0, 2, 2, 2]}>
                                <FiltersNotificationBar filtersCount={activeFilterCount} handleClear={clearAllCustomerFilters} />
                            </Spacer>
                        )}
                    </div>
                </div>
                <div style={{ flex: 1, overflow: "auto" }}>
                    {customers.length > 0 ? (
                        <CustomersTable />
                    ) : (
                        <Spacer xs={[0, 2, 2, 2]}>
                            <NoResults text="No customers found." />
                        </Spacer>
                    )}
                </div>

                <Modal id="ORDERS_FILTER" sideBar>
                    <>
                        <ModalHeader>Apply Filters</ModalHeader>
                        <ModalBody>
                            <Spacer xs={[2]}>
                                <FormLabel>Quote Ref</FormLabel>
                                <FormInput />
                            </Spacer>
                        </ModalBody>
                    </>
                </Modal>
            </Container>
        </CustomersContext.Provider>
    );
};
