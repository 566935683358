import { navigate } from "raviger";
import { feathersApp } from "../../config/feathers";
import { getMergedOrder } from "./getMergedOrder";
import { clearAll } from "./clearAll";

export const deleteOrder = async () => {
    const order = getMergedOrder();
    await feathersApp.service("orders").remove(order.id);
    clearAll();
    navigate("/orders");
};