import {
    FormInput,
    FormLabel,
    Grid,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    Icon,
    NoResults,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Ellipsis,
    useModal,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { user } from "../../../fns/user";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";
import { useMergedRanges } from "../hooks/useMergedRanges";
import { saveRange } from "../../../actions/editSupplier";
import { useModalSupplierRangeContext } from "../../../components/ModalSupplierRange/context";
import { fns } from "@holta/lib";
interface Props {
    children?: React.ReactNode;
}

export const Ranges: React.FC<Props> = ({ children }) => {
    const { openModalSupplierRange } = useModalSupplierRangeContext();
    const supplier = useMergedSupplier();
    const ranges = useMergedRanges();

    if (!supplier || !user.id) {
        return null;
    }
    return (
        <div>
            <Spacer xs={[2]}>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Text size="xl">Ranges</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <button
                            className="primary"
                            onClick={() =>
                                openModalSupplierRange({...fns.supplierRange.create(supplier.id as string), _dbUnsaved: true}, true, saveRange)
                            }
                        >
                            Add Range
                        </button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1]}></Spacer>

                {ranges.length > 0 ? (
                    <Table cols={4}>
                        <Thead>
                            <Tr>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text size="s">Name</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text size="s">Prefix</Text>
                                    </Ellipsis>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ranges.map((range, index) => (
                                <Tr hasHover key={range.id} onClick={() => openModalSupplierRange(range, false, saveRange)}>
                                    <Td colspan={2}>
                                        <Ellipsis>
                                            <Text size="m">{range.name}</Text>
                                        </Ellipsis>
                                    </Td>
                                    <Td colspan={2}>
                                        <Ellipsis>
                                            <Text size="m">{range.prefix}</Text>
                                        </Ellipsis>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Spacer xs={[0, 2, 2, 2]}>
                        <NoResults text="No ranges." />
                    </Spacer>
                )}
            </Spacer>
        </div>
    );
};
