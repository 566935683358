import {useStore} from './useStore';

export const useSuppliersFilterCount = () : number => {
    const filters = useStore( store => store.ui.suppliers);

    let activeFilterCount = 0;
    if (filters.searchFilter) {
        activeFilterCount++;
    }

    return activeFilterCount;
}