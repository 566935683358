import { feathersApp } from '../config/feathers';
import { EditedAddress } from '../config/store';

export const saveAddressesToDb = async (addresses : EditedAddress[]) => {

    const newAddresses = addresses.filter((c) => c._dbUnsaved);
    const updatedAddresses = addresses.filter((c) => !c._dbUnsaved && !c._deleted);
    const deletedAddresses = addresses.filter((c) => c._deleted);

    if (newAddresses.length > 0)
        await feathersApp
            .service("addresses")
            .create(newAddresses.map(({ _dbUnsaved, ...rest }) => ({ ...rest })));
    if (updatedAddresses.length > 0)
        for (let index = 0; index < updatedAddresses.length; index++) {
            await feathersApp.service("addresses").update(updatedAddresses[index].id, updatedAddresses[index]);
        }
    if (deletedAddresses.length > 0)
        await feathersApp.service("addresses").remove(null, {query: {id: { $in : deletedAddresses.map(({ id }) => id)}}});

    return true;
}; 