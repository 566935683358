import { create } from "mutative";
import { useStore } from "../../config/store";
import { getEditOrder } from "./getEditOrder";
import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { getMergedOrderItem } from "./getMergedOrderItem";



export const saveLinkedDoorOrderItem = (linkedDoorOrderItem: libTypes.LinkedDoorOrderItem) => {

    const editOrder = getEditOrder();

    useStore.setState({
        editOrder: create(editOrder, draft => {
            draft.newValues.order.items = fns.order_items.combineEdits(editOrder.newValues.order.items || [], [linkedDoorOrderItem]);
        })
    });

};

