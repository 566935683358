import React from "react";
import styled from "styled-components";
import { Spacer, Grid, Text, FormSelect, FormLabel, FormDate, IconButton, useModal, FormInput } from "@holta/ui";
import { Link } from "raviger";
interface Props {}

export const Header: React.FC<Props> = () => {

    const {openModal} = useModal();
    
    const options = [
        { value: "all", label: "All" },
        { value: "quotes", label: "Quotes" },
        { value: "orders", label: "Orders" },
        { value: "invoices", label: "Invoices" },
    ];

    return (
        <Spacer xs={[2]}>
            <Grid.Row xs={{ justify: "space-between", align: "center" }}>
                <Grid.Col>
                    <Text size="xl">Products</Text>
                </Grid.Col>
            </Grid.Row>
        </Spacer>
    );
};
