import {
    FormInput,
    FormLabel,
    Grid,
    Spacer,
    Text,
    FormTextarea,
    FormSelect,
    Icon,
    NoResults,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Ellipsis,
    Tag,
    useModal,
} from "@holta/ui";
import React from "react";
import styled from "styled-components";
import { user } from "../../../fns/user";
import { useMergedSupplier } from "../hooks/useMergedSupplierValues";
import { useMergedBaseProducts } from "../hooks/useMergedBaseProducts";
import { saveBaseProduct } from "../../../actions/editSupplier";
import { fns } from "@holta/lib";
import { useModalSupplierBaseProductContext } from "../../../components/ModalSupplierBaseProduct/context";
import { useStore } from "../../../config/store";
import { SupplierBaseProduct } from "@holta/lib/types";
interface Props {
    children?: React.ReactNode;
}

export const BaseProducts: React.FC<Props> = ({ children }) => {
    const { openModalSupplierBaseProduct } = useModalSupplierBaseProductContext();
    const supplier = useMergedSupplier();
    const baseProducts = useMergedBaseProducts();
    const linkProducts = useStore((store) => store.linkProducts);
    const categories = useStore((store) => store.categories);

    if (!supplier || !user.id) {
        return null;
    }
    return (
        <div>
            <Spacer xs={[2]}>
                <Grid.Row xs={{ justify: "space-between" }}>
                    <Grid.Col>
                        <Text size="xl">Base Products</Text>
                    </Grid.Col>
                    <Grid.Col>
                        <button
                            className="primary"
                            onClick={() => {
                                openModalSupplierBaseProduct(
                                    {
                                        ...fns.supplierBaseProduct.create(supplier.id as string),
                                        _dbUnsaved: true,
                                    },
                                    true,
                                    saveBaseProduct
                                );
                            }}
                        >
                            Add Base Product
                        </button>
                    </Grid.Col>
                </Grid.Row>
                <Spacer xs={[1]}></Spacer>

                {baseProducts.length > 0 ? (
                    <Table cols={6}>
                        <Thead>
                            <Tr>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text size="s">Code</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text size="s">Product Link</Text>
                                    </Ellipsis>
                                </Th>
                                <Th colspan={2}>
                                    <Ellipsis>
                                        <Text size="s">Cateogries</Text>
                                    </Ellipsis>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {baseProducts.map((baseProduct, index) => (
                                <Row key={index} baseProduct={baseProduct} />
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Spacer xs={[0, 2, 2, 2]}>
                        <NoResults text="No base products." />
                    </Spacer>
                )}
            </Spacer>
        </div>
    );
};

const Row = ({ baseProduct }: { baseProduct: SupplierBaseProduct }) => {
    const { openModalSupplierBaseProduct } = useModalSupplierBaseProductContext();
    const supplier = useMergedSupplier();
    const baseProducts = useMergedBaseProducts();
    const linkProducts = useStore((store) => store.linkProducts);
    const categories = useStore((store) => store.categories);
    const baseProductCategories = fns.supplierBaseProduct.getCategories(baseProduct, linkProducts);
    const categoryNames = baseProductCategories.map((id) => fns.category.getHeirachicalName(categories.find((c) => c.id === id)!, categories));
    return (
        <Tr
            hasHover
            key={baseProduct.id}
            onClick={() => openModalSupplierBaseProduct(baseProduct, false, saveBaseProduct)}
        >
            <Td colspan={2}>
                <Ellipsis>
                    <Text size="m">{baseProduct.code}</Text>
                </Ellipsis>
            </Td>
            <Td colspan={2}>
                <Ellipsis>
                    <Text size="m">
                        {linkProducts.find((lp) => lp.id === baseProduct.linkProductId)?.name}
                    </Text>
                </Ellipsis>
            </Td>
            <Td colspan={2}>
                <Td colspan={2}>
                    <Ellipsis tooltipText={categoryNames.toString()}>
                        {categoryNames.map((name) => (
                            <Tag key={name} type={5}>
                                {name}
                            </Tag>
                        ))}
                    </Ellipsis>
                </Td>
            </Td>
        </Tr>
    );
};
