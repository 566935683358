import { fns, libTypes } from "@holta/lib";
import { getMergedOrder } from "./getMergedOrder";
import { useStore } from "../../config/store";
import { create } from "mutative";
import { getEditOrder } from "./getEditOrder";
import {saveLinkedDoorOrderItem} from "./saveLinkedDoorOrderItem";
import { saveMissingLinkedDoorOrderItem } from "./saveMissingLinkedDoorOrderItem";



export const createLinkedDoors = (handleDuplicates: ( linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[][][], cb: (linkedDoorOrderItems: libTypes.LinkedDoorOrderItem[]) => void ) => void ) =>
    
    {
        const mergedOrder = getMergedOrder();
        const editOrder = getEditOrder();
        const linkProducts = useStore.getState().linkProducts;

        const m = fns.order_items.getLinkedSupplierProducts(
            mergedOrder.items,
            editOrder.currentValues.supplierDoorRange,
            linkProducts,
            mergedOrder.constructionType || "LAYON",
            mergedOrder.supplierDiscount
        );

        for (let i = 0; i < m.length; i++) {

            m[i].matched.forEach((subitem) => saveLinkedDoorOrderItem(subitem));
            m[i].unmatched.forEach((subitem) => saveMissingLinkedDoorOrderItem(subitem));
        }

        
        if (m.some((item) => item.duplicates.length > 0)) {
            //alert("Duplicates items are not supported in bulk imports... yet.");
            
                handleDuplicates(m.filter(item => item.duplicates.length > 0).map(item => item.duplicates), (linkedDoorOrderItems) => {
                    linkedDoorOrderItems.forEach((linkedDoorOrderItem) => {
                        saveLinkedDoorOrderItem(linkedDoorOrderItem);
                    });
                });
                
        } 
        
        
        
    };