import {
    Table,
    Thead,
    Tbody,
    Th,
    Tr,
    Td,
    Spacer,
    FormCheckbox,
    Text,
    Ellipsis,
    Icon,
    NoResults,
    VirtualisedTable
} from "@holta/ui";
import { useFilteredOrders } from "../../../hooks/useFilteredOrders";
import { useStore } from "../../../hooks/useStore";
import React from "react";
import styled from "styled-components";
import { OrdersTableRow } from "./OrdersTableRow";
import { handleOrdersTableHeadingClick } from "../../../actions/ui";
import { SortHighlight } from "../../../components/SortHighlight";

interface Props {}

export const OrdersTable: React.FC<Props> = () => {
    const orders = useFilteredOrders();
    const searchOrders = useStore((store) => store.ordersSearch);
    const ui = useStore((store) => store.ui.orders);

    if ((ui.searchFilter.length > 0 && searchOrders.length === 0) || !orders || orders.length === 0)
        return <NoResults text="No orders found" />;

    return (
        <Spacer
            xs={[0, 2, 2, 2]}
            style={{ flex: 1, display: "flex", flexDirection: "column", height: "100%" }}
        >
            <Table cols={10}>
                <Thead>
                    <Tr>
                        <Th colspan={0} minWidth="50px" align="c">
                            Prod
                        </Th>
                        <Th colspan={4} onClick={() => handleOrdersTableHeadingClick("date")}>
                            <Ellipsis>
                            <SortHighlight
                                direction={ui.sortDirection}
                                uiField={ui.sortField}
                                thisField="date"
                            >
                                Date
                            </SortHighlight>
                            </Ellipsis>
                        </Th>
                        <Th colspan={1.5} align="c">
                            Type
                        </Th>
                        <Th colspan={1.5} align="c">
                            Acc
                        </Th>
                        <Th colspan={5} onClick={() => handleOrdersTableHeadingClick("quoteRef")}>
                            <SortHighlight
                                direction={ui.sortDirection}
                                uiField={ui.sortField}
                                thisField="quoteRef"
                            >
                                Quote Ref.
                            </SortHighlight>
                        </Th>
                        <Th colspan={5} onClick={() => handleOrdersTableHeadingClick("orderRef")}>
                            <SortHighlight
                                direction={ui.sortDirection}
                                uiField={ui.sortField}
                                thisField="orderRef"
                            >
                                Order Ref.
                            </SortHighlight>
                        </Th>
                        <Th colspan={6}>Customer</Th>
                        <Th
                            colspan={6}
                            onClick={() => handleOrdersTableHeadingClick("customerRef")}
                        >
                            <SortHighlight
                                direction={ui.sortDirection}
                                uiField={ui.sortField}
                                thisField="customerRef"
                            >
                                Customer Ref.
                            </SortHighlight>
                        </Th>
                        <Th colspan={4}>Value</Th>
                        <Th colspan={2} align="c">
                            P.Wk
                        </Th>
                        <Th colspan={4}>Del Date</Th>
                        <Th colspan={0} minWidth="50px"></Th>
                    </Tr>
                </Thead>
                <Tbody style={{ flex: 1 }}>
                    <VirtualisedTable Row={OrdersTableRow} items={orders} />
                </Tbody>
            </Table>
        </Spacer>
    );
};
